import { type IDateFormats } from 'app/shared/types/common'
import { format, parse } from 'date-fns'
import moment from 'moment'

/**
 * Pega uma string e retorna uma string sanitizada apenas com números
 * @param {string} value - string -&gt; string que quer sanitizar
 */
export const sanitizeValueFromInput = (value: string): string => {
  return numberMask(value).toString()
}

/**
 * Pega uma string e retorna no formato de CPF
 * @param {string} cpf - string -&gt; string que quer formatar
 */
export const cpfMask = (cpf: string | number): string => {
  return sanitizeValueFromInput(`${cpf}`)
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

/**
 * Pega uma string e retorna no formato de CNPJ
 * @param {string} cnpj - string -&gt; string que quer formatar
 */
export const cnpjMask = (cnpj: string | number): string => {
  return sanitizeValueFromInput(`${cnpj}`)
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

/**
 * Pega uma string e retorna no formato de CPF ou CNPJ
 * @param {string} cpfCnpj - string -&gt; string que quer formatar
 */
export const cpfCnpjMask = (cpfCnpj: string | number): string => {
  const value = sanitizeValueFromInput(`${cpfCnpj}`)
  return value.length <= 11 ? cpfMask(value) : cnpjMask(value)
}

export const hideCpfMask = (cpf: string | number): string => {
  const value = sanitizeValueFromInput(`${cpf}`).replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '***.$2.$3-**')
  return value
}

export const hideCnpjMask = (cnpj: string | number): string => {
  const value = sanitizeValueFromInput(`${cnpj}`).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '**.***.$3/$4-**')
  return value
}

export const hideCpfCnpjMask = (cpfCnpj: string | number): string => {
  const value = sanitizeValueFromInput(`${cpfCnpj}`)
  return value.length <= 11 ? hideCpfMask(value) : hideCnpjMask(value)
}

/**
 * Pega uma string e retorna no formato de celular
 * @param {string} cellphone - string -&gt; string que quer formatar
 */
export const cellphoneMask = (cellphone: string | number): string => {
  return sanitizeValueFromInput(`${cellphone}`)
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1')
}

/**
 * Pega uma string e retorna no formato de CEP
 * @param {string} cep - string -&gt; string que quer formatar
 */
export const cepMask = (cep: string | number): string => {
  return sanitizeValueFromInput(`${cep}`)
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1')
}

/**
 * Pega uma string e retorna apenas os números
 * @param {string} string - string -&gt; string que quer formatar
 */
export const numberMask = (
  string: string,
  returnTypeNumber: boolean = false
): number | string => {
  const onlyNumber = `${string}`.replace(/\D+/g, '')
  return returnTypeNumber ? parseFloat(onlyNumber) : onlyNumber
}

// Mascaras válidas apenas para inputs

/**
 * Pega uma string no formato yyyy-mm-dd e retorna uma string no formato dd/mm/yyyy
 * @param {string} date - string -&gt; data que quer aplicar a mascara
 */
export const dateBrMask = (date: string | number): string => {
  return sanitizeValueFromInput(`${date}`)
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{4})\d+?$/, '$1')
}

export const dateBrFormat = (date: string | number, formated: string = 'dd/MM/yyyy', useSeconds: boolean = false): string => {
  const dataObj = parse(date as string, useSeconds ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd', new Date())
  return format(dataObj, formated)
}

/**
 * Pega uma string no formato dd/mm/yyyy e retorna uma string no formato yyyy-mm-dd
 * @param {string} date - string -&gt; data que quer aplicar a mascara
 */
export const dateUsMask = (date: string): string => {
  return sanitizeValueFromInput(`${date}`)
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{2})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

// Mascaras de valores puros não considerando inputs

/**
 * Pega uma string e retorna uma string com a mascara de data
 * @param {string} date - string -&gt; data que quer aplicar a mascara
 */
export const dateUsBrMask = (
  date: string,
  dateTime: boolean = false
): string => {
  const splitDate = date.match(/-/) == null ? '/' : '-'

  let dateSplited = date as any
  let dateTimeString = ''
  let stringMasked = ''

  dateSplited = dateSplited.split(' ')

  if (dateTime) {
    dateTimeString = `${dateSplited[1]}`
  }
  dateSplited = `${dateSplited[0]}`

  dateSplited = dateSplited.split(splitDate).reverse()

  if (splitDate === '/') {
    stringMasked = dateSplited.join('-')
  } else {
    stringMasked = dateSplited.join('/')
  }

  return stringMasked + (dateTime ? ` ${dateTimeString}` : '')
}

/**
 * Pega uma string ou number e retorna uma string com a mascara de moeda brasileira
 * @param {string | number} float - string | number -> valor que quer aplicar a mascara
 */
export const floatBrMask = (
  float: string | number,
  options: Intl.NumberFormatOptions = {}
): string => {
  float = parseFloat(`${float}`)

  const defaultOption = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  }

  if (options?.minimumFractionDigits && !options?.maximumFractionDigits) {
    options.maximumFractionDigits = options.minimumFractionDigits
  }

  return float.toLocaleString('pt-br', {
    ...defaultOption,
    ...options
  })
}

/**
 * Pega uma string, remove o ponto decimal e substitui a virgula por ponto decimal
 * @param {string | number} floatBr
 */
export const unmaskFloatBrNumber = (floatBr: string): number => {
  return parseFloat(floatBr.replace(/\./g, '').replace(',', '.'))
}

/**
 * Pega uma string ou number e retorna uma string com a mascara de aliquota
 * @param {string | number} aliquota - string | number -&gt; valor que quer aplicar a mascara
 *
 * @example
 * aliquotaMask(0.0001) // 0,0001
*/
export const aliquotaMask = (aliquota: string | number): string => {
  const number = parseFloat(aliquota.toString())
  if (isNaN(number)) return '0'
  return floatBrMask(number, {
    maximumFractionDigits: 4,
    minimumFractionDigits: 4
  })
}

/**
 * Pega uma string, passa para um padrão de mascara e retorna uma string com a mascara
 * @param {string} value - string -&gt; valor que quer aplicar a mascara
 * @param {string} pattern - string -&gt; padrão da mascara
 * @example
 * maskJs('12345678901', '###.###.###-##') // 123.456.789-01
 * maskJs('12345678901', '##.###.###/####-##') // 12.345.678/9012-34
*/
export function maskJs (value: string, pattern: string): string {
  let i = 0
  const v = value.toString()
  return pattern.replace(/#/g, () => v[i++] || '')
};

/**
 * Pega uma string e retorna uma string com a mascara de CNAE
 * @param {string} cnae - string -&gt; valor que quer aplicar a mascara
 * @example
 * cnaeMask('1234567') // 1234-5/67
*/
export const cnaeMask = (cnae: string): string => {
  cnae = cnae.toString().padEnd(7, '0')
  return maskJs(`${cnae ?? ''}`, '####-#/##')
}

/**
 * Pega uma string e retorna uma string com a mascara de CNAE
 * @param {string} cnae - string -&gt; valor que quer aplicar a mascara
 * @example
 * cnaeMask('1234567') // 1234-5/67
*/
export const cnaeMaskDigitavel = (cnae: string): string => {
  return sanitizeValueFromInput(`${cnae}`)
    .replace(/(\d{4})(\d)/, '$1/$2')
    .replace(/(\/\d{1})(\d)/, '$1-$2')
    .replace(/(\/\d{1})(\d)/, '$1/$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const mesAno = (data: string): string => {
  return sanitizeValueFromInput(data).replace(/^(\d{2})(\d{4})\d*$/, '$1/$2')
}

export const ano = (data: string): string => {
  return sanitizeValueFromInput(data).replace(/^(\d{4})\d+?$/, '$1')
}

export const servicoMask = (servico: string): string => {
  servico = servico.toString().padStart(4, '0')
  return maskJs(`${servico ?? ''}`, '##.##')
}

export const servicoMaskDigitavel = (servico: string): string => {
  return sanitizeValueFromInput(`${servico}`)
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export function formatDate (dateString: string, format: IDateFormats = 'MM/DD/YYYY', typeDate: IDateFormats = 'YYYY-MM-DD'): string {
  const date = moment(dateString, typeDate)
  return date.format(`${format}`)
}

export function isYearGreaterThanCurrentYear (date: string): boolean {
  const year = parseInt(moment(date).format('YYYY'))
  const currentYear = parseInt(moment().format('YYYY'))

  return year > currentYear
}

export function isMonthAndYearGreaterThanCurrentMonthAndYear (date: string): boolean {
  const momentDate = moment(date)
  const month = parseInt(momentDate.format('MM'))
  const year = parseInt(momentDate.format('YYYY'))
  const currentMonth = parseInt(moment().format('MM'))
  const currentYear = parseInt(moment().format('YYYY'))

  return year > currentYear || (year === currentYear && month > currentMonth)
}

export const maskFloatInput = (value: number | string): string => {
  value = sanitizeValueFromInput(`${value}`)
  const numericValue: number = typeof value === 'string' ? parseFloat(value) : value

  if (!isNaN(numericValue)) {
    // Convert the numeric value to a string
    const stringValue: string = numericValue.toFixed(2)

    // Use a regular expression to apply the mask pattern
    const formattedValue: string = stringValue.replace(
      /^(\d{0,2})(\d{0,3})(\d{2})$/,
      (match, part1, part2, part3) => {
        return `${part1 || '00'}.${part2 || '000'},${part3}`
      }
    )

    return formattedValue
  } else {
    return `${value}`
  }
}

export const moneyMask = (
  value: number | string,
  options: {
    decimalPlaces: number
  } = {
    decimalPlaces: 2
  }): string => {
  const formatNumber = (value: string): string => {
    return new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: options.decimalPlaces
    })
      .format(
        parseFloat(value) / 10 ** options.decimalPlaces
      )
  }

  value = numberMask(`${value}`).toString()

  if (value === '' || value === null || value === undefined) {
    return moneyMask(0)
  }

  return formatNumber(value)
}

export const inscricaoMunicipalMask = (value: string | number): string => {
  return cpfMask(`${value}`)
}

export const inscricaoEstadualMask = (value: string): string => {
  const acceptOnlyLettersAndNumbers = (value: string): string => {
    return value.replace(/[^a-zA-Z0-9]/g, '')
  }

  const chars = acceptOnlyLettersAndNumbers(value).split('')

  if (chars.length > 14) {
    return inscricaoEstadualMask(chars.slice(0, 14).join(''))
  }

  for (let i = 3; i < chars.length; i += 4) {
    chars.splice(i, 0, '.')
  }

  return chars.join('')
}
