/* eslint-disable @typescript-eslint/explicit-function-return-type */
// import { serializeQueryParams } from 'app/shared/helpers/utils'
// import { useSwr } from 'app/shared/hooks/useSwr'
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { apiProcessos } from 'app/shared/services/api'
import { type IRequestPaginated, type IDefaultRequest } from 'app/shared/types/common'
import { type AxiosResponse } from 'axios'
import { type KeyedMutator } from 'swr'
// import { type KeyedMutator } from 'swr'

export interface INewsLetterType {
  INFID: number
  INFTITULO: string
  INFCONTEUDO: string
  INFDATAPUBLICACAO: string
  AUTOR: IAuthor
  STATUS: IStatus
  ARQUIVO: IFiles
}

interface IAuthor {
  USUUID: string
  USUNOME: string
}

interface IStatus {
  id: number
  name: string
}

interface IFiles {
  INFARQID: number
  INFID: number
  INFARQNOME: string
  INFARQCAMINHO: string
}

const endpointNewsLetter = '/informativo'

const GetNewsLetter = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IRequestPaginated<INewsLetterType>>(
    `${endpointNewsLetter}/list-all?${queryParams}`,
    apiProcessos
  )
}

const createNewsLetter = async (
  payload: {
    titulo: string
    conteudo: string
    dataPublicacao: string
    arquivo: File
    autorId: string
  }
) => {
  const formData = new FormData()

  formData.append('titulo', payload.titulo)
  formData.append('conteudo', payload.conteudo)
  formData.append('dataPublicacao', payload.dataPublicacao)
  formData.append('arquivo', payload.arquivo)
  formData.append('autorId', payload.autorId)

  const response = await apiProcessos.post(`${endpointNewsLetter}/create`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })

  return response
}

const editStatusNewsLetter = async (id: number, status: number, mutate: KeyedMutator<any>) => {
  const response = await apiProcessos.patch(`${endpointNewsLetter}/alter-status/${id}`, { status })
  if (response.status === 200) await mutate()
  return response
}

const editNewsLetter = async (
  payload: {
    id: number
    titulo: string
    conteudo: string
    dataPublicacao: string
    arquivo?: File
    autorId: string
  }
): Promise<AxiosResponse<IDefaultRequest<any>>> => {
  const formData = new FormData()

  formData.append('titulo', payload.titulo)
  formData.append('conteudo', payload.conteudo)
  formData.append('dataPublicacao', payload.dataPublicacao)
  if (payload?.arquivo) {
    formData.append('arquivo', payload.arquivo)
  }
  formData.append('autorId', payload.autorId)

  const response = await apiProcessos.post(
    `${endpointNewsLetter}/update/${payload.id}`,
    formData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )

  return response
}

const getNewsLetterById = async (id: number) => {
  return await apiProcessos.get<IDefaultRequest<INewsLetterType>>(`${endpointNewsLetter}/id/${id}`)
}

export default {
  GetNewsLetter,
  getNewsLetterById,
  createNewsLetter,
  editStatusNewsLetter,
  editNewsLetter
}
