import { Spinner } from 'react-bootstrap'

interface IButtonMetronic {
  variant: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
  rounded?: boolean
  light?: boolean
  type: 'submit' | 'reset' | 'button'
  className?: string
  isLoading?: boolean
  disabled?: boolean
  children: React.ReactNode
  onClick?: () => void
}

const ButtonMetronic = ({ variant, rounded, light, type, className = '', isLoading, disabled, children, onClick }: IButtonMetronic): JSX.Element => {
  const isDisabled = disabled === true || isLoading === true

  return (
    <button
      type={type}
      disabled={isDisabled}
      className={`${className} d-flex align-items-center justify-content-center gap-2 btn btn-${light ? 'light-' : ''}${variant} ${rounded ? 'rounded-pill' : ''}`}
      onClick={onClick}
    >
      {children}
      {isLoading &&
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      }
    </button>
  )
}

export default ButtonMetronic
