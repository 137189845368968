/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { type IDefaultRequest } from 'app/shared/types/common'
import { type ICreateModule, type IModules } from 'app/shared/types/plataform/modules'
import { type KeyedMutator } from 'swr'

import { apiServerPlataforma } from '../api'

const endpointModule = '/modulo'

const GetModules = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IModules>>(
    `${endpointModule}/list-all?${queryParams}`,
    apiServerPlataforma
  )
}

const createModule = async (
  payload: ICreateModule,
  mutate: KeyedMutator<IDefaultRequest<IModules>>
) => {
  const response = await apiServerPlataforma.post(`${endpointModule}/create`, payload)
  if (response.status === 201) void mutate()
  return response
}

const deleteModule = async (
  id: number,
  mutate: KeyedMutator<IDefaultRequest<IModules>>
) => {
  const response = await apiServerPlataforma.delete(`${endpointModule}/delete/${id}`)
  if (response.status === 204) void mutate()
  return response
}

const updateModule = async (
  id: number,
  payload: ICreateModule,
  mutate: KeyedMutator<IDefaultRequest<IModules>>
) => {
  const response = await apiServerPlataforma.put(`${endpointModule}/update/${id}`, payload)
  if (response.status === 200) void mutate()
  return response
}

export default {
  GetModules,
  createModule,
  deleteModule,
  updateModule
}
