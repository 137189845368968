/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { type IDefaultRequest } from 'app/shared/types/common'
import { type AxiosResponse } from 'axios'
import { type KeyedMutator } from 'swr'

import { apiProcessos } from '../api'

export interface ICategoryType {
  CATID: number
  CATDESCRICAO: string
  CATSTATUS: boolean
}

export interface ICategoryRequest {
  descricao: string
  status: number
}

const endpointCategory = '/categoria'

const GetCategories = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<ICategoryType>>(
    `${endpointCategory}/list-all?${queryParams}`,
    apiProcessos
  )
}

const createCategoryy = async (
  payload: { descricao: string, status: number },
  mutate: KeyedMutator<IDefaultRequest<ICategoryType>>
) => {
  const response = await apiProcessos.post(`${endpointCategory}/create`, payload)
  if (response.status === 201) await mutate()
  return response
}

const updateCategory = async (
  id: number,
  payload: { descricao: string, status: number },
  mutate: KeyedMutator<IDefaultRequest<ICategoryType>>
) => {
  const response = await apiProcessos.put(`${endpointCategory}/update/${id}`, payload)
  if (response.status === 200) await mutate()
  return response
}

export const getCategories = async (): Promise<AxiosResponse<IDefaultRequest<ICategoryType>>> => {
  return await apiProcessos.get('categoria/list-all')
}

export const editCategory = async (id: number, dataEdit: ICategoryRequest): Promise<AxiosResponse<IDefaultRequest<any>>> => {
  return await apiProcessos.put(`categoria/update/${id}`, dataEdit)
}

export const createCategory = async (dataEdit: ICategoryRequest): Promise<AxiosResponse<IDefaultRequest<any>>> => {
  return await apiProcessos.post('categoria/create', dataEdit)
}

export default {
  GetCategories,
  createCategoryy,
  updateCategory,
  getCategories,
  editCategory,
  createCategory
}
