/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { boolToInt, serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { apiProcessos } from 'app/shared/services/api'
import { type IDefaultRequestNotPaginated, type IDefaultRequest } from 'app/shared/types/common'
import { type IPermissionScreen, type IGroupsCargos, type IScreenPermissions, type IPermissionsUsers, type IOriginGroup, type IPersonRole } from 'app/shared/types/processos/screenPermissions/screenPermissions'

const endpoint = 'permissoes'

const GetScreens = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IScreenPermissions>>(
    `/telas/list-all?${queryParams}`,
    apiProcessos
  )
}

const GetGroupRolesScreens = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })

  return useSwr<IDefaultRequestNotPaginated<IOriginGroup>>(
    `${endpoint}/grupo/list-all?${queryParams}`,
    apiProcessos
  )
}

const GetPersonRolesScreens = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })

  return useSwr<IDefaultRequest<IPersonRole>>(
    `${endpoint}/pessoa/list-all?${queryParams}`,
    apiProcessos
  )
}

const getScreens = async (params: object = {}) => {
  return await apiProcessos.get<IDefaultRequest<IScreenPermissions>>('/telas/list-all', { params })
}

const GetGroupsCargos = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IGroupsCargos>>(
    `/grupo/list-all?${queryParams}`,
    apiProcessos
  )
}

const getGroupsCargos = async (params: object = {}) => {
  return await apiProcessos.get<IDefaultRequest<IGroupsCargos>>('/grupo/list-all', { params })
}

const createScreenGroups = async (
  payload: { telaid: number[], grpid: number }
) => {
  return await apiProcessos.put('/telas-grupo/update', payload)
}

const updateRoleScreenPermissions = async (
  payload: { idTelas: number[], cargoId: number }
) => {
  return await apiProcessos.put<IDefaultRequest<IPermissionScreen>>(`${endpoint}/cargo/update/${payload.cargoId}`, {
    idTelas: payload.idTelas
  })
}

const updateRoleToGroupScreenPermissions = async (
  payload: { cargoId: number, grupoId: number }
) => {
  return await apiProcessos.put<IDefaultRequest<IPermissionScreen>>(
    `${endpoint}/grupo/update/${payload.grupoId}`, {
      cargoId: payload.cargoId
    }
  )
}

const addRoleToPersonScreenPermissions = async (
  payload: { cargoId: number, pessoaId: number }
) => {
  return await apiProcessos.put<IDefaultRequest<IPermissionScreen>>(
    `${endpoint}/pessoa/update/${payload.pessoaId}`, {
      cargoId: payload.cargoId
    }
  )
}

const createRoleToPersonScreenPermissions = async (
  payload: { cargoId: number, pessoaId: number, status: boolean }
) => {
  return await apiProcessos.post<IDefaultRequest<IPermissionScreen>>(
    `${endpoint}/pessoa/create/${payload.pessoaId}`, {
      cargoId: payload.cargoId,
      status: boolToInt(payload.status)
    }
  )
}

const updateRoleToPersonScreenPermissions = async (
  payload: { cargoId: number, pessoaId: number, status: boolean }
) => {
  return await apiProcessos.put<IDefaultRequest<IPermissionScreen>>(
    `${endpoint}/pessoa/update/${payload.pessoaId}`, {
      cargoId: payload.cargoId,
      status: boolToInt(payload.status)
    }
  )
}

const getRoleScreenPermissions = async (id: number) => {
  return await apiProcessos.get<IDefaultRequest<IPermissionScreen>>(`${endpoint}/cargo/id/${id}`)
}

const getPermissionsFromUser = async () => {
  return await apiProcessos.get<IDefaultRequest<IPermissionsUsers>>(`${endpoint}`)
}

export default {
  GetScreens,
  GetGroupsCargos,
  getScreens,
  getGroupsCargos,
  createScreenGroups,
  updateRoleScreenPermissions,
  updateRoleToGroupScreenPermissions,
  createRoleToPersonScreenPermissions,
  updateRoleToPersonScreenPermissions,
  addRoleToPersonScreenPermissions,
  getRoleScreenPermissions,
  getPermissionsFromUser,
  GetGroupRolesScreens,
  GetPersonRolesScreens
}
