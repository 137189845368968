import { useEffect } from 'react'
import { Navigate, Routes } from 'react-router-dom'

import { useAuth } from './core/Auth'
import { removeAuth } from './core/AuthHelpers'

export function Logout (): JSX.Element {
  const { logout } = useAuth()
  useEffect(() => {
    removeAuth()
    logout()
    document.location.reload()
  }, [logout])

  return (
    <Routes>
      <Navigate to='/auth/login' />
    </Routes>
  )
}
