import Toastfy from 'app/shared/helpers/Toastfy/Toastfy'
import { format } from 'date-fns'

import { type AuthModel } from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'auth_data'
const AUTH_MAXIMUM_TIME_KEY = 'tempoRestante' // Tempo em segundos (30 minutos)

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)

    Toastfy.error('Erro ao obter autenticação')
  }
}

const setAuth = (auth: AuthModel): void => {
  if (!localStorage) {
    return
  }

  const dataFormatada = format(new Date(), 'yyyy-MM-dd HH:mm:ss')
  const storedTime = JSON.parse(localStorage.getItem('tempoRestante') as string)

  try {
    const lsValue = JSON.stringify(auth)
    if (storedTime) {
      localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
      localStorage.setItem(AUTH_MAXIMUM_TIME_KEY, JSON.stringify(
        { tempoRestante: storedTime.tempoRestante, data: storedTime.data, firstRender: storedTime.firstRender }
      ))
    } else {
      localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
      localStorage.setItem(AUTH_MAXIMUM_TIME_KEY, JSON.stringify({ tempoRestante: 1800, data: dataFormatada, firstRender: false }))
    }
  } catch (error) {
    Toastfy.error('Erro ao salvar autenticação')
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = (): void => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
    localStorage.removeItem('tempoRestante')
  } catch (error) {
    Toastfy.error('Erro ao remover autenticação')

    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios (axios: any): void {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = getAuth()
      if (auth?.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }

      return config
    },
    async (err: any) => await Promise.reject(err)
  )
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }
