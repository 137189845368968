/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { systemMessages } from '_metronic/i18n/systemMessages'
import { yupResolver } from '@hookform/resolvers/yup'
import ButtonMetronic from 'app/shared/components/Button/ButtonMetronic'
import { getInternalizeText } from 'app/shared/helpers/utils'
import * as Yup from 'yup'

import { type ILoggedUser } from '../../core/loggedUsers/loggedUserInterfaces'

const schemaConfirmChangeData = Yup.object().shape({
  senha: Yup.string().required(getInternalizeText(systemMessages.keysMessages.AuthValidationRequired, { name: 'Senha' }))
})
type FormDataChangeData = Yup.InferType<typeof schemaConfirmChangeData>

interface IConfirmChangeData {
  setConfirmPassword: React.Dispatch<React.SetStateAction<string>>
  onSubmitForm: SubmitHandler<ILoggedUser>
  values: any
  onClose: () => void
}

const ConfirmChangeData = ({ setConfirmPassword, onSubmitForm, values, onClose }: IConfirmChangeData): JSX.Element => {
  const [requestEndpoint, setRequestEndpoint] = useState<boolean>(false)
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormDataChangeData>({
    defaultValues: { senha: '' },
    resolver: yupResolver(schemaConfirmChangeData)
  })

  const onSubmit: SubmitHandler<FormDataChangeData> = async (data) => {
    setRequestEndpoint(true)
    try {
      setConfirmPassword(data.senha)
      await onSubmitForm({ ...values })
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? 'Erro ao atualizar! Tente novamente.')
    } finally {
      setRequestEndpoint(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='row mb-4'>
        <div className='col-md-12'>
          <label className='form-label fs-6 fw-bolder text-dark required'>Senha Atual</label>
          <input
            {...register('senha')}
            type='password'
            placeholder='Insira sua senha atual'
            className='form-control form-control-solid'
            onChange={(event) => { setValue('senha', event.target.value, { shouldValidate: true }); setConfirmPassword(event.target.value) }}
          />
          <div className='fv-plugins-message-container'>
            {errors.senha && <span role='alert'>{errors.senha.message as string}</span>}
          </div>
        </div>
      </div>

      <div className='d-flex gap-3 justify-content-end mt-15'>
        <ButtonMetronic type='button' variant='danger' rounded onClick={onClose}>Fechar</ButtonMetronic>
        <ButtonMetronic type='submit' variant='primary' rounded className='bg-blue-primary'>
          Confirmar {requestEndpoint && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
        </ButtonMetronic>
      </div>
    </form>
  )
}

export default ConfirmChangeData
