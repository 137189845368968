import { systemMessages } from '_metronic/i18n/systemMessages'
import { getInternalizeText } from 'app/shared/helpers/utils'
import { validateCpf } from 'app/shared/helpers/validateValues'
import { validateEmail } from 'app/shared/helpers/yupValidations'
import * as Yup from 'yup'

export const schemaStepperDataPersonal = Yup.object().shape({
  cpf: Yup.string()
    .required(getInternalizeText(systemMessages.keysMessages.AuthValidationRequired, { name: 'CPF' }))
    .max(14, getInternalizeText(systemMessages.keysMessages.MaxLength, { name: 'CPF', max: 14 }))
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, getInternalizeText(systemMessages.keysMessages.AuthValidationInvalid, { name: 'CPF' }))
    .test('is-valid', 'CPF inválido', (value) => {
      if (!value) return false
      if (!validateCpf(value)) return false
      return true
    }),
  nome: Yup.string().required(getInternalizeText(systemMessages.keysMessages.AuthValidationRequired, { name: 'Nome' })).min(10, 'Mínimo de 10 caracteres'),
  // dataNasc: Yup.string()
  //   .required(getInternalizeText(systemMessages.keysMessages.AuthValidationRequired, { name: 'Data de Nascimento' }))
  //   .matches(/^\d{2}\/\d{2}\/\d{4}$/, getInternalizeText(systemMessages.keysMessages.AuthValidationInvalid, { name: 'Data de Nascimento' }))
  //   .test('date-valid', 'Data de nascimento inválida', (value) => {
  //     if (!value) return false
  //     const [day, month, year] = value.split('/').map(Number)
  //     console.log(day, month, year)
  //     if (!validateDate(day, month, year)) return false
  //     return true
  //   })
  //   .test('is-over-18', getInternalizeText(systemMessages.keysMessages.AuthRegisterLabel18Years), (value) => {
  //     if (!value) {
  //       return false
  //     }

  //     const [day, month, year] = value.split('/').map(Number)
  //     const birthDate = new Date(year, month - 1, day)

  //     if (!validateDate(day, month, year)) {
  //       return false
  //     }

  //     const today = new Date()
  //     const eighteenYearsAgo = new Date(today)
  //     eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18)
  //     return birthDate <= eighteenYearsAgo
  //   }),
  senha: Yup.string().required(getInternalizeText(systemMessages.keysMessages.AuthValidationRequired, { name: 'Senha' })).min(8, getInternalizeText(systemMessages.keysMessages.AuthValidationMinLength, { name: 'Senha', min: 8 })),
  confirmarSenha: Yup.string().required('Confirmação de senha é obrigatória').oneOf([Yup.ref('senha')], 'As senhas devem ser iguais'),
  fotoPerfil: Yup.mixed().notRequired(),
  email: Yup.string()
    .required(getInternalizeText(systemMessages.keysMessages.AuthValidationRequired, { name: 'E-mail' }))
    .test(validateEmail()),
  celular: Yup.string().required(getInternalizeText(systemMessages.keysMessages.AuthValidationRequired, { name: 'Celular' })).matches(/^\(\d{2}\) \d{5}-\d{4}$/, getInternalizeText(systemMessages.keysMessages.AuthValidationInvalid, { name: 'Celular' })),
  telefone: Yup.string(),
  senhaOK: Yup.string().required('Campo obrigatorio')
}) as Yup.ObjectSchema<{
  cpf: string
  nome: string
  // dataNasc: string
  senha: string
  senhaOK: string
  confirmarSenha: string
  fotoPerfil: File | string
  email: string
  celular: string
  telefone: string
}>

// export const schemaStepperCompany = Yup.object().shape({
//   cnpj: Yup.string().test(
//     'is-cnpj-filled',
//     getInternalizeText(systemMessages.keysMessages.AuthValidationInvalid, { name: 'CNPJ' }),
//     function (value) {
//       if (value) {
//         return /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(value)
//       }
//       return true
//     }
//   ),
//   companyName: Yup.string(),
//   ccm: Yup.string(),
//   crc: Yup.string(),
//   openingDate: Yup.string().test(
//     'is-date-filled',
//     getInternalizeText(systemMessages.keysMessages.AuthValidationInvalid, { name: 'Data de Abertura' }),
//     function (value) {
//       if (value) {
//         return /^\d{2}\/\d{2}\/\d{4}$/.test(value)
//       }
//       return true
//     }
//   ),
//   linkedsCompanyName: Yup.array().of(
//     Yup.object().shape({
//       cnpj: Yup.string(),
//       companyName: Yup.string(),
//       ccm: Yup.string(),
//       crc: Yup.string(),
//       openingDate: Yup.string()
//     })
//   )
// }) as Yup.ObjectSchema<{
//   cnpj: string
//   companyName: string
//   ccm: string
//   crc: string
//   openingDate: string
//   linkedsCompanyName: Array<{
//     cnpj: string
//     companyName: string
//     ccm: string
//     crc: string
//     openingDate: string
//   }>
// }>

export const schemaStepperLocation = Yup.object().shape({
  cep: Yup.string().required(getInternalizeText(systemMessages.keysMessages.AuthValidationRequired, { name: 'CEP' })).matches(/^\d{5}-\d{3}$/, getInternalizeText(systemMessages.keysMessages.AuthValidationInvalid, { name: 'CEP' })).max(9, getInternalizeText(systemMessages.keysMessages.MaxLength, { name: 'CEP', max: 9 })),
  logradouro: Yup.string().required(getInternalizeText(systemMessages.keysMessages.AuthValidationRequired, { name: 'Endereço' })),
  numero: Yup.string().required(getInternalizeText(systemMessages.keysMessages.AuthValidationRequired, { name: 'Número' })),
  complemento: Yup.string(),
  bairro: Yup.string().required(getInternalizeText(systemMessages.keysMessages.AuthValidationRequired, { name: 'Bairro' })),
  cidade: Yup.string().required(getInternalizeText(systemMessages.keysMessages.AuthValidationRequired, { name: 'Cidade' })),
  uf: Yup.string().required(getInternalizeText(systemMessages.keysMessages.AuthValidationRequired, { name: 'UF' })).max(2).min(2),
  pronto: Yup.string().required('Campo obrigatorio')
}) as Yup.ObjectSchema<{
  cep: string
  logradouro: string
  numero: string
  complemento: string
  bairro: string
  cidade: string
  uf: string
  pronto: string
}>

export const schemaStepperValidation = Yup.object().shape({
  // codigo: Yup.array()
  //   .of(Yup.string())
  //   .test('len', 'Deve conter exatamente 6 dígitos', (val) => val?.join('').length === 6)
  //   .required(getInternalizeText(systemMessages.keysMessages.AuthValidationRequired))
  //   .test('isNumeric', 'Deve ser um número', (val) => /^\d+$/.test(val?.join(''))),
  codigo: Yup.string().min(6, 'Mínimo de 6 dígitos'),
  stepperCurrent: Yup.number()
}) as Yup.ObjectSchema<{
  codigo: string
  stepperCurrent: number
}>

// Valores iniciais
export const initialValuesAutoRegister = {
  cpf: '',
  nome: '',
  senha: '',
  senhaOK: '',
  codigo: '',
  stepperCurrent: 1,
  fotoPerfil: '',
  // dataNasc: '',
  email: '',
  telefones: '',
  cnpj: '',
  companyName: '',
  ccm: '',
  crc: '',
  openingDate: '',
  cep: '',
  logradouro: '',
  numero: 0,
  complemento: '',
  bairro: '',
  cidade: '',
  uf: '',
  codigoIbge: '',
  pronto: ''
}

export const schemasValidation = [schemaStepperDataPersonal, schemaStepperLocation, schemaStepperValidation]
