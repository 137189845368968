/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState, type ChangeEvent } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'app/shared/components/Button/Button'
import OverlayTooltip from 'app/shared/components/OverlayTooltip/OverlayTooltip'
import ReactModal from 'app/shared/components/ReactModal/ReactModal'
import Toastfy from 'app/shared/helpers/Toastfy/Toastfy'
import { boolToString, slugify } from 'app/shared/helpers/utils'
import * as ValidateValues from 'app/shared/helpers/validateValues'
import * as MaskInput from 'app/shared/helpers/valueMasks'
import { useFormBuilderStore } from 'app/store/FormBuilderStore'
import clsx from 'clsx'
import * as Yup from 'yup'

const getOptionsMask = (): Array<{
  value: string
  label: string
}> => {
  return [
    { value: 'numberMask', label: 'Números' },
    { value: 'moneyMask', label: 'Decimal' },
    { value: 'cpfMask', label: 'CPF' },
    { value: 'cnpjMask', label: 'CNPJ' },
    { value: 'cpfCnpjMask', label: 'CPF/CNPJ' },
    { value: 'cellphoneMask', label: 'Telefone' },
    { value: 'cepMask', label: 'CEP' }
  ]
}

const RenderInputForm: React.FC = ({ ...props }: any) => {
  const name = props.inputParams.name

  const error = props.hookForm.formState.errors[name]

  const onChange = props?.validations?.mask
    ? (e: ChangeEvent<HTMLInputElement>): void => {
        if (typeof MaskInput[props?.validations?.mask] === 'function') {
          const value = MaskInput[props?.validations?.mask](e.target.value)

          props.hookForm.setValue(name, value)
        }
      }
    : (e: ChangeEvent<HTMLInputElement>): void => {
        props.hookForm.setValue(name, e.target.value)
      }

  const rowCols = props?.style?.cols ?? 12

  return (
    <div className={`col-md-${rowCols} col-sm-12`}>
      <label
        className={clsx('form-label fs-6 fw-bolder text-dark', {
          required: props?.validations?.required
        })}
      >{props?.labelFieldName}</label>
      <input
        {...props.inputParams}
        type='text'
        placeholder={props?.placeholder !== '' ? props?.placeholder : props?.labelFieldName}
        readOnly={props?.readOnly}
        disabled={props?.disabled}
        autoComplete='off'
        className={clsx(
          'form-control form-control-solid',
          {
            'is-invalid': error,
            'is-valid': !error && props.hookForm.formState.touchedFields[name]
          }
        )}
        onChange={onChange}
      />
      <div className='fv-plugins-message-container'>
        <span role='alert'>{error?.message}</span>
      </div>
    </div>
  )
}

const InputParamsDetails: React.FC = ({ ...props }: any) => {
  const selectedMask = getOptionsMask().find((option) => option.value === props.validations.mask)?.label

  const paramsDetails = [
    {
      key: 'Obrigatório',
      value: boolToString(Boolean(props.validations.required))
    },
    {
      key: 'Possui Máscara',
      value: boolToString(Boolean(props.validations.mask)) + (selectedMask ? ` (${selectedMask})` : '')
    },
    {
      key: 'Placeholder',
      value: props.placeholder ? props.placeholder : 'Não Informado'
    },
    // {
    //   key: 'Valor Inicial',
    //   value: props.initialValue ? props.initialValue : 'Não Informado'
    // },
    {
      key: 'Tamanho da Coluna',
      value: props?.style?.cols ?? 12
    }
  ]

  return (
    <table className="table">
      <tbody>
        {
          paramsDetails.map((param) => (
            <tr key={param.key} >
              <th>{param.key}</th>
              <td>{param.value}</td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

const RenderInputFormPreview: React.FC = ({ ...props }: any) => {
  const rowCols = props?.style?.cols ?? 12

  return (
    <div className={`col-md-${rowCols} col-sm-12`}>
      <label className='form-label fs-5 fw-bolder text-dark'>{props?.typeFieldName}</label>

      <br />

      <label className='form-label fs-6 fw-bolder text-dark' htmlFor={`input-label-${props?.uuid}`}>{props.labelFieldName}</label>
      {
        !props.isEditing
          ? <InputParamsDetails {...props} />
          : <input
            type='text'
            placeholder={
              props?.placeholder !== '' ? props?.placeholder : props?.labelFieldName
            }
            readOnly={true}
            disabled={true}
            className={clsx(
              'form-control form-control-solid'
            )}
          />
      }
    </div>
  )
}

const ModalInputFormEditing: React.FC = ({ ...props }: any) => {
  const mask = props?.validations?.mask != null ? `${props?.validations?.mask}` : ''

  const [currentMask, setCurrentMask] = useState(mask)

  const [editInputForm] = useFormBuilderStore(state => [state.editInputForm])

  const inputEditSchema = Yup.object().shape({
    labelFieldName: Yup.string().required('Campo obrigatório'),
    placeholder: Yup.string().nullable(),
    validationType: Yup.string().required('Campo obrigatório'),
    initialValue: Yup.string().nullable(),
    validations: Yup.object().shape({
      mask: Yup.string().nullable(),
      required: Yup.boolean().nullable()
    }),
    style: Yup.object().shape({
      cols: Yup.number().required('Campo obrigatório')
    }),
    inputParams: Yup.object().shape({
      required: Yup.boolean().nullable(),
      disabled: Yup.boolean().nullable()
    })
  })

  const cols = Number(props?.style?.cols ?? 12)

  const defaultValues = {
    validationType: `${props.validationType}`,
    labelFieldName: `${props.labelFieldName}`,
    initialValue: (props?.initialValue as string) ?? '',
    placeholder: (props?.placeholder as string) ?? '',
    validations: {
      required: Boolean(props?.validations?.required),
      mask: props?.validations?.mask ? `${props?.validations?.mask}` : ''
    },
    style: {
      cols
    },
    inputParams: {
      required: Boolean(props?.inputParams?.required),
      disabled: Boolean(props?.inputParams?.disabled)
    }
  }

  type FormDataSchema = Yup.InferType<typeof inputEditSchema>

  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm({
    defaultValues,
    resolver: yupResolver(inputEditSchema)
  })

  const closeModal = (): void => {
    editInputForm(props.uuid, {
      ...props,
      isModalOpen: false
    })
  }

  const onSubmitEditInput: SubmitHandler<FormDataSchema> = async (data): Promise<void> => {
    if (data.validations.mask) {
      const mask = data.validations.mask.replace(/Mask$/, '')

      const maskSelected = `validate${mask.charAt(0).toUpperCase()}${mask.slice(1)}`

      if (typeof ValidateValues[maskSelected] === 'function' && data.initialValue) {
        if (!ValidateValues[maskSelected](data.initialValue)) {
          Toastfy.error('Valor inicial inválido')
          return
        }
      }
    }

    editInputForm(props.uuid, {
      ...props,
      ...data,
      inputParams: {
        ...props.inputParams,
        ...data.inputParams
      },
      validations: {
        ...props.validations,
        ...data.validations
      },
      isModalOpen: false
    })
  }

  const handleMaskChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    const mask = e.target.value

    if (mask !== currentMask) {
      setCurrentMask(mask)
      setValue('initialValue', '')

      const input = document.getElementById('input-label-preview') as HTMLInputElement

      if (input) {
        input.value = ''
      }
    }
  }

  const ValidationsNumberType: React.FC = () => {
    return <div className="row">
      <div className="col-md-5 col-sm-12 mt-2">
        <label className='form-label fs-6 fw-bolder text-dark'>Máscara</label>

        <select
          className="form-select"
          id="mascara-validation"
          aria-label="Floating label select"
          {...register('validations.mask')}
          onChange={handleMaskChange}
        >
          {
            getOptionsMask().map((option) => (
              <option
                key={slugify(`${option.label}_${option.value}`)}
                value={option.value}
              >
                {option.label}
              </option>
            ))
          }
        </select>

      </div>
      <div className="col-md-7 col-sm-12 mt-2">
        <label className='form-label fs-6 fw-bolder text-dark' htmlFor='input-label-preview'>Exemplo de campo</label>
        <input
          type='text'
          placeholder='Digite aqui '
          className='form-control form-control-solid'
          id='input-label-preview'
          onChange={(e) => {
            e.preventDefault()

            if (typeof MaskInput[currentMask] === 'function') {
              e.target.value = MaskInput[currentMask](e.target.value) as string
            }
          }}
        />
      </div>

    </div>
  }

  const RenderValidationsByType = (): JSX.Element => {
    switch (getValues('validationType')) {
      case 'number':
        return <ValidationsNumberType />
      default:
        return <></>
    }
  }

  return <ReactModal
    show={props.isModalOpen}
    headerName={`Editando campo "${props.typeFieldName} - ${props.labelFieldName}"`}
    onClose={closeModal}
    modalFooter={
      <>
        <Button
          rounded={true}
          variant='primary'
          onClick={handleSubmit(onSubmitEditInput)}
        >
          Salvar
        </Button>

        <Button
          type='button'
          rounded={true}
          variant='light'
          onClick={closeModal}
        >Voltar</Button>
      </>
    }
  >

    <div className="row">

      <div className="col-md-6 col-sm-12">
        <label className='form-label fs-6 fw-bolder text-dark'>Nome do Campo</label>
        <input
          {...register('labelFieldName')}
          type='text'
          placeholder='Nome do Campo'
          className={clsx(
            'form-control form-control-solid',
            { 'is-invalid': errors.labelFieldName },
            { 'is-valid': errors.labelFieldName == null }
          )}
        />
        <div className='fv-plugins-message-container'>
          <span role='alert'>{errors.labelFieldName?.message}</span>
        </div>
      </div>

    </div>

    <hr />

    <label className='form-label fs-2 fw-bolder text-dark'>Validações</label>

    <div className="row mb-2">

      <div className="col-md-5 col-sm-12 mt-2">
        <label className='form-label fs-6 fw-bolder text-dark' htmlFor='validationType'>Tipo de Resposta</label>

        <select
          className="form-select"
          id="validationType"
          aria-label="Floating label select"
          {...register('validationType')}
          onChange={(e) => {
            const validationType = e.target.value
            setValue('validationType', validationType)
            setValue('initialValue', '')

            let mask = ''

            if (validationType === 'number') {
              mask = 'numberMask'
            }

            setValue('validations.mask', mask)
            setCurrentMask(mask)
          }}
        >
          <option value="string">Texto Comum</option>
          <option value="email">E-mail</option>
          <option value="number">Números</option>
        </select>

      </div>

      {/*
      <div className="col-md-4 col-sm-12 mt-2">

        <div className="form-check form-switch">
          <input
            {...register('inputParams.disabled')}
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="isDisabledCheck"
          />
          <label className="form-check-label" htmlFor="isDisabledCheck">Desabilitado</label>
        </div>

      </div>
    */}

    </div>

    <RenderValidationsByType />

    <div className="row mt-2">
      {/*
      <div className="col-md-6 col-sm-12">
        <label className='form-label fs-6 fw-bolder text-dark'>Valor Inicial</label>
        <input
          {...register('initialValue')}
          type='text'
          placeholder='Valor Inicial'
          className={clsx(
            'form-control form-control-solid',
            { 'is-invalid': errors.initialValue },
            { 'is-valid': errors.initialValue == null }
          )}
          onChange={(e) => {
            setValue('initialValue', initialValue)
          }}
        />
      </div> */}

      <div className="col-md-6 col-sm-12">
        <label className='form-label fs-6 fw-bolder text-dark'>Placeholder</label>
        <input
          {...register('placeholder')}
          type='text'
          placeholder='Placeholder'
          className={clsx(
            'form-control form-control-solid',
            { 'is-invalid': errors.placeholder },
            { 'is-valid': errors.placeholder == null }
          )}
        />
      </div>

    </div>

    <hr />

    <label className='form-label fs-2 fw-bolder text-dark'>Estilo</label>

    <div className="row mb-2">

      <div className="col-md-3 col-sm-12 mt-2">
        <label className='form-label fs-6 fw-bolder text-dark' htmlFor='style.cols'>
          Tamanho da Coluna <OverlayTooltip
            message="Tamanho da coluna que o campo irá ocupar no formulário. Vai de 1 a 12, sendo 12 o tamanho máximo e 1 o mínimo."
          >
            <i className="bi bi-info-circle"></i>
          </OverlayTooltip>
        </label>

        <select
          className="form-select"
          id="style.cols"
          aria-label="Floating label select"
          {...register('style.cols')}
        >
          {
            Array.from({ length: 12 }, (_, index) => index + 1).map((value) => (
              <option
                key={value}
                value={value}
              >
                {value} Coluna{value > 1 ? 's' : ''}
              </option>
            ))
          }
        </select>

      </div>

    </div>

    <hr />

    <div className="row mt-2">
      <div className="col-md-4 col-sm-12 mt-2">
        <div className="form-check form-switch">
          <input
            {...register('validations.required')}
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="isRequiredCheck"
          />
          <label className='form-label fs-6 fw-bolder text-dark' htmlFor='isRequiredCheck'>Obrigatório</label>
        </div>

      </div>
    </div>

  </ReactModal>
}

export { RenderInputForm, RenderInputFormPreview, ModalInputFormEditing }
