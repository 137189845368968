import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

interface ICookiesAccepteds {
  perfomance: boolean
}

interface CookieStore {
  isAcceptedTermOsUse: boolean
  dateAcceptTermOsUse?: Date
  isFirstAccess: boolean
  cookiesAccepteds: ICookiesAccepteds
  acceptTermOfUse: () => void
  updateCookiesAccepteds: (key: string, value: any) => void
  setFirstAccess: (isFirstAccess: boolean) => void
}

const initialValues = {
  isAcceptedTermOsUse: false,
  dateAcceptTermOsUse: undefined,
  isFirstAccess: true,
  cookiesAccepteds: {
    perfomance: false
  }
}

const useCookieStore = create(persist<CookieStore>((set, get) => {
  return {
    ...initialValues,

    acceptTermOfUse: () => {
      set({
        isAcceptedTermOsUse: true,
        dateAcceptTermOsUse: new Date()
      })
    },

    setFirstAccess: (isFirstAccess: boolean) => {
      set({ isFirstAccess })
    },

    updateCookiesAccepteds: (key, value) => {
      set({
        cookiesAccepteds: {
          ...get().cookiesAccepteds,
          [key]: value
        }
      })
    }

  }
}, {
  name: 'cookie-storage',
  storage: createJSONStorage(() => localStorage)
}))

export default useCookieStore
