import clsx from 'clsx'
import { ToolbarType, useLayout } from '../../core'
// import { Toolbar } from './Toolbar'
import { PageTitleWrapper } from './page-title'
import { getInternalizeText } from 'app/shared/helpers/utils'
import { systemMessages } from '_metronic/i18n/systemMessages'
import { BiSearch } from 'react-icons/bi'

const ToolbarWrapper = (props) => {
  const { config, classes } = useLayout()
  if (!config.app?.toolbar?.display) {
    return null
  }

  const isPageTitleVisible = showPageTitle(
    config.app?.toolbar?.layout,
    config.app?.pageTitle?.display
  )

  const className = props.className ? clsx(
    'w-100',
    classes.toolbarContainer.join(' '),
    config.app?.toolbar?.containerClass,
    config.app?.toolbar?.minimize?.enabled ? 'app-toolbar-minimize' : '',
  ) :
    clsx(
      'app-container',
      classes.toolbarContainer.join(' '),
      config.app?.toolbar?.containerClass,
      config.app?.toolbar?.minimize?.enabled ? 'app-toolbar-minimize' : '',
      {
        'container-fluid': config.app?.toolbar?.container === 'fluid',
        'container-xxl': config.app?.toolbar?.container === 'fixed',
      }
    )

  return (
    <div
      id='kt_app_toolbar'
      className={clsx('app-toolbar', classes.toolbar.join(' '), config?.app?.toolbar?.class)}
    >
      <div
        id='kt_app_toolbar_container'
        className={className}
      >
        {isPageTitleVisible && <PageTitleWrapper />}
        {/* <div className='dashboard--page-search-container'>
          <form data-kt-search-element="form" className='position-relative' autoComplete='off'>
            <input type="text" className='search-input form-control ps-6 pe-15 fs-7 h-40px' placeholder={getInternalizeText(systemMessages.keysMessages.DashboardPlaceholderInputSearch)} />
            <BiSearch className='icon' color='#1351b4' />
          </form>
        </div> */}

        {/* <Toolbar /> */}
      </div>
    </div>
  )
}

const showPageTitle = (appToolbarLayout?: ToolbarType, appPageTitleDisplay?: boolean): boolean => {
  const viewsWithPageTitles = ['classic', 'reports', 'saas']
  if (!appToolbarLayout || !appPageTitleDisplay) {
    return false
  }

  return appPageTitleDisplay && viewsWithPageTitles.some((t) => t === appToolbarLayout)
}

export { ToolbarWrapper }
