/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/return-await */
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { type IDefaultRequest } from 'app/shared/types/common'
import { type AxiosResponse } from 'axios'
import { type KeyedMutator } from 'swr'

import { apiProcessos } from '../api'

export interface IRequirementType {
  REQTIPID: number
  REQTIPDESCRICAO: string
  REQTIPSTATUS: boolean
}

export interface IRequirementTypeEdit {
  descricao: string
  status: number
}

const endpointTypeRequerimento = '/tipo-requerimento'

const GetRequerimentos = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IRequirementType>>(
    `${endpointTypeRequerimento}/list-all?${queryParams}`,
    apiProcessos
  )
}

const createTypeRequerimento = async (
  payload: { descricao: string, status: number },
  mutate: KeyedMutator<IDefaultRequest<IRequirementType>>
) => {
  const response = await apiProcessos.post(`${endpointTypeRequerimento}/create`, payload)
  if (response.status === 201) await mutate()
  return response
}

const updateTypeRequerimento = async (
  id: number,
  payload: { descricao: string, status: number },
  mutate: KeyedMutator<IDefaultRequest<IRequirementType>>
) => {
  const response = await apiProcessos.put(`${endpointTypeRequerimento}/update/${id}`, payload)
  if (response.status === 200) await mutate()
  return response
}

export const getRequirementTypes = async (): Promise<AxiosResponse<IDefaultRequest<IRequirementType>>> => {
  return await apiProcessos.get('/tipo-requerimento/list-all')
}

export const editRequirementType = async (id: number, dataEdit: IRequirementTypeEdit): Promise<AxiosResponse<IDefaultRequest<any>>> => {
  return await apiProcessos.put(`tipo-requerimento/update/${id}`, dataEdit)
}

export const createRequirementType = async (dataEdit: IRequirementTypeEdit): Promise<AxiosResponse<IDefaultRequest<any>>> => {
  return await apiProcessos.post('tipo-requerimento/create', dataEdit)
}

export default {
  GetRequerimentos,
  createTypeRequerimento,
  updateTypeRequerimento,
  getRequirementTypes,
  editRequirementType,
  createRequirementType
}
