import React from 'react'

const TermOfUSe = (): JSX.Element => {
  return (
    <div>
      <h1 className="fs-1">Termos de Uso e Privacidade</h1>

      <p className="fs-5">
        <strong className='fw-bold'>Termo de Uso da Plataforma Sigcorp</strong>
      </p>

      <p className="text-muted fs-5">Última Atualização: 28/11/2023</p>

      <p>
        <strong className='fw-bold'>LEIA ATENTAMENTE ESTES TERMOS DE USO</strong>
      </p>

      <p>
        Ao se cadastrar no portal Plataforma Sigcorp e usar nossos serviços, o
        usuário está concordando com estes termos e a quaisquer outras leis
        aplicáveis. A Sigcorp Tecnologia da Informação poderá alterar os Termos
        de Uso a qualquer momento, sem aviso prévio, os quais passarão a vigorar
        a partir de sua publicação na Plataforma Sigcorp. Os serviços disponíveis
        no portal Plataforma Sigcorp são ofertados pela ADMINISTRAÇÃO MUNICIPAL
        com suporte da Sigcorp Tecnologia da Informação, empresa privada
        brasileira de prestação de serviços em tecnologia da informação.
      </p>

      <h2>1. COMO USAR OS SERVIÇOS DA ADMINISTRAÇÃO MUNICIPAL NO PORTAL Plataforma Sigcorp</h2> <br />

      <h3>1.1 Cadastro</h3>

      <p>
        Para utilizar os serviços da ADMINISTRAÇÃO MUNICIPAL disponíveis no
        portal Plataforma Sigcorp, o usuário deve se cadastrar. Para se
        cadastrar, o usuário deve fornecer informações pessoais e atuais como:
        (I) CPF ou CNPJ, conforme o caso; (II) inscrição municipal; (III)
        endereço de e-mail existente; (IV) número de telefone celular e telefone
        fixo existentes e com DDD. Deve também criar uma (V) senha e (VI)
        fornecer o código de segurança.
      </p>

      <p>
        Após preencher o formulário, o usuário receberá um código de
        verificação, via correio eletrônico (e-mail), para digitar no campo
        “Validar Código”. O usuário somente será considerado cadastrado após
        digitar o código de verificação.
      </p>

      <p>
        O usuário responde legalmente sobre a veracidade e autenticidade das
        informações prestadas para acesso ao portal e para a utilização dos
        serviços. Caso a ADMINISTRAÇÃO MUNICIPAL constate qualquer inexatidão
        nas informações prestadas pelo usuário ou ilegalidade em sua conduta,
        poderá suspender ou cancelar a correspondente conta de acesso e não
        concluir ou indeferir a prestação do serviço. O usuário que se cadastrar
        no portal utilizando dados de terceiros poderá incorrer em prática de
        crimes, sem prejuízo de eventual responsabilização de acordo com a
        legislação.
      </p>

      <h3>1.2 Serviços disponíveis</h3>

      <p>
        A ADMINISTRAÇÃO MUNICIPAL pode incluir, remover ou suspender serviços e
        funcionalidades do portal Plataforma Sigcorp a qualquer momento sem
        aviso prévio para o usuário.
      </p>

      <h2>2. CONDUTA E RESPONSABILIDADES DO USUÁRIO</h2> <br />

      <h3>2.1 Atualizar dados pessoais</h3>

      <p>
        É responsabilidade do usuário a atualização dos dados pessoais no portal
        Plataforma Sigcorp. Caso altere ou desative o número de celular ou
        endereço de e-mail, o usuário deverá atualizar imediatamente as
        informações da sua conta no Plataforma Sigcorp para garantir que as
        mensagens da ADMINISTRAÇÃO MUNICIPAL sejam entregues. Eventual falha de
        comunicação por desatualização das informações será de inteira
        responsabilidade do usuário e não poderá ser imputada à ADMINISTRAÇÃO
        MUNICIPAL.
      </p>

      <h3>2.2 Proteger a senha de acesso</h3>

      <p>
        O usuário é responsável por quaisquer ações e transações que ocorram em
        sua conta no portal Plataforma Sigcorp. Cabe ao usuário proteger a senha
        e não repassar para terceiros. A ADMINISTRAÇÃO MUNICIPAL não se
        responsabiliza por qualquer prejuízo resultante do uso não autorizado
        do CPF/CNPJ e senha, com ou sem o consentimento do usuário.
      </p>

      <h3>2.3 Providenciar o dispositivo e o acesso à internet</h3>

      <p>
        O usuário é responsável por providenciar e atualizar os dispositivos
        necessários para acessar o portal e usar os serviços disponíveis. A
        ADMINISTRAÇÃO MUNICIPAL recomenda que, para acessar as funcionalidades do
        Plataforma Sigcorp, o usuário providencie a atualização de pelo menos um
        navegador, a partir das seguintes versões: Google Chrome 66; Firefox 52;
        Microsoft Edge 41; Internet Explorer 11; Safari 10. A ADMINISTRAÇÃO
        MUNICIPAL não garante que o portal funcionará em qualquer dispositivo
        em particular. Além disso, os serviços poderão estar sujeitos a mau
        funcionamento e atrasos inerentes ao tráfego de dados na internet.
      </p>

      <h3>2.4 Receber comunicados da ADMINISTRAÇÃO MUNICIPAL</h3>

      <p>
        Ao efetivar o cadastro, o usuário autoriza expressamente a
        ADMINISTRAÇÃO MUNICIPAL a enviar avisos e notificações para o e-mail
        informado no cadastro e mensagens de textos (SMS) ao celular cadastrado
        como parte das operações necessárias para o uso dos serviços disponíveis
        no portal. Ressalte-se que o principal canal de informação para o
        usuário, contudo, é a Notificação de Mensagens, disponível no Plataforma
        Sigcorp. O e-mail da Plataforma Sigcorp destina-se tão-somente ao envio
        de mensagens automáticas de sistema para o usuário cadastrado, de modo
        que a ADMINISTRAÇÃO MUNICIPAL não responderá nenhum questionamento que
        eventualmente possa ser feito por meio de resposta ao e-mail automático
        enviado. A ADMINISTRAÇÃO MUNICIPAL não envia ao e-mail do usuário:
        links; arquivos em anexo sem a solicitação do usuário; programas para
        instalação; senha ou dados pessoais do usuário; nem solicita nenhum tipo
        de pagamento ou depósito em nome de terceiros ou da ADMINISTRAÇÃO
        MUNICIPAL por e-mail, telefone ou SMS.
      </p>

      <h3>2.5 Verificar frequentemente a Caixa de Mensagens</h3>

      <p>
        Ao efetivar o cadastro, o usuário concorda em receber comunicados e
        informativos eletrônicos da ADMINISTRAÇÃO MUNICIPAL na Notificação de
        Mensagens do Plataforma Sigcorp. É de inteira responsabilidade do
        usuário verificar a sua Notificação de Mensagens para tomar conhecimento
        das mensagens enviadas pela ADMINISTRAÇÃO MUNICIPAL, o que inclui
        intimações sobre procedimentos administrativos instaurados pela
        ADMINISTRAÇÃO MUNICIPAL. A Notificação de Mensagens é o instrumento
        oficial da ADMINISTRAÇÃO MUNICIPAL para intimação do contribuinte acerca
        da instauração, tramitação e decisões relativas a procedimentos e
        requerimentos administrativos de seu interesse, abertos por iniciativa
        da ADMINISTRAÇÃO MUNICIPAL ou do próprio contribuinte. A existência da
        Notificação de Mensagens não impede que a intimação seja também
        efetivada por outros meios, a critério da ADMINISTRAÇÃO MUNICIPAL,
        principalmente enquanto o contribuinte ainda não tiver cadastro no
        Plataforma Sigcorp. A ADMINISTRAÇÃO MUNICIPAL enviará ao usuário
        mensagem para sua conta de e-mail informada no cadastro para alertar
        sobre a existência de nova mensagem na Notificação de Mensagens da
        Plataforma Sigcorp, a qual deverá ser acessada pelo usuário. Eventual
        falha de comunicação decorrente da desativação do e-mail cadastrado ou
        caixa cheia será de inteira responsabilidade do usuário, que será
        considerado cientificado da existência de nova mensagem na Notificação
        de Mensagens do Plataforma Sigcorp, para todos os fins.
      </p>

      <h2>3. LIMITAÇÃO DE RESPONSABILIDADE</h2>

      <p>
        O portal Plataforma Sigcorp pode conter links para sites e plataformas de
        terceiros. A ADMINISTRAÇÃO MUNICIPAL não monitora, nem possui controle
        ou se responsabiliza pelos conteúdos, termos de uso e políticas de
        privacidade desses outros sites e plataformas. A ADMINISTRAÇÃO MUNICIPAL
        também não se responsabiliza pelo conteúdo de outros sites e plataformas
        que disponibilizam links para o portal do Plataforma Sigcorp e para o
        site da ADMINISTRAÇÃO MUNICIPAL.
      </p>

      <h2>4. SOBRE A POLÍTICA DE PRIVACIDADE</h2>

      <p>
        Ao se cadastrar e utilizar nossos serviços, o usuário concorda que a
        ADMINISTRAÇÃO MUNICIPAL poderá usar esses dados de acordo com a política
        de privacidade do portal. Para saber mais sobre o uso das informações
        coletadas neste portal, consulte a Política de Privacidade.
      </p>

      <p>
        O USUÁRIO declara expresso CONSENTIMENTO na coleta dos dados necessários
        ao cumprimento do contrato, nos termos do Art. 7º, inc. V da LGPD, bem
        como os dados imprescindíveis para cumprimento de obrigações legais,
        nos termos do Art. 7º, inc. II da LGPD.
      </p>

      <p>
        <strong className='fw-bold'>Parágrafo primeiro:</strong> O USUÁRIO e a ADMINISTRAÇÃO MUNICIPAL se comprometem a proteger os direitos fundamentais de liberdade e de privacidade e o livre desenvolvimento da personalidade da pessoa natural, relativos ao tratamento dos dados pessoais, nos meios digitais inclusive.
      </p>

      <p>
        <strong className='fw-bold'>Parágrafo segundo:</strong> O USUÁRIO autoriza a coleta de dados pessoais imprescindíveis à execução deste contrato e declara ter sido informado quanto ao tratamento de dados que será realizado pela ADMINISTRAÇÃO MUNICIPAL, nos termos da LGPD, especificamente quanto à coleta dos seguintes dados:
      </p>

      <ul>
        <li><strong className='fw-bold'>a)</strong> dados relacionados à sua identificação pessoal, a fim de que se garanta o fiel uso do sistema pelo respectivo titular dos dados;</li>
        <li><strong className='fw-bold'>b)</strong> dados relacionados ao seu endereço, tendo em vista a necessidade de cadastro completo, envio de documentos/notificações e outras garantias necessárias ao fiel uso do sistema pelo respectivo titular dos dados;</li>
        <li><strong className='fw-bold'>c)</strong> os dados coletados poderão ser utilizados para identificação de terrorismo, compartilhamento junto a órgãos de segurança, conforme solicitação legal pertinente, compartilhamento com autoridade administrativa e judicial no âmbito de suas competências com base no estrito cumprimento do dever legal;</li>
      </ul>

      <p>
        <strong className='fw-bold'>Parágrafo terceiro:</strong> Os dados coletados com base no legítimo interesse do USUÁRIO, bem como para garantir a fiel execução deste contrato por parte da ADMINISTRAÇÃO MUNICIPAL, fundamentam-se no art. 7°, da LGPD, razão pela qual as finalidades descritas no parágrafo segundo não são exaustivas.
      </p>

      <p>
        <strong className='fw-bold'>Parágrafo quarto:</strong> A ADMINISTRAÇÃO MUNICIPAL informa que todos os dados pessoais solicitados e coletados são os estritamente necessários para os fins almejados neste contrato.
      </p>

      <p>
        <strong className='fw-bold'>Parágrafo quinto:</strong> O USUÁRIO autoriza o compartilhamento de seus dados para os fins descritos nesta cláusula, com terceiros legalmente legítimos para defender os interesses da ADMINISTRAÇÃO MUNICIPAL, bem como do USUÁRIO.
      </p>

      <p>
        <strong className='fw-bold'>Parágrafo sexto:</strong> O USUÁRIO tem o tempo determinado de 5 (cinco) anos para acesso aos próprios dados armazenados, podendo também solicitar a exclusão de dados que foram previamente coletados com seu consentimento.
      </p>

      <ul>
        <li><strong className='fw-bold'>a)</strong> A exclusão de dados será efetuada sem que haja prejuízo por parte da CONTRATANTE, tendo em vista a necessidade de guarda de documentos por prazo determinado de 05 (cinco) anos, conforme disposição legal. Para tanto, caso o USUÁRIO deseje efetuar a revogação de algum dado, deverá preencher declaração neste sentido, ciente de que a revogação de determinados dados poderá importar em eventuais prejuízos na prestação de serviços;</li>
        <li><strong className='fw-bold'>b)</strong> O USUÁRIO autoriza, neste mesmo ato, a guarda dos documentos (contratos/documentos fiscais/notificações/comprovantes/ordens de serviço) – em que pese eles possuam dados pessoais – por parte da CONTRATANTE, a fim que ela cumpra com o determinado nas demais normas que regulam o presente contrato, bem como para o cumprimento da obrigação legal nos termos do art. 16, I, da LGPD.</li>
      </ul>

      <p>
        <strong className='fw-bold'>Parágrafo sétimo:</strong> Em eventual vazamento indevido de dados, a ADMINISTRAÇÃO MUNICIPAL se compromete a comunicar imediatamente o USUÁRIO sobre o ocorrido, bem como informar qual(is) o(s) dado(s) vazado(s).
      </p>

      <p>
        <strong className='fw-bold'>Parágrafo oitavo:</strong> A ADMINISTRAÇÃO MUNICIPAL informa que a gerência dos dados ocorrerá através de um sistema que colherá e tratará os dados na forma da lei.
      </p>

      <p>
        <strong className='fw-bold'>Parágrafo nono:</strong> A ADMINISTRAÇÃO MUNICIPAL informa que efetuará a manutenção do registro das operações de tratamento de dados pessoais na forma do parágrafo oitavo.
      </p>

      <p>
        <strong className='fw-bold'>Parágrafo décimo: </strong>  rescindido o presente contrato os dados pessoais
        coletados serão armazenados pelo tempo determinado no parágrafo sexto.
        Passado o tempo de guarda, a CONTRATANTE se compromete a efetuar o
        descarte dos dados adequadamente.
      </p>
    </div>
  )
}

export default TermOfUSe
