import { slugify } from 'app/shared/helpers/utils'

interface HelperInputDetailsProps {
  paramsDetails: Array<{
    key: string
    value: any
  }>
}

export const HelperInputDetails: React.FC<HelperInputDetailsProps> = (
  {
    paramsDetails
  }: HelperInputDetailsProps
) => {
  return (
        <table className="table">
            <tbody>
                {
                    paramsDetails.map((param) => (
                        <tr key={slugify(param.key)} >
                            <th>{param.key}</th>
                            <td>{param.value}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
  )
}
