/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'

import { systemMessages } from '_metronic/i18n/systemMessages'
import { getInternalizeText } from 'app/shared/helpers/utils'
import { numberMask } from 'app/shared/helpers/valueMasks'
import { ServiceAuthRegister } from 'app/shared/services'

const StepperValidation = (): JSX.Element => {
  const minutes = 10
  const [timeRemaining, setTimeRemaining] = useState(minutes * 60)
  const [firstAccess, setFirstAccess] = useState<boolean>(true)
  const [codeSending, setCodeSending] = useState<boolean>(false)
  const { setValue, getValues } = useFormContext()

  const stepperCurrent = getValues('stepperCurrent')

  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null)
  ]

  const handleVeridyCode = (): void => {
    const areAllInputsFilled = inputRefs.every((ref) => ref.current?.value !== '')
    if (areAllInputsFilled) {
      const verificationCodes = inputRefs.map((ref) => ref.current?.value).join('')
      setValue('codigo', verificationCodes)
    } else {
      setValue('codigo', '')
    }
  }

  const handleInputCode = (event: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    const value = event.target.value
    if (value.length === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1]?.current?.focus()
    } else {
      if ((value.length === 0 && index > 0) || value.length === 0) {
        inputRefs[index - 1]?.current?.focus()
      }
    }

    handleVeridyCode()
  }

  const handleKeyDownCode = (event: React.KeyboardEvent<HTMLInputElement>, prevInput: HTMLInputElement | null): void => {
    const inputValue = event.currentTarget.value
    if (event.key === 'Backspace' && !inputValue && prevInput) {
      prevInput.focus()
      prevInput.value = ''
    }
  }

  const handlePasteCode = (event: React.ClipboardEvent<HTMLInputElement>): void => {
    const pastedText = event.clipboardData.getData('text')
    const digits = pastedText.split('')
    digits.forEach((digit, index) => {
      if (index < inputRefs.length && inputRefs[index].current) {
        const input = inputRefs[index].current
        if (input) {
          input.value = digit
        }

        if (index < inputRefs.length - 1 && inputRefs[index + 1].current) {
          inputRefs[index + 1]?.current?.focus()
        }
      }
    })

    event.preventDefault()
    handleVeridyCode()
  }

  const handleResendCode = async (): Promise<void> => {
    try {
      const email = getValues('email')
      const cpf = numberMask(getValues('cpf'))
      const response = await ServiceAuthRegister.sendCode({ email, cpf })
      if (response.status === 201) {
        toast.success('Código reenviado com sucesso')
        setTimeRemaining(10 * 60)
      }
    } catch (error) {
      toast.error('Erro ao reenviar código')
    }
  }

  const handleFormatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60)
    const secondsLeft = seconds % 60
    return `${minutes}:${secondsLeft.toString().padStart(2, '0')}`
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining(timeRemaining - 1)
      } else {
        clearInterval(intervalId)
      }
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [timeRemaining])

  useEffect(() => {
    if (stepperCurrent === 3 && firstAccess) {
      setTimeRemaining(minutes * 60)
      setFirstAccess(false)
    }

    const sendCodingEmail = async (): Promise<void> => {
      if (stepperCurrent === 3 && !codeSending) {
        const email = getValues('email')
        const cpf = numberMask(getValues('cpf'))
        if (email && cpf) {
          try {
            const response = await ServiceAuthRegister.sendCode({ email, cpf })
            if (response.status === 201) {
              setCodeSending(true)
              toast.success('Código de validação enviado com sucesso!')
            }
          } catch (error: any) {
            toast.error(error?.response.data.message ?? 'Erro ao enviar código de validação!')
          }
        }
      }
    }

    void sendCodingEmail()
  }, [stepperCurrent])

  return (
    <>
      <div className='w-100 text-center mb-10'>
        <h1 className='text-dark fw-bolder fs-2 mb-10'>
          {getInternalizeText(systemMessages.keysMessages.AuthPageValidationCode)}
        </h1>

        <div className='stepper--validation-inputs'>
        {Array.from(Array(6).keys()).map((_, index) => (
          <input
            key={index}
            type='text'
            ref={inputRefs[index]}
            className='container-input--verifiycode text-dark'
            maxLength={1}
            onChange={(event) => { handleInputCode(event, index) }}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              handleKeyDownCode(event, inputRefs[index - 1]?.current)
            }}
            onPaste={handlePasteCode}
          />
        ))}
        </div>

        <div className='d-flex justify-content-around align-items-center mt-20'>
          <div className='font-weigth-regular text-start'>
            <p className='fs-5'>{getInternalizeText(systemMessages.keysMessages.MessageCodeValidate)}</p>
            <p className='fs-5'>{getInternalizeText(systemMessages.keysMessages.MessageCodeValidateRemaining)} {handleFormatTime(timeRemaining)}</p>
          </div>
          <div>
            <a href='#' onClick={handleResendCode} className='stepper--validation-notreceivedcode'>{getInternalizeText(systemMessages.keysMessages.AuthRegisterNotReceiveCode)}</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default StepperValidation
