/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useState } from 'react'
import { IoNotifications } from 'react-icons/io5'

import Toastfy from 'app/shared/helpers/Toastfy/Toastfy'
import { ServiceNotification } from 'app/shared/services'
import { type INotification } from 'app/shared/types/plataform/notification'
import clsx from 'clsx'

import CardNotification from '../CardNotification/CardNotification'
import LoadingSpinners from '../Loadings/LoadingSpinners'
import OverlayTooltip from '../OverlayTooltip/OverlayTooltip'
import SideBar from '../SideBar/SideBar'

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'

const SideBarNotifications = (): JSX.Element => {
  const [openSideBar, setOpenSideBar] = useState<boolean>(false)
  const [requestEndpoint, setRequestEndpoint] = useState<boolean>(false)
  const [reSearch, setReSearch] = useState<boolean>(false)
  const [notifications, setNotifications] = useState<{
    isLoading: boolean
    data: INotification[]
  }>({
    isLoading: false,
    data: []
  })

  const hasNotificationsData = ServiceNotification.GetHasNotifications(1)

  const handleClearNotifications = async (): Promise<void> => {
    setRequestEndpoint(true)
    try {
      const response = await ServiceNotification.clearNotifications()
      if (response.status === 200) {
        await hasNotificationsData.mutate()
      }
    } catch (error: any) {
      Toastfy.error(error?.response?.data?.message ?? 'Erro ao limpar notificações! Tente novamente.')
    } finally {
      setRequestEndpoint(false)
      setOpenSideBar(false)
    }
  }

  useEffect(() => {
    const searchNotification = async (): Promise<void> => {
      setNotifications({ isLoading: true, data: [] })
      try {
        const response = await ServiceNotification.getNotificationsWithoutSWR()
        if (response.status === 200) {
          setNotifications({ isLoading: false, data: response.data.data })
        }
      } catch (error: any) {
        setNotifications({ isLoading: false, data: [] })
      } finally {
        await hasNotificationsData.mutate()
      }
    }

    if (reSearch) {
      void searchNotification()
    }

    return () => { setReSearch(false) }
  }, [reSearch])

  useEffect(() => {
    const intervalId = setInterval(async () => {
      await hasNotificationsData.mutate()
    }, 30 * 1000)
    return () => { clearInterval(intervalId) }
  }, [])

  return (
    <>
      <div className={clsx('app-navbar-item', itemClass)}>
        <OverlayTooltip message="Notificações" placement='bottom'>
          <div className={clsx('position-relative', btnClass)}>
            <div className='container-notification' onClick={() => { setReSearch(true); setOpenSideBar(true) }}>
              <IoNotifications color='1351b4' className={clsx('btn-active-light-primary btn-custom icon')} />
              <span className='badge-number'>
                {hasNotificationsData?.data?.data?.NOVOS ?? 0}
              </span>
            </div>
          </div>
        </OverlayTooltip>
      </div>

      <SideBar
        show={openSideBar}
        onHide={() => { setOpenSideBar(false); setReSearch(false) }}
        title='Notificações'
        position='end'
      >
        <div>
          {notifications?.data?.length > 0 && (
            <div className='d-flex justify-content-end mb-4'>
              <small className='cursor-pointer' onClick={handleClearNotifications}>Limpar tudo</small>
              {requestEndpoint && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
            </div>
          )}
          <div className='d-flex flex-column gap-7'>
            {notifications?.data?.map((notification, index) => (
              <CardNotification key={index} notification={notification} onClose={() => { setOpenSideBar(false) }} />
            ))}

            {notifications?.isLoading && (
              <LoadingSpinners>Buscando</LoadingSpinners>
            )}
          </div>
          {!notifications?.isLoading && notifications?.data?.length === 0 && (
            <p className='text-center'>Não há notificações novas</p>
          )}
        </div>
      </SideBar>
    </>
  )
}

export default SideBarNotifications
