/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { type IDefaultRequest } from 'app/shared/types/common'
import { type ICanceledDemands } from 'app/shared/types/processos/accessPrefeitura/canceledDemands'
import { type KeyedMutator } from 'swr'

import { apiProcessos } from '../../api'

const endpointCanceled = '/demandas/cancelamento'

const GetCanceledDemands = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<ICanceledDemands>>(
    `${endpointCanceled}/list-all?${queryParams}`,
    apiProcessos
  )
}

const createCancellation = async (
  payload: { requerimentoId: number, motivo: string }
) => {
  const response = await apiProcessos.post(`${endpointCanceled}/create`, payload)
  return response
}

const editCancellation = async (
  RECCANID: number,
  statusId: number,
  mutate: KeyedMutator<IDefaultRequest<ICanceledDemands>>
) => {
  const response = await apiProcessos.put(`${endpointCanceled}/update/${RECCANID}`, { statusId })
  if (response.status === 200) void mutate()
  return response
}

export default {
  GetCanceledDemands,
  createCancellation,
  editCancellation
}
