/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useRef, useState } from 'react'
import { type SubmitHandler, useForm, useFieldArray, Controller, type ControllerRenderProps } from 'react-hook-form'
import { FiCheck } from 'react-icons/fi'
import { toast } from 'react-toastify'

import { keysMessages } from '_metronic/i18n/keysMessages'
import { systemMessages } from '_metronic/i18n/systemMessages'
import { yupResolver } from '@hookform/resolvers/yup'
import { loginWithSSO } from 'app/modules/auth/core/_requests'
import Button from 'app/shared/components/Button/Button'
import ButtonMetronic from 'app/shared/components/Button/ButtonMetronic'
import SideBar from 'app/shared/components/SideBar/SideBar'
import Toastfy from 'app/shared/helpers/Toastfy/Toastfy'
import { getInternalizeText, isNumber, wait } from 'app/shared/helpers/utils'
import { numberMask } from 'app/shared/helpers/valueMasks'
import { ServicePerson, ServiceAuthRegister } from 'app/shared/services'
import * as Yup from 'yup'

import { getVerificationCode, type LoginData } from '../core/_requests'

import './styles.scss'

const loginVerifycodeSchema = Yup.object().shape({
  verify_code: Yup.array().of(
    Yup.string().required('Campo obrigatório').matches(/^[0-9]+$/, 'Deve ser um dígito de 0 a 9')
  ).min(6, 'Deve conter 6 dígitos')
})
type FormDataVerifyCodeLogin = Yup.InferType<typeof loginVerifycodeSchema>

interface ISideBarVerifyCode {
  show: boolean
  onHide: () => void
  onValidatecode: (verificationCodes: string) => Promise<void>
  dataFormLogin?: LoginData
}

const INITIAL_CODES_VALUES = ['', '', '', '', '', '']

const SideBarVerifyCode = ({ show, onHide, onValidatecode, dataFormLogin }: ISideBarVerifyCode): JSX.Element => {
  const minutes = 10
  const [timeRemaining, setTimeRemaining] = useState(minutes * 60)

  const { control, handleSubmit, setValue, getValues, formState: { defaultValues } } = useForm<FormDataVerifyCodeLogin>({
    resolver: yupResolver(loginVerifycodeSchema),
    defaultValues: {
      verify_code: INITIAL_CODES_VALUES
    }
  })

  const [sendVerificationCode, setSendVerificationCode] = useState(false)

  const handleKeyDownCode = (event: React.KeyboardEvent<HTMLInputElement>, index: number): void => {
    const inputValue = numberMask(event.currentTarget.value).toString()
    const keyDowned = event.key

    const previusInputs = `verify_code.${index - 1}`

    const prevInput = document.getElementById(previusInputs)

    if (keyDowned === 'Backspace' && !inputValue) {
      setValue(`verify_code.${index}`, '')

      if (prevInput) {
        prevInput.focus()
      }
    }

    const keyDownedIsNumber = isNumber(keyDowned)

    if (keyDownedIsNumber && inputValue.length === 1) {
      handleInputCode(keyDowned, index)
    }
  }

  const handleResendCode = async (): Promise<void> => {
    try {
      const typeResendeCode = {
        simpleLogin: async () => {
          return await getVerificationCode({
            cpf: dataFormLogin?.cpf as string,
            password: dataFormLogin?.password as string,
            verifyCode: '',
            keepAlive: dataFormLogin?.keepAlive
          })
        },
        sso: async () => {
          return await loginWithSSO({
            email: dataFormLogin?.email ?? '' as string
          })
        }
      }

      const type = Boolean(dataFormLogin?.email) || Boolean(dataFormLogin?.cpf && !dataFormLogin?.password) ? 'sso' : 'simpleLogin'

      const response = await typeResendeCode[type]()

      if (response.status === 200) {
        Toastfy.success(response.data.message)
      }
    } catch (error: any) {
      toast.error(error?.response.data.message ?? 'Erro ao reenviar código')
    }
  }

  const handlePasteCode = (event: React.ClipboardEvent<HTMLInputElement>): void => {
    event.preventDefault()

    const pastedText = event.clipboardData.getData('text')
    const digits = `${numberMask(pastedText)}`.split('')

    const getOnlyMax6Digits = digits.slice(0, 6)

    getOnlyMax6Digits.forEach((digit, index) => {
      setValue(`verify_code.${index}`, digit)
    })

    if (getOnlyMax6Digits.length) {
      document.getElementById(`verify_code.${getOnlyMax6Digits.length - 1}`)?.focus()
    }
  }

  const handleFormatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60)
    const secondsLeft = seconds % 60
    return `${minutes}:${secondsLeft.toString().padStart(2, '0')}`
  }

  const handleValidateCode = async (): Promise<void> => {
    try {
      setSendVerificationCode(true)

      const verificationCodes = (getValues().verify_code ?? [])
        .filter(value => value)
        .join('')

      if (verificationCodes.length < 6) {
        throw new Error('Código de verificação inválido')
      }

      await onValidatecode(verificationCodes)
    } catch (error) {
      Toastfy.error('Erro ao validar código de verificação')
    }

    setSendVerificationCode(false)
  }

  const handleInputCode = (code: string, index: number): void => {
    const value = numberMask(code).toString()
    const fields = getValues().verify_code ?? []

    if (value.length === 1 && index < fields.length - 1) {
      document.getElementById(`verify_code.${index + 1}`)?.focus()
    } else {
      if ((value.length === 0 && index > 0) || value.length === 0) {
        document.getElementById(`verify_code.${index - 1}`)?.focus()
      }
    }

    setValue(`verify_code.${index}`, value)
  }

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (timeRemaining > 0) {
  //       setTimeRemaining(timeRemaining - 1)
  //     } else {
  //       clearInterval(intervalId)
  //     }
  //   }, 1000)

  //   return () => {
  //     clearInterval(intervalId)
  //   }
  // }, [timeRemaining])

  useEffect(() => {
    setValue('verify_code', INITIAL_CODES_VALUES)

    if (show) {
      setTimeRemaining(600)
    }
  }, [show])

  return (
    <SideBar
      show={show}
      title={getInternalizeText(keysMessages.AuthSidebarFillCode)}
      onHide={onHide}
    >
      <form onSubmit={handleSubmit(handleValidateCode)} className='mt-20'>
        <div className='d-flex justify-content-between'>
          {(getValues().verify_code ?? []).map((_, index) => {
            const idInputVerifyCode = `verify_code.${index}`

            return (
              <Controller
                key={index}
                name={`verify_code.${index}`}
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    id={idInputVerifyCode}
                    type='text'
                    className='container-input--verifycode text-dark'
                    maxLength={1}
                    value={field.value}
                    onChange={(e) => { handleInputCode(e.target.value, index) }}
                    ref={(el) => { field.ref(el) }}
                    onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                      handleKeyDownCode(event, index)
                    }}
                    onPaste={handlePasteCode}
                  />
                )}
              />
            )
          })}
        </div>

        <div className='d-flex justify-content-end'>
          <Button type='submit' variant='dark' rounded size='sm' className='mt-20 colors-blue-medium' loading={sendVerificationCode} disabled={sendVerificationCode} >
            <FiCheck className='icon' />
            {getInternalizeText(systemMessages.keysMessages.AuthValidateCode)}
          </Button>
        </div>
      </form>

      <div className='d-flex justify-content-end mt-10'>
        <a href='#' onClick={handleResendCode} className='stepper--validation-notreceivedcode'>{getInternalizeText(systemMessages.keysMessages.AuthRegisterNotReceiveCode)}</a>
      </div>

      {/* <div className='position-absolute bottom-0 mb-5 font-weigth-regular text-dark'>
        <p className='fs-5 text-dark'>{getInternalizeText(systemMessages.keysMessages.MessageCodeValidate)}</p>
        <p className='fs-5 text-dark'>{getInternalizeText(systemMessages.keysMessages.MessageCodeValidateRemaining)} {handleFormatTime(timeRemaining)}</p>
      </div> */}
    </SideBar>
  )
}

export default SideBarVerifyCode
