import { generateUniqueId } from 'app/shared/helpers/utils'
import { create } from 'zustand'

interface FormBuilderStore {
  inputsForm: any[]
  geyByUuid: (uuid: string) => any
  setInputsForm: (inputsForm: any[]) => void
  setInputEditing: (uuid: string) => void
  addInputForm: (inputForm: any) => void
  removeInputForm: (uuid: string) => void
  clearInputsForm: () => void
  editInputForm: (uuid: string, inputForm: any) => void
}

export const useFormBuilderStore = create<FormBuilderStore>((set, get) => ({
  inputsForm: [],
  geyByUuid: (uuid: string) => {
    const { inputsForm } = get()
    return inputsForm.find((inputForm) => inputForm.uuid === uuid)
  },
  setInputsForm: (inputsForm: any[]) => {
    set({ inputsForm })
  },
  setInputEditing: (uuid: string) => {
    const { inputsForm } = get()
    const newInputsForm = inputsForm.map((inputForm) => {
      inputForm.isEditing = inputForm.uuid === uuid
      return inputForm
    })
    set({ inputsForm: newInputsForm })
  },
  addInputForm: (inputForm: any) => {
    const { inputsForm } = get()
    inputsForm.push({
      ...inputForm,
      uuid: generateUniqueId()
    })
    set({ inputsForm })
  },
  removeInputForm: (uuid: string) => {
    const { inputsForm } = get()
    const newInputsForm = inputsForm.filter((inputForm) => inputForm.uuid !== uuid)
    set({ inputsForm: newInputsForm })
  },
  editInputForm: (uuid: string, inputForm: any) => {
    const { inputsForm } = get()
    const index = inputsForm.findIndex((inputForm) => inputForm.uuid === uuid)
    inputsForm[index] = {
      ...inputForm,
      uuid
    }
    set({ inputsForm })
  },
  clearInputsForm: () => {
    set({ inputsForm: [] })
  }
}))
