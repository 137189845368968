/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { type IDefaultRequestPaginated } from 'app/modules/auth'
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { apiProcessos } from 'app/shared/services/api'

export interface IDepartmentRequest {
  descricao: string
  status: number
}

export interface IDepartmentType {
  DEPID: number
  DEPNOME: string
  DEPSTATUS: boolean
}

const endpointDepartments = '/departamento'

const GetDepartment = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })

  return useSwr<IDefaultRequestPaginated<IDepartmentType>>(
    `${endpointDepartments}/list-all?${queryParams}`,
    apiProcessos
  )
}

const getDepartment = async (params: object = {}) => {
  return await apiProcessos.get<IDefaultRequestPaginated<IDepartmentType>>(
    `${endpointDepartments}/list-all`,
    { params }
  )
}

const updateDepartment = async (
  id: number,
  payload: { nome?: string, status?: number }
) => {
  return await apiProcessos.put(`${endpointDepartments}/update/${id}`, payload)
}

const linkDepartmentToRole = async (
  CARID: number,
  payload: { departamentosId: number[] }
) => {
  return await apiProcessos.put(`${endpointDepartments}/cargo/manage/${CARID}`, payload)
}

const createDepartment = async (
  payload: { nome: string }
) => {
  return await apiProcessos.post(`${endpointDepartments}/create`, payload)
}

export default {
  GetDepartment,
  getDepartment,
  updateDepartment,
  linkDepartmentToRole,
  createDepartment
}
