import { useEffect } from 'react'
import { BiSolidCloudUpload } from 'react-icons/bi'

// import Toastfy from 'app/shared/helpers/Toastfy/Toastfy'
import { generateUniqueId } from 'app/shared/helpers/utils'
import { type ValidFileExtensions } from 'app/shared/helpers/validateValues'
import clsx from 'clsx'

import './styles.scss'
import OverlayTooltip from '../OverlayTooltip/OverlayTooltip'

interface IInputFileGeneric {
  labelsConfig?: {
    label?: string
  }
  inputConfigs: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  onClick?: () => void
  hookFormFunctions?: any
  sizeMax?: number
  typeFiles?: ValidFileExtensions
}

const InputFileGeneric = ({
  labelsConfig, inputConfigs, hookFormFunctions
}: IInputFileGeneric): JSX.Element => {
  const dynamicId = (inputConfigs?.id ?? inputConfigs?.name) ?? `input-file_${generateUniqueId()}`

  if (!hookFormFunctions) {
    return (
      <>
        <label className='form-label fs-6 fw-bolder text-dark'>{labelsConfig?.label ?? 'Envio de arquivo'}</label>

        <label htmlFor={dynamicId} className='input--file-change-file'>
          <BiSolidCloudUpload className='icon' />
          <span className='text'>Escolher arquivo</span>
        </label>

        <input
          {...inputConfigs}
          type="file"
          id={dynamicId}
          className='d-none'
        />
      </>
    )
  }

  const getFiles = Array.from(hookFormFunctions?.watch(inputConfigs?.name) ?? [])
  useEffect(() => {
    // const file = getFiles[0] as File
    // if (getFiles.length > 0) {
    //   const maxSizeInBytes = 1024 * 1024 * 5
    //   if (file.size > maxSizeInBytes) {
    //     Toastfy.warning('Tamanho superior à 5MB')
    //     hookFormFunctions?.setValue('file', '')
    //     return
    //   }

    //   const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/jpg']
    //   if (!allowedMimeTypes?.includes(file.type)) {
    //     Toastfy.warning('Formato de imagem inválido')
    //     hookFormFunctions?.setValue('file', '')
    //   }
    // }
  }, [getFiles])

  const getFilesName = getFiles.map(({ name }: any) => name).join(', ')

  const labelToFilesSelected = getFilesName.length ? getFilesName : 'Escolher arquivo'

  return (
    <>
      <label className={clsx('form-label fs-6 fw-bolder text-dark', {
        required: inputConfigs?.required
      })}>{labelsConfig?.label ?? 'Envio de arquivo'}</label>

      <label htmlFor={dynamicId} className='input--file-change-file'>
        <BiSolidCloudUpload className='icon' />
        <OverlayTooltip message={labelToFilesSelected}>
          <span className='text'>
            {labelToFilesSelected?.length >= 20 ? labelToFilesSelected?.substring(0, 20) + '...' : labelToFilesSelected}
          </span>
        </OverlayTooltip>
      </label>

      <input
        {...inputConfigs}
        required={false}
        type="file" id={dynamicId}
        className='d-none'
      />

      <div className='fv-plugins-message-container'>
        <span role='alert'>{hookFormFunctions?.formState?.errors?.[dynamicId]?.message}</span>
      </div>
    </>

  )
}

export default InputFileGeneric
