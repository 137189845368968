import { keysMessages } from "../keysMessages";

export const ptText = {
  [keysMessages.Password]: "Senha",
  [keysMessages.ButtonCancel]: "Cancelar",
  [keysMessages.ButtonToSendEmail]: "Enviar e-mail",
  [keysMessages.ButtonClose]: "Fechar",
  [keysMessages.ButtonBack]: "Voltar",
  [keysMessages.ButtonSave]: "Salvar",
  [keysMessages.FieldRequired]: "Campo obrigatório",
  [keysMessages.MinLength]: "Tamanho mínimo para {name} é {min}",
  [keysMessages.MaxLength]: "Tamanho máximo para {name} é {max}",
  [keysMessages.AuthAccessSystem]: "Acesso ao Sistema",
  [keysMessages.AuthPageValidationCode]: "Preencha os campos com o código recebido por E-mail",
  [keysMessages.AuthRegisterNotReceiveCode]: "Não recebeu? Reenviar código",
  [keysMessages.AuthPassword]: "Senha",
  [keysMessages.AuthEnterOnlyNumbers]: "Digite somente números",
  [keysMessages.AuthEnterYourSecurityPassword]: "Digite sua senha de segurança",
  [keysMessages.AuthStayLoggedIn]: "Manter-me conectado",
  [keysMessages.AuthSignInWith]: "Entrar com",
  [keysMessages.AuthSignInWithGoogle]: "Entrar com Conta Google",
  [keysMessages.AuthFirstAccess]: "Primeiro acesso?",
  [keysMessages.AuthPrivacePolicy]: "Política de Privacidade",
  [keysMessages.AuthNeededHelp]: "Precisa de ajuda?",
  [keysMessages.MessageCodeValidate]: "Este código é válido por 10 minutos",
  [keysMessages.MessageCodeValidateRemaining]: "Tempo restante: ",
  [keysMessages.AuthValidateCode]: "Validar Código",
  [keysMessages.RegisterTitle]: "Preencha os campos para liberação do acesso ao sistema",
  [keysMessages.Confirm]: 'Finalizar',
  [keysMessages.Next]: 'Avançar',
  [keysMessages.Previous]: 'Anterior',
  [keysMessages.AuthRegisterTitleStepperCompany]: 'Dados da Empresa',
  [keysMessages.AuthRegisterTitleStepperCompanyCPF]: 'Empresas vinculadas a este CPF',

  [keysMessages.AuthRegisterCPFInvalid]: 'CPF inválido',
  [keysMessages.AuthRegisterLabelCorporateReason]: 'Razão Social',
  [keysMessages.AuthRegisterLabelCCM]: 'Inscrição Municipal',
  [keysMessages.AuthRegisterLabelOpeningDate]: 'Data de Abertura',
  [keysMessages.AuthRegisterLabelButtonAddCompany]: 'Adicionar Empresa',
  [keysMessages.AuthRegisterLabelInformationBasic]: 'Informações Básicas',
  [keysMessages.AuthRegisterLabelName]: 'Nome',
  [keysMessages.AuthRegisterLabelBirthdate]: 'Data de Nascimento',
  [keysMessages.AuthRegisterLabel18Years]: 'Você deve ser maior de 18 anos',
  [keysMessages.AuthRegisterLabelFielSending]: 'Envio de Arquivos',
  [keysMessages.AuthRegisterLabelDataToContact]: 'Dados para Contato',
  [keysMessages.AuthRegisterLabelCellPhone]: 'Celular',
  [keysMessages.AuthRegisterLabelCellPhoneFix]: 'Telefone Fixo',
  [keysMessages.AuthRegisterLabelMaximumSize]: 'Tamanho máximo permitido {size} MB',
  [keysMessages.AuthRegisterLabelLocalization]: 'Localização',
  [keysMessages.AuthRegisterLabelAddress]: 'Endereço',
  [keysMessages.AuthRegisterLabelNumber]: 'Número',
  [keysMessages.AuthRegisterLabelComplement]: 'Complemento',
  [keysMessages.AuthRegisterLabelNeighborhood]: 'Bairro',
  [keysMessages.AuthRegisterLabelCity]: 'Cidade',
  [keysMessages.AuthRegisterLabelState]: 'UF',

  [keysMessages.AuthLabelPlaceholderEnterYourName]: 'Insira seu nome',
  [keysMessages.AuthLabelPlaceholderEnterYourBirthdate]: 'Insira sua data de nascimento',
  [keysMessages.AuthLabelPlaceholderEnterYourPassword]: 'Insira sua senha',
  [keysMessages.AuthLabelPlaceholderEnterYourEmail]: 'Insira seu e-mail',
  [keysMessages.AuthLabelPlaceholderEnterYourCellPhone]: 'Insira o número do celular',
  [keysMessages.AuthLabelPlaceholderEnterYourCellPhoneFix]: 'Insira o número do tel. fixo',
  [keysMessages.AuthLabelPlaceholderEnterYourAddress]: 'Insira o endereço',
  [keysMessages.AuthLabelPlaceholderEnterYourNumber]: 'Insira o número',
  [keysMessages.AuthLabelPlaceholderEnterYourComplement]: 'Insira o complemento',
  [keysMessages.AuthLabelPlaceholderEnterYourNeighborhood]: 'Insira o bairro',
  [keysMessages.AuthLabelPlaceholderEnterYourCity]: 'Insira a cidade',

  [keysMessages.TranslatorSelect]: "Selecione seu idioma",
  [keysMessages.MenuNew]: "Novo",
  [keysMessages.MenuActions]: "Ações",
  [keysMessages.MenuCreatePost]: "Criar novo post",
  [keysMessages.MenuPages]: "Páginas",
  [keysMessages.MenuFeatures]: "Recursos",
  [keysMessages.MenuApps]: "Aplicativos",
  [keysMessages.MenuDashboard]: "Painel",
  [keysMessages.AuthGeneralOr]: "Ou",
  [keysMessages.AuthGeneralSubmitButton]: "Enviar",
  [keysMessages.AuthGeneralNoAccount]: "Não possui uma conta?",
  [keysMessages.AuthGeneralSignupButton]: "Cadastrar-se",
  [keysMessages.AuthGeneralForgotButton]: "Esqueceu a senha",
  [keysMessages.AuthGeneralBackButton]: "Voltar",
  [keysMessages.AuthGeneralPrivacy]: "Privacidade",
  [keysMessages.AuthGeneralLegal]: "Legal",
  [keysMessages.AuthGeneralContact]: "Contato",
  [keysMessages.AuthLoginTitle]: "Acessar Conta",
  [keysMessages.AuthLoginButton]: "Entrar",
  [keysMessages.AuthForgotTitle]: "Esqueceu a Senha?",
  [keysMessages.AuthForgotDesc]: "Insira seu e-mail para redefinir sua senha",
  [keysMessages.AuthForgotSuccess]: "Sua conta foi redefinida com sucesso.",
  [keysMessages.AuthRegisterTitle]: "Cadastrar-se",
  [keysMessages.AuthRegisterDesc]: "Digite seus dados para criar sua conta",
  [keysMessages.AuthRegisterSuccess]: "Sua conta foi cadastrada com sucesso.",
  [keysMessages.AuthInputEmail]: "E-mail",
  [keysMessages.AuthInputFullname]: "Nome Completo",
  [keysMessages.AuthInputPassword]: "Senha",
  [keysMessages.AuthInputConfirmPassword]: "Confirmar Senha",
  [keysMessages.AuthInputUsername]: "Nome de Usuário",
  [keysMessages.AuthValidationInvalid]: "{name} não é válido",
  [keysMessages.AuthValidationRequired]: "{name} é obrigatório",
  [keysMessages.AuthValidationMinLength]: "O tamanho mínimo para {name} é {min}",
  [keysMessages.AuthValidationAgreementRequired]: "É necessário aceitar os termos e condições",
  [keysMessages.AuthValidationNotFound]: "{name} solicitado não encontrado",
  [keysMessages.AuthValidationInvalidLogin]: "Os detalhes de login estão incorretos",
  [keysMessages.AuthValidationRequiredField]: "Campo obrigatório",
  [keysMessages.AuthValidationMinLengthField]: "Tamanho mínimo do campo:",
  [keysMessages.AuthValidationMaxLengthField]: "Tamanho máximo do campo:",
  [keysMessages.AuthValidationInvalidField]: "Campo não é válido",
  [keysMessages.EcommerceCommonSelectedRecordsCount]: "Contagem de registros selecionados: ",
  [keysMessages.EcommerceCommonAll]: "Todos",
  [keysMessages.EcommerceCommonSuspended]: "Suspenso",
  [keysMessages.EcommerceCommonActive]: "Ativo",
  [keysMessages.EcommerceCommonFilter]: "Filtrar",
  [keysMessages.EcommerceCommonByStatus]: "por Status",
  [keysMessages.EcommerceCommonByType]: "por Tipo",
  [keysMessages.EcommerceCommonBusiness]: "Negócio",
  [keysMessages.EcommerceCommonIndividual]: "Individual",
  [keysMessages.EcommerceCommonSearch]: "Pesquisar",
  [keysMessages.EcommerceCommonInAllFields]: "em todos os campos",
  [keysMessages.EcommerceEcommerce]: "Comércio Eletrônico",
  [keysMessages.EcommerceCustomersCustomers]: "Clientes",
  [keysMessages.EcommerceCustomersCustomersList]: "Lista de Clientes",
  [keysMessages.EcommerceCustomersNewCustomer]: "Novo Cliente",
  [keysMessages.EcommerceCustomersDeleteCustomerSimpleTitle]: "Excluir Cliente",
  [keysMessages.EcommerceCustomersDeleteCustomerSimpleDescription]: "Tem certeza de que deseja excluir permanentemente este cliente?",
  [keysMessages.EcommerceCustomersDeleteCustomerSimpleWaitDescription]: "Excluindo cliente...",
  [keysMessages.EcommerceCustomersDeleteCustomerSimpleMessage]: "O cliente foi excluído",
  [keysMessages.EcommerceCustomersDeleteCustomerMultyTitle]: "Excluir Clientes",
  [keysMessages.EcommerceCustomersDeleteCustomerMultyDescription]: "Tem certeza de que deseja excluir permanentemente os clientes selecionados?",
  [keysMessages.EcommerceCustomersDeleteCustomerMultyWaitDescription]: "Excluindo clientes...",
  [keysMessages.EcommerceCustomersDeleteCustomerMultyMessage]: "Os clientes selecionados foram excluídos",
  [keysMessages.EcommerceCustomersUpdateStatusTitle]: "O status foi atualizado para os clientes selecionados",
  [keysMessages.EcommerceCustomersUpdateStatusMessage]: "O status dos clientes selecionados foi atualizado com sucesso",
  [keysMessages.EcommerceCustomersEditUpdateMessage]: "O cliente foi atualizado",
  [keysMessages.EcommerceCustomersEditAddMessage]: "O cliente foi criado",

  [keysMessages.AuthSidebarFillCode]: "Preencha os campos com o código recebido por e-mail",

  [keysMessages.Auth_SelectCity]: "Selecione o município",

  [keysMessages.DashboardIndicatorModulo]: "Indicadores por módulo",

  [keysMessages.DashboardPageInitial]: "Página Inicial",
  [keysMessages.DashboardPlaceholderInputSearch]: "O que você procura?",

  [keysMessages.AuthSideBarMenuProccess]: "Processos",
  [keysMessages.AuthSideBarMenuSIGISS]: "SIGISS",

  [keysMessages.AuthLabelThemeLight]: "Claro",
  [keysMessages.AuthLabelThemeDark]: "Escuro",
  [keysMessages.AuthLabelThemeSystem]: "Sistema",

  [keysMessages.AuthLabelKanbanTodDo]: "A fazer",
  [keysMessages.AuthLabelKanbanInProcess]: "Em progresso",
  [keysMessages.AuthLabelKanbanDone]: "Concluído",
  [keysMessages.AuthLabelKanbanBlock]: "Bloqueado",
  [keysMessages.AuthLabelKanbanNewTask]: "Adicionar tarefa",
  [keysMessages.AuthLabelKanbanConfigColumn]: "Configurações da coluna",
  [keysMessages.AuthLabelKanbanSelectedColor]: "Selecione a cor da coluna:",

  [keysMessages.AuthProcessSessionExpired]: "Sessão expira em",

  [keysMessages.AuthTitleFormRequerimento]: "Formulário de requerimento",
  [keysMessages.AuthLabelPolicyPrivacy]: "Política de Privacidade de Dados!",
  [keysMessages.AuthLabelProtectionData]: "A proteção de dados é nossa prioridade. Para obter mais informações sobre como trataremos seus dados, acesse nossa Política de",
  [keysMessages.AuthLabelProtectionDataLink]: "Privacidade de Dados",
}
