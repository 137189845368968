/* eslint-disable @typescript-eslint/explicit-function-return-type */
// import { type IDefaultRequest } from 'app/modules/auth'
// import { serializeQueryParams } from 'app/shared/helpers/utils'
// import { useSwr } from 'app/shared/hooks/useSwr'
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { apiProcessos } from 'app/shared/services/api'
import { type ICreateForm } from 'app/shared/types/processos/formBuilderTypes'

export interface IFormBuilded {
  FORMTIPID: number
  FORMTIPNOME: string
}

const endpointPositions = 'formulario'

const GetForms = async (page?: number, params: object = {}) => {
//   const queryParams = serializeQueryParams({ page, ...params })
//   return useSwr<IDefaultRequest<ICreateForm>>(
//         `${endpointPositions}/list-all?${queryParams}`,
//         apiProcessos
//   )
  const queryParams = serializeQueryParams({ page, ...params })
  return await apiProcessos.get(`${endpointPositions}/list-all?${queryParams}`, {
    data: {
      ...params,
      page
    }
  })
}

const createForm = async (data: ICreateForm) => {
  return await apiProcessos.post(`${endpointPositions}/create`, data)
}

export default {
  GetForms,
  createForm
}
