import OverlayTrigger, {
  type OverlayTriggerProps
} from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

interface ITriggerTooltip extends OverlayTriggerProps {
  message?: string
  delay?: { show: number, hide: number }
  placement?: 'top' | 'bottom' | 'left' | 'right'
}

const OverlayTooltip = ({ message, placement = 'top', delay = { show: 250, hide: 400 }, children, ...props }: ITriggerTooltip | any): JSX.Element => {
  return (
    <OverlayTrigger
      placement={placement}
      delay={delay}
      overlay={<Tooltip>{message}</Tooltip>}
      {...props}
    >
      {children}
    </OverlayTrigger>
  )
}

export default OverlayTooltip
