/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { type IDefaultRequest, type IDefaultRequestObject } from 'app/shared/types/common'
import { type IListAllCompanys, type ICompanyCityHall, type IRegisterClients } from 'app/shared/types/plataform/clients'
import { type AxiosResponse } from 'axios'
import { type KeyedMutator } from 'swr'

import { apiServerPlataforma } from '../api'

const endpointClient = '/prefeitura'

const GetAllCompanys = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IListAllCompanys>>(
    `${endpointClient}/list-all?${queryParams}`,
    apiServerPlataforma
  )
}

const GetCompany = (cnpj: string) => {
  return useSwr<IDefaultRequestObject<ICompanyCityHall>>(
    `${endpointClient}/cnpj/${cnpj}`,
    apiServerPlataforma
  )
}

const getCnpj = async (cnpj: string): Promise<AxiosResponse<IDefaultRequestObject<ICompanyCityHall>, any>> => {
  return await apiServerPlataforma.get<IDefaultRequestObject<ICompanyCityHall>>(`${endpointClient}/cnpj/${cnpj}`)
}

const createClient = async (payload: IRegisterClients) => {
  const response = await apiServerPlataforma.post(`${endpointClient}/create`, payload)
  return response
}

const updateClient = async (
  payload: IRegisterClients,
  PESID: number
) => {
  const response = await apiServerPlataforma.put(`${endpointClient}/update/${PESID}`, payload)
  return response
}

const changeStatusCliente = async (
  CLIID: number,
  mutate: KeyedMutator<IDefaultRequest<IListAllCompanys>>
) => {
  const response = await apiServerPlataforma.put(`${endpointClient}/status/${CLIID}`)
  if (response.status === 200) void mutate()
  return response
}

export default {
  GetAllCompanys,
  GetCompany,
  getCnpj,
  createClient,
  updateClient,
  changeStatusCliente
}
