import { useState } from 'react'
import { BiSolidMessageDetail } from 'react-icons/bi'

import { capitalizeFirstLetter } from 'app/shared/helpers/utils'
import { type INotification } from 'app/shared/types/plataform/notification'

import Badge from '../Badge/Badge'
import ReactModal from '../ReactModal/ReactModal'
import ViewPDF from '../ViewPDF/ViewPDF'

import './styles.scss'

interface ICardNotification {
  notification: INotification
  onClose: () => void
}

const CardNotification = ({ notification, onClose }: ICardNotification): JSX.Element => {
  const [openModalPDF, setOpenModalPDF] = useState<{
    open: boolean
    link: string
  }>({
    open: false,
    link: ''
  })

  return (
    <>
      <div className='container-card--notification'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex gap-2'>
            <BiSolidMessageDetail className="icon" color='1351b4' />
            <small>Notificação</small>
          </div>
          {notification?.NOTDATA?.length > 0 && (
            <small
              className='cursor-pointer'
              onClick={() => { setOpenModalPDF({ open: true, link: JSON.parse(notification.NOTDATA)?.file }) }}>
              Clique aqui
            </small>
          )}
        </div>
        <h5 className='my-5 fw-bold'>{capitalizeFirstLetter(notification.NOTMENSAGEM ?? '')}</h5>
        <Badge backgroundColor='#1351b4' modeDark={false} rounded={false}>{notification.MODULO.MODDESCRICAO}</Badge>
      </div>

      <ReactModal
        headerName='Visualizar PDF'
        show={openModalPDF.open}
        onClose={() => { setOpenModalPDF({ open: false, link: openModalPDF.link }) }}
      >
        <ViewPDF link={openModalPDF.link} />
      </ReactModal>
    </>
  )
}

export default CardNotification
