
import { sideBarAuthDashboard } from './componentsMessages/auth/components/sideBarAuthDashboard';
import { sideBarVerifyCode } from './componentsMessages/auth/components/sideBarVerifyCode';
import { selectCity } from './componentsMessages/auth/selectCity';
import { requerimento } from './componentsMessages/requerimento';

export const keysMessages = {
  Password: "Password",
  Next: "NEXT",
  Previous: "PREVIOUS",
  Confirm: "CONFIRM",
  ButtonBack: "BUTTON_BACK",
  ButtonCancel: "BUTTON_CANCEL",
  ButtonToSendEmail: "BUTTON_TO_SEND_EMAIL",
  ButtonClose: "BUTTON_CLOSE",
  ButtonSave: "BUTTON_SAVE",
  FieldRequired: "FIELD_REQUIRED",
  MinLength: "MIN_LENGTH",
  MaxLength: "MAX_LENGTH",
  AuthAccessSystem: "AUTH.ACCESS_SYSTEM",
  AuthPageValidationCode: "AUTH.PAGE_VALIDATION_CODE",
  AuthRegisterNotReceiveCode: "AUTH.REGISTER_NOT_RECEIVE_CODE",
  AuthPassword: "AUTH.PASSWORD",
  AuthEnterOnlyNumbers: "AUTH.ENTER_ONLY_NUMBERS",
  AuthEnterYourSecurityPassword: "AUTH.ENTER_YOUR_SECURITY_PASSWORD",
  AuthStayLoggedIn: "AUTH.STAY_LOGGED_IN",
  AuthSignInWith: "AUTH.SIGN_IN_WITH",
  AuthSignInWithGoogle: "AUTH.SIGN_IN_WITH_GOOGLE",
  AuthFirstAccess: "AUTH.FIRST_ACCESS",
  AuthPrivacePolicy: "AUTH.PRIVACE_POLICY",
  AuthNeededHelp: "AUTH.NEEDED_HELP",
  MessageCodeValidate: "MESSAGE_CODE_VALIDATE",
  MessageCodeValidateRemaining: "MESSAGE_CODE_VALIDATE_REMAINING",
  AuthValidateCode: "AUTH.VALIDATE_CODE",
  RegisterTitle: "REGISTER.TITLE",
  AuthRegisterTitleStepperCompany: "AUTH.REGISTER_TITLE_STEPPER_COMPANY",
  AuthRegisterTitleStepperCompanyCPF: "AUTH.REGISTER_TITLE_STEPPER_COMPANY_CPF",

  AuthRegisterCPFInvalid: "AUTH.REGISTER_CPF_INVALID",
  AuthRegisterLabelCorporateReason: "AUTH.REGISTER_LABEL_CORPORATE_REASON",
  AuthRegisterLabelCCM: "AUTH.REGISTER_LABEL_CCM",
  AuthRegisterLabelOpeningDate: "AUTH.REGISTER_LABEL_OPENING_DATE",
  AuthRegisterLabelButtonAddCompany: "AUTH.REGISTER_LABEL_BUTTON_ADD_COMPANY",
  AuthRegisterLabelInformationBasic: "AUTH.REGISTER_LABEL_INFORMATION_BASIC",
  AuthRegisterLabelName: "AUTH.REGISTER_LABEL_NAME",
  AuthRegisterLabelBirthdate: "AUTH.REGISTER_LABEL_BIRTHDATE",
  AuthRegisterLabel18Years: "AUTH.REGISTER_LABEL_18_YEARS",
  AuthRegisterLabelFielSending: "AUTH.REGISTER_LABEL_FIELD_SENDING",
  AuthRegisterLabelDataToContact: "AUTH.REGISTER_LABEL_DATA_TO_CONTACT",
  AuthRegisterLabelCellPhone: "AUTH.REGISTER_LABEL_CELL_PHONE",
  AuthRegisterLabelCellPhoneFix: "AUTH.REGISTER_LABEL_CELL_PHONE_FIX",
  AuthRegisterLabelMaximumSize: "AUTH.REGISTER_LABEL_MAXIMUM_SIZE",
  AuthRegisterLabelLocalization: "AUTH.REGISTER_LABEL_LOCALIZATION",
  AuthRegisterLabelAddress: "AUTH.REGISTER_LABEL_ADDRESS",
  AuthRegisterLabelNumber: "AUTH.REGISTER_LABEL_NUMBER",
  AuthRegisterLabelComplement: "AUTH.REGISTER_LABEL_COMPLEMENT",
  AuthRegisterLabelNeighborhood: "AUTH.REGISTER_LABEL_NEIGHBORHOOD",
  AuthRegisterLabelCity: "AUTH.REGISTER_LABEL_CITY",
  AuthRegisterLabelState: "AUTH.REGISTER_LABEL_STATE",

  AuthLabelPlaceholderEnterYourName: "AUTH.LABEL_PLACEHOLDER_ENTER_YOUR_NAME",
  AuthLabelPlaceholderEnterYourBirthdate: "AUTH.LABEL_PLACEHOLDER_ENTER_YOUR_BIRTHDATE",
  AuthLabelPlaceholderEnterYourPassword: "AUTH.LABEL_PLACEHOLDER_ENTER_YOUR_PASSWORd",
  AuthLabelPlaceholderEnterYourEmail: "AUTH.LABEL_PLACEHOLDER_ENTER_YOUR_EMAIL",
  AuthLabelPlaceholderEnterYourCellPhone: "AUTH.LABEL_PLACEHOLDER_ENTER_YOUR_CELL_PHONE",
  AuthLabelPlaceholderEnterYourCellPhoneFix: "AUTH.LABEL_PLACEHOLDER_ENTER_YOUR_CELL_PHONE_FIX",
  AuthLabelPlaceholderEnterYourAddress: "AUTH.LABEL_PLACEHOLDER_ENTER_YOUR_ADDRESS",
  AuthLabelPlaceholderEnterYourNumber: "AUTH.LABEL_PLACEHOLDER_ENTER_YOUR_NUMBER",
  AuthLabelPlaceholderEnterYourComplement: "AUTH.LABEL_PLACEHOLDER_ENTER_YOUR_COMPLEMENT",
  AuthLabelPlaceholderEnterYourNeighborhood: "AUTH.LABEL_PLACEHOLDER_ENTER_YOUR_NEIGHBORHOOD",
  AuthLabelPlaceholderEnterYourCity: "AUTH.LABEL_PLACEHOLDER_ENTER_YOUR_CITY",
  AuthLabelPlaceholderEnterYourState: "AUTH.LABEL_PLACEHOLDER_ENTER_YOUR_STATE",

  TranslatorSelect: "TRANSLATOR.SELECT",
  MenuNew: "MENU.NEW",
  MenuActions: "MENU.ACTIONS",
  MenuCreatePost: "MENU.CREATE_POST",
  MenuPages: "MENU.PAGES",
  MenuFeatures: "MENU.FEATURES",
  MenuApps: "MENU.APPS",
  MenuDashboard: "MENU.DASHBOARD",
  AuthGeneralOr: "AUTH.GENERAL.OR",
  AuthGeneralSubmitButton: "AUTH.GENERAL.SUBMIT_BUTTON",
  AuthGeneralNoAccount: "AUTH.GENERAL.NO_ACCOUNT",
  AuthGeneralSignupButton: "AUTH.GENERAL.SIGNUP_BUTTON",
  AuthGeneralForgotButton: "AUTH.GENERAL.FORGOT_BUTTON",
  AuthGeneralBackButton: "AUTH.GENERAL.BACK_BUTTON",
  AuthGeneralPrivacy: "AUTH.GENERAL.PRIVACY",
  AuthGeneralLegal: "AUTH.GENERAL.LEGAL",
  AuthGeneralContact: "AUTH.GENERAL.CONTACT",
  AuthLoginTitle: "AUTH.LOGIN.TITLE",
  AuthLoginButton: "AUTH.LOGIN.BUTTON",
  AuthForgotTitle: "AUTH.FORGOT.TITLE",
  AuthForgotDesc: "AUTH.FORGOT.DESC",
  AuthForgotSuccess: "AUTH.FORGOT.SUCCESS",
  AuthRegisterTitle: "AUTH.REGISTER.TITLE",
  AuthRegisterDesc: "AUTH.REGISTER.DESC",
  AuthRegisterSuccess: "AUTH.REGISTER.SUCCESS",
  AuthInputEmail: "AUTH.INPUT.EMAIL",
  AuthInputFullname: "AUTH.INPUT.FULLNAME",
  AuthInputPassword: "AUTH.INPUT.PASSWORD",
  AuthInputConfirmPassword: "AUTH.INPUT.CONFIRM_PASSWORD",
  AuthInputUsername: "AUTH.INPUT.USERNAME",
  AuthValidationInvalid: "AUTH.VALIDATION.INVALID",
  AuthValidationRequired: "AUTH.VALIDATION.REQUIRED",
  AuthValidationMinLength: "AUTH.VALIDATION.MIN_LENGTH",
  AuthValidationAgreementRequired: "AUTH.VALIDATION.AGREEMENT_REQUIRED",
  AuthValidationNotFound: "AUTH.VALIDATION.NOT_FOUND",
  AuthValidationInvalidLogin: "AUTH.VALIDATION.INVALID_LOGIN",
  AuthValidationRequiredField: "AUTH.VALIDATION.REQUIRED_FIELD",
  AuthValidationMinLengthField: "AUTH.VALIDATION.MIN_LENGTH_FIELD",
  AuthValidationMaxLengthField: "AUTH.VALIDATION.MAX_LENGTH_FIELD",
  AuthValidationInvalidField: "AUTH.VALIDATION.INVALID_FIELD",
  EcommerceCommonSelectedRecordsCount: "ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT",
  EcommerceCommonAll: "ECOMMERCE.COMMON.ALL",
  EcommerceCommonSuspended: "ECOMMERCE.COMMON.SUSPENDED",
  EcommerceCommonActive: "ECOMMERCE.COMMON.ACTIVE",
  EcommerceCommonFilter: "ECOMMERCE.COMMON.FILTER",
  EcommerceCommonByStatus: "ECOMMERCE.COMMON.BY_STATUS",
  EcommerceCommonByType: "ECOMMERCE.COMMON.BY_TYPE",
  EcommerceCommonBusiness: "ECOMMERCE.COMMON.BUSINESS",
  EcommerceCommonIndividual: "ECOMMERCE.COMMON.INDIVIDUAL",
  EcommerceCommonSearch: "ECOMMERCE.COMMON.SEARCH",
  EcommerceCommonInAllFields: "ECOMMERCE.COMMON.IN_ALL_FIELDS",
  EcommerceEcommerce: "ECOMMERCE.ECOMMERCE",
  EcommerceCustomersCustomers: "ECOMMERCE.CUSTOMERS.CUSTOMERS",
  EcommerceCustomersCustomersList: "ECOMMERCE.CUSTOMERS.CUSTOMERS_LIST",
  EcommerceCustomersNewCustomer: "ECOMMERCE.CUSTOMERS.NEW_CUSTOMER",
  EcommerceCustomersDeleteCustomerSimpleTitle: "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.TITLE",
  EcommerceCustomersDeleteCustomerSimpleDescription: "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.DESCRIPTION",
  EcommerceCustomersDeleteCustomerSimpleWaitDescription: "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.WAIT_DESCRIPTION",
  EcommerceCustomersDeleteCustomerSimpleMessage: "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.MESSAGE",
  EcommerceCustomersDeleteCustomerMultyTitle: "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.TITLE",
  EcommerceCustomersDeleteCustomerMultyDescription: "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.DESCRIPTION",
  EcommerceCustomersDeleteCustomerMultyWaitDescription: "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.WAIT_DESCRIPTION",
  EcommerceCustomersDeleteCustomerMultyMessage: "ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.MESSAGE",
  EcommerceCustomersUpdateStatusTitle: "ECOMMERCE.CUSTOMERS.UPDATE_STATUS.TITLE",
  EcommerceCustomersUpdateStatusMessage: "ECOMMERCE.CUSTOMERS.UPDATE_STATUS.MESSAGE",
  EcommerceCustomersEditUpdateMessage: "ECOMMERCE.CUSTOMERS.EDIT.UPDATE_MESSAGE",
  EcommerceCustomersEditAddMessage: "ECOMMERCE.CUSTOMERS.EDIT.ADD_MESSAGE",

  DashboardPageInitial: "DASHBOARD.PAGE_INITIAL",
  DashboardPlaceholderInputSearch: "DASHBOARD.PLACEHOLDER_INPUT_SEARCH",
  DashboardIndicatorModulo: "DASHBOARD.INDICATOR_MODULO",

  ...sideBarVerifyCode,
  ...selectCity,
  ...sideBarAuthDashboard,

  AuthLabelThemeDark: "AuthLabelThemeDark",
  AuthLabelThemeLight: "AuthLabelThemeLight",
  AuthLabelThemeSystem: "AuthLabelThemeSystem",

  AuthLabelKanbanTodDo: "AuthLabelKanbanTodDo",
  AuthLabelKanbanInProcess: "AuthLabelKanbanInProcess",
  AuthLabelKanbanDone: "AuthLabelKanbanDone",
  AuthLabelKanbanBlock: "AuthLabelKanbanBlock",
  AuthLabelKanbanNewTask: "AuthLabelKanbanNewTask",
  AuthLabelKanbanConfigColumn: "AuthLabelKanbanConfigColumn",
  AuthLabelKanbanSelectedColor: "AuthLabelKanbanSelectedColor",

  AuthProcessSessionExpired: "AuthProcessSessionExpired",

  ...requerimento
};
