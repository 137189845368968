import { type AxiosResponse } from 'axios'

import { type IViaCep } from '../types/viaCep'
import { apiViaCep } from './api'

const GetCep = async (cep: number | string): Promise<AxiosResponse<IViaCep, any>> => {
  const queryParam = `/${cep}/json/`
  return await apiViaCep.get(queryParam)
}

export default {
  GetCep
}
