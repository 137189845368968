import { keysMessages } from "../keysMessages"

export const usText = {
  [keysMessages.Password]: "Password",
  [keysMessages.ButtonCancel]: "Cancel",
  [keysMessages.ButtonToSendEmail]: "Send Email",
  [keysMessages.ButtonBack]: "Back",
  [keysMessages.ButtonClose]: "Close",
  [keysMessages.ButtonSave]: "Save",
  [keysMessages.FieldRequired]: "Field is required",
  [keysMessages.MinLength]: "Minimum length for {name} is {min}",
  [keysMessages.MaxLength]: "Maximum length for {name} is {max}",
  [keysMessages.AuthAccessSystem]: "Access to the System",
  [keysMessages.AuthPageValidationCode]: "Fill in the fields with the code received by SMS",
  [keysMessages.AuthRegisterNotReceiveCode]: "Didn't receive? Resend code",
  [keysMessages.AuthPassword]: "Password",
  [keysMessages.AuthEnterOnlyNumbers]: "Enter only numbers",
  [keysMessages.AuthEnterYourSecurityPassword]: "Enter your security password",
  [keysMessages.AuthStayLoggedIn]: "Stay logged in",
  [keysMessages.AuthSignInWith]: "Sign in with",
  [keysMessages.AuthSignInWithGoogle]: "Sign in with Google",
  [keysMessages.AuthFirstAccess]: "First access?",
  [keysMessages.AuthPrivacePolicy]: "Privacy Policy",
  [keysMessages.AuthNeededHelp]: "Need help?",
  [keysMessages.MessageCodeValidate]: "This code is valid for 10 minutes",
  [keysMessages.MessageCodeValidateRemaining]: "Remaining time: ",
  [keysMessages.AuthValidateCode]: "Validate Code",
  [keysMessages.RegisterTitle]: "Fill in the fields to release access to the system",
  [keysMessages.Confirm]: 'Confirm',
  [keysMessages.Next]: 'Next',
  [keysMessages.Previous]: 'Previous',
  [keysMessages.AuthRegisterTitleStepperCompany]: 'Company Data',
  [keysMessages.AuthRegisterTitleStepperCompanyCPF]: 'Companies linked to this CPF',

  [keysMessages.AuthRegisterCPFInvalid]: 'Invalid CPF',
  [keysMessages.AuthRegisterLabelCorporateReason]: 'Corporate Reason',
  [keysMessages.AuthRegisterLabelCCM]: 'Municipal Registration',
  [keysMessages.AuthRegisterLabelOpeningDate]: 'Opening Date',
  [keysMessages.AuthRegisterLabelButtonAddCompany]: 'Add Company',
  [keysMessages.AuthRegisterLabelInformationBasic]: 'Basic Information',
  [keysMessages.AuthRegisterLabelName]: 'Name',
  [keysMessages.AuthRegisterLabelBirthdate]: 'Birthdate',
  [keysMessages.AuthRegisterLabel18Years]: 'You must be over 18 years old',
  [keysMessages.AuthRegisterLabelFielSending]: 'File Sending',
  [keysMessages.AuthRegisterLabelDataToContact]: 'Contact Data',
  [keysMessages.AuthRegisterLabelCellPhone]: 'Cell Phone',
  [keysMessages.AuthRegisterLabelCellPhoneFix]: 'Fixed Phone',
  [keysMessages.AuthRegisterLabelMaximumSize]: 'Maximum size allowed {size} MB',
  [keysMessages.AuthRegisterLabelLocalization]: 'Localization',
  [keysMessages.AuthRegisterLabelAddress]: 'Address',
  [keysMessages.AuthRegisterLabelNumber]: 'Number',
  [keysMessages.AuthRegisterLabelComplement]: 'Complement',
  [keysMessages.AuthRegisterLabelNeighborhood]: 'Neighborhood',
  [keysMessages.AuthRegisterLabelCity]: 'City',
  [keysMessages.AuthRegisterLabelState]: 'UF',

  [keysMessages.AuthLabelPlaceholderEnterYourName]: 'Enter your name',
  [keysMessages.AuthLabelPlaceholderEnterYourBirthdate]: 'Enter your birthdate',
  [keysMessages.AuthLabelPlaceholderEnterYourPassword]: 'Enter your password',
  [keysMessages.AuthLabelPlaceholderEnterYourEmail]: 'Enter your email',
  [keysMessages.AuthLabelPlaceholderEnterYourCellPhone]: 'Enter your cell phone number',
  [keysMessages.AuthLabelPlaceholderEnterYourCellPhoneFix]: 'Enter your fixed phone number',
  [keysMessages.AuthLabelPlaceholderEnterYourAddress]: 'Enter your address',
  [keysMessages.AuthLabelPlaceholderEnterYourNumber]: 'Enter your number',
  [keysMessages.AuthLabelPlaceholderEnterYourComplement]: 'Enter your complement',
  [keysMessages.AuthLabelPlaceholderEnterYourNeighborhood]: 'Enter your neighborhood',
  [keysMessages.AuthLabelPlaceholderEnterYourCity]: 'Enter your city',

  [keysMessages.TranslatorSelect]: "Select your language",
  [keysMessages.MenuNew]: "new",
  [keysMessages.MenuActions]: "Actions",
  [keysMessages.MenuCreatePost]: "Create New Post",
  [keysMessages.MenuPages]: "Pages",
  [keysMessages.MenuFeatures]: "Features",
  [keysMessages.MenuApps]: "Apps",
  [keysMessages.MenuDashboard]: "Dashboard",
  [keysMessages.AuthGeneralOr]: "Or",
  [keysMessages.AuthGeneralSubmitButton]: "Submit",
  [keysMessages.AuthGeneralNoAccount]: "Don't have an account?",
  [keysMessages.AuthGeneralSignupButton]: "Sign Up",
  [keysMessages.AuthGeneralForgotButton]: "Forgot Password",
  [keysMessages.AuthGeneralBackButton]: "Back",
  [keysMessages.AuthGeneralPrivacy]: "Privacy",
  [keysMessages.AuthGeneralLegal]: "Legal",
  [keysMessages.AuthGeneralContact]: "Contact",
  [keysMessages.AuthLoginTitle]: "Login Account",
  [keysMessages.AuthLoginButton]: "Sign In",
  [keysMessages.AuthForgotTitle]: "Forgotten Password?",
  [keysMessages.AuthForgotDesc]: "Enter your email to reset your password",
  [keysMessages.AuthForgotSuccess]: "Your account has been successfully reset.",
  [keysMessages.AuthRegisterTitle]: "Sign Up",
  [keysMessages.AuthRegisterDesc]: "Enter your details to create your account",
  [keysMessages.AuthRegisterSuccess]: "Your account has been successfuly registered.",
  [keysMessages.AuthInputEmail]: "Email",
  [keysMessages.AuthInputFullname]: "Fullname",
  [keysMessages.AuthInputPassword]: "Password",
  [keysMessages.AuthInputConfirmPassword]: "Confirm Password",
  [keysMessages.AuthInputUsername]: "Username",
  [keysMessages.AuthValidationInvalid]: "{name} is not valid",
  [keysMessages.AuthValidationRequired]: "{name} is required",
  [keysMessages.AuthValidationMinLength]: "{name} minimum length is {min}",
  [keysMessages.AuthValidationAgreementRequired]: "Accepting terms & conditions are required",
  [keysMessages.AuthValidationNotFound]: "The requested {name} is not found",
  [keysMessages.AuthValidationInvalidLogin]: "The login detail is incorrect",
  [keysMessages.AuthValidationRequiredField]: "Required field",
  [keysMessages.AuthValidationMinLengthField]: "Minimum field length:",
  [keysMessages.AuthValidationMaxLengthField]: "Maximum field length:",
  [keysMessages.AuthValidationInvalidField]: "Field is not valid",
  [keysMessages.EcommerceCommonSelectedRecordsCount]: "Selected records count: ",
  [keysMessages.EcommerceCommonAll]: "All",
  [keysMessages.EcommerceCommonSuspended]: "Suspended",
  [keysMessages.EcommerceCommonActive]: "Active",
  [keysMessages.EcommerceCommonFilter]: "Filter",
  [keysMessages.EcommerceCommonByStatus]: "by Status",
  [keysMessages.EcommerceCommonByType]: "by Type",
  [keysMessages.EcommerceCommonBusiness]: "Business",
  [keysMessages.EcommerceCommonIndividual]: "Individual",
  [keysMessages.EcommerceCommonSearch]: "Search",
  [keysMessages.EcommerceCommonInAllFields]: "in all fields",
  [keysMessages.EcommerceEcommerce]: "eCommerce",
  [keysMessages.EcommerceCustomersCustomers]: "Customers",
  [keysMessages.EcommerceCustomersCustomersList]: "Customers list",
  [keysMessages.EcommerceCustomersNewCustomer]: "New Customer",
  [keysMessages.EcommerceCustomersDeleteCustomerSimpleTitle]: "Customer Delete",
  [keysMessages.EcommerceCustomersDeleteCustomerSimpleDescription]: "Are you sure to permanently delete this customer?",
  [keysMessages.EcommerceCustomersDeleteCustomerSimpleWaitDescription]: "Customer is deleting...",
  [keysMessages.EcommerceCustomersDeleteCustomerSimpleMessage]: "Customer has been deleted",
  [keysMessages.EcommerceCustomersDeleteCustomerMultyTitle]: "Customers Delete",
  [keysMessages.EcommerceCustomersDeleteCustomerMultyDescription]: "Are you sure to permanently delete selected customers?",
  [keysMessages.EcommerceCustomersDeleteCustomerMultyWaitDescription]: "Customers are deleting...",
  [keysMessages.EcommerceCustomersDeleteCustomerMultyMessage]: "Selected customers have been deleted",
  [keysMessages.EcommerceCustomersUpdateStatusTitle]: "Status has been updated for selected customers",
  [keysMessages.EcommerceCustomersUpdateStatusMessage]: "Selected customers status have successfully been updated",
  [keysMessages.EcommerceCustomersEditUpdateMessage]: "Customer has been updated",
  [keysMessages.EcommerceCustomersEditAddMessage]: "Customer has been created",

  [keysMessages.AuthSidebarFillCode]: "Fill in the fields with the code received by email",

  [keysMessages.Auth_SelectCity]: "Select the client city",
  [keysMessages.Auth_SearchCity]: "Search city",

  [keysMessages.DashboardIndicatorModulo]: "Module",

  [keysMessages.DashboardPageInitial]: "Dashboard",
  [keysMessages.DashboardPlaceholderInputSearch]: "Search...",

  [keysMessages.AuthSideBarMenuProccess]: "Processes",
  [keysMessages.AuthSideBarMenuSIGISS]: "SIGISS",

  [keysMessages.AuthLabelThemeLight]: "Light",
  [keysMessages.AuthLabelThemeDark]: "Dark",
  [keysMessages.AuthLabelThemeSystem]: "System",

  [keysMessages.AuthLabelKanbanTodDo]: "To Do",
  [keysMessages.AuthLabelKanbanInProcess]: "In Process",
  [keysMessages.AuthLabelKanbanDone]: "Done",
  [keysMessages.AuthLabelKanbanBlock]: "Block",
  [keysMessages.AuthLabelKanbanNewTask]: "New Task",
  [keysMessages.AuthLabelKanbanConfigColumn]: "Config Column",
  [keysMessages.AuthLabelKanbanSelectedColor]: "Selected Color",

  [keysMessages.AuthProcessSessionExpired]: "Session expired in",

  [keysMessages.AuthTitleFormRequerimento]: "claim form",
  [keysMessages.AuthLabelPolicyPrivacy]: "Data Privacy Policy!",
  [keysMessages.AuthLabelProtectionData]: "Data protection is our priority. For more information about how we will treat your data, visit our Privacy Policy",
  [keysMessages.AuthLabelProtectionDataLink]: "Privacy Policy",
}
