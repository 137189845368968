import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { cpfMask, numberMask } from 'app/shared/helpers/valueMasks'
import * as YupValidation from 'app/shared/helpers/yupValidations'
import clsx from 'clsx'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { requestPassword } from '../core/_requests'

const initialValues = {
  email: '',
  cpf: ''
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .required('E-mail é obrigatório')
    .max(50, 'Máximo de 50 caracteres')
    .test(YupValidation.validateEmail()),
  cpf: Yup.string()
    .required('CPF é obrigatório')
    .test(YupValidation.validateCpfMask())
})

export function ForgotPassword (): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [currentState, setCurrentState] = useState<{
    hasErrors: boolean
    message?: string
  }>({
    hasErrors: false,
    message: ''
  })
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setCurrentState({
        hasErrors: false,
        message: ''
      })

      requestPassword({
        email: values.email,
        cpf: numberMask(values.cpf) as string
      })
        .then((e) => {
          setCurrentState({
            hasErrors: false,
            message: e?.data?.message
          })
          setLoading(false)

          setTimeout(() => {
            window.location.href = '/auth/login'
          }, 5000)
        })
        .catch((e) => {
          setCurrentState({
            hasErrors: true,
            message: e?.response?.data?.message
          })
          setLoading(false)
          setSubmitting(false)
          setStatus('The login detail is incorrect')
        })
    }
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Esqueceu a senha?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          Insira seu e-mail para redefinir sua senha.
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {currentState.hasErrors && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Dados inválidos, por favor, verifique as informações preenchidas e tente novamente.
          </div>
        </div>
      )}

      {(!currentState.hasErrors && currentState.message) && (
        <div className='mb-10 bg-light-success  p-8 rounded'>
          {/* <div className='text-info'>Sent password reset. Please check your email</div> */}
          <div >{currentState.message}</div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-2'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>E-mail</label>
        <input
          type='email'
          placeholder='Informe seu e-mail'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>CPF</label>
        <input
          type='cpf'
          placeholder='Informe seu CPF'
          autoComplete='off'
          {...formik.getFieldProps('cpf')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.cpf && formik.errors.cpf },
            {
              'is-valid': formik.touched.cpf && !formik.errors.cpf
            }
          )}
          onChange={(e) => {
            void formik.setFieldValue('cpf', cpfMask(e.target.value))
          }}
        />
        {formik.touched.cpf && formik.errors.cpf && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.cpf}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className={clsx('btn btn-primary me-4 gap-2', {
          disabled: loading || !formik.isValid
        })}>
          <span className='indicator-label'>Resetar </span>
          {
            loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />

            )
          }
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancelar
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
