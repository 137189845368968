export const validateCep = (cep: string): boolean => {
  return cep.match(/^\d{5}-\d{3}$/) !== null
}

export const validateCpf = (cpf?: string): boolean => {
  if (!cpf) return false

  cpf = cpf.replace(/[^\d]+/g, '')
  if (cpf === '') return false
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) { return false }
  // Valida 1o digito
  let add = 0
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i)
  let rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) rev = 0
  if (rev !== parseInt(cpf.charAt(9))) return false
  // Valida 2o digito
  add = 0
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i)
  rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) rev = 0
  if (rev !== parseInt(cpf.charAt(10))) return false
  return true
}

export const validateCnpj = (cnpj: string): boolean => {
  cnpj = cnpj.replace(/[^\d]+/g, '')
  if (cnpj === '') return false
  if (cnpj.length !== 14) return false
  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  ) { return false }
  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  const digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--
    if (pos < 2) pos = 9
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado !== parseInt(digitos.charAt(0))) return false
  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--
    if (pos < 2) pos = 9
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado !== parseInt(digitos.charAt(1))) return false
  return true
}

export const validateCpfCnpj = (cpfCnpj: string): boolean => {
  return validateCpf(cpfCnpj) || validateCnpj(cpfCnpj)
}

export const validateCellphone = (cellphone: string): boolean => {
  cellphone = cellphone.replace(/[^\d]+/g, '')

  if (cellphone.length === 11) {
    return cellphone.match(/^\d{2}\d{5}\d{4}$/) !== null
  } else if (cellphone.length === 10) {
    return cellphone.match(/^\d{2}\d{4}\d{4}$/) !== null
  }
  return false
}

export const validFileExtensions = {
  image: ['jpg', 'png', 'jpeg'],
  document: ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'csv'],
  all: []
}

export const getValidsExtensionsByFileType = (fileType: ValidFileExtensions): string => {
  return validFileExtensions[fileType].length > 0 ? `(${validFileExtensions[fileType].join(', ')})` : 'Qualquer tipo de arquivo'
}

export type ValidFileExtensions = 'image' | 'document' | 'all'

export const validateFileType = (fileName: string = '', fileType: ValidFileExtensions = 'image'): boolean => {
  if (fileType === 'all') return true

  const extensionFile = fileName.split('.').pop() ?? ''

  return Boolean(validFileExtensions[fileType].includes(extensionFile.toLowerCase()))
}

export const validateEmailRegex = (email: string): boolean => {
  return email.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/) !== null
}

export type SizeType = 'MB' | 'KB' | 'GB'

export const typeInBytes = {
  KB: 1000,
  MB: 1000000,
  GB: 1000000000
}

export const validateFileSize = (maxSize: number, type: SizeType = 'MB', currentSize: number): boolean => {
  const fileSizeInBytes = maxSize * typeInBytes[type]

  console.log({
    fileSizeInBytes,
    currentSize,
    bool: fileSizeInBytes >= currentSize
  })

  return fileSizeInBytes >= currentSize
}
