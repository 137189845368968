import { type Resolver } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { slugify } from 'app/shared/helpers/utils'
import * as YupValidations from 'app/shared/helpers/yupValidations'
import * as yup from 'yup'

import { type InputDetails } from './types'

export function generateNameForInput (inputDetails: InputDetails): string {
  const { labelFieldName, uuid } = inputDetails

  return slugify(`${labelFieldName}_uuid_${uuid}`)
}

function convertToYupSchema (validationConfig: InputDetails): yup.AnySchema {
  const { validationType, validations } = validationConfig

  let schema: yup.StringSchema | yup.NumberSchema | yup.MixedSchema

  switch (validationType) {
    case 'string':
    case 'email':
      schema = yup.string() as yup.StringSchema

      break

    case 'number':
      schema = yup.string() as yup.StringSchema

      break

    case 'file':
      schema = yup.mixed() as yup.MixedSchema

      break

    default:
      schema = yup.string() as yup.StringSchema
      break
  }

  if (validations?.mask != null) {
    const mask = validations.mask.replace(/Mask$/, '')

    const yupValidateTest = `validate${mask.charAt(0).toUpperCase()}${mask.slice(1)}Mask`

    if (typeof YupValidations[yupValidateTest] === 'function') {
      schema = schema.test(YupValidations[yupValidateTest]())
    }
  }

  if (
    validations?.required === true &&
    validationType !== 'file'
  ) {
    schema = schema.required('Campo obrigatório')
  }

  if (validationType === 'email') {
    schema = schema.test(YupValidations.validateEmail({
      isEmailRequired: validations?.required === true
    }))
  }

  if (validationType === 'file') {
    if (validations?.required === true) {
      schema = schema.test(YupValidations.validateRequired())
    }

    schema = schema.test(YupValidations.validateFileTypes(validations?.fileType ?? 'all'))
    schema = schema.test(YupValidations.validateFileSize(validations?.maxSize ?? 5.000, validations?.sizeType ?? 'MB'))
  }

  return schema
}

export function createInitialHookForm (validationConfigObject: InputDetails[]): {
  defaultValues: Record<string, any>
  resolver: Resolver
} {
  const getAllInitialValues = (): Record<string, any> => {
    const initialValues: Record<string, any> = {}
    const schemaObject: Record<string, any> = {}

    validationConfigObject?.forEach((validationConfig) => {
      const keyFromValidationConfig = generateNameForInput(validationConfig)
      initialValues[keyFromValidationConfig] = validationConfig?.initialValue ?? ''
      schemaObject[keyFromValidationConfig] = convertToYupSchema(validationConfig)
    })

    return {
      initialValues,
      schema: yup.object().shape(schemaObject)
    }
  }

  const configObject = getAllInitialValues()

  const dados = {
    defaultValues: configObject.initialValues,
    schema: configObject.schema,
    resolver: yupResolver(configObject.schema)
  }

  return dados
}

export const convertDataKeysToUuid = (data: any = {}): any => {
  const objeto = {}

  for (const key in data) {
    const uuid = key.split('_uuid_').pop()

    objeto[`${uuid}`] = data[key]
  }

  return objeto
}
