import * as Yup from 'yup'

export const schemaJoinedDocumentsMunicipal = Yup.object().shape({
  descricao: Yup.string().required('Descrição é obrigatório').max(250, 'Máximo de 250 caracteres')
})
export type FormJoinedDocumentsMunicipal = Yup.InferType<typeof schemaJoinedDocumentsMunicipal>

export const schemaJoinedDocumentsFiscal = Yup.object().shape({
  descricao: Yup.string().required('Descrição é obrigatório').max(250, 'Máximo de 250 caracteres')
})
export type FormJoinedDocumentsFiscal = Yup.InferType<typeof schemaJoinedDocumentsFiscal>
