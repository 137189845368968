/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'app/shared/components/Button/Button'
import OverlayTooltip from 'app/shared/components/OverlayTooltip/OverlayTooltip'
import ReactModal from 'app/shared/components/ReactModal/ReactModal'
import Toastfy from 'app/shared/helpers/Toastfy/Toastfy'
import { boolToString } from 'app/shared/helpers/utils'
import * as ValidateValues from 'app/shared/helpers/validateValues'
import { useFormBuilderStore } from 'app/store/FormBuilderStore'
import clsx from 'clsx'
import * as Yup from 'yup'

import { HelperInputDetails } from './HelpersRenderComponents'

const RenderTextFieldForm: React.FC = ({ ...props }: any) => {
  const name = props.inputParams.name

  const error = props.hookForm.formState.errors[name]

  const rowCols = props?.style?.cols ?? 12

  return (
    <div className={`col-md-${rowCols} col-sm-12`}>
      <label
        className={
          clsx('form-label fs-6 fw-bolder text-dark', {
            required: props?.validations?.required
          })
        }
        htmlFor={`input-label-${props?.uuid}`}
      >
        {props?.labelFieldName}
      </label>
      <textarea
        {...props.inputParams}
        className={clsx(
          'form-control form-control-solid',
          {
            'is-invalid': error,
            'is-valid': !error && props.hookForm.formState.touchedFields[name]
          }
        )}
        placeholder={props?.placeholder !== '' ? props?.placeholder : props?.labelFieldName}
        id={`input-label-${props?.uuid}`}
      />
      <div className='fv-plugins-message-container'>
        <span role='alert'>{error?.message}</span>
      </div>
    </div>
  )
}

const TextFieldParamsDetails: React.FC = ({ ...props }: any) => {
  const paramsDetails = [
    {
      key: 'Obrigatório',
      value: boolToString(Boolean(props.validations.required))
    },
    // {
    //   key: 'Valor Inicial',
    //   value: props.initialValue ? props.initialValue : 'Não Informado'
    // },
    {
      key: 'Placeholder',
      value: props.placeholder ? props.placeholder : 'Não Informado'
    },
    {
      key: 'Tamanho da Coluna',
      value: props?.style?.cols ?? 12
    }
  ]

  return <HelperInputDetails paramsDetails={paramsDetails} />
}

const RenderTextFieldFormPreview: React.FC = ({ ...props }: any) => {
  const rowCols = props?.style?.cols ?? 12

  return (
    <div className={`col-md-${rowCols} col-sm-12`}>
      <label className='form-label fs-5 fw-bolder text-dark'>{props?.typeFieldName}</label>

      <br />

      <label className='form-label fs-6 fw-bolder text-dark' htmlFor={`input-label-${props?.uuid}`}>{props.labelFieldName}</label>
      {
        !props.isEditing
          ? <TextFieldParamsDetails {...props} />
          : <textarea
            {...props.inputParams}
            readOnly={true}
            disabled={true}
            className={clsx(
              'form-control form-control-solid'
            )}
            placeholder={props?.placeholder !== '' ? props?.placeholder : props?.labelFieldName}
            id={`input-label-${props?.uuid}`}
          />
      }
    </div>
  )
}

const ModalTextFieldFormEditing: React.FC = ({ ...props }: any) => {
  const [editInputForm] = useFormBuilderStore(state => [state.editInputForm])

  const inputEditSchema = Yup.object().shape({
    labelFieldName: Yup.string().required('Campo obrigatório'),
    placeholder: Yup.string().nullable(),
    validationType: Yup.string().required('Campo obrigatório'),
    initialValue: Yup.string().nullable(),
    validations: Yup.object().shape({
      mask: Yup.string().nullable(),
      required: Yup.boolean().nullable()
    }),
    style: Yup.object().shape({
      cols: Yup.number().required('Campo obrigatório')
    }),
    inputParams: Yup.object().shape({
      required: Yup.boolean().nullable(),
      disabled: Yup.boolean().nullable()
    })
  })

  const cols = Number(props?.style?.cols ?? 12)

  const defaultValues = {
    validationType: `${props.validationType}`,
    labelFieldName: `${props.labelFieldName}`,
    placeholder: (props?.placeholder as string) ?? '',
    initialValue: (props?.initialValue as string) ?? '',
    validations: {
      required: Boolean(props?.validations?.required),
      mask: props?.validations?.mask ? `${props?.validations?.mask}` : ''
    },
    style: {
      cols
    },
    inputParams: {
      required: Boolean(props?.inputParams?.required),
      disabled: Boolean(props?.inputParams?.disabled)
    }
  }

  type FormDataSchema = Yup.InferType<typeof inputEditSchema>

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues,
    resolver: yupResolver(inputEditSchema)
  })

  const closeModal = (): void => {
    editInputForm(props.uuid, {
      ...props,
      isModalOpen: false
    })
  }

  const onSubmitEditInput: SubmitHandler<FormDataSchema> = async (data): Promise<void> => {
    if (data.validations.mask) {
      const mask = data.validations.mask.replace(/Mask$/, '')

      const maskSelected = `validate${mask.charAt(0).toUpperCase()}${mask.slice(1)}`

      if (typeof ValidateValues[maskSelected] === 'function' && data.initialValue) {
        if (!ValidateValues[maskSelected](data.initialValue)) {
          Toastfy.error('Valor inicial inválido')
          return
        }
      }
    }

    editInputForm(props.uuid, {
      ...props,
      ...data,
      inputParams: {
        ...props.inputParams,
        ...data.inputParams
      },
      validations: {
        ...props.validations,
        ...data.validations
      },
      isModalOpen: false
    })
  }

  return <ReactModal
    show={props.isModalOpen}
    headerName={`Editando campo "${props.typeFieldName} - ${props.labelFieldName}"`}
    onClose={closeModal}
    modalFooter={
      <>
        <Button
          rounded={true}
          variant='primary'
          onClick={handleSubmit(onSubmitEditInput)}
        >
          Salvar
        </Button>

        <Button
          type='button'
          rounded={true}
          variant='light'
          onClick={closeModal}
        >Voltar</Button>
      </>
    }
  >

    <div className="row">

      <div className="col-md-6 col-sm-12">
        <label className='form-label fs-6 fw-bolder text-dark'>Nome do Campo</label>
        <input
          {...register('labelFieldName')}
          type='text'
          placeholder='Nome do Campo'
          className={clsx(
            'form-control form-control-solid',
            { 'is-invalid': errors.labelFieldName },
            { 'is-valid': errors.labelFieldName == null }
          )}
        />
        <div className='fv-plugins-message-container'>
          <span role='alert'>{errors.labelFieldName?.message}</span>
        </div>
      </div>

    </div>

    <hr />

    <label className='form-label fs-2 fw-bolder text-dark'>Validações</label>

    <div className="row mt-2">

      {/* <div className="col-md-6 col-sm-12">
        <label className='form-label fs-6 fw-bolder text-dark'>Valor Inicial</label>
        <input
          {...register('initialValue')}
          type='text'
          placeholder='Valor Inicial'
          className={clsx(
            'form-control form-control-solid',
            { 'is-invalid': errors.initialValue },
            { 'is-valid': errors.initialValue == null }
          )}
        />
      </div> */}

      <div className="col-md-6 col-sm-12">
        <label className='form-label fs-6 fw-bolder text-dark'>Placeholder</label>
        <input
          {...register('placeholder')}
          type='text'
          placeholder='Placeholder'
          className={clsx(
            'form-control form-control-solid',
            { 'is-invalid': errors.placeholder },
            { 'is-valid': errors.placeholder == null }
          )}
        />
      </div>

    </div>

    <hr />

    <label className='form-label fs-2 fw-bolder text-dark'>Estilo</label>

    <div className="row mb-2">

      <div className="col-md-3 col-sm-12 mt-2">
        <label className='form-label fs-6 fw-bolder text-dark' htmlFor='style.cols'>
          Tamanho da Coluna <OverlayTooltip
            message="Tamanho da coluna que o campo irá ocupar no formulário. Vai de 1 a 12, sendo 12 o tamanho máximo e 1 o mínimo."
          >
            <i className="bi bi-info-circle"></i>
          </OverlayTooltip>
        </label>

        <select
          className="form-select"
          id="style.cols"
          aria-label="Floating label select"
          {...register('style.cols')}
        >
          {
            Array.from({ length: 12 }, (_, index) => index + 1).map((value) => (
              <option
                key={value}
                value={value}
              >
                {value} Coluna{value > 1 ? 's' : ''}
              </option>
            ))
          }
        </select>

      </div>

    </div>

    <hr />

    <div className="row mt-2">
      <div className="col-md-4 col-sm-12 mt-2">
        <div className="form-check form-switch">
          <input
            {...register('validations.required')}
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="isRequiredCheck"
          />
          <label className='form-label fs-6 fw-bolder text-dark' htmlFor='isRequiredCheck'>Obrigatório</label>
        </div>

      </div>
    </div>

  </ReactModal>
}

export { RenderTextFieldForm, RenderTextFieldFormPreview, ModalTextFieldFormEditing }
