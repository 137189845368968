/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */

import { useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { FcGoogle } from 'react-icons/fc'
import { useIntl } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'

import { systemMessages } from '_metronic/i18n/systemMessages'
import { yupResolver } from '@hookform/resolvers/yup'
import { useGoogleLogin } from '@react-oauth/google'
import { type AuthModel, useAuth } from 'app/modules/auth'
import { getVerificationCode, login, loginWithSSO, type LoginData } from 'app/modules/auth/core/_requests'
import Button from 'app/shared/components/Button/Button'
import ReactModal from 'app/shared/components/ReactModal/ReactModal'
import Toastfy from 'app/shared/helpers/Toastfy/Toastfy'
import { getInternalizeText } from 'app/shared/helpers/utils'
import { cpfMask, numberMask } from 'app/shared/helpers/valueMasks'
import { validateCpfMask, validateEmail } from 'app/shared/helpers/yupValidations'
import { ServicePerson } from 'app/shared/services'
import useCookieStore from 'app/store/Plataform/CookieStore'
import axios from 'axios'
import clsx from 'clsx'
import * as Yup from 'yup'

import SideBarVerifyCode from './SideBarVerifyCode'
import TermOfUSe from './TermOfUse'

const loginSchema = Yup.object().shape({
  email: Yup.string().nullable()
    .test(validateEmail({
      isEmailRequired: false
    })),
  cpf: Yup.string().required('CPF obrigatório').test(validateCpfMask()),
  password: Yup.string().required('Insira a senha').min(8, 'Mínimo de 8 caracteres').required('Senha é obrigatória'),
  keepAlive: Yup.boolean(),
  isAcceptTerms: Yup.boolean().test('is-accept-terms', 'É necessário aceitar os termos', (value) => value === true),
  verifyCode: Yup.string()
})

interface GoogleUserInfo {
  id: string
  email: string
  verified_email: boolean
  name: string
  given_name: string
  family_name: string
  picture: string
  locale: string
}

export function Login (): JSX.Element {
  const [requestEndpoint, setRequestEndpoint] = useState(false)
  const [isOpenedTerms, setIsOpenedTerms] = useState(false)
  const [showSideBar, setShowSideBar] = useState(false)
  const [dataLogin, setDataLogin] = useState<LoginData | null>(null)
  const { saveAuth, logout, setCurrentUser } = useAuth()
  const [isAcceptedTermOsUse, acceptTermOfUse] = useCookieStore(state => [
    state.isAcceptedTermOsUse,
    state.acceptTermOfUse
  ])

  const navigate = useNavigate()

  const intl = useIntl()

  const { register, handleSubmit, formState: { errors }, setValue, getValues, trigger, watch } = useForm({
    defaultValues: {
      email: '',
      cpf: '',
      password: '',
      keepAlive: false,
      verifyCode: '',
      isAcceptTerms: isAcceptedTermOsUse
    },
    resolver: yupResolver(loginSchema)
  })

  const onSubmitLogin: SubmitHandler<LoginData> = async (data) => {
    setRequestEndpoint(true)
    setDataLogin(data)

    try {
      data.cpf = `${numberMask(data.cpf ?? '')}`

      const response = await getVerificationCode(data)

      Toastfy.success(response.data.message)

      setShowSideBar(true)
    } catch (error: any) {
      Toastfy.error(error?.response?.data?.message ?? 'Erro ao enviar código de verificação')
    } finally {
      setRequestEndpoint(false)
    }
  }

  const onValidatecode = async (verifyCode: string): Promise<void> => {
    try {
      if (!dataLogin) {
        return
      }

      let userToken: string
      let response: any

      if (!dataLogin.password) {
        const dataToSSO = dataLogin.email
          ? {
              email: dataLogin.email
            }
          : {
              verifyCode
            }

        response = await loginWithSSO({
          ...dataToSSO,
          verifyCode
        })

        userToken = response.data.data.access_token
      } else {
        response = await login({
          ...dataLogin,
          verifyCode
        })

        userToken = response.data.data.access_token
      }

      const authToSaved: AuthModel = {
        token: userToken,
        city_selected: undefined
      }

      Toastfy.success(response.message)

      acceptTermOfUse()

      saveAuth(authToSaved)
    } catch (error: any) {
      logout()
      Toastfy.error(error?.response.data.message ?? 'Erro ao autenticar usuário')
    }
  }

  const validateIfUserExists = async (data: {
    cpf?: string
    email?: string
    name: string
    picture: string
  }): Promise<void> => {
    const { email, name, picture } = data

    try {
      if (email) {
        await ServicePerson.getEmailUser(email)

        if (getValues('isAcceptTerms') === false) {
          Toastfy.error('É necessário aceitar os termos de uso')
          setIsOpenedTerms(true)
          return
        }

        await loginWithSSO({
          email
        })

        setDataLogin({
          email
        })

        setShowSideBar(true)
      }
    } catch (error) {
      Toastfy.error('Usuário não encontrado, por favor realize o cadastro')

      navigate('/auth/registration', {
        state: {
          email,
          name,
          picture
        }
      })
    }
  }

  const validateUserOnGoogle = async (token: string): Promise<void> => {
    setRequestEndpoint(true)

    try {
      const {
        data: {
          email,
          name,
          picture
        }
      } = await axios.get<GoogleUserInfo>('https://www.googleapis.com/userinfo/v2/me', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      await validateIfUserExists({
        email,
        name,
        picture
      })
    } catch (error) {
      Toastfy.error('Erro ao autenticar usuário')
    }

    setRequestEndpoint(false)
  }

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async tokenResponse => {
      void validateUserOnGoogle(tokenResponse.access_token)
    },
    onError: () => {
      Toastfy.error('Erro ao autenticar usuário')
    }
  })

  return (
    <>
      <form
        className='form w-100'
        onSubmit={handleSubmit(onSubmitLogin)}
        noValidate
        id='kt_login_signin_form'
        autoComplete='off'
      >
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>
            {intl.formatMessage({ id: 'AUTH.ACCESS_SYSTEM' })}
          </h1>
        </div>

        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark required'>CPF</label>
          <input
            {...register('cpf')}
            type='text'
            placeholder='999.999.999-99'
            autoComplete='new-cpf'
            className={clsx(
              'form-control form-control-solid',
              { 'is-invalid': errors.cpf },
              { 'is-valid': errors.cpf == null && watch('cpf') }
            )}
            onChange={(e) => {
              setValue('cpf', cpfMask(e.target.value))
              void trigger('cpf')
            }}
          />
          <div className='fv-plugins-message-container'>
            <span role='alert' className="text-danger">{errors.cpf?.message}</span>
          </div>
          {(errors.cpf == null) && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>Digite seu CPF</span>
            </div>
          )}
        </div>

        <div className='fv-row'>
          <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'AUTH.PASSWORD' })}</label>
          <input
            {...register('password')}
            type='password'
            minLength={8}
            placeholder='Insira sua senha'
            autoComplete='new-password'
            className={clsx(
              'form-control form-control-solid',
              { 'is-invalid': errors.password },
              { 'is-valid': errors.password == null && watch('password') }
            )}
          />
          <div className='fv-plugins-message-container'>
            <span role='alert' className="text-danger">{errors.password?.message}</span>
          </div>
          {(errors.password == null) && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{intl.formatMessage({ id: 'AUTH.ENTER_YOUR_SECURITY_PASSWORD' })}</span>
            </div>
          )}
        </div>

        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mt-2 mb-5'>
          <div />
          <Link to='/auth/forgot-password' className='text-blue-primary'>{intl.formatMessage({ id: 'AUTH.FORGOT.TITLE' })}</Link>
        </div>

        {/* <div className='mb-10'>
          <label className='form-check form-check-custom  mb-5'>
            <input
              {...register('keepAlive')}
              className='form-check-input'
              type='checkbox'
              value='1'
            />
            <span className='form-check-label text-dark fs-6'>{intl.formatMessage({ id: 'AUTH.STAY_LOGGED_IN' })}</span>
          </label>
        </div> */}
        {
          !isAcceptedTermOsUse && (
            <div className='mb-10'>
              <label className='form-check form-check-custom mb-5'>
                <input
                  {...register('isAcceptTerms')}
                  className='form-check-input'
                  type='checkbox'
                  value='1'
                />
                <span className='form-check-label text-dark fs-6'>
                  <a
                    className='text-blue-primary fw-bolder fs-6 cursor-pointer'
                    onClick={() => { setIsOpenedTerms(true) }}
                  >
                    Li os termos de Uso do Portal
                  </a>
                </span>
              </label>

              {/* <span>
                <a
                  className='link-primary fw-bolder fs-6 cursor-pointer'
                  onClick={() => { setIsOpenedTerms(true) }}
                >
                  Leia os Termos e Condições
                </a>
              </span> */}

              <div className='fv-plugins-message-container'>
                <span role='alert' className="text-danger">{errors.isAcceptTerms?.message}</span>
              </div>
            </div>
          )
        }
        <div className='d-grid'>
          <Button type='submit' variant='dark' rounded size='lg' className='colors-blue-medium' loading={requestEndpoint} disabled={requestEndpoint} >
            {getInternalizeText(systemMessages.keysMessages.AuthLoginButton)}
          </Button>
        </div>
      </form >

      <hr className='my-8' />

      <div className='d-grid gap-5 mb-8'>
        {/* <button type='button' className='btn back-gray-02 fw-bold d-flex align-items-center justify-content-center gap-2 border-radius-lg'>
          {intl.formatMessage({ id: 'AUTH.SIGN_IN_WITH' })}
          <img src={toAbsoluteUrl('images/govbr.svg')} alt="" />
        </button> */}
        <button
          type='button'
          className='btn back-gray-02 fw-bold d-flex align-items-center justify-content-center gap-2 border-radius-lg'
          onClick={() => {
            loginWithGoogle()
          }}
        >
          <FcGoogle className='icon' />{intl.formatMessage({ id: 'AUTH.SIGN_IN_WITH_GOOGLE' })}
        </button>

      </div>

      <div className='text-gray-500 text-center fw-bold fs-5'>
        <Link to='/auth/registration' className='text-blue-primary'>
          {intl.formatMessage({ id: 'AUTH.FIRST_ACCESS' })}
        </Link>
      </div>

      <SideBarVerifyCode show={showSideBar} onHide={() => { setShowSideBar(false) }} onValidatecode={onValidatecode} dataFormLogin={dataLogin!} />

      <ReactModal
        show={isOpenedTerms}
        headerName='Termo de Uso e Privacidade'
        onClose={() => { setIsOpenedTerms(false) }}
        fullscreen={true}
        modalFooter={
          <>
            <Button
              type='button'
              rounded={true}
              variant='light'
              onClick={() => { setIsOpenedTerms(false) }}
            >
              Voltar
            </Button>
            {
              !isAcceptedTermOsUse && (
                <Button
                  type='button'
                  rounded={true}
                  variant='primary'
                  className='bg-blue-primary'
                  onClick={() => {
                    setIsOpenedTerms(false)
                    setValue('isAcceptTerms', true)
                  }}
                >
                  Aceitar Termos e Condições
                </Button>
              )
            }
          </>
        }
      >
        <TermOfUSe />
      </ReactModal >

    </>
  )
}
