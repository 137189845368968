/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { type IDefaultRequest } from 'app/shared/types/common'
import { type IGraphicsStatus } from 'app/shared/types/processos/accessPrefeitura/dashboard'
import { type IAssignProcess, type IDemandasDepartamentos } from 'app/shared/types/processos/accessPrefeitura/myDepartaments'
import { type KeyedMutator } from 'swr'

import { apiProcessos } from '../../api'

const endpointDemandas = '/demandas'
const endpointRequeriment = '/requerimento'

const GetDemandasDepartamentos = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IDemandasDepartamentos>>(
    `${endpointDemandas}/departamento?${queryParams}`,
    apiProcessos
  )
}

const GetMyDemandas = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IDemandasDepartamentos>>(
    `${endpointDemandas}/logged-user?${queryParams}`,
    apiProcessos
  )
}

const getDemandForMe = async (
  REQID: number,
  mutate: KeyedMutator<IDefaultRequest<IDemandasDepartamentos>>,
  mutateMyDemandas?: KeyedMutator<IDefaultRequest<IDemandasDepartamentos>>,
  mutateStatusRequirements?: KeyedMutator<IDefaultRequest<IGraphicsStatus>>
) => {
  const response = await apiProcessos.put(`${endpointDemandas}/atribuir/${REQID}`)
  if (response.status === 200) {
    void mutate()
    void mutateMyDemandas?.()
    void mutateStatusRequirements?.()
  }
  return response
}

const closeProcess = async (
  REQID: number,
  payload: { statusId: number }
) => {
  const response = await apiProcessos.put(`${endpointRequeriment}/change-status/${REQID}`, payload)
  return response
}

const assignProcess = async (
  payload: IAssignProcess,
  mutate: KeyedMutator<IDefaultRequest<IDemandasDepartamentos>>
) => {
  const response = await apiProcessos.post(`${endpointDemandas}/atribuir-para`, payload)
  if (response.status === 200) void mutate()
  return response
}

export default {
  GetDemandasDepartamentos,
  GetMyDemandas,
  getDemandForMe,
  closeProcess,
  assignProcess
}
