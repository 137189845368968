/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  type FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef
} from 'react'

import { type WithChildren } from '_metronic/helpers'
import { LayoutSplashScreen } from '_metronic/layout/core'
import { getCitysFromUser, getClientsFromUser, getPermissionsSDigital, getPersonByToken, getProfilesFromUser, getUserByToken } from 'app/modules/auth/core/_requests'
import { inObject, removeEmptyProperties, slugify } from 'app/shared/helpers/utils'
import { type IPermissionsUsers } from 'app/shared/types/processos/screenPermissions/screenPermissions'
import { type IPermissions, useUserStore, type IPermissionsUser } from 'app/store/Plataform/UserStore'

import { type CityModel, type AuthModel, type UserModel, type UserPersonModel } from './_models'
import * as authHelper from './AuthHelpers'

interface AuthContextProps {
  auth: AuthModel | undefined
  accessUserByToken: (apiToken: string) => Promise<void>
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  currentPeople: any[]
  setCurrentUser: (user: UserModel | undefined) => void
  setPeople: (people: any[]) => void
  getUserPermissions: (apiToken?: string) => Promise<IPermissionsUser>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  accessUserByToken: async (): Promise<void> => { },
  saveAuth: () => { },
  currentUser: undefined,
  currentPeople: [],
  setPeople: () => { },
  setCurrentUser: () => { },
  getUserPermissions: async (apiToken?: string): Promise<IPermissionsUser> => {
    return {}
  },
  logout: () => { }
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = (): AuthContextProps => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [
    currentUser,
    currentPeople,
    setUser,
    setPeople,
    setCitys,
    setCurrentCity,
    setPerson,
    setPermissions,
    logoutStore
  ] = useUserStore(state => [
    state.user,
    state.people,
    state.setUser,
    state.setPeople,
    state.setCitys,
    state.setCurrentCity,
    state.setPerson,
    state.setPermissions,
    state.logout
  ])

  const saveAuth = (auth: AuthModel | undefined): void => {
    setAuth(auth)

    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const setCurrentUser = (user: UserModel | undefined): void => {
    setUser(user)
  }

  const logout = (): void => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    logoutStore()
  }

  const getUserPermissions = async (apiToken?: string): Promise<IPermissionsUser> => {
    const permissionsSDigital: any = {
      data: []
    }

    try {
      const [
        responsePermissionsSDigital
      ] = await Promise.all([
        getPermissionsSDigital((apiToken ?? auth?.token) ?? '')
      ])

      permissionsSDigital.data = responsePermissionsSDigital.data.data
    } catch (error) {

    }

    const sortProducts = (response: IPermissionsUser): IPermissionsUser => {
      const sortPermissions = (permissions: any[]): any[] => {
        return permissions
          .map(permisison => {
            const screens = permisison?.screens ? sortPermissions(permisison?.screens) : undefined

            return {
              ...permisison,
              screens
            }
          })
          .sort((a, b) => a.id - b.id)
      }

      const sortedResponse: any = {}

      for (const key in response) {
        if (Object.prototype.hasOwnProperty.call(response, key)) {
          const sortedGroup = response[key].sort((a, b) => a.id - b.id)
            .map(group => {
              return {
                ...group,
                screens: sortPermissions(group.screens) ?? undefined
              }
            })

          sortedResponse[key] = sortedGroup
        }
      }

      return sortedResponse
    }

    const parsePermissions = (productName: string, permissions: IPermissions[]): IPermissionsUser => {
      return sortProducts({
        [productName]: permissions
      })
    }

    const parsePermissionsFromSDigital = (permissionSDigital: IPermissionsUsers[]): IPermissions[] => {
      const parseMap = (permission: IPermissionsUsers): IPermissions => {
        return {
          id: permission.TELAID,
          name: permission.TELANOME,
          slug: slugify(permission.TELANOME),
          group: permission.Grupo,
          screens: permission.Telas.map((p) => {
            return parseMap(p)
          })
        }
      }

      return permissionSDigital.map((permission) => {
        return parseMap(permission)
      })
    }

    return {
      ...parsePermissions('s-Digital', parsePermissionsFromSDigital(permissionsSDigital.data))
    }
  }

  const accessUserByToken = async (apiToken: string): Promise<void> => {
    const authByLocalStorage = authHelper.getAuth()

    const { data: userResponse } = await getUserByToken(apiToken)

    // const { data: cityResponse } = await getCitysFromUser(apiToken)
    const { data: cityResponse } = await getClientsFromUser(apiToken)

    const { data: profilesFromUserResponse } = await getProfilesFromUser(apiToken, removeEmptyProperties({
      aceite: 1
    }))

    const { data: personResponse } = await getPersonByToken(apiToken)

    const [userData] = userResponse.data
    const citysClientes = cityResponse.data
    const personData = personResponse.data

    setCitys(citysClientes)

    if (authByLocalStorage?.city_selected && citysClientes.length > 0) {
      const cidadeLogado = citysClientes.find(city => city.CLITENANT === authByLocalStorage?.city_selected) as CityModel

      setCurrentCity(cidadeLogado)
      const permissions = await getUserPermissions()
      setPermissions(permissions)
    } else if (authByLocalStorage?.city_selected == null && citysClientes.length === 1) {
      setCurrentCity(citysClientes[0])
      authHelper.setAuth({ token: apiToken, city_selected: `${citysClientes[0].CLITENANT}` })
      const permissions = await getUserPermissions()
      setPermissions(permissions)
    } else {
      setCurrentCity(undefined)
    }

    setPerson(personData)
    setCurrentUser(userData)

    setPeople(profilesFromUserResponse.data ?? [])
  }

  return (
    <AuthContext.Provider value={{
      auth,
      accessUserByToken,
      saveAuth,
      currentUser,
      currentPeople,
      setCurrentUser,
      setPeople,
      getUserPermissions,
      logout
    }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, accessUserByToken } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    setShowSplashScreen(true)

    const requestUser = async (apiToken: string): Promise<() => boolean> => {
      try {
        if (!didRequest.current) {
          await accessUserByToken(apiToken)
        }
      } catch (error) {
        console.log({
          error
        })

        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    const authOnLocalStorage = authHelper.getAuth()

    if (authOnLocalStorage?.token) {
      void requestUser(authOnLocalStorage.token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [auth])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
