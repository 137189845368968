import { inObject, slugify } from 'app/shared/helpers/utils'
import { MenuInnerWithSub } from '../../header/header-menus/MenuInnerWithSub'
import { MenuItem } from '../../header/header-menus/MenuItem'
import { SidebarMenuItemLateral } from './SidebarMenuLateral'

import './styles.scss'
import { IPermissionMenu, IPermissions, useUserStore } from 'app/store/Plataform/UserStore'
import { accessCityHall, accessMaster, getPermissionLevelByGroupLevel, validatePermission } from 'app/shared/helpers/permissions'
import { PLATAFORMA_ROUTES } from 'app/routing/routes'

const SidebarMenuMain = () => {

  const [
    getSidebarMenu
  ] = useUserStore((state) => [state.getSidebarMenu])

  const plataformaPath = '/plataforma'

  const createNewPermission = ({
    screens,
    id,
    name,
    slug,
    permission
  }: {
    screens: IPermissions[]
    id: number
    name: string
    slug: string
    permission: number | number[] | null
  }): IPermissions => {
    return {
      group: screens.length > 0,
      screens,
      id,
      name,
      slug,
      permission
    }
  }

  const filterPlataformaPermissions = (permissions: IPermissions[]) => {
    return permissions.filter((permission) => {
      if (permission.group) {
        permission.screens = filterPlataformaPermissions(permission.screens)
      }

      if (permission.permission === null) {
        return true
      }

      return validatePermission(permission.permission!)
    })
  }

  const plataformaPermissions = {
    menuData: {
      to: plataformaPath,
      fontIcon: '/media/icons/duotune/general/gen008.svg'
    },
    referencePath: PLATAFORMA_ROUTES,
    productName: 'Plataforma',
    permissions: filterPlataformaPermissions([
      createNewPermission({
        id: 999,
        name: "Vínculos",
        slug: `${plataformaPath}/vinculos/`,
        permission: null,
        screens: [
          createNewPermission({
            id: 1,
            name: "Aprovações",
            slug: `${plataformaPath}/vinculos/aprovacoes`,
            permission: null,
            screens: []
          }),
          createNewPermission({
            id: 11,
            name: "Solicitações",
            slug: `${plataformaPath}/vinculos/solicitacoes`,
            permission: null,
            screens: []
          }),
        ]
      }),
      createNewPermission({
        id: 1000,
        name: "Empresa",
        slug: `${plataformaPath}/empresa`,
        permission: null,
        screens: [
          createNewPermission({
            id: 2,
            name: "Solicitar Cadastro de Empresa",
            slug: `${plataformaPath}/empresa/cadastro`,
            permission: null,
            screens: []
          }),
          createNewPermission({
            id: 3,
            name: "Ver Empresas Pendentes",
            slug: `${plataformaPath}/empresas-pendentes`,
            // permission: getPermissionLevelByGroupLevel([GroupPermissionsLevel.ADM_PREFEITURA]),
            permission: null,
            screens: []
          }),
        ],
      }),
      createNewPermission({
        id: 1001,
        name: "Área da Prefeitura",
        slug: `/`,
        permission: getPermissionLevelByGroupLevel(accessCityHall),
        screens: [
          createNewPermission({
            id: 4,
            name: "Cadastro de Empresas",
            slug: `${plataformaPath}/prefeitura/cadastro/empresa`,
            permission: getPermissionLevelByGroupLevel(accessCityHall),
            screens: []
          }),
          createNewPermission({
            id: 5,
            name: "Listagem das Empresas",
            slug: `${plataformaPath}/prefeitura/empresas`,
            permission: getPermissionLevelByGroupLevel(accessCityHall),
            screens: []
          }),
        ],
      }),
      createNewPermission({
        id: 1002,
        name: "Adm. Sistema",
        slug: `/adm`,
        permission: getPermissionLevelByGroupLevel(accessMaster),
        screens: [
          createNewPermission({
            id: 6,
            name: "Cadastro de Clientes",
            slug: `${plataformaPath}/cadastro/prefeitura`,
            permission: getPermissionLevelByGroupLevel(accessMaster),
            screens: []
          }),
          createNewPermission({
            id: 7,
            name: "Listagem de Clientes",
            slug: `${plataformaPath}/clientes`,
            permission: getPermissionLevelByGroupLevel(accessMaster),
            screens: []
          }),
          createNewPermission({
            id: 8,
            name: "Módulos",
            slug: `${plataformaPath}/modulos`,
            permission: getPermissionLevelByGroupLevel(accessMaster),
            screens: []
          }),
        ],
      }),
    ]),
  } as IPermissionMenu

  const productsPermissions = [
    plataformaPermissions,
    ...getSidebarMenu()
  ]

  const RenderMenuItems = ({
    permissions,
    referencePath
  }: {
    permissions: IPermissions[],
    referencePath: Record<number, string>
  }) => {

    if (!permissions.length) return (
      <MenuItem to='#' title='Sem Telas Disponíveis' />
    )

    return permissions.map((permission) => {
      const path = inObject(referencePath, permission.id, '')

      if (permission.group) {
        return (
          <MenuInnerWithSub
            key={`${permission.id}-${slugify(permission.name)}`}
            to={path}
            title={permission.name}
            hasBullet
            hasArrow
            menuPlacement='right-start'
            menuTrigger={'{default:\'click\', lg: \'hover\'}'}
          >
            {
              permission.screens.length > 0 ?
                <RenderMenuItems
                  key={permission.slug}
                  permissions={permission.screens}
                  referencePath={referencePath}
                />
                : (
                  <div className='menu-item ms-2'>
                    Sem Telas Disponíveis
                  </div>
                )
            }
          </MenuInnerWithSub>
        )
      }

      return (
        <MenuItem key={permission.id} to={path} title={permission.name} hasBullet />
      )

    })
  }

  return (
    <>
      {/* <SidebarMenuItemLateral
        to='/plataforma'
        fontIcon='bi-layers'
        title='Plataforma'
        hasArrow
        menuPlacement='right-start'
        menuTrigger={'{default:\'click\', lg: \'hover\'}'}
      >
        <MenuItem to='/plataforma/parametrizacao-acessos' title='Parametrização de acessos' hasBullet />
        <MenuItem to='/plataforma/cadastro-prefeitura' title='Cadastro de Prefeitura' hasBullet />

        <MenuInnerWithSub
          to='/plataforma/parametrizacao-acessos'
          title='Parametrização de acessos'
          hasBullet
          hasArrow
          menuPlacement='right-start'
          menuTrigger={'{default:\'click\', lg: \'hover\'}'}
        >
          <MenuItem to='/plataforma/parametrizacao-acessos/perfil' title='Perfil de Acesso' hasBullet />
        </MenuInnerWithSub>


      </SidebarMenuItemLateral> */}

      {
        productsPermissions.map((product: IPermissionMenu) => {

          const {
            menuData,
            permissions,
            productName,
            referencePath
          } = product

          return (
            <SidebarMenuItemLateral
              key={productName}
              {
              ...menuData
              }
              title={productName}
              hasArrow
              menuPlacement='right-start'
              menuTrigger={'{default:\'click\', lg: \'hover\'}'}
            >
              <RenderMenuItems
                key={productName}
                permissions={permissions}
                referencePath={referencePath}
              />
            </SidebarMenuItemLateral>
          )
        })
      }

      {/* <SidebarMenuItemLateral
        to='/processos'
        icon='document'
        title='s-Digital'
        hasArrow
        menuPlacement='right-start'
        menuTrigger={'{default:\'click\', lg: \'hover\'}'}
      >
        <MenuItem to='/processos/inicio' title='Página inicial' hasBullet />
        <MenuItem to='/processos/prefeitura' title='Página inicial Prefeitura' hasBullet />
        <MenuItem to='/processos/novo-processo' title='Novo Processo' hasBullet />
        <MenuItem to='/processos/parametros-acesso' title='Parâmetros de Acesso' hasBullet />
        <MenuItem to='/processos/gerenciar-informativos' title='Gerenciar Informativos' hasBullet />
        <MenuInnerWithSub
          to='/processos/cadastro'
          title='Cadastros'
          hasBullet
          hasArrow
          menuPlacement='right-start'
          menuTrigger={'{default:\'click\', lg: \'hover\'}'}
        >
          <MenuItem to='/processos/cadastro/tipo-requerimento' title='Tipo Requerimento' hasBullet />
          <MenuItem to='/processos/cadastro/categoria' title='Categoria' hasBullet />
          <MenuItem to='/processos/cadastro/assunto' title='Assunto' hasBullet />
          <MenuItem to='/processos/parametros-acesso/cargos' title='Cargos' hasBullet />
          <MenuItem to='/processos/cadastro/departamentos' title='Departamentos' hasBullet />
          <MenuItem to='/processos/cadastro/fluxo-requerimento' title='Fluxos de Requerimento' hasBullet />
          <MenuItem to='/processos/cadastro/formularios-fluxos' title='Fluxos' hasBullet />
          <MenuItem to='/processos/cadastro/formularios-requerimento' title='Formulário para Requerimento' hasBullet />
        </MenuInnerWithSub>
      </SidebarMenuItemLateral> */}

      {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        title='User management'
        icon='abstract-28'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export { SidebarMenuMain }
