import Modal, { type ModalProps } from 'react-bootstrap/Modal' /* https://react-bootstrap.github.io/components/modal/ */
import './LayoutReactModal.css'

interface IReactModal extends ModalProps {
  show: boolean
  onClose: () => void
  headerName?: string
  children: React.ReactNode
  size?: 'sm' | 'lg' | 'xl'
  customSize?: boolean
  closeButton?: boolean
  customContentClass?: string
  customDialogClass?: string
  customBodyClassName?: string
  customHeaderClass?: string
  modalFooter?: JSX.Element
  noHeader?: boolean
}

const defaultModalContentClassName = 'BoxShadow'
const defaultModalDialogClassName = ''

export default function ReactModal ({
  headerName,
  show,
  size = 'lg',
  customSize = false,
  onClose,
  children,
  customContentClass = defaultModalContentClassName,
  customDialogClass = defaultModalDialogClassName,
  customBodyClassName,
  customHeaderClass,
  closeButton = true,
  modalFooter,
  noHeader = false,
  fullscreen = 'false',
  ...props
}: IReactModal): JSX.Element {
  return (
    <Modal
      show={show}
      onHide={onClose}
      {...(!customSize &&
      {
        size
      }
      )}
      style={{ zIndex: 1060 }}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      scrollable
      dialogClassName={customDialogClass}
      contentClassName={customContentClass}
      fullscreen={fullscreen}
      {...props}

    >
      {
        !noHeader &&
        <Modal.Header className={customHeaderClass} closeButton={closeButton}>
          {headerName && (
            <Modal.Title id='contained-modal-title-vcenter'>
              {headerName}
            </Modal.Title>
          )}
        </Modal.Header>
      }

      <Modal.Body className={customBodyClassName}>{children}</Modal.Body>

      {modalFooter && <Modal.Footer>{modalFooter}</Modal.Footer>}
    </Modal>
  )
}
