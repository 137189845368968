/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { type IDefaultRequestObject, type IDefaultRequest } from 'app/shared/types/common'
import { type IHasNotification, type INotification } from 'app/shared/types/plataform/notification'

import { apiServerPlataforma } from '../api'

const endpointNotification = '/notificacoes'

const GetNotifications = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<INotification>>(
    `${endpointNotification}/list-all?${queryParams}`,
    apiServerPlataforma
  )
}

const getNotificationsWithoutSWR = async () => {
  const response = await apiServerPlataforma.get<IDefaultRequest<INotification>>(`${endpointNotification}/list-all`)
  return response
}

const GetHasNotifications = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequestObject<IHasNotification>>(
    `${endpointNotification}/has?${queryParams}`,
    apiServerPlataforma
  )
}

const clearNotifications = async () => {
  const response = await apiServerPlataforma.put(`${endpointNotification}/clear`)
  return response
}

export default {
  GetNotifications,
  getNotificationsWithoutSWR,
  GetHasNotifications,
  clearNotifications
}
