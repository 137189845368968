/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { type IDefaultRequestObject } from '../types/common'
import { type IReceitaFederal } from '../types/receitaFederal'
import { apiReceita, apiServerPlataforma } from './api'

const GetCNPJ = async (cnpj: number | string) => {
  const queryParam = `empresa/ws/${cnpj}`
  return await apiServerPlataforma.get<IDefaultRequestObject<IReceitaFederal>>(queryParam)
}

export default {
  GetCNPJ
}
