/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { type IDefaultRequest } from 'app/shared/types/common'
import { type IGraphicsStatus } from 'app/shared/types/processos/accessPrefeitura/dashboard'

import { apiProcessos } from '../../api'

const endpointDashboardPrefeitura = '/dashboard'

const GetStatusRequerimento = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IGraphicsStatus>>(
    `${endpointDashboardPrefeitura}/status?${queryParams}`,
    apiProcessos
  )
}

const GetStatusAtendimento = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IGraphicsStatus>>(
    `${endpointDashboardPrefeitura}/atendimento?${queryParams}`,
    apiProcessos
  )
}

const GetStatusSLA = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IGraphicsStatus>>(
    `${endpointDashboardPrefeitura}/sla?${queryParams}`,
    apiProcessos
  )
}

export default {
  GetStatusRequerimento,
  GetStatusAtendimento,
  GetStatusSLA
}
