import { useNavigate } from 'react-router-dom'

import Button from 'app/shared/components/Button/Button'

import TermOfUSe from './TermOfUse'

export function TermOfUsePage (): JSX.Element {
  const navigate = useNavigate()

  return (
    <>
      <div className='px-4'>
        <TermOfUSe />
      </div>

      <div className="d-flex justify-content-end p-4">
        <Button rounded size='lg' className='bg-blue-primary'
          onClick={() => {
            navigate('/auth')
          }}
        >Voltar</Button>
      </div>
    </>
  )
}
