import { useEffect, type Dispatch, type SetStateAction } from 'react'
import ReactQuill, { Quill } from 'react-quill'

import 'quill/dist/quill.snow.css'

interface ITextEditor {
  value: string
  setValue: Dispatch<SetStateAction<string>>
  changeValue?: () => void
  onTotalChars?: Dispatch<SetStateAction<number | string>>
  readOnly?: boolean
}

const TextEditor = ({ value, setValue, changeValue, onTotalChars, readOnly, ...props }: ITextEditor): JSX.Element => {
  const Link = Quill.import('formats/link')

  Link.sanitize = function (url: string) {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `http://${url}`
    }
    return url
  }

  const handleProcedureContentChange = (content, delta, source, editor): void => {
    setValue(content)
    changeValue?.()
    onTotalChars?.(editor.getLength() - 1)
    if (editor.getLength() === 1) {
      setValue('')
      content = ''
      onTotalChars?.(0)
    }
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      // [{ size: [] }],
      [{ font: [] }],
      [{ align: ['right', 'center', 'justify'] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'], ['clean']
    ]
  }

  const formats = [
    'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'link', 'align', 'size', 'font'
  ]

  useEffect(() => {
    changeValue && changeValue?.()
  }, [value])

  return (
    <ReactQuill
      {...props}
      theme="snow"
      value={value}
      onChange={handleProcedureContentChange}
      readOnly={readOnly}
      modules={modules}
      formats={formats}
      style={{ height: '200px' }}
    />
  )
}

export default TextEditor
