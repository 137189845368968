/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { BsExclamationCircle, BsCheckCircle } from 'react-icons/bs'
import { IoIosEyeOff, IoMdEye } from 'react-icons/io'
import { toast } from 'react-toastify'

import { yupResolver } from '@hookform/resolvers/yup'
import ButtonMetronic from 'app/shared/components/Button/ButtonMetronic'
import { cpfCnpjMask, hideCpfMask } from 'app/shared/helpers/valueMasks'
import { ServiceLoggedUser } from 'app/shared/services'
import { type ILoggedUserPassword } from 'app/shared/types/plataform/loggedUser'
import { useUserStore } from 'app/store/Plataform/UserStore'
import clsx from 'clsx'
import * as Yup from 'yup'

import './styles.scss'

const schemaLoggedUserPassword = Yup.object().shape({
  senha: Yup.string().required('Senha atual é obrigatória'),
  novaSenha: Yup.string().required('Nova senha é obrigatória'),
  novaSenha_confirmation: Yup.string().required('Confirmação de senha é obrigatória')
    .oneOf([Yup.ref('novaSenha')], 'As senhas devem ser iguais')
}) as Yup.ObjectSchema<ILoggedUserPassword>

const initValuesLoggedUserPassword: ILoggedUserPassword = {
  senha: '',
  novaSenha: '',
  novaSenha_confirmation: ''
}

interface IChangePassword {
  onClose: () => void
}

const ChangePassword = ({ onClose }: IChangePassword): JSX.Element => {
  const { user } = useUserStore()
  const [requestEndpoint, setRequestEndpoint] = useState<boolean>(false)
  const [showCurrentPassword] = useState<boolean>(false)
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
  const [password, setPassword] = useState('')

  const { register, handleSubmit, formState: { errors }, reset, watch } = useForm<ILoggedUserPassword>({
    defaultValues: initValuesLoggedUserPassword,
    resolver: yupResolver(schemaLoggedUserPassword)
  })

  const hasUppercase = /[A-Z]/.test(password)
  const hasLowercase = /[a-z]/.test(password)
  const hasNumber = /\d/.test(password)
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>_-]/.test(password)
  const hasEightChar = password.length >= 8

  const onSubmit: SubmitHandler<ILoggedUserPassword> = async (data) => {
    if (!hasUppercase || !hasLowercase || !hasNumber || !hasSpecialChar || !hasEightChar) {
      toast.error('A senha deve conter letras maiúsculas, minúsculas, números, caracteres especiais e no mínimo 8 caracteres!')
      return
    }

    setRequestEndpoint(true)
    try {
      const response = await ServiceLoggedUser.changePassword(data)
      if (response?.status === 200) {
        toast.success('Senha alterada com sucesso!')
        reset()
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? 'Erro ao salvar a senha! Tente novamente.')
    } finally {
      setRequestEndpoint(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
      <h4>Dados Pessoais</h4>
      <div className='separator mb-5'></div>

      <div className="row mb-15">
        <div className="col-md-3">
          <label className='form-label fs-6 fw-bolder text-dark required'>CPF</label>
          <input
            type='text'
            className='form-control form-control-solid'
            value={hideCpfMask(cpfCnpjMask(user?.USUCPF!))}
            disabled
          />
        </div>
        <div className="col-md-6">
          <label className='form-label fs-6 fw-bolder text-dark required'>Nome</label>
          <input
            type='text'
            className='form-control form-control-solid'
            value={user?.USUNOME}
            disabled
          />
        </div>
        <div className="col-md-3">
          <label className='form-label fs-6 fw-bolder text-dark required'>E-mail</label>
          <input
            type='text'
            className='form-control form-control-solid'
            value={user?.USUEMAIL}
            disabled
          />
        </div>
      </div>

      <h4>Alterar Senha</h4>
      <div className='separator mb-5'></div>
      <div className="row">
        <div className='col-md-4'>
          <label className='form-label fs-6 fw-bolder text-dark required'>Senha Atual</label>
          <input
            {...register('senha')}
            type={showCurrentPassword ? 'text' : 'password'}
            placeholder='Insira a senha atual'
            autoComplete='new-password'
            className={clsx(
              'form-control form-control-solid',
              { 'is-invalid': errors.senha },
              { 'is-valid': errors.senha == null && watch('senha') }
            )}
          />
          <div className='fv-plugins-message-container'>
            {errors.senha && <span role='alert'>{errors.senha.message as string}</span>}
          </div>
        </div>
        <div className='col-md-4'>
          <label className='form-label fs-6 fw-bolder text-dark required'>Nova Senha</label>
          <div className='position-relative'>
            <input
              {...register('novaSenha')}
              type={showNewPassword ? 'text' : 'password'}
              placeholder='Insira a nova senha'
              autoComplete='off'
              className={clsx(
                'form-control form-control-solid',
                { 'is-invalid': errors.novaSenha },
                { 'is-valid': errors.novaSenha == null && watch('novaSenha') }
              )}
              onChange={(e) => { setPassword(e.target.value) }}
            />
            {showNewPassword
              ? (
                <IoMdEye
                  className='icon position-absolute'
                  style={{ right: '37px', top: '50%', transform: 'translateY(-50%)' }}
                  onClick={() => { setShowNewPassword(!showNewPassword) }}
                />
                )
              : (
                <IoIosEyeOff
                  className='icon position-absolute'
                  style={{ right: '37px', top: '50%', transform: 'translateY(-50%)' }}
                  onClick={() => { setShowNewPassword(!showNewPassword) }}
                />
                )}
          </div>
          <small className='mt-3 d-block'>Mínimo de 8 caracteres com letras maiúsculas, minúsculas, números e caracteres especiais</small>
          <div className='fv-plugins-message-container'>
            {errors.novaSenha && <span role='alert'>{errors.novaSenha.message as string}</span>}
          </div>

          <div className='container--force-password mt-4'>
            {password.length > 0 && (
              <p>Força da Senha:</p>
            )}
            <ul className='d-flex flex-column gap-5'>

              <li style={{ color: hasEightChar ? 'green' : 'red' }}>
                {hasEightChar
                  ? <div className='d-flex gap-2 alig-items-center'><BsCheckCircle className='icon' /> 8 caracteres</div>
                  : <div className='d-flex gap-2 alig-items-center'><BsExclamationCircle className='icon' /> 8 caracteres</div>
                }
              </li>
              <li style={{ color: hasUppercase ? 'green' : 'red' }}>
                {hasUppercase
                  ? <div className='d-flex gap-2 alig-items-center'><BsCheckCircle className='icon' /> Letras maiúsculas</div>
                  : <div className='d-flex gap-2 alig-items-center'><BsExclamationCircle className='icon' /> Letras maiúsculas</div>
                }
              </li>
              <li style={{ color: hasLowercase ? 'green' : 'red' }}>
                {hasLowercase
                  ? <div className='d-flex gap-2 alig-items-center'><BsCheckCircle className='icon' /> Letras minúsculas</div>
                  : <div className='d-flex gap-2 alig-items-center'><BsExclamationCircle className='icon' /> Letras minúsculas</div>
                }
              </li>
              <li style={{ color: hasNumber ? 'green' : 'red' }}>
                {hasNumber
                  ? <div className='d-flex gap-2 alig-items-center'><BsCheckCircle className='icon' /> Números</div>
                  : <div className='d-flex gap-2 alig-items-center'><BsExclamationCircle className='icon' /> Números</div>
                }
              </li>
              <li style={{ color: hasSpecialChar ? 'green' : 'red' }}>
                {hasSpecialChar
                  ? <div className='d-flex gap-2 alig-items-center'><BsCheckCircle className='icon' /> Caracteres especiais</div>
                  : <div className='d-flex gap-2 alig-items-center'><BsExclamationCircle className='icon' /> Caracteres especiais</div>
                }
              </li>
            </ul>
          </div>

        </div>
        <div className='col-md-4'>
          <label className='form-label fs-6 fw-bolder text-dark required'>Confirmar Senha</label>
          <div className='position-relative'>
            <input
              {...register('novaSenha_confirmation')}
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder='Confirme a nova senha'
              autoComplete='off'
              className={clsx(
                'form-control form-control-solid',
                { 'is-invalid': errors.novaSenha_confirmation },
                { 'is-valid': errors.novaSenha_confirmation == null && watch('novaSenha_confirmation') }
              )}
            />
            {showConfirmPassword
              ? (
                <IoMdEye
                  className='icon position-absolute'
                  style={{ right: '37px', top: '50%', transform: 'translateY(-50%)' }}
                  onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}
                />
                )
              : (
                <IoIosEyeOff
                  className='icon position-absolute'
                  style={{ right: '37px', top: '50%', transform: 'translateY(-50%)' }}
                  onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}
                />
                )}
          </div>
          <div className='fv-plugins-message-container'>
            {errors.novaSenha_confirmation && <span role='alert'>{errors.novaSenha_confirmation.message as string}</span>}
          </div>
        </div>
      </div>

      <div className='d-flex gap-3 justify-content-end mt-20'>
        <ButtonMetronic type='button' variant='danger' rounded onClick={onClose}>Fechar</ButtonMetronic>
        <ButtonMetronic type='submit' variant='primary' rounded>
          Salvar {requestEndpoint && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
        </ButtonMetronic>
      </div>
    </form>
  )
}

export default ChangePassword
