import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { toAbsoluteUrl } from '_metronic/helpers'

const AuthLayout = (): JSX.Element => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root != null) {
      root.style.height = '100%'
    }
    return () => {
      if (root != null) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
        <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 order-2 order-lg-1'>
          <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
            <div className='w-lg-500px p-10'>
              <Outlet />

              {/* <div className='d-flex justify-content-between w-100 m-auto mt-12 text-primary fs-base font-weight-semi-bold'>

                <a href='#' target='_blank' className='text-blue-primary'>
                  {intl.formatMessage({ id: 'AUTH.NEEDED_HELP' })}
                </a>

              </div> */}
            </div>
          </div>
        </div>

        <div className='d-flex flex-lg-row-fluid w-lg-50 order-1 order-lg-2 justify-content-center align-items-center logo-company-page--login'>
          <img src={toAbsoluteUrl('/images/logo-sigcorp.svg')} alt="Logo SigCorp" title='Logo SigCorp' style={{ width: '80%', height: 'auto' }} />
        </div>
      </div>
    </>
  )
}

export { AuthLayout }
