/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { FiCheck } from 'react-icons/fi'

import { yupResolver } from '@hookform/resolvers/yup'
import { FormBuilderHelper } from 'app/modules/processos/FormBuilder/FormBuilderHelper'
import { type InputDetails } from 'app/modules/processos/FormBuilder/types'
import { convertDataKeysToUuid, createInitialHookForm } from 'app/modules/processos/FormBuilder/utilsFormBuilderFunctions'
import { type IFormPreenchido } from 'app/modules/processos/requerimento/steppersRequerimento/StepperDetail'
import ButtonMetronic from 'app/shared/components/Button/ButtonMetronic'
import CheckBox from 'app/shared/components/CheckBox/CheckBox'
import ModalConfirm from 'app/shared/components/Modals/ModalConfirm/ModalConfirm'
import Toastfy from 'app/shared/helpers/Toastfy/Toastfy'
import { ServiceDemandasById, ServiceForms, ServiceJoinedProcess } from 'app/shared/services'
import { type IProcessReturn } from 'app/shared/services/processos/process'
import { type IFORMULARIOTIPO, type IFormsCompleteds } from 'app/shared/types/processos/accessPrefeitura/demandaById'
import clsx from 'clsx'

import { schemaJoinedDocumentsMunicipal, type FormJoinedDocumentsMunicipal } from './core/validations'

interface IJoinedDocumentsMunicipal {
  process: IProcessReturn
  onClose: () => void
}

const JoinedDocumentsMunicipal = ({ process, onClose }: IJoinedDocumentsMunicipal): JSX.Element => {
  const [requestEndpoint, setRequestEndpoint] = useState<boolean>(false)
  const [selectedForms, setSelectedForms] = useState<number[]>([])
  const [formPreenchido] = useState<IFormPreenchido[]>([])
  const [formsCompleteds, setFormsCompleteds] = useState<IFormsCompleteds[]>([])
  const [openModalSaveProcess, setOpenModalSaveProcess] = useState<boolean>(false)
  const [openModalFormToEditFiscal, setOpenModalFormToEditFiscal] = useState<{
    open: boolean
    formToEdit: InputDetails[]
  }>({
    open: false,
    formToEdit: []
  })

  const availableFormsData = ServiceJoinedProcess.GetFormsAvailable(process.REQID)
  const requestedJoinsData = ServiceJoinedProcess.GetJoinedProcess(process.REQID, {
    statusId: 1
  })

  // const demandaDetailData = ServiceDemandasById.GetDemandaById(Number(process.REQID))
  const demandaDetailData = ServiceDemandasById.GetResumoById(Number(process.REQID))

  const { register, handleSubmit, formState: { errors }, watch } = useForm<FormJoinedDocumentsMunicipal>({
    defaultValues: { descricao: '' },
    resolver: yupResolver(schemaJoinedDocumentsMunicipal)
  })

  const onSubmit: SubmitHandler<FormJoinedDocumentsMunicipal> = async (data) => {
    setRequestEndpoint(true)
    try {
      const dataToSubmit = {
        formTiposIds: selectedForms,
        solicitacao: data.descricao
      }
      const response = await ServiceJoinedProcess.createJoinedProcess(process.REQID, dataToSubmit)
      if (response.status === 200) {
        Toastfy.success('Solicitação de juntada de documentos realizada com sucesso!')
        onClose()
      }
    } catch (error: any) {
      Toastfy.error(error?.response?.data?.message ?? 'Erro ao solicitar juntada de documentos! Tente novamente.')
    } finally {
      setRequestEndpoint(false)
    }
  }

  const handleAddForm = (id: number): void => {
    if (selectedForms.includes(id)) {
      setSelectedForms(selectedForms.filter((form) => form !== id))
    } else {
      setSelectedForms([...selectedForms, id])
    }
  }

  const handleSaveFiscal = async (): Promise<void> => {
    setRequestEndpoint(true)
    try {
      const response = await ServiceForms.createAnswersForm(
        process.REQID as number,
        { formulariosRespondidos: formsCompleteds }
      )
      if (response.status === 200) {
        Toastfy.success('Respostas salvas com sucesso')
        onClose()
      }
    } catch (error: any) {
      Toastfy.error(error?.response.data.message ?? 'Erro ao salvar respostas')
    } finally {
      setRequestEndpoint(false)
      setOpenModalSaveProcess(false)
      setFormsCompleteds([])
    }
  }

  const FormToBuilder = ({ formInputs, fiscal = false }: { formInputs: InputDetails[], fiscal?: boolean }): JSX.Element => {
    const parentHookForm = useForm({
      ...createInitialHookForm(formInputs),
      mode: 'onSubmit',
      reValidateMode: 'onSubmit'
    })

    const onSubmit = (data: any): void => {
      const dataWithUuidKey = convertDataKeysToUuid(data)

      const formAnswers = openModalFormToEditFiscal.formToEdit.map((form) => {
        const findyByUuid = dataWithUuidKey[form.uuid]

        return {
          formCampoId: form.FORMCAMPID,
          formId: form.FORMTIPID,
          campoId: form.campoId,
          valor: typeof findyByUuid === 'object' ? findyByUuid[0] : findyByUuid
        }
      })

      const answersToSend: IFormsCompleteds[] = []

      formAnswers.forEach((form) => {
        const formIdAlreadyExists = answersToSend.find(answer => answer.formularioTipoId === form.formId)
        if (formIdAlreadyExists?.formularioTipoId === form.formId) {
          const formIndex = answersToSend.findIndex(answer => answer.formularioTipoId === form.formId)
          answersToSend[formIndex].respostas.push({
            campoId: form.campoId as number,
            formCampoId: form.formCampoId as number,
            valor: form.valor
          })
        } else {
          answersToSend.push({
            formularioTipoId: form.formId as number,
            respostas: [{
              campoId: form.campoId as number,
              formCampoId: form.formCampoId as number,
              valor: form.valor
            }]
          })
        }
      })

      setFormsCompleteds(answersToSend)
      setOpenModalSaveProcess(true)
    }

    return (
      <>
        <FormBuilderHelper
          formInputs={formInputs}
          parentHookForm={parentHookForm}
        />

        <div className='d-flex justify-content-end'>
          <ButtonMetronic type='button' variant='primary' className='bg-blue-primary mt-5' rounded onClick={parentHookForm.handleSubmit(onSubmit)}>
            <FiCheck className='icon' />
            Salvar {requestEndpoint && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
          </ButtonMetronic>
        </div>
      </>
    )
  }

  const parseToInputForm = (forms: IFORMULARIOTIPO): any => {
    return forms.CAMPOS.map(({ FORMCAMPESTRUTURA, FORMCAMPID, FORMTIPID }) => {
      const initialValue = ''
      return { ...FORMCAMPESTRUTURA, FORMCAMPID, FORMTIPID, initialValue }
    })
  }

  useEffect(() => {
    // if (requestedJoinsData.data?.data) {
    //   const formToEdit = requestedJoinsData.data.data.flatMap(forms =>
    //     forms.FORMULARIOS.map(form => parseToInputForm(form))
    //   )
    //   const singleArray = formToEdit.flatMap(form => form)
    //   setOpenModalFormToEditFiscal({
    //     open: true,
    //     formToEdit: singleArray
    //   })
    // }

    if (demandaDetailData.data?.data) {
      setOpenModalFormToEditFiscal({
        open: true,
        formToEdit: demandaDetailData.data?.data?.FORMULARIOS?.flatMap(
          (forms) => parseToInputForm(forms.FORMULARIOTIPO as IFORMULARIOTIPO)
        )
      })
    }
  }, [demandaDetailData.data?.data])

  return (
    <div>
      <Tabs
        defaultActiveKey='request'
        id='tabs'
        className='mb-3'
      >
        <Tab eventKey={'request'} title='Solicitar'>
          <h5 className='mb-10'>Protocolo do Processo: <strong>{process.REQPROTOCOLO}</strong></h5>

          <div className='mb-10'>
            <p>Selecione os formulários:</p>
            {availableFormsData.isLoading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
            <div className='d-flex flex-column gap-5'>
              {availableFormsData.data?.data?.map((form, index) => (
                <CheckBox
                  key={index}
                  label={form.FORMTIPNOME}
                  onChange={() => { handleAddForm(form.FORMTIPID) }}
                  type='check'
                  id={`${form.FORMTIPID}`}
                />
              ))}
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
              <div className='col-md-12'>
                <label className='form-label fs-6 fw-bolder text-dark required'>Solicitação</label>
                <textarea
                  {...register('descricao')}
                  placeholder='Digite sua solicitação...'
                  className={clsx(
                    'form-control form-control-solid',
                    { 'is-invalid': errors.descricao },
                    { 'is-valid': errors.descricao == null && watch('descricao') }
                  )}
                />
                <div className='fv-plugins-message-container'>
                  {errors.descricao && <span role='alert'>{errors.descricao.message as string}</span>}
                </div>
              </div>
            </div>

            <div className='d-flex justify-content-end gap-3 mt-10'>
              <ButtonMetronic type='button' variant='danger' rounded onClick={onClose}>Fechar</ButtonMetronic>
              <ButtonMetronic type='submit' variant='primary' className='bg-blue-primary' rounded>
                <FiCheck className='icon' />
                Confirmar {requestEndpoint && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
              </ButtonMetronic>
            </div>
          </form>
        </Tab>
        <Tab eventKey={'response'} title='Responder'>
          <h5 className='mb-10'>Protocolo do Processo: <strong>{process.REQPROTOCOLO}</strong></h5>
          {demandaDetailData.isLoading && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}

          <div>
            {/* {requestedJoinsData.data?.data.map((requestJoined, index) => (

            ))} */}
            {/* {requestedJoinsData.data?.data.map((requestJoined) => (
              requestJoined.FORMULARIOS.flatMap(
                (forms) => <FormToBuilder formInputs={parseToInputForm(forms)} />
              )
            ))} */}
            {demandaDetailData.data?.data?.FORMULARIOS?.length! < 1
              ? (
              <p>Nenhuma solicitação de juntada de documentos pendente.</p>
                )
              : (
              <FormToBuilder formInputs={openModalFormToEditFiscal.formToEdit} />
                )}
          </div>
        </Tab>
      </Tabs>

      <ModalConfirm
        show={openModalSaveProcess}
        onClose={() => { setOpenModalSaveProcess(false) }}
        onConfirm={() => { void handleSaveFiscal() }}
        isLoading={requestEndpoint}
      >
        <p className='text-center'>Confirmar o envio das respostas?</p>
      </ModalConfirm>
    </div>
  )
}

export default JoinedDocumentsMunicipal
