/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useReactQuery } from 'app/shared/hooks/useReactQuery'
import { useSwr } from 'app/shared/hooks/useSwr'
import { type IDefaultRequest } from 'app/shared/types/common'
import { type IChangeStatusVinculados } from 'app/shared/types/plataform/vinculados'
import { type ILinkedAndBonds, type IVinculados } from 'app/shared/types/plataform/vinculos'
import { type KeyedMutator } from 'swr'

import { apiServerPlataforma } from '../api'

const endpointVinculados = '/vinculados'

const GetVinculados = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IVinculados>>(
    `${endpointVinculados}?${queryParams}`,
    apiServerPlataforma
  )
}

const GetVinculadosQuery = () => {
  return useReactQuery<IDefaultRequest<IVinculados>>(
    endpointVinculados,
    apiServerPlataforma
  )
}

const createVinculo = async (
  pesId: number,
  payload: ILinkedAndBonds,
  mutate: KeyedMutator<IDefaultRequest<IVinculados>>
) => {
  const response = await apiServerPlataforma.post(`${endpointVinculados}/${pesId}`, payload)
  if (response.status === 200) await mutate()
  return response
}

const updateVinculo = async (
  pesRelId: number,
  payload: IChangeStatusVinculados,
  mutate?: KeyedMutator<IDefaultRequest<IVinculados>>
) => {
  const response = await apiServerPlataforma.patch(`${endpointVinculados}/${pesRelId}`, payload)
  if (response.status === 200) mutate && await mutate()
  return response
}

export default {
  GetVinculados,
  GetVinculadosQuery,
  createVinculo,
  updateVinculo
}
