/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { type IDefaultRequest } from 'app/shared/types/common'
import { type IFormsAvailable, type IListAllJoinedProcess } from 'app/shared/types/processos/joinedProcess/joinedProcess'

import { apiProcessos } from '../../api'

const endpointJoinedProcess = '/requerimento/juntada'

const GetJoinedProcess = (REQID?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ ...params })
  return useSwr<IDefaultRequest<IListAllJoinedProcess>>(
    `${endpointJoinedProcess}/list-all/${REQID}?${queryParams}`,
    apiProcessos
  )
}

const GetFormsAvailable = (REQID?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ ...params })
  return useSwr<IDefaultRequest<IFormsAvailable>>(
    `${endpointJoinedProcess}/formularios/${REQID}?${queryParams}`,
    apiProcessos
  )
}

export const createJoinedProcess = async (
  REQID: number,
  payload: { formTiposIds: number[], solicitacao: string }
) => {
  const response = await apiProcessos.post(`${endpointJoinedProcess}/create/${REQID}`, payload)
  return response
}

const updateJoinedProcess = async (
  REQJUNID: number,
  payload: { statusId: number, justificativa: string }
) => {
  const response = await apiProcessos.put(`${endpointJoinedProcess}/aprovacao/${REQJUNID}`, payload)
  return response
}

export default {
  GetJoinedProcess,
  GetFormsAvailable,
  createJoinedProcess,
  updateJoinedProcess
}
