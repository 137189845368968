/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'

import { systemMessages } from '_metronic/i18n/systemMessages'
import { getInternalizeText } from 'app/shared/helpers/utils'
import { cepMask } from 'app/shared/helpers/valueMasks'
import { ServiceViaCep } from 'app/shared/services'
import clsx from 'clsx'

const StepperCorrespondence = (): JSX.Element => {
  const [, setRequestEndpoint] = useState<boolean>(false)
  const [fieldDisabled, setFieldDisabled] = useState<boolean>(false)

  const { register, formState: { errors, isDirty, dirtyFields }, setValue, setError, getValues, watch } = useFormContext()

  const handleSearchCep = async (value: string): Promise<void> => {
    setRequestEndpoint(true)
    setFieldDisabled(true)
    try {
      const cep = value.replace('-', '')
      if (cep.length < 8) {
        return
      }
      const response = await ServiceViaCep.GetCep(cep)
      if (!response.data.ibge) {
        toast.warning('CEP não encontrado')
        setFieldDisabled(true)
        setValue('cep', '')
        setValue('logradouro', '')
        setValue('complemento', '')
        setValue('bairro', '')
        setValue('cidade', '')
        setValue('uf', '')
        setValue('codigoIbge', '')
        setValue('pronto', '')
        return
      }
      if (response.status === 200) {
        // toast.success('CEP encontrado com sucesso')
        if (!response.data.bairro && !response.data.complemento && !response.data.logradouro) {
          setFieldDisabled(false)
        }
        setValue('logradouro', response.data.logradouro)
        setValue('complemento', response.data.complemento)
        setValue('bairro', response.data.bairro)
        setValue('cidade', response.data.localidade)
        setValue('uf', response.data.uf)
        setValue('codigoIbge', response.data.ibge)
        setValue('pronto', 'ok')
      }
      // 64083550
    } catch (error) {
      toast.error('Erro ao buscar CEP')
    } finally {
      setRequestEndpoint(false)
    }
  }

  const handleVerifyDirtyFields = (field: string): void => {
    if (isDirty && fieldDisabled) {
      const value = getValues(field)
      setValue(field, value)
    }
  }

  return (
    <>
      <div className='w-100 mb-10'>
        <h1 className='text-dark fw-bolder fs-2 mb-5'>
          {getInternalizeText(systemMessages.keysMessages.AuthRegisterLabelLocalization)}
        </h1>

        <div className='row mb-4'>
          <div className='col-md-2'>
            <label className='form-label fs-6 fw-bolder text-dark required'>CEP</label>
            <input
              {...register('cep')}
              type='text'
              placeholder='00000-000'
              maxLength={9}
              className={clsx(
                'form-control form-control-solid',
                { 'is-invalid': errors?.cep },
                { 'is-valid': errors?.cep == null && watch('cep') }
              )}
              onBlur={(event) => { void handleSearchCep(event.target.value) }}
              onChange={(event) => {
                if (event.target.value.length >= 8) {
                  void handleSearchCep(event.target.value)
                }
                setValue('cep', cepMask(event.target.value), { shouldValidate: true, shouldTouch: true })
              }}
            />
            <div className='fv-plugins-message-container'>
              {errors?.cep && <span role='alert'>{errors?.cep.message as string}</span>}
            </div>
          </div>
          <div className='col-md-5'>
            <label className='form-label fs-6 fw-bolder text-dark required'>{getInternalizeText(systemMessages.keysMessages.AuthRegisterLabelAddress)}</label>
            <input
              {...register('logradouro')}
              type='text'
              placeholder={getInternalizeText(systemMessages.keysMessages.AuthLabelPlaceholderEnterYourAddress)}
              className={clsx(
                'form-control form-control-solid',
                { 'is-invalid': errors?.logradouro },
                { 'is-valid': errors?.logradouro == null && watch('logradouro') }
              )}
              onChange={() => { handleVerifyDirtyFields('logradouro') }}
              disabled={fieldDisabled}
            />
            <div className='fv-plugins-message-container'>
              {errors?.logradouro && <span role='alert'>{errors?.logradouro.message as string}</span>}
            </div>
          </div>
          <div className='col-md-2'>
            <label className='form-label fs-6 fw-bolder text-dark required'>{getInternalizeText(systemMessages.keysMessages.AuthRegisterLabelNumber)}</label>
            <input
              {...register('numero')}
              type='text'
              placeholder='9999'
              className={clsx(
                'form-control form-control-solid',
                { 'is-invalid': errors?.numero },
                { 'is-valid': errors?.numero == null && watch('numero') }
              )}
            />
            <div className='fv-plugins-message-container'>
              {errors?.numero && <span role='alert'>{errors?.numero.message as string}</span>}
            </div>
          </div>
          <div className='col-md-3'>
            <label className='form-label fs-6 fw-bolder text-dark'>{getInternalizeText(systemMessages.keysMessages.AuthRegisterLabelComplement)}</label>
            <input
              {...register('complemento')}
              type='text'
              placeholder={getInternalizeText(systemMessages.keysMessages.AuthLabelPlaceholderEnterYourComplement)}
              className={clsx(
                'form-control form-control-solid',
                { 'is-invalid': errors?.complemento },
                { 'is-valid': errors?.complemento == null && watch('complemento') }
              )}
              // onChange={() => { handleVerifyDirtyFields('enderecos[0].complemento') }}
              // disabled={fieldDisabled}
            />
            <div className='fv-plugins-message-container'>
              {errors?.complemento && <span role='alert'>{errors?.complemento.message as string}</span>}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <label className='form-label fs-6 fw-bolder text-dark required'>{getInternalizeText(systemMessages.keysMessages.AuthRegisterLabelNeighborhood)}</label>
            <input
              {...register('bairro')}
              type='text'
              placeholder={getInternalizeText(systemMessages.keysMessages.AuthLabelPlaceholderEnterYourNeighborhood)}
              className={clsx(
                'form-control form-control-solid',
                { 'is-invalid': errors?.bairro },
                { 'is-valid': errors?.bairro == null && watch('bairro') }
              )}
              onChange={() => { handleVerifyDirtyFields('bairro') }}
              disabled={fieldDisabled}
            />
            <div className='fv-plugins-message-container'>
              {errors?.bairro && <span role='alert'>{errors?.bairro.message as string}</span>}
            </div>
          </div>
          <div className='col-md-4'>
            <label className='form-label fs-6 fw-bolder text-dark required'>{getInternalizeText(systemMessages.keysMessages.AuthRegisterLabelCity)}</label>
            <input
              {...register('cidade')}
              type='text'
              placeholder={getInternalizeText(systemMessages.keysMessages.AuthLabelPlaceholderEnterYourCity)}
              className={clsx(
                'form-control form-control-solid',
                { 'is-invalid': errors?.cidade },
                { 'is-valid': errors?.cidade == null && watch('cidade') }
              )}
              onChange={() => { setValue('cidade', getValues('cidade')) }}
              disabled
            />
            <div className='fv-plugins-message-container'>
              {errors?.cidade && <span role='alert'>{errors?.cidade.message as string}</span>}
            </div>
          </div>
          <div className='col-md-2'>
            <label className='form-label fs-6 fw-bolder text-dark required'>{getInternalizeText(systemMessages.keysMessages.AuthRegisterLabelState)}</label>
            <input
              {...register('uf')}
              type='text'
              placeholder='UF'
              className={clsx(
                'form-control form-control-solid',
                { 'is-invalid': errors?.uf },
                { 'is-valid': errors?.uf == null && watch('uf') }
              )}
              onChange={() => { setValue('uf', getValues('uf')) }}
              disabled
            />
            <div className='fv-plugins-message-container'>
              {errors?.uf && <span role='alert'>{errors?.uf.message as string}</span>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StepperCorrespondence
