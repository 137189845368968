/* eslint-disable @typescript-eslint/no-unused-vars */
import { lazy, type FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'

import { getCSSVariableValue } from '_metronic/assets/ts/_utils'
import { type WithChildren } from '_metronic/helpers'
import { MasterLayout } from '_metronic/layout/MasterLayout'
import PlataformaRoutes from 'app/modules/plataforma/PlataformaRoutes'
import DashboardInitial from 'app/pages/dashboard/DashboardInitial'
import BuilderPageWrapper from 'app/pages/layout-builder/BuilderPageWrapper'
import { MenuTestPage } from 'app/pages/MenuTestPage'

const PrivateRoutes = (): JSX.Element => {
  const ProcessosRoutes = lazy(async () => await import('app/modules/processos/ProcessosRoutes'))
  const PlataformaRoutes = lazy(async () => await import('app/modules/plataforma/PlataformaRoutes'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='select-city/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
        <Route path='dashboard' element={<DashboardInitial />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />

        {/* Lazy Modules */}
        <Route
          path='processos/*'
          element={
            <SuspensedView>
              <ProcessosRoutes />
            </SuspensedView>
          }
        />
        <Route
          path='plataforma/*'
          element={
            <SuspensedView>
              <PlataformaRoutes />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      0: baseColor
    },
    barThickness: 1,
    shadowBlur: 5
  })

  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
