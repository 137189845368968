/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { serializeQueryParams } from '../helpers/utils'
import { useSwr } from '../hooks/useSwr'
import { type IDefaultRequest, type IDefaultRequestObject } from '../types/common'
import { type IListSupervisor, type IPhysicalPerson } from '../types/plataform/person'
import { apiServerPlataforma } from './api'

const endpointPerson = '/pessoa'

const GetPersons = (params: {
  prefeitura?: number
  nome?: string
  perPage?: number
  municipio?: string
}) => {
  const queryParams = serializeQueryParams({ ...params })

  return useSwr<IDefaultRequestObject<IPhysicalPerson>>(
    `${endpointPerson}/list?${queryParams}`,
    apiServerPlataforma
  )
}

const GetSupervisor = (params: object = {}) => {
  const queryParams = serializeQueryParams({ ...params })
  return useSwr<IDefaultRequest<IListSupervisor>>(
    `${endpointPerson}/list?${queryParams}`,
    apiServerPlataforma
  )
}

const GetUserByCpf = (cpf: string) => {
  return useSwr<IDefaultRequestObject<IPhysicalPerson>>(
    `${endpointPerson}/cpf/${cpf}`,
    apiServerPlataforma
  )
}

export const getUserByCpf = async (cpf: string | number) => {
  const response = await apiServerPlataforma.get<IDefaultRequestObject<IPhysicalPerson>>(`${endpointPerson}/cpf/${cpf}`)
  return response
}

export const verifyRegister = async (params: object = {}) => {
  const queryParams = serializeQueryParams({ ...params })
  const response = await apiServerPlataforma.get<IDefaultRequestObject<{ CADASTROEXISTE: boolean }>>(
    `${endpointPerson}/verificar?${queryParams}`
  )
  return response
}

export const getEmailUser = async (email: string) => {
  const response = await apiServerPlataforma.get<IDefaultRequestObject<IPhysicalPerson>>(`${endpointPerson}/email/${email}`)
  return response
}

export const accessAtUser = async (personID: number) => {
  const response = await apiServerPlataforma.post<IDefaultRequestObject<{ access_token: string }>>(`auth/acess-at/${personID}`)
  return response
}

export const accessAtMe = async () => {
  const response = await apiServerPlataforma.post<IDefaultRequestObject<{ access_token: string }>>('auth/acess-at')
  return response
}

export default {
  GetPersons,
  GetSupervisor,
  GetUserByCpf,
  getUserByCpf,
  verifyRegister,
  getEmailUser,
  accessAtUser,
  accessAtMe
}
