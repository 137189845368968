import { type FC, Fragment, useEffect } from 'react'

import { RenderInputFileForm } from 'app/modules/processos/FormBuilder/Renders/RenderFileInput'
import { RenderInputForm } from 'app/modules/processos/FormBuilder/Renders/RenderInput'
import { RenderText } from 'app/modules/processos/FormBuilder/Renders/RenderText'
import { RenderTextFieldForm } from 'app/modules/processos/FormBuilder/Renders/RenderTextField'
import { type InputDetails } from 'app/modules/processos/FormBuilder/types'
import { generateNameForInput } from 'app/modules/processos/FormBuilder/utilsFormBuilderFunctions'

import { RenderSelectFieldForm } from './Renders/RenderSelect'
import { RenderTextEditor } from './Renders/RenderTextEditor'

interface FormBuilderHelperProps {
  formInputs: InputDetails[]
  parentHookForm?: any
}

const RenderComponentForm: FC<{ input: any }> = ({ input }) => {
  switch (input.type) {
    case 'text':
      return <RenderText {...input} />
    case 'input':
      return <RenderInputForm {...input} />
    case 'textField':
      return <RenderTextFieldForm {...input} />
    case 'select':
      return <RenderSelectFieldForm {...input} />
    case 'textEditor':
      return <RenderTextEditor {...input} />
    case 'radio':
      return <>
        Radio
      </>
    case 'file':
      return <RenderInputFileForm {...input} />
    default:
      return <>
        Erro ao renderizar componente favor excluir e adicionar novamente
      </>
  }
}

export const FormBuilderHelper: FC<FormBuilderHelperProps> = ({
  parentHookForm,
  ...props
}) => {
  const hookForm = parentHookForm

  useEffect(() => {
    hookForm.reset()
  }, [])

  return (
    <>
      <div className='row'>
        {
          props?.formInputs?.map((input) => {
            const nameInput = generateNameForInput(input)

            return (
              <Fragment key={input.uuid}>

                <RenderComponentForm input={{
                  ...input,
                  inputParams: {
                    ...hookForm.register(nameInput),
                    ...input.inputParams
                  },
                  hookForm
                }} />

              </Fragment>
            )
          })
        }
      </div>
    </>
  )
}
