const {
  PORT,
  REACT_APP_BASE_LAYOUT_CONFIG_KEY,
  REACT_APP_API_URL,
  REACT_APP_API_URL_CREATE_REQUIREMENT,
  REACT_APP_THEME_API_URL,
  REACT_APP_API_VIACEP,
  REACT_APP_API_RECEITA_FEDERAL,
  REACT_APP_API_SSO,
  REACT_APP_CLIENT_ID_GOOGLE
} = process.env

export const ENV_VARS = {
  PORT,
  BASE_LAYOUT_CONFIG_KEY: REACT_APP_BASE_LAYOUT_CONFIG_KEY,
  API_URL: REACT_APP_API_URL,
  API_URL_CREATE_REQUIREMENT: REACT_APP_API_URL_CREATE_REQUIREMENT,
  THEME_API_URL: REACT_APP_THEME_API_URL,
  API_VIACEP: REACT_APP_API_VIACEP,
  API_RECEITA_FEDERAL: REACT_APP_API_RECEITA_FEDERAL,
  API_SSO: REACT_APP_API_SSO,
  CLIENT_ID_GOOGLE: REACT_APP_CLIENT_ID_GOOGLE
}
