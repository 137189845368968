import { useUserStore } from 'app/store/Plataform/UserStore'

import { inObject } from './utils'

const getPermissionLevel = (): number => {
  const groupId = useUserStore.getState().person?.GRPID ?? GroupPermissionsLevel.USUARIO_PADRAO

  return PermissionsLevel[groupId]
}

const validatePermission = (permissionLevel: number | number[] | null): boolean => {
  const isValidPermission = (permissionLevel: number): boolean => {
    return getPermissionLevel() === permissionLevel
  }

  if (!permissionLevel) {
    return true
  }

  if (Array.isArray(permissionLevel)) {
    return permissionLevel
      .filter((level) => isValidPermission(level)).length > 0
  }

  return isValidPermission(permissionLevel)
}

const ProductPermissions = {
  S_DIGITAL: 's-Digital'
}

const GroupPermissionsLevel = {
  ADM_MASTER: 1,
  ADM_PREFEITURA: 2,
  ADM_EMPRESA: 3,
  CONTADOR: 4,
  USUARIO_FISCAL: 5,
  USUARIO_PADRAO: 6
}

const PermissionsLevel = {
  [GroupPermissionsLevel.ADM_MASTER]: 0,
  [GroupPermissionsLevel.ADM_PREFEITURA]: 1,
  [GroupPermissionsLevel.ADM_EMPRESA]: 2,
  [GroupPermissionsLevel.CONTADOR]: 3,
  [GroupPermissionsLevel.USUARIO_FISCAL]: 4,
  [GroupPermissionsLevel.USUARIO_PADRAO]: 5
}

const getPermissionLevelByGroupLevel = (groupsLevel: number[]): number[] => {
  return groupsLevel
    .map((groupLevel) => inObject(PermissionsLevel, groupLevel, null))
    .filter((level) => level !== null)
}

const accessCityHall = [GroupPermissionsLevel.ADM_MASTER, GroupPermissionsLevel.ADM_PREFEITURA, GroupPermissionsLevel.USUARIO_FISCAL]
const accessMaster = [GroupPermissionsLevel.ADM_MASTER]

export {
  getPermissionLevel,
  getPermissionLevelByGroupLevel,
  validatePermission,
  GroupPermissionsLevel,
  ProductPermissions,
  accessCityHall,
  accessMaster
}
