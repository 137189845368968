/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { type IDefaultRequestNotArray, type IDefaultRequestPaginated } from 'app/modules/auth'
import Toastfy from 'app/shared/helpers/Toastfy/Toastfy'
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { type IStatusDesc, type IDefaultRequestObject } from 'app/shared/types/common'
import { type AxiosResponse } from 'axios'

import { apiProcessos } from '../api'

const endpointRequeriment = '/requerimento'

export interface IProcessReturn {
  REQID: number
  FLUID: number
  STEPID: any
  PESID: number
  REQRESPONSAVEL: number
  REQSTATUS: number
  REQINCLUIDOEM: string
  CRIADOR: {
    PESID: number
    USUUID: string
    PESNOME: string
    PESTIPO: number
    PESTIPODESC: {
      id: number
      descricao: string
    }
  } | null
  RESPONSAVEL: {
    PESID: number
    USUUID: string
    PESNOME: string
    PESTIPO: number
    PESTIPODESC: {
      id: number
      descricao: string
    }
  } | null
  REQSTATUSDESC: {
    id: number
    name: string
  }
  REQPROTOCOLO: string
  FLUXO: {
    FLUID: number
    REQTIPID: number
    REQTIPDESCRICAO: string
    CATID: number
    CATDESCRICAO: string
    ASSID: number
    ASSDESCRICAO: string
    STEPID: number
    FLUDESCRICAO: string
    FLUTIPO: number
    FLUPRAZO: number
    FLUSTATUS: boolean
    FLUTIPODESC: {
      id: number
      name: string
    }
  }
}

export interface IProcessResume {
  REQUERIMENTO: {
    REQID: number
    FLUID: number
    STEPID: number
    PESID: number
    REQRESPONSAVEL: number
    REQSTATUS: number
    REQINCLUIDOEM: string
    CRIADOR: {
      PESID: number
      USUUID: string
      PESNOME: string
      PESTIPO: number
      PESTIPODESC: {
        id: number
        descricao: string
      }
      FISICA: {
        FISID: number
        PESID: number
        FISCPF: string
      }
      JURIDICA: any
    }
    REQSTATUSDESC: {
      id: number
      name: string
    }
    REQPROTOCOLO: string
    FLUXO: {
      FLUID: number
      REQTIPID: number
      REQTIPDESCRICAO: string
      CATID: number
      CATDESCRICAO: string
      ASSID: number
      ASSDESCRICAO: string
      STEPID: number
      FLUDESCRICAO: string
      FLUTIPO: number
      FLUPRAZO: number
      FLUSTATUS: boolean
      FLUTIPODESC: {
        id: number
        name: string
      }
      ASSUNTO: {
        ASSID: number
        ASSDESCRICAO: string
        ASSSTATUS: boolean
      }
      TIPOREQUERIMENTO: {
        REQTIPID: number
        REQTIPDESCRICAO: string
        REQTIPSTATUS: boolean
      }
      CATEGORIA: {
        CATID: number
        CATDESCRICAO: string
        CATSTATUS: boolean
      }
    }
  }
  RESUMOS: [{
    TRAMITACAODATA: string
    TRAMITACAODESC: string
    TRAMITACOES: [{
      FLUID: number
      PESID: number
      REQID: number
      REQTRAACAO: number
      REQTRAACAODESC: IStatusDesc
      RESPONSAVEL: {
        PESID: number
        PESNOME: string
      }
      REQTRATRAMITADOPARA: [{
        STEPID: number
        DEPRESPONSAVEL: number
        STEPDESCRICAO: string
        DEPARTAMENTORESPONSAVEL: {
          DEPID: number
          DEPNOME: string
        }
      }]
    }]
  }]
}

const getPersonProcess = async (
  params: {
    page?: number
    perPage?: number
    status?: number[]
  } = {}
): Promise<AxiosResponse<IDefaultRequestPaginated<IProcessReturn>>> => {
  return await apiProcessos.get(`${endpointRequeriment}/list`, {
    params
  })
}

const GetPersonProcess = (
  page?: number,
  params: {
    page?: number
    perPage?: number
    status?: number[]
    protocolo?: string
  } = {}
) => {
  const queryParams = serializeQueryParams({ page, ...params })

  return useSwr<IDefaultRequestPaginated<IProcessReturn>>(
    `${endpointRequeriment}/list?${queryParams}`,
    apiProcessos
  )
}

const GetEveryPersonProcess = (
  page?: number,
  params: {
    page?: number
    perPage?: number
    status?: number[]
    protocolo?: string
  } = {}
) => {
  const queryParams = serializeQueryParams({ page, ...params })

  return useSwr<IDefaultRequestPaginated<IProcessReturn>>(
    `${endpointRequeriment}/all?${queryParams}`,
    apiProcessos
  )
}

const GetResumeRequirements = (reqId: number) => {
  return useSwr<IDefaultRequestObject<IProcessResume>>(
    `${endpointRequeriment}/tramitacao/resumo/${reqId}`,
    apiProcessos
  )
}

const getGeneratePDFUrl = async (reqId: number) => {
  return await apiProcessos.get<IDefaultRequestNotArray<{
    url: string
  }>>(`${endpointRequeriment}/pdf/id/${reqId}`)
}

const openPdfFromProcess = async (reqId: number) => {
  try {
    const response = await getGeneratePDFUrl(reqId)
    return response
    // window.open(data.data.url, '_blank')
  } catch (error: any) {
    Toastfy.error(error.response?.data?.message ?? 'Erro ao gerar PDF')
  }
}
export default {
  getPersonProcess,
  GetPersonProcess,
  GetEveryPersonProcess,
  GetResumeRequirements,
  getGeneratePDFUrl,
  openPdfFromProcess
}
