/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { BiSolidCloudUpload } from 'react-icons/bi'
import { RiCloseCircleLine } from 'react-icons/ri'
import { toast } from 'react-toastify'

import { toAbsoluteUrl } from '_metronic/helpers'
import { systemMessages } from '_metronic/i18n/systemMessages'
import { yupResolver } from '@hookform/resolvers/yup'
import { UserModel } from 'app/modules/auth'
import { type ILoggedUser } from 'app/modules/plataforma/core/loggedUsers/loggedUserInterfaces'
import { initValuesLoggedUser, schemaLoggedUser } from 'app/modules/plataforma/core/loggedUsers/loggedUserValidation'
import ButtonMetronic from 'app/shared/components/Button/ButtonMetronic'
import CheckBox from 'app/shared/components/CheckBox/CheckBox'
import LoadingSpinners from 'app/shared/components/Loadings/LoadingSpinners'
import ReactModal from 'app/shared/components/ReactModal/ReactModal'
import { capitalizeFirstLetter, getInternalizeText } from 'app/shared/helpers/utils'
import { cellphoneMask, cepMask, cpfCnpjMask, cpfMask, numberMask } from 'app/shared/helpers/valueMasks'
import { ServicePerson, ServiceLoggedUser, ServiceViaCep } from 'app/shared/services'
import { type ILoggedUserAddress, type ILoggedUserUpdateAddress } from 'app/shared/types/plataform/loggedUser'
import { useUserStore } from 'app/store/Plataform/UserStore'
import clsx from 'clsx'
import * as Yup from 'yup'

import ConfirmChangeData from './ConfirmChangeData'

interface IChangeData {
  onClose: () => void
}

const ChangeData = ({ onClose }: IChangeData): JSX.Element => {
  const [requestEndpoint, setRequestEndpoint] = useState<boolean>(false)
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [fieldDisabled, setFieldDisabled] = useState<boolean>(true)
  const [submitDisabled, setsubmitDisabled] = useState<boolean>(true)
  const [openModalConfirmChangeEmail, setOpenModalConfirmChangeEmail] = useState<boolean>(false)
  const [uploadAvatar, setUploadAvatar] = useState<File | null>(null)
  const [imagePreview, setImagePreview] = useState<string | null>('')
  const [registeredAddresses, setRegisteredAddresses] = useState<ILoggedUserAddress[]>([])
  const [openModalBigPhoto, setOpenModalBigPhoto] = useState<boolean>(false)
  const [cellPhoneAndTelePhone, setCellPhoneAndTelePhone] = useState<{
    celular: string
    telefone: string
  }>({
    celular: '',
    telefone: ''
  })
  const { user, setUser, person, setPerson } = useUserStore()

  const addressesData = ServiceLoggedUser.GetAddresses()
  const userData = ServicePerson.GetUserByCpf(user?.USUCPF!)
  const phonesData = ServiceLoggedUser.GetDataPhones(1)

  const { register, handleSubmit, formState: { errors, isDirty, dirtyFields }, setValue, resetField, getValues, watch } = useForm<ILoggedUser>({
    defaultValues: initValuesLoggedUser,
    resolver: yupResolver(schemaLoggedUser)
  })

  const clearFields = (): void => {
    resetField('cep')
    resetField('endereco')
    resetField('numero')
    resetField('bairro')
    resetField('cidade')
    resetField('uf')
    resetField('complemento')
    resetField('ibge')
    resetField('enderecoPrincipal')
    resetField('unico')
    setValue('PESENDID', undefined)

    const select = document.getElementById('select-address') as HTMLSelectElement
    select.selectedIndex = 0
    handleVerifyStatusSubmit()
  }

  const teste = getValues()

  const handleGetNameFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0]
    if (file) {
      setUploadAvatar(file)
      const maxSizeInBytes = 1024 * 1024
      if (file.size > maxSizeInBytes) {
        toast.warning('Tamanho superior à 1MB')
        setImagePreview(null)
        setUploadAvatar(null)
        return
      }

      const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/jpg']
      if (!allowedMimeTypes?.includes(file.type)) {
        toast.warning('Formato de imagem inválido')
        setImagePreview(null)
        setUploadAvatar(null)
        return
      }

      const formData = new FormData()
      formData.append('imagem', file)
      setValue('imagem', file)

      handleVerifyStatusSubmit()

      const reader = new FileReader()
      reader.onloadend = () => {
        setImagePreview(reader.result as string)
      }
      reader.readAsDataURL(file)

      setsubmitDisabled(false)
    }
  }

  // useEffect(() => {
  //   setValue('imagem', uploadAvatar!)
  //   if (uploadAvatar ?? imagePreview) {
  //     if (uploadAvatar?.size) {
  //       const maxSizeInBytes = 1024 * 1024
  //       if (uploadAvatar?.size > maxSizeInBytes) {
  //         toast.warning('Tamanho superior à 1MB')
  //         setImagePreview(null)
  //         setUploadAvatar(null)
  //       }
  //     }

  //     if (uploadAvatar?.type ?? imagePreview) {
  //       const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/jpg']
  //       if (!allowedMimeTypes?.includes(uploadAvatar?.type!)) {
  //         toast.warning('Formato de imagem inválido')
  //         setImagePreview(null)
  //         setUploadAvatar(null)
  //       }
  //     }
  //   }
  // }, [uploadAvatar])

  const onSubmit: SubmitHandler<ILoggedUser> = async (data) => {
    const PESENDID = getValues('PESENDID')

    if (data.imagem) {
      setRequestEndpoint(true)
      try {
        const response = await ServiceLoggedUser.updateUserPhoto(data.imagem)
        if (response.status === 200) {
          toast.success('Foto de perfil atualizada com sucesso!')
          setUser({
            ...user!,
            USUIMGPATH: response.data.data[0].USUIMGPATH
          })
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.message ?? 'Erro ao atualizar foto de perfil! Tente novamente.')
      }

      if (!data.cep) {
        setRequestEndpoint(false)
        return
      }
    }

    const addressData = {
      codigoIbge: data.ibge,
      logradouro: data.endereco,
      bairro: data.bairro,
      numero: String(data.numero),
      complemento: data.complemento as string,
      cep: numberMask(data.cep).toString(),
      PESENDSTATUS: 1,
      PESENDPRINCIPAL: getValues('enderecoPrincipal'),
      unico: data.unico
    }

    // const addressMainExists = addressesData.data?.data.some(address => address.PESENDPRINCIPAL)
    const addressMainExists = registeredAddresses.some(address => address.PESENDPRINCIPAL)
    if (!addressMainExists && !addressData.PESENDPRINCIPAL) {
      toast.warning('Necessário ter ao menos um endereço principal!')
      setRequestEndpoint(false)
      return
    }

    if (dirtyFields.email && confirmPassword === '') {
      setOpenModalConfirmChangeEmail(true)
      setRequestEndpoint(false)
    } else {
      if (dirtyFields.email) {
        setRequestEndpoint(true)
        try {
          const PESEMAILID = phonesData.data?.data.emails[0].PESEMAILID as number
          const dataToSend = {
            email: data.email,
            senha: confirmPassword
          }
          const responseEmail = await ServiceLoggedUser.updateEmail(PESEMAILID, dataToSend, phonesData.mutate)
          if (responseEmail.status === 200) {
            toast.success('E-mail atualizado com sucesso!')
            // setPerson({
            //   ...person!,
            //   PESSOA: {
            //     ...person?.PESSOA!,
            //     EMAILS: [{
            //       ...person?.PESSOA?.EMAILS![0]!,
            //       PESEMAIL: data.email
            //     }]
            //   }
            // })
          }
        } catch (error: any) {
          toast.error(error?.response?.data?.errors?.senha?.[0] ?? error?.response?.data?.message)
        } finally {
          setOpenModalConfirmChangeEmail(false)
          setConfirmPassword('')
        }
      }

      const celular = phonesData.data?.data.telefones.filter(telefone => telefone.FONETIPO.id === 1)
      const telefone = phonesData.data?.data.telefones.filter(telefone => telefone.FONETIPO.id === 2)
      if (numberMask(watch('celular')) !== celular?.[0]?.PESFONETELEFONE || numberMask(watch('telefone') as string) !== telefone?.[0]?.PESFONETELEFONE) {
        try {
          setRequestEndpoint(true)
          let dataPhones = [
            { pesFoneId: celular?.[0]?.PESFONEID as number ?? '', numero: numberMask(data.celular) as string, principal: true }
          ]

          if (!telefone?.[0]?.PESID && watch('telefone')) {
            const payload = {
              telefone: numberMask(data.telefone!) as string,
              principal: false
            }
            const response = await ServiceLoggedUser.createContact(payload, phonesData.mutate)
            if (response.status === 200) {
              dataPhones = [
                { pesFoneId: celular?.[0]?.PESFONEID as number ?? '', numero: numberMask(data.celular) as string, principal: true },
                { pesFoneId: response.data.data[1].PESFONEID, numero: numberMask(data.telefone!) as string, principal: false }
              ]
            }
          } else {
            if (watch('telefone')) {
              dataPhones = [
                { pesFoneId: celular?.[0]?.PESFONEID as number ?? '', numero: numberMask(data.celular) as string, principal: true },
                { pesFoneId: telefone?.[0]?.PESFONEID as number ?? '', numero: numberMask(data.telefone!) as string, principal: false }
              ]
            }
          }

          if (cellPhoneAndTelePhone.celular !== numberMask(watch('celular')) || cellPhoneAndTelePhone.telefone !== numberMask(watch('telefone') as string)) {
            const response = await ServiceLoggedUser.updateContact(dataPhones, phonesData.mutate)
            if (response.status === 200) {
              toast.success('Dados de contato atualizados com sucesso!')
              setPerson({
                ...person!,
                PESSOA: {
                  ...person?.PESSOA!,
                  TELEFONES: response.data.data[0]
                }
              })
            }
          }
        } catch (error: any) {
          toast.error(error?.response?.data?.message ?? 'Erro ao atualizar os dados! Tente novamente.')
        }
      }

      if (addressData.cep !== '' ?? addressData.codigoIbge !== '') {
        setRequestEndpoint(true)

        try {
          const dataToSend = {
            ...data,
            celular: numberMask(data.celular).toString(),
            telefone: numberMask(data.telefone!).toString(),
            cep: numberMask(data.cep !== '' ? data.cep! : user?.USUCEP!) as string,
            bairro: data.bairro !== '' ? data.bairro : user?.USUBAIRRO as string,
            cidade: data.cidade !== '' ? data.cidade : user?.USUCIDADE as string,
            uf: data.uf !== '' ? data.uf : user?.USUUF as string,
            endereco: data.endereco !== '' ? data.endereco : user?.USUENDERECO as string,
            ibge: data.ibge !== '' ? data.ibge : user?.USUCODIBGE as string,
            cpf: numberMask(data.cpf !== '' ? data.cpf : user?.USUCPF!) as string
          }

          if (isNaN(Number(PESENDID)) || PESENDID === '') {
            const response = await ServiceLoggedUser.createAddress(addressData, addressesData.mutate)
            if (response.status === 201) {
              toast.success('Endereço cadastrado com sucesso!')
            }
          } else {
            const response = await ServiceLoggedUser.updateAddress(Number(PESENDID), addressData, addressesData.mutate)
            if (response.status === 200) {
              toast.success('Endereço atualizado com sucesso!')
            }
          }
        } catch (error: any) {
          toast.error(error?.response?.data?.message ?? 'Erro ao salvar os dados! Tente novamente.')
        } finally {
          setRequestEndpoint(false)
          setOpenModalConfirmChangeEmail(false)
          clearFields()
        }
      }
    }

    setRequestEndpoint(false)
  }

  const handleChangeAddress = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const addressId = event.target.value
    if (!addressId && addressId !== '') {
      toast.warning('Erro ao selecionar um endereço! Tente novamente.')
      clearFields()
      return
    }

    // const addressSelected = addressesData.data?.data.find(address => address.ENDERECO.ENDID === Number(addressId))
    const addressSelected = registeredAddresses.find(address => address.ENDERECO.ENDID === Number(addressId))
    setValue('cep', cepMask(addressSelected?.ENDERECO.ENDCEP!))
    setValue('endereco', addressSelected?.ENDERECO.ENDLOGRADOURO!)
    setValue('numero', addressSelected?.ENDERECO.ENDNUMERO! ?? '')
    setValue('bairro', addressSelected?.ENDERECO.ENDBAIRRO!)
    setValue('cidade', addressSelected?.ENDERECO.MUNICIPIO.MUNNOME!)
    setValue('uf', addressSelected?.ENDERECO.MUNICIPIO.MUNUF!)
    setValue('complemento', addressSelected?.ENDERECO.ENDCOMPLEMENTO!)
    setValue('ibge', addressSelected?.ENDERECO.MUNICIPIO.MUNCODIBGE!)
    setValue('enderecoPrincipal', addressSelected?.PESENDPRINCIPAL! ?? false)
    setValue('PESENDID', String(addressSelected?.PESENDID!))

    setsubmitDisabled(false)
    if (!addressId) {
      handleVerifyStatusSubmit()
    }
  }

  const handleSearchCep = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (event.target.value === '' || event.target.value.length < 9) return

    setRequestEndpoint(true)
    setFieldDisabled(true)
    setValue('unico', false)
    try {
      const cep = event.target.value.replace('-', '')
      const response = await ServiceViaCep.GetCep(cep)
      if (response.status === 200 && !response.data.erro) {
        toast.success('CEP encontrado com sucesso')
        if (!response.data.bairro && !response.data.complemento && !response.data.logradouro) {
          setFieldDisabled(false)
          setValue('unico', true)
        }
        handleVerifyStatusSubmit()
        setValue('cep', response.data.cep)
        setValue('endereco', response.data.logradouro)
        setValue('complemento', response.data.complemento)
        setValue('bairro', response.data.bairro)
        setValue('cidade', response.data.localidade)
        setValue('uf', response.data.uf)
        setValue('ibge', response.data.ibge.toString())
      } else {
        toast.warning('CEP não encontrado')
        clearFields()
        setsubmitDisabled(true)
        handleVerifyStatusSubmit()
      }
    } catch (error) {
      toast.error('Erro ao buscar CEP')
    } finally {
      setRequestEndpoint(false)
      // setsubmitDisabled(false)
    }
  }

  const handleVerifyDirtyFields = (field: any): void => {
    if (isDirty && fieldDisabled) {
      const value = getValues(field)
      setValue(field, value)
    }
  }

  const handleVerifyStatusSubmit = (): void => {
    if (watch('cep') || watch('imagem') || watch('email') || watch('telefone') || watch('celular')) {
      setsubmitDisabled(false)
      if (watch('cep')) setsubmitDisabled(false)
      if (watch('imagem')) setsubmitDisabled(false)
      if (watch('email')) setsubmitDisabled(false)
      if (watch('telefone')) setsubmitDisabled(false)
      if (watch('celular')) setsubmitDisabled(false)

      if (watch('cep') && watch('imagem') && watch('email') && watch('celular') && watch('telefone')) setsubmitDisabled(false)
      if (watch('cep') || watch('imagem') || watch('email') || watch('telefone') || watch('celular')) setsubmitDisabled(false)
    } else {
      setsubmitDisabled(true)
    }

    if (!watch('cep') && !watch('imagem') && !watch('email') && !watch('celular') && !watch('telefone')) setsubmitDisabled(true)
  }

  useEffect(() => {
    handleVerifyStatusSubmit()
  }, [dirtyFields.email])

  useEffect(() => {
    const celular = phonesData.data?.data.telefones.filter(telefone => telefone.FONETIPO.id === 1)
    const telefone = phonesData.data?.data.telefones.filter(telefone => telefone.FONETIPO.id === 2)

    setValue('cpf', cpfCnpjMask(userData.data?.data.FISICA.FISCPF!) ?? '')
    setValue('nome', userData.data?.data.PESNOME ?? '')
    setValue('email', userData.data?.data.EMAILS[0].PESEMAIL! ?? '')
    setValue('celular', cellphoneMask(celular?.[0]?.PESFONETELEFONE!) ?? '')
    setValue('telefone', cellphoneMask(telefone?.[0]?.PESFONETELEFONE!) ?? '')

    setCellPhoneAndTelePhone({
      celular: numberMask(celular?.[0]?.PESFONETELEFONE!) as string ?? '',
      telefone: numberMask(telefone?.[0]?.PESFONETELEFONE!) as string ?? ''
    })
  }, [userData.data?.data, phonesData.data?.data])

  useEffect(() => {
    if (!addressesData.error && addressesData.data?.data.length! >= 1) {
      setRegisteredAddresses(addressesData.data?.data ?? [])
    }
  }, [addressesData.data?.data])

  return (
    <>
      {phonesData.isLoading && userData.isLoading && addressesData.isLoading && (
        <LoadingSpinners />
      )}

      {!phonesData.isLoading && !userData.isLoading && !addressesData.isLoading && (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h4>Dados Pessoais</h4>
            <div className='separator mb-5'></div>
            <div className="row mb-15">
              <div className="col-md-3">
                <label className='form-label fs-6 fw-bolder text-dark'>CPF</label>
                <input
                  {...register('cpf')}
                  type='text'
                  placeholder='Ex.: 000.000.000-00'
                  className='form-control form-control-solid'
                  // value={cpfMask(userData.data?.data.FISICA.FISCPF!) ?? ''}
                  disabled
                />
                <div className='fv-plugins-message-container'>
                  {errors.cpf && <span role='alert'>{errors.cpf.message as string}</span>}
                </div>
              </div>
              <div className="col-md-6">
                <label className='form-label fs-6 fw-bolder text-dark'>Nome</label>
                <input
                  {...register('nome')}
                  type='text'
                  placeholder='Insira seu nome'
                  className='form-control form-control-solid'
                  // value={capitalizeFirstLetter(userData.data?.data.PESNOME!) ?? ''}
                  disabled
                />
                <div className='fv-plugins-message-container'>
                  {errors.nome && <span role='alert'>{errors.nome.message as string}</span>}
                </div>
              </div>
              <div className="col-md-3">
                <label className='form-label fs-6 fw-bolder text-dark'></label>
                <div className='w-100 d-flex justify-content-center mb-3 cursor-pointer' onClick={() => { setOpenModalBigPhoto(true) }}>
                  {imagePreview
                    ? (
                      <img src={imagePreview} className='stepper--data-personal-avatar' />
                      )
                    : (
                      <img src={toAbsoluteUrl(user?.USUIMGPATH ? `${user?.USUIMGPATH}` : '/media/avatars/blank.png')} className='stepper--data-personal-avatar' />
                      )}
                </div>
                <label className='form-label fs-6 fw-bolder text-dark'>Foto de Perfil</label>

                {/* Mudando estilo do seletor de arquivos */}
                <label htmlFor="stepper--personal-data-inputfile" className='stepper--personal-data-file'>
                  <BiSolidCloudUpload className='icon' />
                  Selecione o arquivo
                </label>
                <input type="file" id='stepper--personal-data-inputfile' onChange={handleGetNameFile} />
                <small className='stepper--personal-data-name mt-2 d-flex justify-content-between'>
                  {uploadAvatar && `${uploadAvatar?.name} - ${uploadAvatar?.size}KB`}
                  {imagePreview && <RiCloseCircleLine className='icon cursor-pointer' onClick={() => { setValue('imagem', ''); setUploadAvatar(null); setImagePreview(null); handleVerifyStatusSubmit() }} />}
                </small>

                <div className='fv-plugins-message-container'>
                  {errors.imagem && <span role='alert'>{errors.imagem.message as string}</span>}
                </div>
                {/* Fim */}

                <small className='d-block mt-2'>
                  {getInternalizeText(systemMessages.keysMessages.AuthRegisterLabelMaximumSize, { size: '1' })} <br />
                  Tipos permitidos .JPG e .PNG
                </small>
              </div>
            </div>

            <h4>Dados para Contato</h4>
            <div className='separator mb-5'></div>
            <div className='row mb-15'>
              <div className='col-md-4'>
                <label className='form-label fs-6 fw-bolder text-dark required'>E-mail</label>
                <input
                  {...register('email')}
                  type='text'
                  placeholder='Insire seu e-mail'
                  className={clsx(
                    'form-control',
                    { 'is-invalid': errors.email },
                    { 'is-valid': errors.email == null && watch('email') }
                  )}
                />
                <div className='fv-plugins-message-container'>
                  {errors.email && <span role='alert'>{errors.email.message as string}</span>}
                </div>
              </div>
              <div className='col-md-4'>
                <label className='form-label fs-6 fw-bolder text-dark required'>Celular</label>
                <input
                  {...register('celular')}
                  type='text'
                  placeholder='(00) 00000-0000'
                  className={clsx(
                    'form-control',
                    { 'is-invalid': errors.celular },
                    { 'is-valid': errors.celular == null && watch('celular') }
                  )}
                  onChange={(e) => {
                    setValue('celular', cellphoneMask(e.target.value), { shouldValidate: true, shouldTouch: true })
                    handleVerifyStatusSubmit()
                  }}
                />
                <div className='fv-plugins-message-container'>
                  {errors.celular && <span role='alert'>{errors.celular.message as string}</span>}
                </div>
              </div>
              <div className='col-md-4'>
                <label className='form-label fs-6 fw-bolder text-dark'>Telefone</label>
                <input
                  {...register('telefone')}
                  type='text'
                  placeholder='(00) 00000-0000'
                  className={clsx(
                    'form-control',
                    { 'is-invalid': errors.telefone },
                    { 'is-valid': errors.telefone == null && watch('telefone') }
                  )}
                  onChange={(e) => {
                    setValue('telefone', cellphoneMask(e.target.value), { shouldValidate: true, shouldTouch: true })
                    handleVerifyStatusSubmit()
                  }}
                />
                <div className='fv-plugins-message-container'>
                  {errors.telefone && <span role='alert'>{errors.telefone.message as string}</span>}
                </div>
              </div>
            </div>

            <h4>Localização</h4>
            <div className='separator mb-5'></div>
            <div className='row mb-4'>
              <div className='col-md-12'>
                <label className='form-label fs-6 fw-bolder text-dark'>Endereços Cadastrados</label>
                <select className="form-select" id='select-address' onChange={handleChangeAddress}>
                  <option>Selecione uma opção</option>
                  {addressesData?.data?.data?.map((address, index) => (
                    <option key={index} value={address.ENDERECO.ENDID}>
                      {`${address.ENDERECO.ENDLOGRADOURO} - ${cepMask(address.ENDERECO.ENDCEP)} | ${address.ENDERECO.ENDBAIRRO}`}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='row mb-4'>
              <div className='col-md-3'>
                <label className='form-label fs-6 fw-bolder text-dark required'>CEP</label>
                <input
                  {...register('cep')}
                  type='text'
                  placeholder='00000-000'
                  className={clsx(
                    'form-control',
                    { 'is-invalid': errors.cep },
                    { 'is-valid': errors.cep == null && watch('cep') }
                  )}
                  onChange={(e) => {
                    setValue('cep', cepMask(e.target.value), { shouldValidate: true, shouldTouch: true })
                    void handleSearchCep(e)
                  }}
                // onBlur={handleSearchCep}
                />
                <div className='fv-plugins-message-container'>
                  {errors.cep && <span role='alert'>{errors.cep.message as string}</span>}
                </div>
              </div>
              <div className='col-md-7'>
                <label className='form-label fs-6 fw-bolder text-dark required'>Endereço</label>
                <input
                  {...register('endereco')}
                  type='text'
                  placeholder='Insira seu endereço'
                  className={clsx(
                    'form-control',
                    { 'is-invalid': errors.endereco },
                    { 'is-valid': errors.endereco == null && watch('endereco') }
                  )}
                  onChange={() => { handleVerifyDirtyFields('endereco') }}
                  disabled={fieldDisabled}
                />
                <div className='fv-plugins-message-container'>
                  {errors.endereco && <span role='alert'>{errors.endereco.message as string}</span>}
                </div>
              </div>
              <div className='col-md-2'>
                <label className='form-label fs-6 fw-bolder text-dark required'>Número</label>
                <input
                  {...register('numero')}
                  type='text'
                  placeholder='0000'
                  className={clsx(
                    'form-control',
                    { 'is-invalid': errors.numero },
                    { 'is-valid': errors.numero == null && watch('numero') }
                  )}
                />
                <div className='fv-plugins-message-container'>
                  {errors.numero && <span role='alert'>{errors.numero.message as string}</span>}
                </div>
              </div>
            </div>

            <div className='row mb-4'>
              <div className='col-md-4'>
                <label className='form-label fs-6 fw-bolder text-dark required'>Bairro</label>
                <input
                  {...register('bairro')}
                  type='text'
                  placeholder='Insira seu bairro'
                  className={clsx(
                    'form-control',
                    { 'is-invalid': errors.bairro },
                    { 'is-valid': errors.bairro == null && watch('bairro') }
                  )}
                  onChange={() => { handleVerifyDirtyFields('bairro') }}
                  disabled={fieldDisabled}
                />
                <div className='fv-plugins-message-container'>
                  {errors.bairro && <span role='alert'>{errors.bairro.message as string}</span>}
                </div>
              </div>
              <div className='col-md-6'>
                <label className='form-label fs-6 fw-bolder text-dark required'>Município</label>
                <input
                  {...register('cidade')}
                  type='text'
                  placeholder='Insira seu município'
                  className={clsx(
                    'form-control',
                    { 'is-invalid': errors.cidade },
                    { 'is-valid': errors.cidade == null && watch('cidade') }
                  )}
                  onChange={() => { handleVerifyDirtyFields('cidade') }}
                  disabled
                />
                <div className='fv-plugins-message-container'>
                  {errors.cidade && <span role='alert'>{errors.cidade.message as string}</span>}
                </div>
              </div>
              <div className='col-md-2'>
                <label className='form-label fs-6 fw-bolder text-dark required'>UF</label>
                <input
                  {...register('uf')}
                  type='text'
                  placeholder='Insira seu UF'
                  className={clsx(
                    'form-control',
                    { 'is-invalid': errors.uf },
                    { 'is-valid': errors.uf == null && watch('uf') }
                  )}
                  onChange={() => { handleVerifyDirtyFields('uf') }}
                  disabled
                />
                <div className='fv-plugins-message-container'>
                  {errors.uf && <span role='alert'>{errors.uf.message as string}</span>}
                </div>
              </div>
            </div>

            <div className='row mb-4'>
              <div className='col-md-12'>
                <label className='form-label fs-6 fw-bolder text-dark'>Complemento</label>
                <input
                  {...register('complemento')}
                  type='text'
                  placeholder='Insira seu complemento'
                  className={clsx(
                    'form-control',
                    { 'is-invalid': errors.complemento },
                    { 'is-valid': errors.complemento == null && watch('complemento') }
                  )}
                />
                <div className='fv-plugins-message-container'>
                  {errors.complemento && <span role='alert'>{errors.complemento.message as string}</span>}
                </div>
              </div>
            </div>

            <div className='row mb-4'>
              <div className='col-md-4'>
                <CheckBox
                  {...register('enderecoPrincipal')}
                  type='check'
                  checked={watch('enderecoPrincipal')}
                  onChange={(e) => {
                    setValue('enderecoPrincipal', !getValues('enderecoPrincipal'))
                    const address = registeredAddresses.find(address => address.PESENDID === Number(getValues('PESENDID')))
                    if (address) {
                      setRegisteredAddresses((prevState) => {
                        const newAddress = prevState.map((address) => {
                          if (address.PESENDID === Number(getValues('PESENDID'))) {
                            return { ...address, PESENDPRINCIPAL: getValues('enderecoPrincipal') }
                          }
                          return address
                        })
                        return newAddress
                      })
                    }
                  }}
                  label='Endereço Principal?'
                />
              </div>
            </div>

            <div className='d-flex gap-3 justify-content-end mt-20'>
              <ButtonMetronic type='button' variant='primary' light rounded onClick={onClose}>Fechar</ButtonMetronic>
              <ButtonMetronic type='submit' variant='primary' className='bg-blue-primary' rounded disabled={submitDisabled}>
                Salvar {requestEndpoint && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
              </ButtonMetronic>
            </div>
          </form>

          <ReactModal
            headerName='Confirmar alteração do e-mail?'
            show={openModalConfirmChangeEmail}
            size='sm'
            onClose={() => { setOpenModalConfirmChangeEmail(false) }}
          >
            <ConfirmChangeData setConfirmPassword={setConfirmPassword} values={teste} onSubmitForm={onSubmit} onClose={() => { setOpenModalConfirmChangeEmail(false) }} />
          </ReactModal>

          <ReactModal
            headerName='Foto de Perfil'
            show={openModalBigPhoto}
            onClose={() => { setOpenModalBigPhoto(false) }}
          >
            <div className='w-100 d-flex justify-content-center mb-3'>
              {imagePreview
                ? (
                  <img src={imagePreview} className='stepper--data-personal-avatar' width={500} height={500} />
                  )
                : (
                  <img src={toAbsoluteUrl(user?.USUIMGPATH ? `${user?.USUIMGPATH}` : '/media/avatars/blank.png')} className='stepper--data-personal-avatar bigsize' />
                  )}
            </div>
          </ReactModal>
        </>
      )}
    </>
  )
}

export default ChangeData
