/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { type TestConfig } from 'yup'

import { DateUtils } from './utils'
import {
  type ValidFileExtensions,
  validateCellphone,
  validateCep,
  validateCnpj,
  validateCpf,
  validateFileType,
  validFileExtensions,
  validateEmailRegex,
  validateFileSize as validateFileSizeValue,
  type SizeType
} from './validateValues'

export const validateCepMask = (): TestConfig => {
  return {
    message: 'CEP inválido',
    name: 'validateMaskCep',
    test: (cep?: number | string) => validateCep(`${cep}`)
  } as TestConfig
}

export const validateCpfMask = (): TestConfig => {
  return {
    test: (cpf?: number | string) => validateCpf(`${cpf}`),
    message: 'CPF inválido',
    name: 'validateCpf'
  } as TestConfig
}

export const validateCnpjMask = (): TestConfig => {
  return {
    test: (cnpj?: number | string) => validateCnpj(`${cnpj}`),
    message: 'CNPJ inválido',
    name: 'validateCnpj'
  } as TestConfig
}

export const validateCpfCnpjMask = (): TestConfig => {
  return {
    test: (cpfCnpj?: number | string) => {
      if (!cpfCnpj) cpfCnpj = ''

      return validateCpf(`${cpfCnpj}`) || validateCnpj(`${cpfCnpj}`)
    },
    message: 'CPF/CNPJ inválido',
    name: 'validateCpfCnpj'
  } as TestConfig
}

export const validateCellphoneMask = (): TestConfig => {
  return {
    test: (cellphone?: number | string) => {
      return validateCellphone(`${cellphone}`)
    },
    message: 'Celular inválido',
    name: 'validateCellphone'
  } as TestConfig
}

export const validateFileTypes = (fyleTypeToValidate: ValidFileExtensions): TestConfig => {
  return {
    test: (files?: [File]) => {
      const filesInArray = Array.from(files ?? [])

      const isEmpty = filesInArray.length === 0

      if (isEmpty) return true

      const fileInArray = Array.from(files ?? [])

      if (!fileInArray.length) return true

      const validatedFiles = fileInArray.filter((file) => {
        const validationFileType = validateFileType(`${file.name}`, fyleTypeToValidate)

        return validationFileType
      })

      return validatedFiles.length > 0
    },
    message: `Tipo de arquivo inválido, arquivos válidos são: (${validFileExtensions[fyleTypeToValidate].join(', ')})`,
    name: 'validateFileTypes'
  } as TestConfig
}

export const validateRequired = (): TestConfig => {
  return {
    test: (files?: [File]) => {
      const isEmpty = Array.from(files ?? []).length === 0

      if (isEmpty) return false

      return true
    },
    message: 'Campo obrigatório',
    name: 'requiredFile'
  } as TestConfig
}

export const validateFileSize = (
  maxSize: number,
  type: SizeType = 'MB'
): TestConfig => {
  return {
    test: (files?: [File]) => {
      console.log('TESTE')
      const filesInArray = Array.from(files ?? [])

      const isEmpty = filesInArray.length === 0

      console.log({
        isEmpty
      })
      if (isEmpty) return true

      const notValidatedFiles = (filesInArray.filter((file) => {
        return !validateFileSizeValue(maxSize, type as SizeType, file.size)
      }))

      return notValidatedFiles.length === 0
    },
    message: `Tamanho máximo permitido: ${maxSize} ${type}`,
    name: 'validateFileSize'
  } as TestConfig
}

interface IvalidateDateConfigs {
  minDate?: Date
  maxDate?: Date
}

export const validateDate = ({ minDate, maxDate }: IvalidateDateConfigs): TestConfig => {
  let message = 'Data inválida. '

  if (minDate) {
    message += ` Data mínima: ${minDate.toLocaleDateString()}`
  }

  if (maxDate) {
    message += ` Data máxima: ${maxDate.toLocaleDateString()}`
  }

  return {
    test: (date?: string) => {
      if (!date) return false

      if (!DateUtils.validateDate(date)) {
        return false
      }

      let isValid: boolean = true

      const dateParse = DateUtils.parseDate(date)

      if (isValid && maxDate) {
        isValid = DateUtils.isSmaller(dateParse, maxDate)
      }

      if (isValid && minDate) {
        isValid = DateUtils.isBigger(dateParse, minDate)
      }

      return isValid
    },
    message,
    name: 'validateDate'
  } as TestConfig
}

export const validateEmail = (
  { isEmailRequired }: { isEmailRequired: boolean } = { isEmailRequired: true }
): TestConfig => {
  return {
    test: (email?: string) => {
      if (!isEmailRequired && !email) return true

      if (!email) return false

      return validateEmailRegex(email)
    },
    message: 'E-mail inválido',
    name: 'validateEmail'
  } as TestConfig
}

// interface IvalidateValor {
//   min: number
//   max?: number
//   type?: 'float' | 'integer'
//   decimalPlaces?: number
// }

// const defaultConfig = {
//   min: 0,
//   type: 'float',
//   decimalPlaces: 2
// } as IvalidateValor

// export const validateValor = (
//   configValidate: IvalidateValor = defaultConfig
// ) => {
//   const object = {
//     ...defaultConfig,
//     ...configValidate
//   }

//   let message = object.max && object.min ? 'Valor permitdo. ' : 'Valor inválido. '

//   const configFloat = {
//     maximumFractionDigits: object.decimalPlaces,
//     minimumFractionDigits: object.decimalPlaces
//   }

//   if (object.min && !object.max) {
//     message += `Valor mínimo: ${object.type === 'integer'
//       ? object.min
//       : floatBrMask(object.min, configFloat)
//       }`
//   }

//   if (object.max && !object.min) {
//     message += `Valor máximo: ${object.type === 'integer'
//       ? object.max
//       : floatBrMask(object.max, configFloat)
//       }`
//   }

//   if (object.max && object.min) {
//     message += `Entre
//       ${object.type === 'integer'
//         ? object.min
//         : floatBrMask(object.min, configFloat)
//       }
//       e
//       ${object.type === 'integer'
//         ? object.max
//         : floatBrMask(object.max, configFloat)
//       }`
//   }
//   return {
//     test: (valor?: number | string) => {
//       const valorString = unmaskFloatBrNumber(`${valor}`)
//       let valorNumber = parseFloat(`${valorString}`) ?? 0

//       if (!valorNumber) valorNumber = 0

//       let isValid: boolean = true

//       if (isValid && object?.max) isValid = valorNumber <= object.max
//       if (isValid && object?.min) isValid = valorNumber >= object.min

//       return isValid
//     },
//     message,
//     name: 'validateValor'
//   } as TestConfig
// }
