/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { type IDefaultRequest, type IDefaultRequestObject } from 'app/shared/types/common'
import { type ICompanyCityHall } from 'app/shared/types/plataform/clients'
import { type IPorte, type INaturezaJuridica, type IRegisterCompany, type IQualifications } from 'app/shared/types/plataform/company'
import { type ICompanysHomologAll } from 'app/shared/types/plataform/companysHomolog'
import { type KeyedMutator } from 'swr'

import { apiServerPlataforma } from '../api'

const endpointCompanys = '/empresa'
const endpointNaturezaJuridica = '/natureza-juridica'
const endpointPorte = '/porte'
const endpointQualifications = '/enum/Representante/RepresentanteQualificacao'

const GetCompany = (cnpj: string) => {
  return useSwr<IDefaultRequestObject<ICompanyCityHall>>(
    `${endpointCompanys}/cnpj/${cnpj}`,
    apiServerPlataforma
  )
}

const GetQualifications = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IQualifications>>(
    `${endpointQualifications}/list-all?${queryParams}`,
    apiServerPlataforma
  )
}

const GetCompanysHomolog = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<ICompanysHomologAll>>(
    `${endpointCompanys}/homologacao/list-all?${queryParams}`,
    apiServerPlataforma
  )
}

const changeStatusCompanyHomolog = async (
  EMPHOMID: number,
  status: number,
  mutate: KeyedMutator<IDefaultRequest<ICompanysHomologAll>>
) => {
  const response = await apiServerPlataforma.put(`${endpointCompanys}/homologacao/update/${EMPHOMID}`, { status })
  if (response.status === 201) void mutate()
  return response
}

const GetNaturezaJuridica = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<INaturezaJuridica>>(
    `${endpointNaturezaJuridica}/list-all?${queryParams}`,
    apiServerPlataforma
  )
}

const GetPorte = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IPorte>>(
    `${endpointPorte}/list-all?${queryParams}`,
    apiServerPlataforma
  )
}

const createCompany = async (payload: IRegisterCompany) => {
  const response = await apiServerPlataforma.post(`${endpointCompanys}/create`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

const updateCompany = async (
  payload: IRegisterCompany,
  PESID: number
) => {
  const response = await apiServerPlataforma.put(`${endpointCompanys}/update/${PESID}`, payload)
  return response
}

export default {
  GetCompany,
  GetQualifications,
  GetCompanysHomolog,
  changeStatusCompanyHomolog,
  GetNaturezaJuridica,
  GetPorte,
  createCompany,
  updateCompany
}
