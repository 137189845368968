import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import { systemMessages } from '_metronic/i18n/systemMessages'
import Stepper from 'app/shared/components/Stepper/Stepper'
import { getInternalizeText } from 'app/shared/helpers/utils'
import { numberMask } from 'app/shared/helpers/valueMasks'
import { ServiceAuthRegister } from 'app/shared/services'
import { type IAutoRegister } from 'app/shared/types/autoRegister'

import StepperCorrespondence from './SteppersRegistration/StepperCorrespondence'
import StepperPeronsalData from './SteppersRegistration/StepperPersonalData'
import StepperValidation from './SteppersRegistration/StepperValidation'
import { initialValuesAutoRegister, schemasValidation } from './SteppersRegistration/Validations'

import './SteppersRegistration/styles.scss'

interface IGoogleAutoRegister {
  cpf?: string
  name?: string
  email?: string
  picture?: string
}

export function Registration (): JSX.Element {
  const navigator = useNavigate()

  const location = useLocation()

  const data = location.state as IGoogleAutoRegister

  const handleDataSubmit = (dataSubmit: IAutoRegister): IAutoRegister => {
    const data: IAutoRegister = {
      ...dataSubmit,
      cpf: String(numberMask(dataSubmit.cpf)),
      codigo: dataSubmit.codigo,
      // dataNasc: formatDate(dataSubmit.dataNasc, 'YYYY-MM-DD'),
      email: dataSubmit.email,
      telefone: numberMask(`${dataSubmit.telefone}`) as number,
      celular: numberMask(`${dataSubmit.celular}`) as number,
      cep: String(numberMask(dataSubmit.cep)),
      codigoIbge: dataSubmit.codigoIbge
    }

    return data
  }

  const dadosParaSalvar = async (dataForm: IAutoRegister): Promise<void> => {
    try {
      const data = handleDataSubmit(dataForm)
      const response = await ServiceAuthRegister.createRegister(data)
      if (response.status === 201) {
        toast.success('Cadastro realizado com sucesso!')
        navigator('/auth')
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message ?? 'Erro ao realizar cadastro!')
    }
  }

  initialValuesAutoRegister.email = data?.email ?? ''
  initialValuesAutoRegister.nome = data?.name ?? ''

  return (
    <div className='p-10 container'>
      <div className='mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>
          {getInternalizeText(systemMessages.keysMessages.RegisterTitle)}
        </h1>
      </div>

      <Stepper
        steps={['Dados Pessoais', 'Localização', 'Validação']}
        schemaValdation={schemasValidation}
        dadosParaSalvar={dadosParaSalvar}
        initialValues={initialValuesAutoRegister}
        exit
        className='w-100'
      >
        <div className='current flex-column' data-kt-stepper-element='content'>
          <StepperPeronsalData />
        </div>

        <div className='flex-column' data-kt-stepper-element='content'>
          <StepperCorrespondence />
        </div>

        {/* <div className='flex-column' data-kt-stepper-element='content'>
          <StepperPersonalJuridica />
        </div> */}

        <div className='flex-column' data-kt-stepper-element='content'>
          <StepperValidation />
        </div>
      </Stepper>
    </div>
  )
}
