import { type SetStateAction, useEffect, useState } from 'react'

import { useDebounce } from '_metronic/helpers'
import { keysMessages } from '_metronic/i18n/keysMessages'
import Button from 'app/shared/components/Button/Button'
import { getInternalizeText } from 'app/shared/helpers/utils'
import { useUserStore } from 'app/store/Plataform/UserStore'
import clsx from 'clsx'

import { type CityModel } from './core/_models'
import { useAuth } from './core/Auth'

interface ISelectCityProps {
  onClickButton: () => void
}

export function SelectCity ({ onClickButton }: ISelectCityProps): JSX.Element {
  const [citys, currentCity, setCurrentCity] = useUserStore(state => [state.citys, state.currentCity, state.setCurrentCity])
  const { saveAuth, auth } = useAuth()
  const [searchTerm, setSearchTerm] = useState('')
  const [resultFromClients, setResultFromClients] = useState<CityModel[]>(citys)
  const textSearch = useDebounce(searchTerm, 300)
  const [citySelected, setCitySelected] = useState<CityModel | null>(currentCity ?? null)

  const selectCity = (city: CityModel): void => {
    if (citySelected && (citySelected.CLITENANT !== currentCity?.CLITENANT)) {
      setCurrentCity(city)
      saveAuth({ token: auth?.token as string, city_selected: `${city.CLITENANT}` })
      onClickButton()
    }
  }

  const handleChange = (e: { target: { value: SetStateAction<string> } }): void => {
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    const searchClient = (): void => {
      if (textSearch) {
        const result = citys.filter((city) => {
          return city.CLINOME.toLowerCase().includes(textSearch.toLowerCase())
        })

        setResultFromClients(result)
      } else {
        setResultFromClients(citys)
      }
    }

    searchClient()
  }, [textSearch])

  return (
    <>

      <div className={'card card-flush mt-10'}>

        <div className='card-body pt-5'>

          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>{getInternalizeText(keysMessages.Auth_SearchCity)}</label>
            <input
              type='text'
              placeholder="Digite para pesquisar..."
              className='form-control bg-transparent'
              value={searchTerm}
              onChange={handleChange}
            />
          </div>

          <hr className='my-8' />

          <div className='h-200px overflow-auto'>
            {resultFromClients?.map((city) => (
              <div
                key={city.CLITENANT}
                onDoubleClick={() => { selectCity(city) }}
                onClick={() => { setCitySelected(city) }}
                className={
                  clsx('cursor-pointer card h-40px mb-5 d-flex align-items-center justify-content-center', {
                    'table-active': citySelected?.CLITENANT === city.CLITENANT
                  })
                }
                style={{ filter: citySelected?.CLITENANT === city.CLITENANT ? 'brightness(.9)' : '' } }
              >
                {city.CLINOME}
              </div>
            ))}
          </div>

          {/* Versão antiga */}
          {/* <Table
            striped={false}
            bordered
            hover
            className="table table-row-bordered table-row-gray-300 gy-7 gs-5 text-center align-middle"
          >
            <thead>
              <tr>
                <th>Município</th>
              </tr>
            </thead>
            <tbody>
              {
                resultFromClients?.map((city) => (
                  <tr
                    key={city.TENANT}
                    onDoubleClick={() => { selectCity(city) }}
                    onClick={() => { setCitySelected(city) }}
                    className={
                      clsx('cursor-pointer', {
                        'table-active': citySelected?.TENANT === city.TENANT
                      })
                    }
                  >
                    <td>{city.NOME}</td>
                  </tr>
                ))
              }
            </tbody>
          </Table> */}

        </div>

        <div className='card-footer d-flex justify-content-end'>
          <Button
            type='submit'
            rounded={true}
            variant='primary'
            disabled={!citySelected}
            className='bg-blue-primary'
            onClick={() => {
              if (citySelected) {
                selectCity(citySelected)
              }
            }}
          >Selecionar</Button>
        </div>
      </div>

    </>
  )
}
