import { useEffect, useState } from 'react'
import { Card, Spinner } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'
import { VscCopy } from 'react-icons/vsc'
import Carousel from 'react-multi-carousel'
import { Link } from 'react-router-dom'

import OverlayTooltip from 'app/shared/components/OverlayTooltip/OverlayTooltip'
import ReactModal from 'app/shared/components/ReactModal/ReactModal'
import Toastfy from 'app/shared/helpers/Toastfy/Toastfy'
import { capitalizeFirstLetter } from 'app/shared/helpers/utils'
import { dateUsBrMask } from 'app/shared/helpers/valueMasks'
import { ServiceProcess } from 'app/shared/services'
import { type IProcessReturn } from 'app/shared/services/processos/process'

import JoinedDocumentsMunicipal from './joinedDocuments/JoinedDocumentsMunicipal'

import 'react-multi-carousel/lib/styles.css'
import './styles.scss'

const MyProtocolsInStandBy = (): JSX.Element => {
  const [processosInStandBy, setProcessosInStandBy] = useState<IProcessReturn[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [visualizingProcess, setVisualizingProcess] = useState<number[]>([])
  const [processSelected, setProcessSelected] = useState<IProcessReturn | null>(null)
  const [openModalJoinedDocuments, setOpenModalJoinedDocuments] = useState<boolean>(false)

  useEffect(() => {
    void ServiceProcess
      .getPersonProcess({
        perPage: 20,
        page: 1,
        status: [1, 3, 5]
      })
      .then((response) => {
        setProcessosInStandBy(response.data.data)
      })
      .catch((error) => {
        if (error?.response?.status !== 404) {
          Toastfy.error(error.response?.data?.message ?? 'Erro ao carregar os processos em atendimento.')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 2000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  const ProcessCardLoader = (): JSX.Element => (
    <Card className='me-4'>
      <Card.Body>
        <ContentLoader
          height={10}
          width={100}
          speed={2}
          backgroundColor='#FCFCFC'
          foregroundColor='#cac8c8'
        >
          <rect x='0' y='0' rx='1' ry='5' width='100' height='10' />
        </ContentLoader>
        <ContentLoader
          height={10}
          width={100}
          speed={2}
          backgroundColor='#FCFCFC'
          foregroundColor='#cac8c8'
        >
          <rect x='0' y='0' rx='1' ry='5' width='100' height='10' />
        </ContentLoader>
        <ContentLoader
          height={10}
          width={100}
          speed={2}
          backgroundColor='#FCFCFC'
          foregroundColor='#cac8c8'
        >
          <rect x='0' y='0' rx='1' ry='5' width='100' height='10' />
        </ContentLoader>
      </Card.Body>
    </Card>
  )

  const LoadingCardProcess = (): JSX.Element => (
    <div className="container text-center">
      <div className="row">
        <div className="col">
          <ProcessCardLoader />
        </div>
        <div className="col">
          <ProcessCardLoader />
        </div>
        <div className="col">
          <ProcessCardLoader />
        </div>
      </div>
    </div>
  )

  const generatePdf = async (processo: IProcessReturn): Promise<void> => {
    setVisualizingProcess([...visualizingProcess, processo.REQID])
    const response = await ServiceProcess.openPdfFromProcess(processo.REQID)
    if (response?.status === 200) {
      Toastfy.success('PDF requerido com sucesso! Logo estará disponível para vizualização')
    }
    setVisualizingProcess(visualizingProcess.filter((id) => id !== processo.REQID))
  }

  const CardProcess = ({
    processo
  }: {
    processo: IProcessReturn
  }): JSX.Element => {
    return (
      <Card className='me-4 container-card--proccess'>
        <Card.Body className='p-0'>
          <Card.Title className='fs-6 fw-bold text-white'>
            <div className='d-flex justify-content-between align-items-center'>
              {processo.REQPROTOCOLO}
              <OverlayTooltip message='Juntada de Documentos'>
                <div>
                  <VscCopy className='icon' onClick={() => { setProcessSelected(processo); setOpenModalJoinedDocuments(true) }} />
                </div>
              </OverlayTooltip>
            </div>
          </Card.Title>
          <Card.Text>
            <span className='fs-8 fw-bold text-white'>
              {capitalizeFirstLetter(processo.FLUXO.FLUDESCRICAO)} - Data de abertura: {dateUsBrMask(processo.REQINCLUIDOEM)}
            </span>
            <br />
            <span className='fs-7 text-white mt-3 d-block'>
              {processo.FLUXO.CATDESCRICAO}
            </span>
          </Card.Text>
          <div className="d-flex justify-content-between">
            <Link to={`/processos/visualizar-processo/${processo.REQPROTOCOLO}`}>
              {/* <a className="link-primary fw-bolder">Acompanhar</a> */}
              <span className='link-primary fw-bolder'>Acompanhar</span>
            </Link>

            <div>
              {visualizingProcess.includes(processo.REQID)
                ? (
                  <>
                    <Spinner animation="border" variant="primary" />
                  </>
                  )
                : (
                  <>
                    <a className="link-primary fw-bolder ms-2 cursor-pointer btn-show--pdf" onClick={() => {
                      void generatePdf(processo)
                    }}>Visualizar Processo</a>
                  </>
                  )}
            </div>
          </div>
        </Card.Body >
      </Card >
    )
  }

  return (
    <>
      <div className="row mt-4">
        <div className="col-md-12">
          <h1 className='fs-3 fw-bold mb-0 text-gray-800'>
            Meus Protocolos em Atendimento
          </h1>
          <hr className='mt-1' />
        </div>
      </div>

      {
        isLoading
          ? <LoadingCardProcess />
          : processosInStandBy.length === 0
            ? (
              <>
                <div className="col-md-12">
                  <div className="alert alert-warning" role="alert">
                    Você não possui protocolo em atendimento.
                  </div>
                </div>
              </>
              )
            : (
              <div className="row">
                <Carousel
                  responsive={responsive}
                  draggable={false}
                  showDots={true}
                  infinite={true}
                  dotListClass="custom-dot-list-style"
                  itemClass="mb-4"
                >
                  {
                    processosInStandBy.map((processo) => (
                      <div key={processo.REQID} className=' mb-4'>
                        <CardProcess processo={processo} />
                      </div>
                    ))
                  }
                </Carousel>
              </div>
              )
      }

      <ReactModal
        headerName='Juntada de Documentos'
        show={openModalJoinedDocuments}
        backdrop="static"
        onClose={() => { setOpenModalJoinedDocuments(false) }}
      >
        <JoinedDocumentsMunicipal process={processSelected!} onClose={() => { setOpenModalJoinedDocuments(false) }} />
      </ReactModal>
    </>
  )
}

export default MyProtocolsInStandBy
