import { useEffect, useState } from 'react'
import { AiFillClockCircle } from 'react-icons/ai'

import { type AuthModel, useAuth, setAuth, getAuth } from 'app/modules/auth'
import { refreshToken } from 'app/modules/auth/core/_requests'
import Toastfy from 'app/shared/helpers/Toastfy/Toastfy'
import { differenceInSeconds, format } from 'date-fns'

import ButtonMetronic from '../Button/ButtonMetronic'
import ReactModal from '../ReactModal/ReactModal'

import './styles.scss'

const SessionExpires = (): JSX.Element => {
  const [openModal, setOpenModal] = useState(false)
  const [requestEndpoint, setRequestEndpoint] = useState(false)
  const [firstRenderRefresh, setFirstRenderRefresh] = useState<boolean>(() => {
    const storedTime = JSON.parse(localStorage.getItem('tempoRestante') as string)
    if (storedTime) {
      return storedTime.firstRender
    }
    return false
  })
  const [tempoRestante, setTempoRestante] = useState<string>('')

  const { logout } = useAuth()
  const token: AuthModel | undefined = getAuth()

  const handleRefreshToken = async (): Promise<void> => {
    setRequestEndpoint(true)
    try {
      const response = await refreshToken()
      if (response.status === 200) {
        const authData: AuthModel = {
          city_selected: token?.city_selected,
          token: response.data.data.access_token
        }
        setAuth(authData)
        localStorage.setItem('tempoRestante', JSON.stringify(
          { tempoRestante: 15 * 60, data: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), firstRender: false }
        ))
        setFirstRenderRefresh(false)
        Toastfy.success('Sessão prolongada com sucesso.')
      }
    } catch (error: any) {
      Toastfy.error(error?.response?.data?.message ?? 'Erro ao prolongar tempo de sessão! Tente novamente.')
    } finally {
      setOpenModal(false)
      setRequestEndpoint(false)
    }
  }

  const handleCloseModal = (): void => {
    setOpenModal(false)
    const storedTime = JSON.parse(localStorage.getItem('tempoRestante') as string)
    localStorage.setItem('tempoRestante', JSON.stringify(
      { tempoRestante: storedTime.tempoRestante, data: storedTime.data, firstRender: true }
    ))
    setFirstRenderRefresh(true)
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      const storedTime = JSON.parse(localStorage.getItem('tempoRestante') as string)

      if (storedTime) {
        const differenceSeconds = differenceInSeconds(new Date(), new Date(storedTime.data))
        const timeRemaining = storedTime.tempoRestante - differenceSeconds
        const minutes = Math.floor(timeRemaining / 60)
        const seconds = timeRemaining % 60

        if (timeRemaining <= 0) {
          clearInterval(intervalId)
          logout()
        }

        if (timeRemaining <= 300) {
          setOpenModal(true)
        }

        setTempoRestante(`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`)
      } else {
        logout()
      }
    }, 1000)

    return () => {
      clearInterval(intervalId)
      setOpenModal(false)
    }
  }, [])

  const Sppiner = (): JSX.Element => (
    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
  )

  return (
    <>
      <div className='bg-gray-primary container--session-expired py-5 app-sidebar-logo justify-content-center align-items-center gap-2 overflow-hidden'>
        <AiFillClockCircle className='icon' />
        <label>
          Sessão expira em: {!tempoRestante ? <Sppiner /> : tempoRestante}
        </label>
      </div>

      <ReactModal
        headerName='Prolongar tempo de sessão'
        show={openModal && !firstRenderRefresh}
        size='sm'
        closeButton={false}
        backdrop='static'
        onClose={() => { setOpenModal(false) }}
      >
        <div className='d-flex justify-content-center flex-column'>
          <img src={'/media/icons/platform/session-expired-icon.svg'} className='session-expired-icon' />
          <div className='text-center text-blue mt-5 fs-4'>
            <p className='font-weight-bold mb-1'>Tempo restante</p>
            <p className='font-weight-bold'>{tempoRestante}</p>
          </div>

          <hr />

          <div className='d-flex flex-column gap-7'>
            <span className='text-center'>Adicionar 15 minutos?</span>
            <ButtonMetronic type='button' variant='primary' className='bg-blue-primary w-100' rounded
              onClick={() => { void handleRefreshToken() }}
            >
              SIM {requestEndpoint && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
            </ButtonMetronic>
            <ButtonMetronic type='button' variant='danger' className='w-100' rounded
              onClick={handleCloseModal}
            >NÃO</ButtonMetronic>
          </div>
        </div>
      </ReactModal>
    </>
  )
}

export default SessionExpires
