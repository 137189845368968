import { toast, type ToastPromiseParams } from 'react-toastify'
import { type ToastOptions } from 'react-toastify/dist/types'

import { getSystemTheme } from '../utils'

const isDuplicate = {
  success: '',
  error: '',
  info: '',
  warning: ''
}

function resetarDuplicado (
  index: keyof typeof isDuplicate,
  message: string,
  timer: any
): boolean {
  if (isDuplicate[index] === message) {
    return false
  }

  isDuplicate[index] = message

  setTimeout(() => {
    isDuplicate[index] = ''
  }, timer + 800)

  return true
}

const Toastfy = {
  success (message: string, options: object = {}) {
    const oneToastfy = resetarDuplicado('success', message, this.mergeOptions(options).autoClose)

    if (!oneToastfy) {
      return
    }
    return toast.success(message, this.mergeOptions(options))
  },

  error (message: string, options: object = {}) {
    const oneToastfy = resetarDuplicado('error', message, this.mergeOptions(options).autoClose)

    if (!oneToastfy) {
      return
    }

    return toast.error(message, {
      ...this.mergeOptions(options),
      autoClose: 5000,
      position: 'top-center'
    } satisfies ToastOptions)
  },

  info (message: string, options: object = {}) {
    const oneToastfy = resetarDuplicado('info', message, this.mergeOptions(options).autoClose)

    if (!oneToastfy) {
      return
    }
    return toast.info(message, this.mergeOptions(options))
  },

  warning (message: string, options: object = {}) {
    const oneToastfy = resetarDuplicado('warning', message, this.mergeOptions(options).autoClose)

    if (!oneToastfy) {
      return
    }

    return toast.warning(message, this.mergeOptions(options))
  },

  async promisse (
    callback: any,
    optionsToast: object = {},
    optionsPromisse: object = {}
  ): Promise<unknown> {
    const optionsDefaultPromisse = {
      pending: 'Carregando...',
      success: 'Sucesso!',
      error: 'Erro!'
    }

    const result = await toast.promise(
      callback,
      {
        ...optionsDefaultPromisse,
        ...optionsPromisse
      } satisfies ToastPromiseParams,
      this.mergeOptions(optionsToast)
    )

    return result
  },

  mergeOptions (options: object): ToastOptions {
    const defaultOptions: ToastOptions = {
      position: 'bottom-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: getSystemTheme(),
      style: { zIndex: 9999 }
    }

    return {
      ...defaultOptions,
      ...options
    } satisfies ToastOptions
  }
}

export default Toastfy
