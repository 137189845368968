import { type ChangeEvent } from 'react'

interface ICheckBox {
  type: 'check' | 'radio' | 'switch'
  label: string
  className?: string
  id?: string
  disabled?: boolean
  sizeSmall?: boolean
  checked?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const CheckBox = ({ type, label, className, id, disabled, sizeSmall = false, checked, onChange }: ICheckBox): JSX.Element => {
  return (
    <div className={`form-check form-${type} form-check-custom form-check-solid text-dark ${className}`}>
      <input className={`form-check-input me-4 ${sizeSmall ? 'h-20px w-30px' : ''}`} checked={checked} type="checkbox" id={id} disabled={disabled} onChange={onChange} />
      <label className="form-check-label text-dark" htmlFor={id}>{label}</label>
    </div>
  )
}

export default CheckBox
