/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { type IAddress, type IChangeLoggedUser } from 'app/modules/plataforma/core/loggedUsers/loggedUserInterfaces'
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { type IDefaultRequestObject, type IDefaultRequest } from 'app/shared/types/common'
import { type IListAllPhones, type ILoggedUserAddress, type ILoggedUserPassword } from 'app/shared/types/plataform/loggedUser'
// import { type IPhysicalPerson } from 'app/shared/types/plataform/person'
import { type IPhysicalPerson } from 'app/shared/types/plataform/person'
import { type KeyedMutator } from 'swr'

import { apiServerPlataforma, apiSso } from '../api'

const endpointVinculados = '/user/change-password'
const endpointGetAddresses = '/pessoa/endereco'
const endpointContact = '/pessoa/contatos'

const GetAddresses = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<ILoggedUserAddress>>(
    `${endpointGetAddresses}?${queryParams}`,
    apiServerPlataforma
  )
}

const createAddress = async (
  payload: IAddress,
  mutate: KeyedMutator<IDefaultRequest<ILoggedUserAddress>>
) => {
  const response = await apiServerPlataforma.post(endpointGetAddresses, payload)
  if (response.status === 201) await mutate()
  return response
}

const updateAddress = async (
  id: number | string,
  payload: IAddress,
  mutate: KeyedMutator<IDefaultRequest<ILoggedUserAddress>>
) => {
  const response = await apiServerPlataforma.put(`${endpointGetAddresses}/${id}`, payload)
  if (response.status === 200) await mutate()
  return response
}

const updateUserPhoto = async (
  payload: File | string
) => {
  const response = await apiServerPlataforma.post('/user/upload-image', { imagem: payload }, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

const changePassword = async (payload: ILoggedUserPassword) => {
  const response = await apiServerPlataforma.patch(`${endpointVinculados}`, payload)
  return response
}

const updateLoggedUser = async (
  payload: IChangeLoggedUser,
  mutate: KeyedMutator<IDefaultRequestObject<IPhysicalPerson>>
) => {
  const response = await apiSso.post('/user', payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  if (response.status === 200) await mutate()
  return response
}

const updateEmail = async (
  pesEmailId: number,
  payload: { email: string, senha: string },
  mutate: KeyedMutator<IDefaultRequestObject<IListAllPhones>>
) => {
  const response = await apiServerPlataforma.put(`${endpointContact}/edit-email/${pesEmailId}`, payload)
  if (response.status === 200) await mutate()
  return response
}

const updateContact = async (
  payload: Array<{ pesFoneId: number, numero: string }>,
  mutate: KeyedMutator<IDefaultRequestObject<IListAllPhones>>
) => {
  const response = await apiServerPlataforma.put(`${endpointContact}/edit-contato`, payload)
  if (response.status === 200) await mutate()
  return response
}

const createContact = async (
  payload: { telefone: string, principal: boolean },
  mutate: KeyedMutator<IDefaultRequestObject<IListAllPhones>>
) => {
  const response = await apiServerPlataforma.post(`${endpointContact}/add-telefone`, payload)
  if (response.status === 201) await mutate()
  return response
}

const GetDataPhones = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequestObject<IListAllPhones>>(
    `${endpointContact}/list-all?${queryParams}`,
    apiServerPlataforma
  )
}

export default {
  GetAddresses,
  createAddress,
  updateUserPhoto,
  changePassword,
  updateLoggedUser,
  updateAddress,
  updateEmail,
  updateContact,
  GetDataPhones,
  createContact
}
