/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { type IDefaultRequestObject, type IDefaultRequest } from 'app/shared/types/common'
import { type IListSituations, type ICreateSituation, type IRequerimentoByID, type ICreateCommentRequeriment, type IResumoRequerimento } from 'app/shared/types/processos/accessPrefeitura/demandaById'
import { type KeyedMutator } from 'swr'

import { apiProcessos } from '../../api'

const endpointDemandas = '/requerimento/tramitacao/id'
const endpointResumo = '/requerimento/tramitacao'
const endpointSituations = '/requerimento/parecer'
const endpointComment = '/requerimento/tramitacao/comentarios'

const GetDemandaById = (REQID: number) => {
  return useSwr<IDefaultRequest<IRequerimentoByID>>(
    `${endpointDemandas}/${REQID}`,
    apiProcessos
  )
}

const GetResumoById = (REQID: number) => {
  return useSwr<IDefaultRequestObject<IResumoRequerimento>>(
    `${endpointResumo}/resumo/${REQID}`,
    apiProcessos
  )
}

const GetSituations = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IListSituations>>(
    `${endpointSituations}/list-all?${queryParams}`,
    apiProcessos
  )
}

const createSituation = async (
  payload: ICreateSituation,
  mutate: KeyedMutator<IDefaultRequest<IRequerimentoByID>>
) => {
  const response = await apiProcessos.post('/requerimento/parecer/create', payload)
  if (response.status === 201) void mutate()
  return response
}

const deleteSituation = async (
  reqParId: number,
  mutate: KeyedMutator<IDefaultRequest<IRequerimentoByID>>
) => {
  const response = await apiProcessos.delete(`${endpointSituations}/delete/${reqParId}`)
  if (response.status === 204) void mutate()
  return response
}

const editSituation = async (
  reqParId: number,
  payload: { reqId: number, descricao: string, publico: boolean },
  mutate: KeyedMutator<IDefaultRequest<IRequerimentoByID>>
) => {
  const response = await apiProcessos.put(`${endpointSituations}/edit/${reqParId}`, payload)
  if (response.status === 200) void mutate()
  return response
}

const createCommentRequeriment = async (
  payload: ICreateCommentRequeriment,
  mutate: KeyedMutator<IDefaultRequest<IRequerimentoByID>>
) => {
  const response = await apiProcessos.post(`${endpointComment}/create`, payload)
  if (response.status === 201) void mutate()
  return response
}

export default {
  GetDemandaById,
  GetResumoById,
  GetSituations,
  createSituation,
  deleteSituation,
  editSituation,
  createCommentRequeriment
}
