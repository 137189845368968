/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { boolToInt, serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { apiProcessos } from 'app/shared/services/api'
import { type IDefaultRequest } from 'app/shared/types/common'
import { type AxiosResponse } from 'axios'

export interface IRoleType {
  CARID: number
  CARNOME: string
  CARSTATUS: boolean
  DEPARTAMENTOS: [{
    DEPCARID: number
    DEPID: number
    CARID: number
    DEPARTAMENTO: {
      DEPID: number
      DEPNOME: string
      DEPSTATUS: boolean
    }
  }]
}

export interface IRoleRequest {
  nome: string
  idDepartamento: number
  status?: number
}

const endpointRoles = '/cargo'

const GetRole = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IRoleType>>(
    `${endpointRoles}/list-all?${queryParams}`,
    apiProcessos
  )
}

export const getRoles = async (payload: {
  isPaginated?: boolean
  status?: number
}): Promise<AxiosResponse<IDefaultRequest<IRoleType>>> => {
  return await apiProcessos.get(`${endpointRoles}/list-all`, {
    params: {
      ...payload,
      isPaginated: boolToInt(payload.isPaginated ?? false)
    }
  })
}

export const getRoleById = async (id: number): Promise<AxiosResponse<IDefaultRequest<IRoleType>>> => {
  return await apiProcessos.get(`${endpointRoles}/id/${id}`)
}

export const createRole = async (dataEdit: IRoleRequest): Promise<AxiosResponse<IDefaultRequest<IRoleType>>> => {
  return await apiProcessos.post(`${endpointRoles}/create`, dataEdit)
}

const updateRole = async (
  id: number,
  payload: { nome?: string, status?: number }
) => {
  return await apiProcessos.put(`${endpointRoles}/update/${id}`, payload)
}

export default {
  GetRole,
  updateRole,
  getRoles,
  getRoleById,
  createRole
}
