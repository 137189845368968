import { type AuthModel, getAuth, removeAuth } from 'app/modules/auth'
import { getUserByToken } from 'app/modules/auth/core/_requests'
import { ENV_VARS } from 'app/shared/helpers/envs'
import axios, { type InternalAxiosRequestConfig } from 'axios'

export const apiViaCep = axios.create({
  baseURL: ENV_VARS.API_VIACEP
})

export const apiReceita = axios.create({
  baseURL: ENV_VARS.API_RECEITA_FEDERAL
})
// Api de módulos

export const apiSso = axios.create({
  baseURL: `${ENV_VARS.API_URL}/sso`
})

export const apiServerPlataforma = axios.create({
  baseURL: `${ENV_VARS.API_URL}/plataforma`
})

export const apiProcessos = axios.create({
  baseURL: `${ENV_VARS.API_URL}/processos`
})

const handleUnauthorized = (): void => {
  const token: AuthModel | undefined = getAuth()

  getUserByToken(`${token?.token}`).catch(() => {
    removeAuth()
    window.location.href = '/login'
  })
}

const addInterceptors = (axiosInstance: any, configs: {
  useCity: boolean
} = {
  useCity: false
}): void => {
  axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      config.headers['Content-Type'] = config.headers['Content-Type'] ?? 'application/json'

      const token: AuthModel | undefined = getAuth()

      if (token?.token) {
        config.headers.Authorization = `Bearer ${token?.token}`
      }

      if (token?.city_selected && configs.useCity) {
        config.baseURL += `/${token!.city_selected}`
      }

      // if (!config.headers['X-Retry'] && configs.useCity) {
      //   if (token?.city_selected) {
      //     config.baseURL += `/${token!.city_selected}`
      //   }
      // }

      return config
    },
    async (error: any) => {
      if (error.response.status === 401) {
        handleUnauthorized()
      } else {
        return await Promise.reject(error)
      }
    }
  )

  axiosInstance.interceptors.response.use(
    (response: any) => {
      return response
    },
    async (error: any) => {
      const originalRequest = error.config

      if (error.response.status === 403 && !originalRequest._retry) {
        // originalRequest.headers['X-Retry'] = true
        // originalRequest.headers['X-Count'] = originalRequest.headers['X-Count'] ? originalRequest.headers['X-Count'] + 1 : 1

        try {
          // Repetir a requisição com a mesma configuração
          if (originalRequest?.headers['X-Count'] > 3) {
            return await Promise.reject(error)
          }

          // return axiosInstance(originalRequest)
        } catch (err) {
          // Lidar com erros se a repetição da requisição também falhar
          return await Promise.reject(err)
        }
      }

      return await Promise.reject(error)
    }
  )
}

addInterceptors(apiSso)
addInterceptors(apiServerPlataforma)
addInterceptors(apiProcessos, { useCity: true })
