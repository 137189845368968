/* eslint-disable no-prototype-builtins */
import { systemMessages } from '_metronic/i18n/systemMessages'
import { LocalStorageKeys } from 'app/shared/enums/localStorageKeys'
import Toastfy from 'app/shared/helpers/Toastfy/Toastfy'
import * as DateFns from 'date-fns'
import { v4 as uuidv4 } from 'uuid'

/**
 * Verifica se existe uma propriedade no objeto existe se existir retorna o valor dela
 * se não, retorna o valor padrão
 * @param {object | Array<any>} object - O objeto que quer verificar a propriedade
 * @param {string | number} property - A propriedade que quer verificar
 * @param {any} defaultValue - O valor padrão caso a propriedade não exista
 * @returns {any} - O valor da propriedade ou o valor padrão
 */
export const inObject = (
  object: object | any[],
  property: string | number,
  defaultValue: any
): any => {
  return Object.prototype.hasOwnProperty.call(object, property)
    ? (object as any)[property]
    : defaultValue
}

/**
 * Cria um timer em milisegundos e espera o timer acabar para continuar o código.
 * @param {number} ms - tempo em milisegundos
 */
export async function wait (ms: number): Promise<void> {
  await new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

type SystemTheme = 'dark' | 'light'

/**
 * Pega o tema do sistema no localStorage
 * @returns {string} - O tema do sistema
 * @example
 * getSystemTheme() // 'light'
 * getSystemTheme() // 'dark'
*/
export const getSystemTheme = (): SystemTheme => {
  const theme = localStorage.getItem(LocalStorageKeys.SYSTEM_THEME)
  if (theme) {
    return theme as SystemTheme
  }
  return 'light'
}

/**
 * Pega o idioma do sistema no localStorage
 * @returns {string} - O idioma do sistema
 * @example
 * getSystemLanguage() // 'pt-BR'
 * getSystemLanguage() // 'en-US'
*/
export const getSystemLanguage = (): InternalizeText => {
  const language = localStorage.getItem(LocalStorageKeys.SYSTEM_LANGUAGE)

  if (language) {
    return language as InternalizeText
  }

  return 'pt'
}

type InternalizeText = 'pt' | 'en'

/**
 * Pega o idioma do sistema no localStorage
 * @returns {string} - O idioma do sistema
 * @example
 * getSystemLanguage() // 'pt-BR'
 * getSystemLanguage() // 'en-US'
*/
export const getInternalizeText = (key: string, extraString: unknown = {}): string => {
  const language = getSystemLanguage()

  if (!systemMessages.hasOwnProperty(language)) {
    return 'Não encontrado'
  }

  let messagesFromLanguage = inObject(systemMessages[language], key, null)

  if (extraString) {
    for (const key in extraString) {
      messagesFromLanguage = messagesFromLanguage.replace(`{${key}}`, extraString[key])
    }
  }

  return messagesFromLanguage
}

/**
 * Verifica se uma string é um número
 * @param {string} string - A string que quer verificar
 * @returns {boolean} - Se a string é um número ou não
 */
export const isNumber = (string: string): boolean =>
  !isNaN(parseFloat(string)) && !isNaN(+string)

/**
 * Pega uma string e limita ela em um determinado tamanho
 * @param {string} string - A string que quer limitar
 * @param {number} length - O tamanho que quer limitar
 * @returns {string} - A string limitada
 */
export const trimStringLimited = (string: string, length: number): string =>
  string.length > length ? string.substring(0, length) + '...' : string

/**
 * Converte um valor booleano para inteiro
 *
 * @param {boolean} value - O valor booleano
 * @returns {number} - O valor inteiro
 * @example
 * boolToInt(true) // 1
 * boolToInt(false) // 0
 */
export const boolToInt = (value: boolean = false): number => (value ? 1 : 0)

/**
 * Converte um valor booleano para string
 *
 * @param {boolean} value - O valor booleano
 * @returns {string} - O valor string
 * @example
 * boolToString(true) // 'Sim'
 * boolToString(false) // 'Não'
 */
export const boolToString = (value: boolean = false): string =>
  value ? 'Sim' : 'Não'

/**
 * Pega um objeto e serializa ele na forma de query string
 * @param {object} params - O objeto que quer serializar
 * @returns {string} - A query string
 */
export const serializeQueryParams = (params: any): string => {
  const esc = encodeURIComponent
  return Object.keys(params)
    .map((keyParam) => {
      if (Array.isArray(params[keyParam])) {
        return params[keyParam]
          .map((value: any, index: number) => {
            return esc(keyParam) + '[]=' + esc(value)
          })
          .join('&')
      } else {
        return esc(keyParam) + '=' + esc(params[keyParam])
      }
    })
    .join('&')
}

/**
 * Gera um ID único usando o UUID Versão 4(aleatório).
 * @returns { string } Unique ID string.
*/
export const generateUniqueId = (): string => {
  const uniqueId: string = uuidv4()
  return uniqueId
}

export const validateDate = (day: number, month: number, year: number): boolean => {
  const formattedDate = `${day}/${month}/${year}`
  const parsedDate = DateFns.parse(formattedDate, 'dd/MM/yyyy', new Date())
  return DateFns.isValid(parsedDate)
}

/**
 * Gera uma string com padrão de slug
 * @param {string} text - A string que quer transformar em slug
 * @returns {string} - A string em slug
 * @example
 * slugify('Olá mundo') // 'ola-mundo'
*/
export const slugify = (text: string): string => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

/**
 * Primeira letra maiúscula
 * @param {string} text - A string que quer transformar
 * @returns {string} - A string com a primeira letra maiúscula
*/
export const capitalizeFirstLetter = (text: string): string => {
  return text?.charAt(0)?.toUpperCase() + text?.slice(1)
}

/**
 * Pega um objeto e retira as propriedades que são vazias
 * @param {object} object - O objeto que quer verificar
 * @returns {object} - O objeto sem as propriedades vazias
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const removeEmptyProperties = (obj: Object): Object => {
  Object.keys(obj).forEach((key) => {
    const valorValidar = (obj as any)[key] as any

    if (valorValidar && typeof valorValidar === 'object') {
      removeEmptyProperties(valorValidar)
    } else if (
      valorValidar === null ||
      valorValidar === undefined ||
      valorValidar === ''
    ) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete (obj as any)[key]
    }
  })

  return obj
}

export interface IMockadeSwrRequest<T> {
  isValidating: boolean
  data: T
  mutate: () => Promise<void>
}

/**
 * Cria um mock de uma requisição do SWR
 * @param {any[]} dataToMock - O array de dados que quer mockar
 * @returns {object} - O objeto mockado
*/
export const createMockadeSwrRequest = (dataToMock: any[], extra: {
  page?: number
  total_pages?: number
  per_page?: number
} = {
  page: 1,
  total_pages: 1,
  per_page: 1
}
): IMockadeSwrRequest<any> => {
  Toastfy.info('Dados mockados')

  return {
    isValidating: false,
    data: {
      data: dataToMock,
      total: dataToMock.length,
      page: 1,
      total_pages: 1,
      per_page: 10
    },
    mutate: async () => { }
  }
}

/**
 * Pega um array e divide ele em pedaços de acordo com o tamanho especificado
 * @param {any[]} array - O array que quer dividir
 * @param {number} size - O tamanho de cada pedaço
 * @returns {any[]} - O array dividido
*/
export const chunkArray = (array: any[], size: number): any[] => {
  // Calculate the number of chunks needed based on the array length and the specified size
  const numberOfChunks = Math.ceil(array.length / size)

  // Use Array.from to create an array with the specified length (number of chunks)
  return Array.from({ length: numberOfChunks }, (v, index) =>
    // For each index, slice the original array to get a chunk of 'size' elements
    array.slice(index * size, index * size + size)
  )
}

/**
 * Pega o caminho de um arquivo e retorna uma URL para ele
 * @param file
 * @returns {string} - A URL do arquivo
 */
export const getUrlFromFile = (file: any): string => {
  if (!file) {
    return ''
  }

  if (file[0] instanceof File) {
    return URL.createObjectURL(file[0])
  }

  return ''
}

export const cleanMaskProtocol = (protocol: string): number => {
  return parseInt(protocol.replace(/[^0-9]/g, ''), 10)
}

export const fillArrayWithValues = ({
  array, length, callbackToFill
}: {
  array: any[]
  length: number
  callbackToFill: (
    index: number,
    length: number
  ) => any
}): any[] => {
  return Array.from({ length }, callbackToFill)
}

type ITypeHash = 'SHA-1' | 'SHA-256' | 'SHA-384' | 'SHA-512'

export const Hash = {

  AVAILABLE_HASH: {
    SHA1: 'SHA-1' as ITypeHash,
    SHA256: 'SHA-256' as ITypeHash,
    SHA384: 'SHA-384' as ITypeHash,
    SHA512: 'SHA-512' as ITypeHash
  },

  async generateHash (stringTohash: string, typeHash: ITypeHash): Promise<string> {
    if (window.crypto?.subtle) {
      const encoder = new TextEncoder()
      const data = encoder.encode(stringTohash)

      // Use the SubtleCrypto API to hash the data with SHA-256
      const hashBuffer = await window.crypto.subtle.digest(typeHash, data)

      // Convert the hash buffer to a hex string
      const hashArray = Array.from(new Uint8Array(hashBuffer))
      const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('')

      return hashHex
    } else {
      Toastfy.error('Seu navegador não suporta a criptografia')
      return ''
    }
  }

}

export const DateUtils = {
  parseDate (date: string): Date {
    return DateFns.parse(
      date,
      'yyyy-MM-dd',
      new Date()
    )
  },
  formatDate (date: string | Date, format: string): string {
    return DateFns.format(new Date(date) as Date, format)
  },
  validateDate (date: string): boolean {
    return DateFns.isValid(DateUtils.parseDate(date))
  },
  getLastDayOfMonth (date: Date): Date {
    return DateFns.endOfMonth(date)
  },
  getToday (): Date {
    return this.parseDate(this.getDateNow())
  },
  getDateNow (format: string = 'yyyy-MM-dd'): string {
    return this.formatDate(new Date(), format)
  },
  // Adds
  addDays (date: Date, days: number): Date {
    return DateFns.addDays(date, days)
  },
  addMonths (date: Date, months: number): Date {
    return DateFns.addMonths(date, months)
  },
  addYears (date: Date, years: number): Date {
    return DateFns.addYears(date, years)
  },
  // Subs
  subDays (date: Date, days: number): Date {
    return DateFns.subDays(date, days)
  },
  subMonths (date: Date, months: number): Date {
    return DateFns.subMonths(date, months)
  },
  subYears (date: Date, years: number): Date {
    return DateFns.subYears(date, years)
  },
  // Diff
  isAfter (date: Date, dateToCompare: Date): boolean {
    return DateFns.isAfter(date, dateToCompare)
  },
  isBefore (date: Date, dateToCompare: Date): boolean {
    return DateFns.isBefore(date, dateToCompare)
  },
  isSameDay (date: Date, dateToCompare: Date): boolean {
    return DateFns.isSameDay(date, dateToCompare)
  },
  isBigger (date: Date, dateToCompare: Date): boolean {
    return DateFns.isAfter(date, dateToCompare) || DateFns.isSameDay(date, dateToCompare)
  },
  isSmaller (date: Date, dateToCompare: Date): boolean {
    return DateFns.isBefore(date, dateToCompare) || DateFns.isSameDay(date, dateToCompare)
  }
}

/**
 * Pega um objeto e um caminho e retorna o valor do caminho

 * @param {object} objeto - O objeto que você quer acessar. Ex: {dados: {usuario: {sobrenome: 'Silva'}}}
 * @param {string} field - O caminho do objeto que você quer acessar. Ex: 'dados.usuario.sobrenome'.
 * Te retornará o valor de sobrenome caso tenha, se não retorna UNDEFINED
 * @param {any} defaultReturn - Se não achar nada, vai retornar o defaultReturn que você passar

 */
export function getAttributeValueByAttributePath (
  objeto: object,
  field: string,
  defaultReturn: any = undefined
): any {
  const attributesField = field.split('.')
  let currentObject = objeto

  for (let i = 0; i < attributesField.length; i++) {
    if (currentObject === undefined) {
      return defaultReturn
    }
    currentObject = currentObject[attributesField[i] as keyof typeof objeto]
  }

  return currentObject ?? defaultReturn
}
