/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { type IDefaultRequestObject } from 'app/shared/types/common'
import { type ICompanyCityHall } from 'app/shared/types/plataform/clients'
import { type ISearchCNPJ } from 'app/shared/types/plataform/cnpj'
import { type AxiosResponse } from 'axios'

import { apiServerPlataforma } from '../api'

// const endpointCNPJ = '/empresas'
const endpointCnpj = '/empresa'

const GetCNPJ = async (cnpj: string) => {
  return await apiServerPlataforma.get<IDefaultRequestObject<ISearchCNPJ>>(`${endpointCnpj}/cnpj/${cnpj}`)
}

const getCnpj = async (cnpj: string): Promise<AxiosResponse<IDefaultRequestObject<ICompanyCityHall>, any>> => {
  return await apiServerPlataforma.get<IDefaultRequestObject<ICompanyCityHall>>(`${endpointCnpj}/cnpj/${cnpj}`)
}

const getCnpjVinculados = async (cnpj: string): Promise<AxiosResponse<IDefaultRequestObject<ICompanyCityHall>, any>> => {
  return await apiServerPlataforma.get<IDefaultRequestObject<ICompanyCityHall>>(`${endpointCnpj}/vinculada/${cnpj}`)
}

export default {
  GetCNPJ,
  getCnpj,
  getCnpjVinculados
}
