/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { type IDefaultRequest } from 'app/shared/types/common'
import { type IVinculos } from 'app/shared/types/plataform/vinculados'
import { type ILinkedAndBonds } from 'app/shared/types/plataform/vinculos'
import { type KeyedMutator } from 'swr'

import { apiServerPlataforma } from '../api'

const endpointVinculados = '/vinculos'

const GetVinculos = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IVinculos>>(
    `${endpointVinculados}?${queryParams}`,
    apiServerPlataforma
  )
}

const createVinculo = async (
  pesId: number,
  payload: ILinkedAndBonds,
  mutate: KeyedMutator<IDefaultRequest<IVinculos>>
) => {
  const response = await apiServerPlataforma.post(`${endpointVinculados}/${pesId}`, payload)
  if (response.status === 200) await mutate()
  return response
}

const updateVinculo = async (
  pesRelId: number,
  payload: { idGrupo: number, status?: number, aceite?: number },
  mutate: KeyedMutator<IDefaultRequest<IVinculos>>
) => {
  const response = await apiServerPlataforma.patch(`${endpointVinculados}/${pesRelId}`, payload)
  if (response.status === 200) await mutate()
  return response
}

export default {
  GetVinculos,
  createVinculo,
  updateVinculo
}
