import { type ButtonProps, Button as ReactButton, Spinner } from 'react-bootstrap'

interface IButton extends ButtonProps {
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'link' | 'outline-primary' | 'outline-secondary' | 'outline-success' | 'outline-danger' | 'outline-warning' | 'outline-info' | 'outline-light' | 'outline-dark' | 'outline-link'
  rounded: boolean
  children: React.ReactNode
  type?: 'button' | 'submit' | 'reset'
  size?: 'sm' | 'lg'
  className?: string
  defaultClassName?: string
  loading?: boolean
  disabled?: boolean
  onClick?: () => void
}

const Button = ({
  variant,
  rounded,
  children,
  type,
  size,
  defaultClassName = 'd-flex justify-content-center align-items-center gap-2',
  className,
  disabled,
  loading,
  onClick,
  ...props
}: IButton): JSX.Element => {
  return (
    <ReactButton
      variant={variant}
      type={type}
      size={size}
      disabled={disabled}
      className={`${defaultClassName} ${className} ${rounded ? 'rounded-pill' : ''}`}
      {...props}
      onClick={onClick}
    >
      {children}
      {
        loading && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />

        )
      }
    </ReactButton>
  )
}

export default Button
