import { type UserModel, type CityModel, type UserPersonModel } from 'app/modules/auth'
import { create } from 'zustand'

import { PROCESSOS_ROUTES } from 'app/routing/routes'
// import { type IPermissionsUsers } from 'app/shared/types/processos/screenPermissions/screenPermissions'

export interface IPermissions {
  id: number
  name: string
  slug: string
  group: boolean
  screens: IPermissions[]
  permission?: number[] | number | undefined | null
}

export type IPermissionsUser = Record<string, IPermissions[]>

export interface IPermissionMenu {
  productName: string
  permissions: IPermissions[]
  referencePath: Record<number, string>
  menuData: {
    to: string
    icon?: string
    fontIcon?: string
  }
}

interface UserStore {
  user: UserModel | undefined
  person: UserPersonModel | undefined
  citys: CityModel[]
  currentCity: CityModel | undefined
  people: UserPersonModel[]
  permissions: IPermissionsUser
  setPermissions: (permissions: IPermissionsUser) => void
  setUser: (user: UserModel | undefined) => void
  setPerson: (person: UserPersonModel | undefined) => void
  setCitys: (citys: CityModel[]) => void
  setCurrentCity: (currentCity: CityModel | undefined) => void
  setPeople: (people: any) => void
  getSidebarMenu: () => IPermissionMenu[]
  getScreenPermissions: (productName: string) => number[]
  logout: () => void
}

const initialValues = {
  user: undefined,
  permissions: {},
  citys: [],
  person: undefined,
  people: [],
  currentCity: undefined
}

const castingPerson = (person: any): UserPersonModel[] => {
  return person.map((person) => {
    const pessoa = (person.JURIDICA !== null ? person.JURIDICA : person.FISICA).PESSOA

    const FISICA = person.FISICA !== null
      ? {
          FISID: person.FISICA.FISID,
          PESID: person.FISICA.PESID,
          FISCPF: person.FISICA.FISCPF,
          FISDTNASC: person.FISICA.FISDTNASC
        }
      : undefined

    const JURIDICA = person.JURIDICA !== null
      ? {
          JURID: person.JURIDICA.JURID,
          PESID: person.JURIDICA.PESID,
          JURCNPJ: person.JURIDICA.JURCNPJ,
          JURFANTASIA: person.JURIDICA.JURFANTASIA,
          JURNOMEFANTASIA: person.JURIDICA.JURNOMEFANTASIA
        }
      : undefined

    const personName = JURIDICA?.JURFANTASIA ?? pessoa.PESNOME
    const cpfCnpj = JURIDICA?.JURCNPJ ?? FISICA?.FISCPF

    return {
      PESSOA: {
        ...pessoa,
        CPF_CNPJ: cpfCnpj,
        PESSOA_NOME: personName,
        FISICA,
        JURIDICA,
        TELEFONES: person.TELEFONES,
        EMAILS: person.EMAILS
      },
      GRPID: person.GRPID,
      ORGPESID: person.ORGPESID,
      GRPIDDESC: person.GRPIDDESC
    } satisfies UserPersonModel
  })
}

export const useUserStore = create<UserStore>((set, get) => ({
  ...initialValues,
  setUser: (user: UserModel | undefined) => {
    set({ user })
  },
  setCitys: (citys: CityModel[]) => {
    set({ citys })
  },
  setPermissions: (permissions: IPermissionsUser) => {
    set({ permissions })
  },
  setCurrentCity: (currentCity: CityModel | undefined) => {
    set({ currentCity })
  },
  setPerson: (person: UserPersonModel | undefined) => {
    set({ person })
  },
  setPeople: (people: UserPersonModel[]) => {
    set({ people: castingPerson(people) })
  },
  logout: () => {
    set(initialValues)
  },
  getSidebarMenu: (): IPermissionMenu[] => {
    const routesConfigs = {
      's-Digital': {
        to: '/processos',
        icon: 'document'
      }
    }

    const PATH_CONFIG = {
      's-Digital': PROCESSOS_ROUTES
    }

    return Object.keys(get().permissions).map((productName) => {
      const permissions = get().permissions[productName]

      return {
        productName,
        permissions,
        menuData: routesConfigs[productName],
        referencePath: PATH_CONFIG[productName]
      }
    })
  },
  getScreenPermissions: (productName: string): number[] => {
    const getReturnOnlyId = (permissions: IPermissions[]): number[] => {
      return permissions.reduce<number[]>((acc, permission) => {
        if (permission.group) {
          return [...acc, ...getReturnOnlyId(permission.screens)]
        }

        return [...acc, permission.id]
      }, [])
    }

    if (!get().permissions[productName]) {
      return []
    }

    const permissions = getReturnOnlyId(get().permissions[productName] ?? [])

    return permissions.sort((a, b) => a - b)
  }
}))
