/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { type FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'

import { keysMessages } from '_metronic/i18n/keysMessages'
import { App } from 'app/App'
import { AuthPage, Logout, useAuth } from 'app/modules/auth'
import { AuthLayout } from 'app/modules/auth/AuthLayout'
import { TermOfUsePage } from 'app/modules/auth/components/TermOfUsePage'
import { SelectCity } from 'app/modules/auth/SelectCity'
import { ErrorsPage } from 'app/modules/errors/ErrorsPage'
import { PrivateRoutes } from 'app/routing/PrivateRoutes'
import Button from 'app/shared/components/Button/Button'
import { getInternalizeText } from 'app/shared/helpers/utils'
import { useUserStore } from 'app/store/Plataform/UserStore'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser, logout } = useAuth()
  const [currentCity, citys] = useUserStore(state => [state.currentCity, state.citys])

  const verifyIfTheUserHasntCitySelected: boolean = citys.length > 1 && !currentCity

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route path='termo-de-uso-e-privacidade' element={<TermOfUsePage />} />
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {
            currentUser
              ? verifyIfTheUserHasntCitySelected
                ? (
                  <>
                    <Route element={<AuthLayout />}>
                      <Route path='select-city' element={
                        <>

                          <div className='card-header pt-5'>
                            <h3 className='card-title text-gray-800 fw-bold'>{getInternalizeText(keysMessages.Auth_SelectCity)}</h3>
                            <div className='card-toolbar'></div>
                          </div>

                          <SelectCity onClickButton={() => { }} />

                          <div className='d-grid mt-10'>
                            <Button rounded size='sm' className='bg-blue-primary' onClick={logout}>{getInternalizeText(keysMessages.ButtonBack)}</Button>
                          </div>
                        </>
                      } />
                      <Route path='*' element={<Navigate to='/select-city' />} />
                    </Route>
                  </>
                  )
                : (
                  <>
                    <Route path='/*' element={<PrivateRoutes />} />
                    <Route index element={<Navigate to='/dashboard' />} />
                  </>
                  )
              : (
                <>
                  <Route path='auth/*' element={<AuthPage />} />
                  <Route path='*' element={<Navigate to='/auth' />} />
                </>
                )
          }
        </Route>
      </Routes>
    </BrowserRouter >
  )
}

export { AppRoutes }
