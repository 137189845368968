import { Worker, Viewer } from '@react-pdf-viewer/core'

interface IViewPDF {
  link: string
}

const ViewPDF = ({ link }: IViewPDF): JSX.Element => {
  return (
    <div>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Viewer
          fileUrl={link}
        />
      </Worker>
    </div>
  )
}

export default ViewPDF
