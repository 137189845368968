import clsx from 'clsx'
import { KTIcon } from '../../../helpers'
import { ThemeModeSwitcher } from '../../../partials'
import { useLayout } from '../../core'

import SessionExpires from 'app/shared/components/SessionExpires/SessionExpires'
import HeaderUser from 'app/shared/components/HeaderUser/HeaderUser'
import Badge from 'app/shared/components/Badge/Badge'
import SideBarNotifications from 'app/shared/components/SideBarNotifications/SideBarNotifications'
import { useUserStore } from 'app/store/Plataform/UserStore'

const itemClass = 'ms-1 ms-md-4'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

import './style.scss'

const Navbar = () => {
  const { config } = useLayout()
  const { person, currentCity } = useUserStore()

  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <AiOutlineBarChart className='icon' color='#1351B4' />
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <BsHeadset className='icon' color='#1351B4' />
          <KTIcon iconName='element-plus' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      <SessionExpires />

      <SideBarNotifications />

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className="d-flex flex-column rounded-2 mt-2 cursor-pointer" onClick={() => {
        const alterarPerfilButton = document.getElementById('menu-item-alterar-perfil');

        if (alterarPerfilButton?.children[0]?.children[0]) {
          const firstChild = alterarPerfilButton.children[0].children[0] as HTMLElement;
          firstChild.click();
        }
      }}>
        <div className="p-2">
          <Badge backgroundColor='#1351b4' modeDark={false} rounded>
            <div className='fw-bolder fs-6 mx-2'>{person?.PESSOA.PESNOME}</div>
          </Badge>
        </div>
        {
          currentCity && (
            <div className="ms-6">
              {currentCity?.CLINOME}
            </div>
          )
        }
      </div>

      <div className='d-flex flex-column align-items-center'>
        <HeaderUser />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {/* <img src={toAbsoluteUrl('/media/avatars/300-3.jpg')} alt='' /> */}
          {/* <div className='d-flex justify-content-center gap-2 text-blue-primary fw-bold py-2 px-4 border-radius-lg bg-white-light'>
            <IoMenu className='icon' />
            Menu
          </div> */}
        </div>
        {/* <HeaderUserMenu /> */}
        {/* <HeaderMenu /> */}
      </div>

      {
        config.app?.header?.default?.menu?.display && (
          <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
            <div
              className='btn btn-icon btn-active-color-primary w-35px h-35px'
              id='kt_app_header_menu_toggle'
            >
              <KTIcon iconName='text-align-left' className={btnIconClass} />
            </div>
          </div>
        )
      }
    </div >
  )
}

export { Navbar }
