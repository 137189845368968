import Offcanvas from 'react-bootstrap/Offcanvas'

interface ISideBar {
  show: boolean
  title: string
  children: React.ReactNode
  position?: 'start' | 'end' | 'top' | 'bottom'
  onHide: () => void
}

const SideBar = ({ show, title, children, position = 'end', onHide }: ISideBar): JSX.Element => {
  return (
    <>
      <Offcanvas show={show} onHide={onHide} placement={position} backdrop='static'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='fs-3 text-center font-weight-bold text-dark p-5'>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{children}</Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default SideBar
