/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import ReactQuill, { Quill } from 'react-quill'

import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'app/shared/components/Button/Button'
import OverlayTooltip from 'app/shared/components/OverlayTooltip/OverlayTooltip'
import ReactModal from 'app/shared/components/ReactModal/ReactModal'
import { boolToString } from 'app/shared/helpers/utils'
import { useFormBuilderStore } from 'app/store/FormBuilderStore'
import clsx from 'clsx'
import * as Yup from 'yup'

import 'quill/dist/quill.snow.css'

const RenderText: React.FC = ({ ...props }: any) => {
  const rowCols = props?.style?.cols ?? 12

  return (
    <div className={`col-md-${rowCols} col-sm-12`}>
      <div style={{
        maxWidth: '100%',
        overflow: 'hidden'
      }}>
        <div>
          <div dangerouslySetInnerHTML={{ __html: props?.html ?? '' }} />
        </div>
      </div>
    </div>
  )
}

const InputParamsDetails: React.FC = ({ ...props }: any) => {
  const paramsDetails = [
    {
      key: 'Obrigatório',
      value: boolToString(Boolean(props.validations.required))
    },
    {
      key: 'Tamanho da Coluna',
      value: props.style.cols
    }
  ]

  return (
    <>
      <label className='form-label fs-5 fw-bolder text-dark'>{props?.labelFieldName}</label>

      <table className="table">
        <tbody>
          {
            paramsDetails.map((param, index) => (
              <tr key={param.key} >
                <th>{param.key}</th>
                <td>{param.value}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </>
  )
}

const RenderTextPreview: React.FC = ({ ...props }: any) => {
  const rowCols = props?.style?.cols ?? 12

  return (
    <>
      <div className={`col-md-${rowCols} col-sm-12`}>
        <label className='form-label fs-5 fw-bolder text-dark'>{props?.typeFieldName}</label>

        <br />

        {
          !props.isEditing
            ? <InputParamsDetails {...props} />
            : <div dangerouslySetInnerHTML={{ __html: props?.html ?? '' }} />
        }
      </div>
    </>
  )
}

const ModalTextEditing: React.FC = ({ ...props }: any) => {
  const [editInputForm] = useFormBuilderStore(state => [state.editInputForm])
  const [value, setValue] = useState(props?.html ?? '')

  const inputEditSchema = Yup.object().shape({
    labelFieldName: Yup.string().required('Campo obrigatório'),
    validationType: Yup.string().required('Campo obrigatório'),
    validations: Yup.object().shape({
      required: Yup.boolean().nullable(),
      fileType: Yup.string().required('Campo obrigatório')
    }),
    style: Yup.object().shape({
      cols: Yup.number().required('Campo obrigatório')
    }),
    inputParams: Yup.object().shape({
      required: Yup.boolean().nullable(),
      disabled: Yup.boolean().nullable()
    })
  })

  const cols = Number(props?.style?.cols ?? 12)

  const defaultValues = {
    validationType: `${props.validationType}`,
    labelFieldName: `${props.labelFieldName}`,
    validations: {
      required: Boolean(props?.validations?.required),
      fileType: `${props?.validations?.fileType}`
    },
    style: {
      cols
    },
    html: `${props?.html}`,
    inputParams: {
      required: Boolean(props?.inputParams?.required),
      disabled: Boolean(props?.inputParams?.disabled)
    }
  }

  type FormDataSchema = Yup.InferType<typeof inputEditSchema>

  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues,
    resolver: yupResolver(inputEditSchema)
  })

  const closeModal = (): void => {
    editInputForm(props.uuid, {
      ...props,
      isModalOpen: false
    })
  }

  const onSubmitEditInput: SubmitHandler<FormDataSchema> = async (data): Promise<void> => {
    editInputForm(props.uuid, {
      ...props,
      ...data,
      inputParams: {
        ...props.inputParams,
        ...data.inputParams,
        required: Boolean(data.validations.required)
      },
      validations: {
        ...props.validations,
        ...data.validations
      },
      html: value,
      isModalOpen: false
    })
  }

  const Link = Quill.import('formats/link')

  Link.sanitize = function (url: string) {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `http://${url}`
    }
    return url
  }

  return <ReactModal
    show={props.isModalOpen}
    headerName={`Editando campo "${props.typeFieldName} - ${props.labelFieldName}"`}
    onClose={closeModal}
    modalFooter={
      <>
        <Button
          rounded={true}
          variant='primary'
          onClick={handleSubmit(onSubmitEditInput)}
        >
          Salvar
        </Button>

        <Button
          type='button'
          rounded={true}
          variant='light'
          onClick={closeModal}
        >Voltar</Button>
      </>
    }
  >
    <>
      <div className="row">

        <div className="col-md-6 col-sm-12">
          <label className='form-label fs-6 fw-bolder text-dark'>Nome do Campo</label>
          <input
            {...register('labelFieldName')}
            type='text'
            placeholder='Nome do Campo'
            className={clsx(
              'form-control form-control-solid',
              { 'is-invalid': errors.labelFieldName },
              { 'is-valid': errors.labelFieldName == null }
            )}
          />
          <div className='fv-plugins-message-container'>
            <span role='alert'>{errors.labelFieldName?.message}</span>
          </div>
        </div>

      </div>

      <div className="row">

        <label className='form-label fs-6 fw-bolder text-dark'>Digite aqui o texto</label>

        <div
          style={{
            height: '250px',
            display: 'block'
          }}
        >
          <ReactQuill
            theme="snow" value={value}
            onChange={setValue}
            modules={{
              toolbar: [
                [{ font: [] }],
                [{ header: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link'],
                ['clean']
              ]
            }}
            formats={[
              'header',
              'bold', 'italic', 'underline', 'strike', 'blockquote',
              'list', 'bullet',
              'link'
            ]}
            style={{
              height: '200px'
            }}
          />
        </div>

      </div>

      <hr className='mt-2' />

      <div className="row">
        <label className='form-label fs-2 fw-bolder text-dark'>Estilo</label>
      </div>

      <div className="row mb-2">

        <div className="col-md-3 col-sm-12 mt-2">
          <label className='form-label fs-6 fw-bolder text-dark' htmlFor='style.cols'>
            Tamanho da Coluna <OverlayTooltip
              message="Tamanho da coluna que o campo irá ocupar no formulário. Vai de 1 a 12, sendo 12 o tamanho máximo e 1 o mínimo."
            >
              <i className="bi bi-info-circle"></i>
            </OverlayTooltip>
          </label>

          <select
            className="form-select"
            id="style.cols"
            aria-label="Floating label select"
            {...register('style.cols')}
          >
            {
              Array.from({ length: 12 }, (_, index) => index + 1).map((value) => (
                <option
                  key={value}
                  value={value}
                >
                  {value} Coluna{value > 1 ? 's' : ''}
                </option>
              ))
            }
          </select>

        </div>

      </div>

    </>

  </ReactModal>
}

export { RenderText, RenderTextPreview, ModalTextEditing }
