/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ENV_VARS } from 'app/shared/helpers/envs'
import { DateUtils, Hash } from 'app/shared/helpers/utils'
import { apiProcessos, apiServerPlataforma } from 'app/shared/services/api'
import { type IListAllClients } from 'app/shared/types/plataform/clients'
import { type IPermissionsUsers } from 'app/shared/types/processos/screenPermissions/screenPermissions'
import axios, { type AxiosResponse } from 'axios'

import { type UserModel, type CityModel, type IDefaultRequest, type UserPersonModel } from './_models'

const PLATAFORMA_URL = `${ENV_VARS.API_URL}/plataforma`

export const GET_USER_BY_ACCESSTOKEN_URL = `${PLATAFORMA_URL}/verify_token`
export const LOGIN_URL = `${PLATAFORMA_URL}/auth/login`
export const REFRESH_TOKEN = `${PLATAFORMA_URL}/auth/refresh-token`
export const LOGIN_WITH_SSO = `${PLATAFORMA_URL}/auth/login-api`
export const REQUEST_PASSWORD_URL = 'user/reset-password'

export interface LoginData {
  email?: string | null
  cpf?: string
  password?: string
  keepAlive?: boolean
  verifyCode?: string
  isAcceptTerms?: boolean
}

const apiPlataformaUniqueInstance = axios.create({
  baseURL: PLATAFORMA_URL,
  headers: {
    'Content-type': 'application/json'
  }
})

export async function getVerificationCode (loginData: LoginData): Promise<any> {
  return await apiPlataformaUniqueInstance.post(LOGIN_URL, {
    email: loginData.email,
    cpf: loginData.cpf,
    keepAlive: loginData.keepAlive,
    password: loginData.password
  })
}

export async function login (loginData: LoginData): Promise<any> {
  return await apiPlataformaUniqueInstance.post(LOGIN_URL, {
    email: loginData.email,
    cpf: loginData.cpf,
    keepAlive: loginData.keepAlive,
    password: loginData.password,
    verifyCode: loginData.verifyCode
  })
}

export async function refreshToken (): Promise<any> {
  return await apiServerPlataforma.post(REFRESH_TOKEN)
}

export async function loginWithSSO ({
  cpf,
  email,
  verifyCode
}: {
  cpf?: string
  email?: string
  verifyCode?: string
}): Promise<any> {
  const date = DateUtils.getDateNow('yyyy-MM-dd')
  const keyToEncode = `_sigcorp_${date}_` + (cpf ?? email)
  const validateKey = await Hash.generateHash(keyToEncode, Hash.AVAILABLE_HASH.SHA256)

  return await apiPlataformaUniqueInstance.post(LOGIN_WITH_SSO, {
    cpf,
    email,
    verifyCode
  }, {
    headers: {
      'X-Validate-Key': validateKey
    }
  })
}

export async function requestPassword (resetPasswordObject: {
  email: string
  cpf: string
}) {
  return await apiPlataformaUniqueInstance.post(REQUEST_PASSWORD_URL, resetPasswordObject)
}

const addHeaderAuthorization = (token: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export async function getUserByToken (token: string): Promise<AxiosResponse<IDefaultRequest<UserModel>>> {
  return await apiPlataformaUniqueInstance.get('auth/get-user', {
    ...addHeaderAuthorization(token)
  })
}

export async function getPersonByToken (token: string): Promise<AxiosResponse<{
  message: string
  data: UserPersonModel
}>> {
  return await apiPlataformaUniqueInstance.get('pessoa/logged', {
    ...addHeaderAuthorization(token)
  })
}

export async function getCitysFromUser (token: string): Promise<AxiosResponse<IDefaultRequest<CityModel>>> {
  return await apiPlataformaUniqueInstance.get('pessoa/municipios', {
    ...addHeaderAuthorization(token)
  })
}

export async function getClientsFromUser (token: string): Promise<AxiosResponse<IDefaultRequest<IListAllClients>>> {
  return await apiPlataformaUniqueInstance.get('clientes/list-all', {
    ...addHeaderAuthorization(token)
  })
}

export async function getProfilesFromUser (token: string, params: object = {}): Promise<AxiosResponse<IDefaultRequest<any>>> {
  return await apiPlataformaUniqueInstance.get('perfis', {
    ...addHeaderAuthorization(token)
  })
}

export async function getPermissionsSDigital (token: string) {
  return await apiProcessos.get<IDefaultRequest<IPermissionsUsers>>('permissoes')
}
