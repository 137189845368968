import { type ISendCode, type IAutoRegister } from 'app/shared/types/autoRegister'
import { type AxiosResponse } from 'axios'

import { apiServerPlataforma } from '../api'

const endpoint = '/pessoa'

const createRegister = async (payload: IAutoRegister): Promise<AxiosResponse<IAutoRegister>> => {
  const response = await apiServerPlataforma.post(`${endpoint}/auto-cadastro`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

const sendCode = async (payload: ISendCode): Promise<AxiosResponse<ISendCode>> => {
  const response = await apiServerPlataforma.post(`${endpoint}/generate-code`, payload)
  return response
}

export default {
  createRegister,
  sendCode
}
