import { systemMessages } from '_metronic/i18n/systemMessages'
import { PageTitle } from '_metronic/layout/core'
import MyProtocolsInStandBy from 'app/modules/processos/pages/home/MyProtocolsInStandBy'
import { getInternalizeText } from 'app/shared/helpers/utils'

import './styles.scss'

const DashboardInitial = (): JSX.Element => {
  return (
    <>
      <PageTitle>{getInternalizeText(systemMessages.keysMessages.DashboardPageInitial)}</PageTitle>

      {/* <div>
        <h6>{getInternalizeText(systemMessages.keysMessages.DashboardIndicatorModulo)}</h6>
        <select className='form-select w-300px'>
          <option>Indicadores</option>
        </select>
      </div> */}

      {/* <div className='row mt-10'>
        <div className='col-lg-8'>
          <ChatIndicator title='Indicadores' subtitle='Indicadores' typeChart='area' className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-lg-4'>
          <ChatIndicator title='Indicadores' subtitle='Indicadores' typeChart='bar' className='card-xl-stretch mb-xl-4' />
        </div>
      </div> */}

      <div>
        {/* <KanbanBoard /> */}
        {/* <HomeNewsLetter /> */}
        <MyProtocolsInStandBy />
      </div>
    </>
  )
}

export default DashboardInitial
