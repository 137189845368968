/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'app/shared/components/Button/Button'
import InputFileGeneric from 'app/shared/components/InputFile/InputFileGeneric'
import OverlayTooltip from 'app/shared/components/OverlayTooltip/OverlayTooltip'
import ReactModal from 'app/shared/components/ReactModal/ReactModal'
import { boolToString } from 'app/shared/helpers/utils'
import { type ValidFileExtensions, getValidsExtensionsByFileType } from 'app/shared/helpers/validateValues'
import { floatBrMask, moneyMask, unmaskFloatBrNumber } from 'app/shared/helpers/valueMasks'
import { useFormBuilderStore } from 'app/store/FormBuilderStore'
import clsx from 'clsx'
import * as Yup from 'yup'

const TypeFileValid: React.FC<{
  validations: {
    fileType: string
    required: boolean
    maxSize: number
    sizeType: string
  }
}> = (
  { validations }
) => {
  return (
      <>
        {
          getValidsExtensionsByFileType(validations.fileType as ValidFileExtensions)
        }
      </>
  )
}

const RenderInputFileForm: React.FC = ({ ...props }: any) => {
  const rowCols = props?.style?.cols ?? 12

  return (
    <div className={`col-md-${rowCols} col-sm-12`}>

      <InputFileGeneric
        hookFormFunctions={props.hookForm}
        inputConfigs={props.inputParams}
        labelsConfig={{
          label: props.labelFieldName
        }}
      />

      <TypeFileValid validations={props.validations} />

    </div>
  )
}

const InputFileParamsDetails: React.FC = ({ ...props }: any) => {
  const paramsDetails = [
    {
      key: 'Obrigatório',
      value: boolToString(Boolean(props.validations.required))
    },
    {
      key: 'Tipo de Arquivo',
      value: getValidsExtensionsByFileType(props.validations.fileType)
    },
    {
      key: 'Tamanho da Coluna',
      value: props.style.cols
    }
  ]

  return (
    <>
      <label className='form-label fs-5 fw-bolder text-dark'>{props?.labelFieldName}</label>

      <table className="table">
        <tbody>
          {
            paramsDetails.map((param, index) => (
              <tr key={param.key} >
                <th>{param.key}</th>
                <td>{param.value}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </>
  )
}

const RenderInputFileFormPreview: React.FC = ({ ...props }: any) => {
  const rowCols = props?.style?.cols ?? 12

  return (
    <div className={`col-md-${rowCols} col-sm-12`}>
      <label className='form-label fs-5 fw-bolder text-dark'>{props?.typeFieldName}</label>

      <br />

      {
        !props.isEditing
          ? <InputFileParamsDetails {...props} />
          : <>
            <InputFileGeneric
              inputConfigs={{
                ...props.inputParams,
                disabled: true,
                readOnly: true
              }}
              labelsConfig={{
                label: props.labelFieldName
              }}
            />

            <TypeFileValid validations={props.validations} />

          </>

      }
    </div>
  )
}

const maskValueOfMemory = (value: string): string => {
  return moneyMask(value, {
    decimalPlaces: 4
  })
}

const ModalInputFileFormEditing: React.FC = ({ ...props }: any) => {
  const [editInputForm] = useFormBuilderStore(state => [state.editInputForm])

  const inputEditSchema = Yup.object().shape({
    labelFieldName: Yup.string().required('Campo obrigatório'),
    validationType: Yup.string().required('Campo obrigatório'),
    validations: Yup.object().shape({
      required: Yup.boolean().nullable(),
      fileType: Yup.string().required('Campo obrigatório'),
      maxSize: Yup.string().required('Campo obrigatório'),
      sizeType: Yup.string().required('Campo obrigatório')
    }),
    style: Yup.object().shape({
      cols: Yup.number().required('Campo obrigatório')
    }),
    inputParams: Yup.object().shape({
      required: Yup.boolean().nullable(),
      disabled: Yup.boolean().nullable()
    })
  })

  const cols = Number(props?.style?.cols ?? 12)

  const defaultValues = {
    validationType: `${props.validationType}`,
    labelFieldName: `${props.labelFieldName}`,
    validations: {
      required: Boolean(props?.validations?.required),
      fileType: `${props?.validations?.fileType}` ?? 'all',
      maxSize: floatBrMask(`${props?.validations?.maxSize}` ?? 5.0000, {
        maximumFractionDigits: 4,
        minimumFractionDigits: 4
      }),
      sizeType: `${props?.validations?.sizeType}` ?? 'MB'
    },
    style: {
      cols
    },
    inputParams: {
      required: Boolean(props?.inputParams?.required),
      disabled: Boolean(props?.inputParams?.disabled)
    }
  }

  type FormDataSchema = Yup.InferType<typeof inputEditSchema>

  const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({
    defaultValues,
    resolver: yupResolver(inputEditSchema)
  })

  const closeModal = (): void => {
    editInputForm(props.uuid, {
      ...props,
      isModalOpen: false
    })
  }

  const onSubmitEditInput: SubmitHandler<FormDataSchema> = async (data): Promise<void> => {
    editInputForm(props.uuid, {
      ...props,
      ...data,
      inputParams: {
        ...props.inputParams,
        ...data.inputParams,
        required: Boolean(data.validations.required)
      },
      validations: {
        ...props.validations,
        ...data.validations,
        maxSize: unmaskFloatBrNumber(data.validations.maxSize)
      },
      isModalOpen: false
    })
  }

  return <ReactModal
    show={props.isModalOpen}
    headerName={`Editando campo "${props.typeFieldName} - ${props.labelFieldName}"`}
    onClose={closeModal}
    modalFooter={
      <>
        <Button
          rounded={true}
          variant='primary'
          onClick={handleSubmit(onSubmitEditInput)}
        >
          Salvar
        </Button>

        <Button
          type='button'
          rounded={true}
          variant='light'
          onClick={closeModal}
        >Voltar</Button>
      </>
    }
  >

    <div className="row">

      <div className="col-md-6 col-sm-12">
        <label className='form-label fs-6 fw-bolder text-dark'>Nome do Campo</label>
        <input
          {...register('labelFieldName')}
          type='text'
          placeholder='Nome do Campo'
          className={clsx(
            'form-control form-control-solid',
            { 'is-invalid': errors.labelFieldName },
            { 'is-valid': errors.labelFieldName == null }
          )}
        />
        <div className='fv-plugins-message-container'>
          <span role='alert'>{errors.labelFieldName?.message}</span>
        </div>
      </div>

    </div>

    <hr />

    <label className='form-label fs-2 fw-bolder text-dark'>Validações</label>

    <div className="row mt-2">

      <div className="col-md-5 col-sm-12 mt-2">
        <label className='form-label fs-6 fw-bolder text-dark' htmlFor='validationType'>Tipo de Arquivo</label>

        <select
          className="form-select"
          id="validations.fileType"
          aria-label="Floating label select"
          {...register('validations.fileType')}
        >
          <option value="image">Imagem</option>
          <option value="document">Documento</option>
          <option value="all">Sem Validação</option>
        </select>

      </div>

      <div className="col-md-7 col-sm-12 mt-2">
        <label className='form-label fs-6 fw-bolder text-dark' htmlFor='validationType'>Arquivos disponíveis</label>

        <p className="fs-5 fw-bolder text-dark">
          {
            getValidsExtensionsByFileType(watch('validations.fileType') as ValidFileExtensions)
          }
        </p>

      </div>

    </div>

    <div className="row mt-2">

      <div className="col-md-5 col-sm-12 mt-2">
        <label className='form-label fs-6 fw-bolder text-dark' htmlFor='validationType'>Tamanho de Arquivo</label>

        <input
          type='text'
          placeholder='Tamanho de Arquivo'
          className={clsx(
            'form-control form-control-solid',
            { 'is-invalid': errors.labelFieldName },
            { 'is-valid': errors.labelFieldName == null }
          )}
          {...register('validations.maxSize')}
          onChange={(e) => {
            setValue('validations.maxSize', maskValueOfMemory(e.target.value))
          }}
        />

      </div>

      <div className="col-md-7 col-sm-12 mt-2">
        <label className='form-label fs-6 fw-bolder text-dark' htmlFor='validationType'>Tipo de memória</label>

        <select
          className="form-select"
          {...register('validations.sizeType')}
          aria-label="Floating label select"
        >
          <option value="MB">MB</option>
          <option value="KB">KB</option>
        </select>

      </div>

    </div>

    <hr />

    <label className='form-label fs-2 fw-bolder text-dark'>Estilo</label>

    <div className="row mb-2">

      <div className="col-md-3 col-sm-12 mt-2">
        <label className='form-label fs-6 fw-bolder text-dark' htmlFor='style.cols'>
          Tamanho da Coluna <OverlayTooltip
            message="Tamanho da coluna que o campo irá ocupar no formulário. Vai de 1 a 12, sendo 12 o tamanho máximo e 1 o mínimo."
          >
            <i className="bi bi-info-circle"></i>
          </OverlayTooltip>
        </label>

        <select
          className="form-select"
          id="style.cols"
          aria-label="Floating label select"
          {...register('style.cols')}
        >
          {
            Array.from({ length: 12 }, (_, index) => index + 1).map((value) => (
              <option
                key={value}
                value={value}
              >
                {value} Coluna{value > 1 ? 's' : ''}
              </option>
            ))
          }
        </select>

      </div>

    </div>

    <hr />

    <div className="row mt-2">
      <div className="col-md-4 col-sm-12 mt-2">
        <div className="form-check form-switch">
          <input
            {...register('validations.required')}
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="isRequiredCheck"
          />
          <label className='form-label fs-6 fw-bolder text-dark' htmlFor='isRequiredCheck'>Obrigatório</label>
        </div>

      </div>
    </div>

  </ReactModal>
}

export { RenderInputFileForm, RenderInputFileFormPreview, ModalInputFileFormEditing }
