interface ILoadingSpinners {
  children?: React.ReactNode
}

const LoadingSpinners = ({ children }: ILoadingSpinners): JSX.Element => {
  return (
    <div className="d-flex flex-column gap-3 justify-content-center align-items-center">
      <div className="d-flex gap-3">
        <div className="spinner-grow ml-auto bg-blue-primary" role="status" aria-hidden="true"></div>
        <div className="spinner-grow ml-auto bg-blue-primary" role="status" aria-hidden="true"></div>
        <div className="spinner-grow ml-auto bg-blue-primary" role="status" aria-hidden="true"></div>
      </div>
      <strong>
        {children ?? 'Carregando dados...'}
      </strong>
    </div >
  )
}

export default LoadingSpinners
