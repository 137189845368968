/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { type IDefaultRequest } from 'app/shared/types/common'
import { type IOriginGroup } from 'app/shared/types/plataform/originGroup'

import { apiServerPlataforma } from '../api'

const endpointOriginGroup = '/enum/Vinculo/GruposOrigem'

const GetOriginGroup = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IOriginGroup>>(
    `${endpointOriginGroup}/list-all?${queryParams}`,
    apiServerPlataforma
  )
}

export default {
  GetOriginGroup
}
