/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useEffect, useRef, useState } from 'react'
import { type SubmitHandler, useForm, FormProvider } from 'react-hook-form'
import { BiCheck } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { StepperComponent } from '_metronic/assets/ts/components'
import { systemMessages } from '_metronic/i18n/systemMessages'
import { yupResolver } from '@hookform/resolvers/yup'
import { initialValuesAutoRegister } from 'app/modules/auth/components/SteppersRegistration/Validations'
import Toastfy from 'app/shared/helpers/Toastfy/Toastfy'
import { getInternalizeText } from 'app/shared/helpers/utils'
import { formatDate, numberMask } from 'app/shared/helpers/valueMasks'
import { ServiceAuthRegister } from 'app/shared/services'
import { type IAutoRegister } from 'app/shared/types/autoRegister'

import ButtonMetronic from '../Button/ButtonMetronic'

import './styles.scss'

interface IStepper {
  steps: string[]
  schemaValdation: any[]
  callBackValidation?: Record<string | number, (data: any) => Promise<boolean> | boolean>
  dadosParaSalvar?: (data: any) => any
  initialValues?: any
  exit?: boolean
  className?: string
  children: React.ReactNode
}

const Stepper = ({ steps, schemaValdation, callBackValidation, dadosParaSalvar, initialValues, exit, className, children }: IStepper): JSX.Element => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [stepCurrent, setStepCurrent] = useState<number>(1)
  const [currentSchema, setCurrentSchema] = useState(schemaValdation[0])
  const [requestEndpoint, setRequestEndpoint] = useState<boolean>(false)
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(currentSchema)
  })

  const navigate = useNavigate()

  const loadStepper = (): void => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = (): void => {
    if (stepper.current) {
      if (stepper?.current.currentStepIndex === 1) {
        navigate(-1)
        return
      }

      stepper.current.goPrev()
      setStepCurrent(stepper.current.currentStepIndex)
      methods.setValue('stepperCurrent', stepper.current.currentStepIndex)

      if (stepper.current.currentStepIndex !== 0) {
        setCurrentSchema(schemaValdation[stepper.current.currentStepIndex - 1])
      }
    }
  }

  // const handleDataSubmit = (dataSubmit: IAutoRegister): IAutoRegister => {
  //   const data: IAutoRegister = {
  //     ...dataSubmit,
  //     cpf: String(numberMask(dataSubmit.cpf)),
  //     codigo: methods.getValues('codigo'),
  //     dataNasc: formatDate(dataSubmit.dataNasc, 'YYYY-MM-DD'),
  //     emails: dataSubmit.emails,
  //     telefones: dataSubmit.telefones.map((telefone) => String(numberMask(telefone))),
  //     enderecos: [{
  //       ...dataSubmit.enderecos[0],
  //       cep: String(numberMask(dataSubmit.enderecos[0].cep)),
  //       munId: 2,
  //       codigoIbge: String(2211001)
  //     }]
  //   }

  //   return data
  // }

  const isArrayOfEmptyStrings = (array: string[]): boolean => {
    if (Array.isArray(array)) {
      return array.every((item) => item === '')
    }

    return false
  }

  const submitStep: SubmitHandler<any> = async (dataForm) => {
    if (requestEndpoint) return

    if (stepper.current?.currentStepIndex === steps.length) {
      // if (!methods.getValues('codigo') || isArrayOfEmptyStrings(methods.getValues('codigo'))) {
      //   toast.warning('Preencha todos os campos')
      //   return
      // }

      try {
        const validLastStep = await schemaValdation[schemaValdation.length - 1].validate(dataForm, { abortEarly: false })
      } catch (error: any) {
        if (error.inner) {
          const errors = {}
          error.inner.forEach((err) => {
            errors[err.path] = err.message
          })

          Object.keys(errors).forEach((fieldName) => {
            methods.setError(fieldName, { message: errors[fieldName] })
            methods.setFocus(fieldName)
          })
        }
        return
      }

      setRequestEndpoint(true)
      try {
        await dadosParaSalvar?.(dataForm)
      } catch (error: any) {
        Toastfy.error(error?.response?.data?.message ?? 'Erro ao realizar cadastro! Tente novamente.')
      } finally {
        setRequestEndpoint(false)
        // setButtonDisabled(true)
        // methods.reset()
      }

      return
    }

    if (stepper.current) {
      const isValid = await methods.trigger()
      if (!isValid) {
        return
      }

      if (stepper.current?.currentStepIndex != null) {
        const indexToValidate = stepper.current?.currentStepIndex - 1

        if (typeof callBackValidation?.[indexToValidate] === 'function') {
          setRequestEndpoint(true)
          try {
            const isValid = await callBackValidation?.[indexToValidate]?.(dataForm)

            setRequestEndpoint(false)

            if (!isValid) {
              return
            }
          } catch (error) {
            setRequestEndpoint(false)
            Toastfy.error('Erro ao validar dados, favor tentar novamente!')
            return
          }
        }
      }

      if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
        stepper.current.goNext()
      } else {
        stepper.current.goto(1)
      }
      setStepCurrent(stepper.current.currentStepIndex)
      methods.setValue('stepperCurrent', stepper.current.currentStepIndex)

      if (stepper.current.currentStepIndex !== steps.length) {
        setCurrentSchema(schemaValdation[stepper.current.currentStepIndex - 1])
      }
    }
  }

  useEffect(() => {
    if (stepperRef.current) {
      loadStepper()
    }
  }, [])

  // useEffect(() => {
  //   const sendCodingEmail = async (): Promise<void> => {
  //     if (stepper.current?.currentStepIndex === steps.length && !codeSending) {
  //       const { getValues } = methods
  //       const email = getValues('email')
  //       const cpf = numberMask(getValues('cpf'))
  //       if (email && cpf) {
  //         try {
  //           const response = await ServiceAuthRegister.sendCode({ email, cpf })
  //           if (response.status === 201) {
  //             setCodeSending(true)
  //             toast.success('Código de validação enviado com sucesso!')
  //           }
  //         } catch (error) {
  //           toast.error('Erro ao enviar código de validação!')
  //         }
  //       }
  //     }
  //   }

  //   void sendCodingEmail()
  // }, [stepCurrent])

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_account_stepper'
    >
      <div className={`stepper stepper-pills ${className}`} id="kt_stepper_example_clickable">
        <div className="stepper-nav flex-center flex-wrap mb-10">
          {steps.map((step, index) => (
            <div key={index} className="stepper-item mx-8 my-4 current" data-kt-stepper-element="nav" data-kt-stepper-action="step">
              <div className='min-w-150px d-flex flex-column align-items-center gap-3 text-blue-medium'>
                <div className={`container-step--number ${index + 1 === stepCurrent ? 'step-active' : ''}`}>
                  <BiCheck className={`stepper-check text-success fas fa-check ${stepCurrent > index + 1 ? '' : 'd-none'}`} />
                  {/* <i className={`stepper-check text-success fas fa-check ${stepCurrent > index + 1 ? '' : 'd-none'}`}></i> */}
                  <span className={`stepper-number ${index + 1 === stepCurrent || stepCurrent < index + 1 ? '' : 'd-none'}`}>{index + 1}</span>
                </div>
                <div>
                  <h3 className='container-step--title text-blue-medium'>{step}</h3>
                </div>
              </div>
              {index + 1 !== steps.length && stepCurrent > index + 1 && <div className="stepper-line"></div>}
              {index + 1 !== steps.length && index + 1 >= stepCurrent && <div className="stepper-line-gray"></div>}
            </div>
          ))}
        </div>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(submitStep)} encType='multipart/form-data' autoComplete='off'>
            {children}

            <div className='d-flex flex-stack pt-10'>
              <div className='mr-2'>
                {exit && stepCurrent === 1 && (
                  <a className="btn btn-light-danger rounded-pill" onClick={prevStep}>
                    Sair
                  </a>
                )}

                {stepCurrent > 1 && (
                  <a className="btn btn-light-danger rounded-pill" onClick={prevStep}>
                    {getInternalizeText(systemMessages.keysMessages.Previous)}
                  </a>
                )}
              </div>

              <div>
                <ButtonMetronic isLoading={requestEndpoint} disabled={buttonDisabled} type='submit' variant='primary' rounded light className='bg-blue-primary text-white'>
                  {getInternalizeText(stepCurrent === steps.length ? systemMessages.keysMessages.Confirm : systemMessages.keysMessages.Next)}
                </ButtonMetronic>
              </div>
            </div>
          </form>
        </FormProvider>

      </div>
    </div>
  )
}

export default Stepper
