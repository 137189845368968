const processosPath = '/processos'

export const PROCESSOS_ROUTES = {
  1: `${processosPath}/inicio`,
  2: `${processosPath}/prefeitura`,
  3: `${processosPath}/novo-processo`,
  4: `${processosPath}/parametros-acesso`,
  5: `${processosPath}/gerenciar-informativos`,
  6: `${processosPath}/cadastro/tipo-requerimento`,
  7: `${processosPath}/cadastro/categoria`,
  8: `${processosPath}/cadastro/assunto`,
  9: `${processosPath}/parametros-acesso/cargos`,
  10: `${processosPath}/cadastro/departamentos`,
  11: `${processosPath}/cadastro/fluxo-requerimento`,
  12: `${processosPath}/cadastro/formularios-fluxos`,
  13: `${processosPath}/cadastro/formularios-requerimento`,
  14: `${processosPath}/cadastro`,
  15: `${processosPath}/parametros-acesso`,
  16: `${processosPath}/parametros-acesso/grupo-origens`,
  17: `${processosPath}/parametros-acesso/pessoa`,
  18: `${processosPath}/processos`
}

const plataformaPath = '/plataforma'

export const PLATAFORMA_ROUTES = {
  999: `${plataformaPath}/vinculos`,
  1000: `${plataformaPath}/empresa`,
  1001: `${plataformaPath}/prefeitura`,
  1002: `${plataformaPath}/adm`,

  1: `${plataformaPath}/vinculos/aprovacoes`,
  2: `${plataformaPath}/empresa/cadastro`,
  3: `${plataformaPath}/empresas-pendentes`,
  4: `${plataformaPath}/prefeitura/cadastro/empresa`,
  5: `${plataformaPath}/prefeitura/empresas`,
  6: `${plataformaPath}/cadastro/prefeitura`,
  7: `${plataformaPath}/clientes`,
  8: `${plataformaPath}/modulos`,
  11: `${plataformaPath}/vinculos/solicitacoes`,
  12: `${plataformaPath}/vinculos/area-vinculos`
}
