/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { AiOutlineUserSwitch } from 'react-icons/ai'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import { LiaExchangeAltSolid } from 'react-icons/lia'
import { TfiExchangeVertical } from 'react-icons/tfi'
import { Link, useNavigate } from 'react-router-dom'

import { toAbsoluteUrl, useDebounce } from '_metronic/helpers'
import { useAuth, type CityModel, type UserPersonModel } from 'app/modules/auth'
import { getCitysFromUser, getClientsFromUser, getProfilesFromUser } from 'app/modules/auth/core/_requests'
import { SelectCity } from 'app/modules/auth/SelectCity'
import ChangeData from 'app/modules/plataforma/modals/loggedUser/ChangeData'
import ChangePassword from 'app/modules/plataforma/modals/loggedUser/ChangePassword'
import Button from 'app/shared/components/Button/Button'
import OverlayTooltip from 'app/shared/components/OverlayTooltip/OverlayTooltip'
import ReactModal from 'app/shared/components/ReactModal/ReactModal'
import Stepper from 'app/shared/components/Stepper/Stepper'
import { validatePermission } from 'app/shared/helpers/permissions'
import Toastfy from 'app/shared/helpers/Toastfy/Toastfy'
import { slugify } from 'app/shared/helpers/utils'
import { cnpjMask, cpfCnpjMask, numberMask } from 'app/shared/helpers/valueMasks'
import { ServicePerson } from 'app/shared/services'
import { useUserStore } from 'app/store/Plataform/UserStore'
import clsx from 'clsx'
import * as Yup from 'yup'

import './styles.scss'
import Badge from '../Badge/Badge'

const schemasValidation = [
  Yup.object().shape({
    person: Yup.string().required('Pessoa é obrigatório')
  }),
  Yup.object().shape({
    city: Yup.string()
  })
]

const HeaderUser = (): JSX.Element => {
  const [
    person,
    citys,
    people,
    currentCity
  ] = useUserStore(state => [
    state.person,
    state.citys,
    state.people,
    state.currentCity
  ])
  const [showModal, setShowModal] = useState(false)
  const [peopleToShow] = useState<UserPersonModel[]>(people)
  const [cityToShow, setCityShow] = useState(citys)
  const { user } = useUserStore()
  const [isWantedChangeUser, setIsWantedChangeUser] = useState(false)
  const personObject = useRef<UserPersonModel | undefined>()
  const personToken = useRef('')
  const [searchTerm, setSearchTerm] = useState('')
  const [seacrhTermCity, setSeacrhTermCity] = useState('')
  const { saveAuth, auth, logout } = useAuth()
  const [openModalDataPersonal, setOpenModalDataPersonal] = useState<boolean>(false)
  const [openModalChangePassword, setOpenModalChangePassword] = useState<boolean>(false)

  const navigate = useNavigate()

  const searchCity = (cityToFilter: CityModel[]): CityModel[] => {
    if (seacrhTermCity) {
      const result = cityToFilter.filter((city) => {
        const { CLINOME } = city

        return CLINOME.toLowerCase().includes(seacrhTermCity.toLowerCase())
      })

      return result
    } else {
      return cityToShow
    }
  }

  const searchPeople = (peopleToFilter: UserPersonModel[]): UserPersonModel[] => {
    const filterCurrentPersonSelected = peopleToFilter.filter((person) => {
      const { PESSOA } = person
      const personId = PESSOA.PESID

      return personId !== personObject.current?.PESSOA.PESID
    })

    if (searchTerm) {
      const result = peopleToFilter.filter((person) => {
        const { PESSOA } = person
        const personName = PESSOA.JURIDICA?.JURFANTASIA ?? PESSOA.PESNOME
        const isNameFinded = personName.toLowerCase().includes(searchTerm.toLowerCase())
        const isCpfCnpjFinded = `${numberMask(searchTerm)}`.length > 0
          ? `${numberMask(PESSOA.CPF_CNPJ)}`.includes(numberMask(searchTerm) as string ?? 0)
          : false

        return isNameFinded || isCpfCnpjFinded
      })

      return result
    } else {
      return peopleToShow
    }
  }

  const previewUserAccess = async (data: any): Promise<boolean> => {
    try {
      const tokenAcess = (await ServicePerson.accessAtUser(data.person)).data.data.access_token

      // const { data: { data: cityData } } = await getCitysFromUser(tokenAcess)
      const { data: { data: cityData } } = await getClientsFromUser(tokenAcess)

      setCityShow(cityData)

      personToken.current = tokenAcess
    } catch (error: any) {
      Toastfy.error(error?.response?.data?.message ?? 'Ocorreu um erro ao selecionar o usuário!')
      return false
    }

    return true
  }

  const openCloseModal = (): void => {
    const isGonnaOpen = !showModal
    setShowModal(isGonnaOpen)

    if (isGonnaOpen) {
      setIsWantedChangeUser(isGonnaOpen)
    }
  }

  const SelectUser = (): JSX.Element => {
    const {
      setValue,
      watch,
      formState: {
        errors
      }
    } = useFormContext()

    const currentPerson = person?.PESSOA.PESID

    return <>

      <hr className='my-8' />

      <div className='h-200px overflow-auto'>
        {
          searchPeople(peopleToShow)
            .map((person) => {
              const { PESSOA } = person
              const personId = PESSOA.PESID

              if (person.PESSOA.PESID === currentPerson) return null

              return (
                <div
                  key={personId}
                  className={
                    clsx('cursor-pointer card h-40px w-100 m-auto mb-5 row justify-content-center', {
                      'table-active': watch('person') === personId
                    })
                  }
                  onClick={() => {
                    setValue('person', personId)
                    personObject.current = person
                  }}
                  style={{ filter: watch('person') === personId ? 'brightness(.9)' : '' }}
                >
                  <div className='col-md-6 text-center'>{PESSOA.PESSOA_NOME}</div>
                  <div className='col-md-6 text-center'>{cpfCnpjMask(PESSOA.CPF_CNPJ ?? '')}</div>
                </div>
              )
            })
        }
      </div>

      {
        errors?.person && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <>
                {
                  errors?.person?.message
                }
              </>
            </div>
          </div>
        )
      }

      {/* <Table
        striped={false}
        bordered
        hover
        className="table table-row-bordered table-row-gray-300 gy-7 gs-5 text-center align-middle"
      >
        <thead>
          <tr>
            <th>Pessoa</th>
            <th>CPF/CNPJ</th>
          </tr>
        </thead>
        <tbody>
          {
            searchPeople(peopleToShow).map((person) => {
              const { PESSOA } = person

              const personId = PESSOA.PESID

              return (
                <tr
                  key={personId}
                  className={clsx('cursor-pointer', {
                    'table-active': watch('person') === personId
                  })}
                  onClick={() => {
                    setValue('person', personId)
                    personObject.current = person
                  }}
                >
                  <td>{PESSOA.PESSOA_NOME}</td>
                  <td>{cpfCnpjMask(PESSOA.CPF_CNPJ)}</td>
                </tr>
              )
            })
          }
        </tbody>
      </Table> */}
    </>
  }

  const SelectCityFromUser = (): JSX.Element => {
    const {
      setValue, watch, formState: {
        errors
      }
    } = useFormContext()

    return (
      <>

        {
          errors?.person && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <>
                  {
                    errors?.person?.message
                  }
                </>
              </div>
            </div>
          )
        }

        <div className='card card-flush'>

          <div className='card-body pt-5'>

            <h5 className='d-flex justify-content-center'>Municípios</h5>

            <hr className='my-8' />

            <div className='h-200px overflow-auto'>
              {
                searchCity(cityToShow).map((city) => {
                  const tenantCity = city.CLITENANT

                  return (
                    <div
                      key={tenantCity}
                      onDoubleClick={() => { setValue('city', tenantCity) }}
                      onClick={() => { setValue('city', tenantCity) }}
                      className={
                        clsx('cursor-pointer card h-40px mb-5 d-flex align-items-center justify-content-center', {
                          'table-active': watch('city') === tenantCity
                        })
                      }
                      style={{ filter: watch('city') === tenantCity ? 'brightness(.9)' : '' }}
                    >
                      {city.CLINOME}
                    </div>
                  )
                })
              }
            </div>

            {/* <Table
              striped={false}
              bordered
              hover
              className="table table-row-bordered table-row-gray-300 gy-7 gs-5 text-center align-middle"
            >
              <thead>
                <tr>
                  <th>Município</th>
                </tr>
              </thead>
              <tbody>
                {
                  searchCity(cityToShow).map((city) => {
                    const tenantCity = city.TENANT

                    return (

                      <tr
                        key={tenantCity}
                        className={clsx('cursor-pointer', {
                          'table-active': watch('city') === tenantCity
                        })}
                        onClick={() => { setValue('city', tenantCity) }}
                      >
                        <td>{city.NOME}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table> */}

          </div>

        </div>

      </>
    )
  }

  const wantToBackToOriginalUser = async (): Promise<void> => {
    try {
      const token = (await ServicePerson.accessAtMe()).data.data.access_token

      saveAuth({
        token
      })
    } catch (error) {
      Toastfy.error('Ocorreu um erro ao selecionar o usuário!')
    } finally {
      navigate('/dashboard')
    }
  }

  const selectUser = async (data: {
    person: string
    city: string
  }): Promise<void> => {
    const { city: citySelected } = data

    const token = personToken.current

    const backupAuth = { ...auth }

    try {
      saveAuth({
        token,
        city_selected: citySelected
      })

      openCloseModal()
    } catch (error) {
      Toastfy.error('Ocorreu um erro ao selecionar o usuário!')

      saveAuth({
        token: backupAuth.token!,
        city_selected: backupAuth.city_selected
      })
    } finally {
      navigate('/dashboard')
    }
  }

  const MenuItemLink = ({
    title,
    linkChildren
  }: {
    title: string
    linkChildren: {
      to: string
      onClick?: () => void
      className?: string
    }
  }): JSX.Element => {
    return (
      <div className='menu-item px-5'>
        <Link
          className='menu-link px-5'
          {...linkChildren}
        >
          {title}
        </Link>
      </div>
    )
  }

  const menusSchema = [
    {
      title: 'Meus Dados',
      linkChildren: {
        to: '#',
        onClick: () => { setOpenModalDataPersonal(true) }
      },
      permission: null
    },
    {
      title: 'Alternar Perfil',
      linkChildren: {
        to: '#',
        onClick: openCloseModal
      },
      permission: null
    },
    {
      title: 'Alterar Senha',
      linkChildren: {
        to: '#',
        onClick: () => { setOpenModalChangePassword(true) }
      },
      permission: null
    },
    {
      title: 'Manual',
      linkChildren: {
        to: '#',
        onClick: () => {
          window.open('https://s3.etheriumtech.com.br:9000/novaplataforma/Manual%20Plataforma.pdf', '_blank')
        }
      },
      permission: null
    }
  ]

  return (
    <>
      <div
        className='cursor-pointer symbol d-flex gap-3 p-4'
        data-kt-menu-trigger='hover'
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-start'
      >
        <img
          src={toAbsoluteUrl(user?.USUIMGPATH !== null ? `${user?.USUIMGPATH}` : '/media/avatars/blank.png')}
          className='border-radius-lg w-50px h-50px' alt="Perfil" title='Perfil'
        />
      </div>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
        data-kt-menu='true'
      >

        {
          menusSchema.map((menu) => {
            if (!validatePermission(menu.permission)) return null

            return (
              <div
                key={slugify(menu.title)}
                id={`menu-item-${slugify(menu.title)}`}
              >
                <MenuItemLink
                  title={menu.title}
                  linkChildren={menu.linkChildren}
                />
              </div>
            )
          })
        }

        <div className='separator my-2'></div>

        <div className='menu-item px-5 my-1'>
          <a onClick={() => {
            logout()
            document.location.reload()
          }} className='menu-link px-5'>
            Sair
          </a>
        </div>
        {/* end::Menu item */}
      </div>

      <ReactModal
        show={showModal}
        headerName='Selecione o Usuário'
        onClose={openCloseModal}
        modalFooter={
          <>
            <Button
              type='button'
              rounded={true}
              variant='light'
              onClick={openCloseModal}
            >
              Voltar
            </Button>
          </>
        }
      >

        <div className='d-flex justify-content-center gap-2 mb-2'>
          <div className='d-flex justify-content-center align-items-center cursor-pointer'>
            <OverlayTooltip message={!isWantedChangeUser ? 'Modificar Usuário' : 'Modificar Cidade'}>
              <div onClick={() => { setIsWantedChangeUser(!isWantedChangeUser) }}>
                <Badge modeDark={false} rounded backgroundColor='#1351b4'>
                  <div className='fw-bolder fs-5 mx-2'>
                    {person?.PESSOA.JURIDICA?.JURFANTASIA ?? person?.PESSOA.PESNOME}
                  </div>
                  <span onClick={() => { setIsWantedChangeUser(!isWantedChangeUser) }}>
                    {!isWantedChangeUser
                      ? <LiaExchangeAltSolid className='icon' style={{ transform: 'rotate(270deg)' }} />
                      : <IoMdCloseCircleOutline className='icon' />
                    }
                  </span>
                </Badge>
                <div className='fw-bolder fs-5 text-dark ms-4 mt-1'>{currentCity?.CLINOME}</div>
              </div>
            </OverlayTooltip>
          </div>

          <div>
            {person?.PERSONIFICADO === true && (
              <OverlayTooltip message="Voltar para o meu usuário">
                <span
                  className="badge rounded-pill text-bg-secondary me-2 cursor-pointer"
                  onClick={() => { void wantToBackToOriginalUser() }}
                >
                  <AiOutlineUserSwitch className='icon' /> Voltar ao usuário principal
                </span>
              </OverlayTooltip>
            )}
          </div>
        </div >

        {
          !isWantedChangeUser
            ? <>
              <SelectCity onClickButton={openCloseModal} />
            </>
            : <>
              <Stepper
                className='w-100'
                steps={['Pessoa', 'Cidade']}
                schemaValdation={schemasValidation}
                callBackValidation={{
                  0: previewUserAccess
                }}
                dadosParaSalvar={selectUser}
              >
                <div className='current flex-column' data-kt-stepper-element='content'>

                  <div className='card card-flush'>

                    <div className='card-body pt-5'>

                      <div className='fv-row mb-8'>
                        <input
                          type='text'
                          placeholder="Digite para pesquisar..."
                          className='form-control bg-transparent'
                          value={searchTerm}
                          onChange={(e) => { setSearchTerm(e.target.value) }}
                        />
                      </div>

                      <SelectUser />
                    </div>

                  </div>

                </div>

                <div className='flex-column' data-kt-stepper-element='content'>

                  <div className='card card-flush'>

                    <div className='card-body pt-5'>

                      <div className='fv-row mb-8'>
                        <input
                          type='text'
                          placeholder="Digite para pesquisar..."
                          className='form-control bg-transparent'
                          value={seacrhTermCity}
                          onChange={(e) => { setSeacrhTermCity(e.target.value) }}
                        />
                      </div>

                      <SelectCityFromUser />

                    </div>

                  </div>

                </div>

              </Stepper>
            </>
        }
      </ReactModal>

      <ReactModal
        show={openModalDataPersonal}
        headerName='Manutenção de cadastro pessoal'
        size='xl'
        onClose={() => { setOpenModalDataPersonal(false) }}
      >
        <ChangeData onClose={() => { setOpenModalDataPersonal(false) }} />
      </ReactModal>

      <ReactModal
        show={openModalChangePassword}
        headerName='Alterar Senha'
        size='xl'
        onClose={() => { setOpenModalChangePassword(false) }}
      >
        <ChangePassword onClose={() => { setOpenModalChangePassword(false) }} />
      </ReactModal>
    </>
  )
}

export default HeaderUser
