/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useMutation, useQuery } from 'react-query'

import { type AxiosInstance } from 'axios'

export function useReactQuery<Data = any, Error = any> (
  key: string,
  api: AxiosInstance,
  params?: any,
  method?: 'get' | 'post' | 'put' | 'patch' | 'delete'
) {
  const { data, error, isLoading, refetch } = useQuery<Data, Error>(
    key,
    async () => {
      const response = await api.get(key, params)
      return response.data
    }
  )

  const mutation = useMutation(key, {
    onSuccess: () => {
      void refetch()
    }
  })

  return { data, error, isLoading, mutation }
}
