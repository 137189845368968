/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { type IDefaultRequest, type IDefaultRequestObject } from 'app/shared/types/common'
import { type IListApensaveis, type IApensamento } from 'app/shared/types/processos/apensamento'
import { type KeyedMutator } from 'swr'

import { apiProcessos } from '../api'

const endpointApensamento = '/demandas/apensamento'

const GetApensados = (REQID?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ ...params })
  return useSwr<IDefaultRequestObject<IApensamento>>(
    `${endpointApensamento}/listar/${REQID}?${queryParams}`,
    apiProcessos
  )
}

const GetApensaveis = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IListApensaveis>>(
    `demandas/apensaveis?${queryParams}`,
    apiProcessos
  )
}

export const createApensamento = async (
  REQID: number,
  payload: { idRequerimentosApensados: number[], justificativa: string },
  mutate: KeyedMutator<IDefaultRequestObject<IApensamento>>
) => {
  const response = await apiProcessos.post(`${endpointApensamento}/gerenciar/${REQID}`, payload)
  if (response.status === 200) await mutate()
  return response
}

export default {
  GetApensados,
  GetApensaveis,
  createApensamento
}
