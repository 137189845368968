import { systemMessages } from '_metronic/i18n/systemMessages'
import { getInternalizeText } from 'app/shared/helpers/utils'
import { validateEmail } from 'app/shared/helpers/yupValidations'
import * as Yup from 'yup'

import { type ILoggedUser } from './loggedUserInterfaces'

const validateMaxImageSize = (file: any): boolean => {
  if (!file) return true
  if (file) {
    const maxSizeInBytes = 1024 * 1024
    if (file.size <= maxSizeInBytes) {
      return true
    }
  }
  return false
}

const validateImageMimeType = (file: any): boolean => {
  if (!file) return true
  if (file) {
    const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/jpg']
    if (allowedMimeTypes.includes(file.type)) {
      return true
    }
  }
  return false
}

export const schemaLoggedUser = Yup.object().shape({
  cpf: Yup.string().required(getInternalizeText(systemMessages.keysMessages.AuthValidationRequired, { name: 'CPF' })).matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, getInternalizeText(systemMessages.keysMessages.AuthValidationInvalid, { name: 'CPF' })),
  nome: Yup.string().required(getInternalizeText(systemMessages.keysMessages.AuthValidationRequired, { name: 'Nome' })),
  imagem: Yup.mixed().nullable().test('types-valid', 'Tipo de arquivo inválido', validateImageMimeType)
    .test('max-size', 'Tamanho superior de 1MB', validateMaxImageSize),

  // Contato
  email: Yup.string()
    .required(getInternalizeText(systemMessages.keysMessages.AuthValidationRequired, { name: 'E-mail' }))
    .test(validateEmail()),
  celular: Yup.string().required(getInternalizeText(systemMessages.keysMessages.AuthValidationRequired, { name: 'Celular' })).matches(/^\(\d{2}\) \d{5}-\d{4}$/, getInternalizeText(systemMessages.keysMessages.AuthValidationInvalid, { name: 'Celular' })),
  telefone: Yup.string().test('telefone-requerid', 'Telefone inválido', function (value) {
    const { telefone } = this.parent
    if (telefone || telefone.length > 0) {
      return Yup.string().matches(/^\(\d{2}\) \d{4}-\d{4}$/, getInternalizeText(systemMessages.keysMessages.AuthValidationInvalid, { name: 'Telefone' })).notRequired().nullable().isValidSync(value)
    }
    return true
  }),

  // Endereço
  cep: Yup.string().when('cep-vadid', (cep, field) => cep.length > 1 ? field.matches(/^\d{5}-\d{3}$/, getInternalizeText(systemMessages.keysMessages.AuthValidationInvalid, { name: 'CEP' })) : field),
  endereco: Yup.string().test('endereco-required', 'Endereço é obrigatório', function (value) {
    const { cep } = this.parent
    if (cep && cep.length > 1) {
      return Yup.string().required().isValidSync(value)
    }
    return true
  }),
  numero: Yup.string().test('numero-required', 'Número é obrigatório', function (value) {
    const { cep } = this.parent
    if (cep && cep.length > 1) {
      return Yup.string().required().isValidSync(value)
    }
    return true
  }),
  bairro: Yup.string().test('bairro-required', 'Bairro é obrigatório', function (value) {
    const { cep } = this.parent
    if (cep && cep.length > 1) {
      return Yup.string().required().isValidSync(value)
    }
    return true
  }),
  cidade: Yup.string().when('cep-valid', (cep, field) => cep.length > 1 ? field.required('Cidade é obrigatório') : field),
  uf: Yup.string().when('cep-valid', (cep, field) => cep.length > 1 ? field.required('Estado é obrigatório') : field),
  complemento: Yup.string().nullable().test('complement-invalid', 'Mínimo de 2 caracteres', function (value) {
    const { complemento } = this.parent
    if (complemento && complemento.length >= 1) {
      return Yup.string().min(2, 'Mínimo de 2 caracteres').isValidSync(value)
    }
    return true
  }),
  ibge: Yup.string().notRequired().nullable(),
  enderecoPrincipal: Yup.boolean(),
  PESENDID: Yup.string(),
  unico: Yup.boolean().nullable()
}) as Yup.ObjectSchema<ILoggedUser>

export const initValuesLoggedUser = {
  cpf: '',
  nome: '',
  imagem: '',

  email: '',
  celular: '',
  telefone: '',

  cep: '',
  endereco: '',
  numero: '',
  bairro: '',
  cidade: '',
  uf: '',
  complemento: '',
  ibge: '',
  enderecoPrincipal: false,
  PESENDID: '',
  unico: false
}
