import { createRoot } from 'react-dom/client'
// Axios
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ToastContainer } from 'react-toastify'

// Apps

import { GoogleOAuthProvider } from '@react-oauth/google'
import { ENV_VARS } from 'app/shared/helpers/envs'
import axios from 'axios'
import { Chart, registerables } from 'chart.js'

// eslint-disable-next-line import-helpers/order-imports
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'

/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import { AuthProvider, setupAxios } from './app/modules/auth'
import { AppRoutes } from './app/routing/AppRoutes'

import 'react-toastify/dist/ReactToastify.css'

// Estilo global
import './app/assets/styles/global.scss'

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container != null) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <MetronicI18nProvider>
        <GoogleOAuthProvider clientId={ENV_VARS.CLIENT_ID_GOOGLE!}>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </GoogleOAuthProvider>
      </MetronicI18nProvider>
      <ReactQueryDevtools initialIsOpen={true} />
    </QueryClientProvider>
  )
}
