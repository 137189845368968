/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/return-await */
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { type IDefaultRequest } from 'app/shared/types/common'
import { type AxiosResponse } from 'axios'
import { type KeyedMutator } from 'swr'

import { apiProcessos } from '../api'

export interface ISubjectType {
  ASSID: number
  ASSDESCRICAO: string
  ASSSTATUS: boolean
}

export interface ISubjectRequest {
  descricao: string
  status: number
}

const endpointSubject = '/assunto'

const GetSubjects = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<ISubjectType>>(
    `${endpointSubject}/list-all?${queryParams}`,
    apiProcessos
  )
}

const createdSubject = async (
  payload: { descricao: string, status: number },
  mutate: KeyedMutator<IDefaultRequest<ISubjectType>>
) => {
  const response = await apiProcessos.post(`${endpointSubject}/create`, payload)
  if (response.status === 200) await mutate()
  return response
}

const updatedCategory = async (
  id: number,
  payload: { descricao: string, status: number },
  mutate: KeyedMutator<IDefaultRequest<ISubjectType>>
) => {
  const response = await apiProcessos.put(`${endpointSubject}/update/${id}`, payload)
  if (response.status === 200) await mutate()
  return response
}

export const getSubjects = async (): Promise<AxiosResponse<IDefaultRequest<ISubjectType>>> => {
  return await apiProcessos.get('assunto/list-all')
}

export const editSubject = async (id: number, dataEdit: ISubjectRequest): Promise<AxiosResponse<IDefaultRequest<any>>> => {
  return await apiProcessos.put(`assunto/update/${id}`, dataEdit)
}

export const createSubject = async (dataEdit: ISubjectRequest): Promise<AxiosResponse<IDefaultRequest<any>>> => {
  return await apiProcessos.post('assunto/create', dataEdit)
}

export default {
  GetSubjects,
  createdSubject,
  updatedCategory,
  getSubjects,
  editSubject,
  createSubject
}
