/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { type IEditFluxoRequerimento } from 'app/modules/processos/pages/fluxoRequerimento/fluxoRequerimento'
import { type ICreateStepFluxo } from 'app/modules/processos/pages/registerFluxo/core/interfaces'
import { serializeQueryParams } from 'app/shared/helpers/utils'
import { useSwr } from 'app/shared/hooks/useSwr'
import { type IDefaultRequest } from 'app/shared/types/common'
import { type IFormsCompleteds } from 'app/shared/types/processos/accessPrefeitura/demandaById'
import { type IListGroupsFluxo, type ICreateFluxoRequerimento, type listGroupsFluxos, type IListFormsFluxo, type IListStepsFluxo, type IListPDF } from 'app/shared/types/processos/createFluxo/createFluxo'
import { type KeyedMutator } from 'swr'

import { apiProcessos, apiServerPlataforma } from '../../api'

const endpointGroupFluxo = '/fluxo'
const endpointFormsFluxo = '/formulario'
const endpointGroupsFluxo = '/grupo'
const endpointRequerimento = '/requerimento'

const GetListGroupsFluxo = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<listGroupsFluxos>>(
    `${endpointGroupFluxo}/list-all?${queryParams}`,
    apiProcessos
  )
}

const GetListFormsFluxo = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IListFormsFluxo>>(
    `${endpointFormsFluxo}/list-all?${queryParams}`,
    apiProcessos
  )
}

const GetListGroupFluxo = (page?: number, params: object = {}) => {
  const queryParams = serializeQueryParams({ page, ...params })
  return useSwr<IDefaultRequest<IListGroupsFluxo>>(
    `${endpointGroupsFluxo}/list-all?${queryParams}`,
    apiProcessos
  )
}

const getListGroupsFluxo = async (tipoRequerimentoId: string) => {
  const response = await apiServerPlataforma.get(`${endpointGroupFluxo}/list-all?tipoRequerimentoId=${tipoRequerimentoId}`)
  return response
}

const createFluxoRequerimento = async (
  payload: ICreateFluxoRequerimento,
  mutate: KeyedMutator<IDefaultRequest<listGroupsFluxos>>
) => {
  const response = await apiProcessos.post(`${endpointGroupFluxo}/create`, payload)
  if (response.status === 200) void mutate()
  return response
}

const updateFluxoRequerimento = async (
  id: number,
  payload: IEditFluxoRequerimento,
  mutate: KeyedMutator<IDefaultRequest<listGroupsFluxos>>
) => {
  const response = await apiProcessos.put(`${endpointGroupFluxo}/update/${id}`, payload)
  if (response.status === 200) void mutate()
  return response
}

const updateStatusRequerimento = async (
  id: number,
  mutate: KeyedMutator<IDefaultRequest<listGroupsFluxos>>
) => {
  const response = await apiProcessos.patch(`${endpointGroupFluxo}/change-status/${id}`)
  if (response.status === 200) void mutate()
  return response
}

const createFluxo = async (
  fluxoId: number,
  payload: IFormsCompleteds[]
) => {
  const response = await apiProcessos.post(`${endpointRequerimento}/create`, { fluxoId, formulariosRespondidos: payload }, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

const createStepFluxo = async (
  FLUID: number,
  payload: ICreateStepFluxo[],
  mutate: KeyedMutator<IDefaultRequest<listGroupsFluxos>>
) => {
  const response = await apiProcessos.put(`${endpointGroupFluxo}/steps/editor/${FLUID}`, { steps: payload })
  if (response.status === 200) void mutate()
  return response
}

const GetStepsFluxoById = (FLUID: number) => {
  return useSwr<IDefaultRequest<IListStepsFluxo>>(
    `${endpointGroupFluxo}/steps/id/${FLUID}`,
    apiProcessos
  )
}

const GetFluxoById = (FLUID: number) => {
  return useSwr<IDefaultRequest<listGroupsFluxos>>(
    `${endpointGroupFluxo}/id/${FLUID}`,
    apiProcessos
  )
}

const GetPDFRequirement = (REQID: number) => {
  return useSwr<IDefaultRequest<IListPDF>>(
    `${endpointRequerimento}/pdf/id/${REQID}`,
    apiProcessos
  )
}

const getPDF = async (REQID: number) => {
  const response = await apiProcessos.get(`${endpointRequerimento}/pdf/id/${REQID}`)
  return response
}

export default {
  GetListGroupsFluxo,
  GetListFormsFluxo,
  GetListGroupFluxo,
  getListGroupsFluxo,
  createFluxoRequerimento,
  updateFluxoRequerimento,
  updateStatusRequerimento,
  createFluxo,
  createStepFluxo,
  GetStepsFluxoById,
  GetFluxoById,
  GetPDFRequirement,
  getPDF
}
