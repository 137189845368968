import Modal from 'react-bootstrap/Modal'

import { KTSVG } from '_metronic/helpers'

import ButtonMetronic from '../../Button/ButtonMetronic'

interface IModalConfirm {
  show: boolean
  children?: React.ReactNode
  isLoading?: boolean
  onClose: () => void
  onConfirm: () => void
}

const ModalConfirm = ({ show, children, onClose, isLoading, onConfirm }: IModalConfirm): JSX.Element => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      style={{ zIndex: 1061 }}
      centered
      size='sm'
    >
      <Modal.Body>
        <div className='d-flex flex-column align-items-center gap-5'>
          <KTSVG path="/media/icons/duotune/general/gen037.svg" className="svg-icon-muted svg-icon-7hx text-success" width='100px' height='100px' />
          <p>
            {children ?? 'Tem certeza que deseja aprovar?'}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-center'>
        <ButtonMetronic type='button' variant="danger" rounded onClick={onClose}>
          Fechar
        </ButtonMetronic>
        <ButtonMetronic type='button' variant="primary" rounded className='bg-blue-primary' onClick={onConfirm}>
          Confirmar {isLoading && <span className="ms-2 spinner-border spinner-border-sm align-middle"></span>}
        </ButtonMetronic>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalConfirm
