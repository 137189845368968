/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { BiSolidCloudUpload } from 'react-icons/bi'
import { BsCheckCircle, BsExclamationCircle } from 'react-icons/bs'
import { IoIosEyeOff, IoMdEye } from 'react-icons/io'
import { RiCloseCircleLine } from 'react-icons/ri'
import { toast } from 'react-toastify'

import { toAbsoluteUrl } from '_metronic/helpers'
import { systemMessages } from '_metronic/i18n/systemMessages'
import { getInternalizeText, validateDate } from 'app/shared/helpers/utils'
import { validateCpf } from 'app/shared/helpers/validateValues'
import { cellphoneMask, cpfCnpjMask, cpfMask, dateBrMask, numberMask } from 'app/shared/helpers/valueMasks'
import { ServicePerson } from 'app/shared/services'
import clsx from 'clsx'

const StepperPeronsalData = (): JSX.Element => {
  const [uploadAvatar, setUploadAvatar] = useState<File | null>(null)
  const [imagePreview, setImagePreview] = useState<string | null>(null)
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
  const [password, setPassword] = useState('')
  const { register, setValue, formState: { errors }, trigger, setError, watch } = useFormContext()

  const handleGetNameFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0]
    if (file) {
      setUploadAvatar(file)
      const maxSizeInBytes = 1024 * 1024
      if (file.size > maxSizeInBytes) {
        toast.warning('Tamanho superior à 1MB')
        setImagePreview(null)
        setUploadAvatar(null)
        return
      }

      const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/jpg']
      if (!allowedMimeTypes?.includes(file.type)) {
        toast.warning('Formato de imagem inválido')
        setImagePreview(null)
        setUploadAvatar(null)
        return
      }

      const formData = new FormData()
      formData.append('fotoPerfil', file)
      setValue('fotoPerfil', file)

      const reader = new FileReader()
      reader.onloadend = () => {
        setImagePreview(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleAlreadyExistsCPF = async (cpf: string): Promise<boolean> => {
    try {
      // const response = await ServicePerson.getUserByCpf(numberMask(cpf))
      const cpfMask = String(numberMask(cpf!))
      const response = await ServicePerson.verifyRegister({ cpf: cpfMask })
      if (response.status === 200 && response.data.data.CADASTROEXISTE) {
        toast.warning('CPF já cadastrado!')
        setError('cpf', { message: 'CPF já cadastrado!' })
        setValue('cpf', '')
        return true
      }
    } catch (error: any) {
      // toast.warning(error?.response?.message ?? 'Erro ao buscar CPF! Tente novamente.')
    }

    return false
  }

  const isEmailValid = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleAlreadyExistsEmail = async (email: string): Promise<boolean> => {
    if (!isEmailValid(email) && !email.includes('.com')) {
      return false
    }

    try {
      // const response = await ServicePerson.getEmailUser(email)
      const response = await ServicePerson.verifyRegister({ email })
      if (response.status === 200 && response.data.data.CADASTROEXISTE) {
        toast.warning('E-mail já cadastrado!')
        setError('email', { message: 'E-mail já cadastrado!' })
        setValue('email', '')
        return true
      }
    } catch (error: any) {
      // toast.warning(error?.response?.message ?? 'Erro ao buscar E-mail! Tente novamente.')
    }

    return false
  }

  const hasUppercase = /[A-Z]/.test(password)
  const hasLowercase = /[a-z]/.test(password)
  const hasNumber = /\d/.test(password)
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>_-]/.test(password)
  const hasEightChar = password.length >= 8

  useEffect(() => {
    if (hasUppercase && hasLowercase && hasNumber && hasSpecialChar && hasEightChar) {
      setValue('senhaOK', 'OK')
    } else {
      setValue('senhaOK', '')
    }
  }, [hasUppercase, hasLowercase, hasNumber, hasSpecialChar, hasEightChar])

  // useEffect(() => {
  //   setValue('fotoPerfil', uploadAvatar)
  //   if (uploadAvatar ?? imagePreview) {
  //     if (uploadAvatar?.size) {
  //       const maxSizeInBytes = 1024 * 1024
  //       if (uploadAvatar?.size > maxSizeInBytes) {
  //         toast.warning('Tamanho superior à 1MB')
  //         setImagePreview(null)
  //         setUploadAvatar(null)
  //       }
  //     }

  //     if (uploadAvatar?.type ?? imagePreview) {
  //       const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/jpg']
  //       if (!allowedMimeTypes?.includes(uploadAvatar?.type!)) {
  //         toast.warning('Formato de imagem inválido')
  //         setImagePreview(null)
  //         setUploadAvatar(null)
  //       }
  //     }
  //   }
  // }, [uploadAvatar, imagePreview])

  useEffect(() => {
    setValue('email', '')
    setValue('senha', '')
  }, [])

  return (
    <>
      <div className='w-100 mb-10'>
        <h1 className='text-dark fw-bolder fs-2 mb-5'>
          {getInternalizeText(systemMessages.keysMessages.AuthRegisterLabelInformationBasic)}
        </h1>

        <div className='row mb-4'>
          <div className='col-md-4'>
            <label className='form-label fs-6 fw-bolder text-dark required'>CPF</label>
            <input
              {...register('cpf')}
              type='text'
              placeholder='Ex. 000.000.000-00'
              className={clsx(
                'form-control form-control-solid',
                { 'is-invalid': errors.cpf },
                { 'is-valid': errors.cpf == null && watch('cpf') }
              )}
              maxLength={14}
              onBlur={(event) => { void handleAlreadyExistsCPF(event.target.value) }}
              onChange={(event) => {
                if (event.target.value.length >= 11) {
                  void handleAlreadyExistsCPF(event.target.value)
                }
                setValue('cpf', cpfMask(event.target.value), { shouldValidate: true, shouldTouch: true })
              }}
            />
            <div className='fv-plugins-message-container'>
              {errors.cpf && <span role='alert'>{errors.cpf.message as string}</span>}
            </div>
          </div>
          <div className='col-md-8'>
            <label className='form-label fs-6 fw-bolder text-dark required'>{getInternalizeText(systemMessages.keysMessages.AuthRegisterLabelName)}</label>
            <input
              {...register('nome')}
              type='text'
              placeholder={getInternalizeText(systemMessages.keysMessages.AuthLabelPlaceholderEnterYourName)}
              className={clsx(
                'form-control form-control-solid',
                { 'is-invalid': errors.nome },
                { 'is-valid': errors.nome == null && watch('nome') }
              )}
              onChange={(event) => { setValue('nome', event.target.value, { shouldValidate: true, shouldTouch: true }) }}
            />
            <div className='fv-plugins-message-container'>
              {errors.nome && <span role='alert'>{errors.nome.message as string}</span>}
            </div>
          </div>
        </div>

        <h1 className='text-dark fw-bolder fs-2 mb-5 mt-20'>
          {getInternalizeText(systemMessages.keysMessages.AuthRegisterLabelDataToContact)}
        </h1>

        <div className='row'>
          <div className='col-md-4'>
            <label className='form-label fs-6 fw-bolder text-dark required'>E-mail</label>
            <input
              {...register('email')}
              autoComplete='off'
              type='text'
              placeholder={getInternalizeText(systemMessages.keysMessages.AuthLabelPlaceholderEnterYourEmail)}
              className={clsx(
                'form-control form-control-solid',
                { 'is-invalid': errors?.email },
                { 'is-valid': errors?.email == null && watch('email') }
              )}
              onBlur={(event) => { void handleAlreadyExistsEmail(event.target.value) }}
              onChange={(event) => {
                void handleAlreadyExistsEmail(event.target.value)
                setValue('email', event.target.value, { shouldValidate: true, shouldTouch: true })
              }}
            />
            <div className='fv-plugins-message-container'>
              {errors?.email && <span role='alert'>{errors?.email.message as string}</span>}
            </div>
          </div>
          <div className='col-md-2'>
            <label className='form-label fs-6 fw-bolder text-dark required'>{getInternalizeText(systemMessages.keysMessages.AuthRegisterLabelCellPhone)}</label>
            <input
              {...register('celular')}
              type='text'
              placeholder={getInternalizeText(systemMessages.keysMessages.AuthLabelPlaceholderEnterYourCellPhone)}
              className={clsx(
                'form-control form-control-solid',
                { 'is-invalid': errors.celular },
                { 'is-valid': errors.celular == null && watch('celular') }
              )}
              onChange={(event) => { setValue('celular', cellphoneMask(event.target.value), { shouldValidate: true }) }}
            />
            <div className='fv-plugins-message-container'>
              {errors.celular && <span role='alert'>{errors.celular.message as string}</span>}
            </div>
          </div>
          <div className='col-md-2'>
            <label className='form-label fs-6 fw-bolder text-dark'>{getInternalizeText(systemMessages.keysMessages.AuthRegisterLabelCellPhoneFix)}</label>
            <input
              {...register('telefone')}
              type='text'
              placeholder={getInternalizeText(systemMessages.keysMessages.AuthLabelPlaceholderEnterYourCellPhoneFix)}
              className={clsx(
                'form-control form-control-solid',
                { 'is-invalid': errors.telefone },
                { 'is-valid': errors.telefone == null && watch('telefone') }
              )}
              onChange={(event) => { setValue('telefone', cellphoneMask(event.target.value), { shouldValidate: true }) }}
            />
            <div className='fv-plugins-message-container'>
              {errors.telefone && <span role='alert'>{errors.telefone.message as string}</span>}
            </div>
          </div>
          <div className='col-md-4 container-photo--profile'>
            <label className='form-label fs-6 fw-bolder text-dark'></label>
            <div className='w-100 d-flex justify-content-center mb-3'>
              {imagePreview
                ? (
                  <img src={imagePreview} className='stepper--data-personal-avatar' />
                  )
                : (
                  <img src={toAbsoluteUrl('/media/avatars/blank.png')} className='stepper--data-personal-avatar' />
                  )}
            </div>
            <label className='form-label fs-6 fw-bolder text-dark'>Foto de Perfil</label>

            {/* Mudando estilo do seletor de arquivos */}
            <label htmlFor="stepper--personal-data-inputfile" className='stepper--personal-data-file'>
              <BiSolidCloudUpload className='icon' />
              Selecione o arquivo
            </label>
            <input type="file" id='stepper--personal-data-inputfile' onChange={handleGetNameFile} />
            <small className='stepper--personal-data-name mt-2 d-flex justify-content-between'>
              {uploadAvatar && `${uploadAvatar?.name} - ${uploadAvatar?.size}KB`}
              {imagePreview && <RiCloseCircleLine className='icon cursor-pointer' onClick={() => { setUploadAvatar(null); setImagePreview(null) }} />}
            </small>

            <div className='fv-plugins-message-container'>
              {errors.imagem && <span role='alert'>{errors.imagem.message as string}</span>}
            </div>
            {/* Fim */}

            <small className='d-block mt-2'>
              {getInternalizeText(systemMessages.keysMessages.AuthRegisterLabelMaximumSize, { size: '1' })} <br />
              Tipos permitidos .JPG e .PNG
            </small>
          </div>
        </div>

        <h1 className='text-dark fw-bolder fs-2 mb-5 mt-15'>Segurança</h1>

        <div className='row'>
          {/* <div className='col-md-4'>
            <label className='form-label fs-6 fw-bolder text-dark required'>{getInternalizeText(systemMessages.keysMessages.AuthRegisterLabelBirthdate)}</label>
            <input
              {...register('dataNasc')}
              type='text'
              placeholder={getInternalizeText(systemMessages.keysMessages.AuthLabelPlaceholderEnterYourBirthdate)}
              className={clsx(
                'form-control form-control-solid',
                { 'is-invalid': errors.dataNasc },
                { 'is-valid': errors.dataNasc == null && watch('dataNasc') }
              )}
              onChange={(event) => {
                setValue('dataNasc', dateBrMask(event.target.value), { shouldValidate: true })
              }}
            />
            <div className='fv-plugins-message-container'>
              {errors.dataNasc && <span role='alert'>{errors.dataNasc.message as string}</span>}
            </div>
          </div> */}
          <div className='col-md-4'>
            <label className='form-label fs-6 fw-bolder text-dark required'>{getInternalizeText(systemMessages.keysMessages.AuthInputPassword)}</label>
            <div className='position-relative'>
              <input
                {...register('senha')}
                autoComplete='new-password'
                type={showNewPassword ? 'text' : 'password'}
                placeholder={getInternalizeText(systemMessages.keysMessages.AuthLabelPlaceholderEnterYourPassword)}
                minLength={8}
                className={clsx(
                  'form-control form-control-solid',
                  { 'is-invalid': errors.senha },
                  { 'is-valid': errors.senha == null && watch('senha') }
                )}
                onChange={(event) => {
                  setValue('senha', event.target.value, { shouldValidate: true, shouldTouch: true })
                  setPassword(event.target.value)
                }}
              />
              {showNewPassword
                ? (
                  <IoMdEye
                    className='icon position-absolute'
                    style={{ right: '37px', top: '50%', transform: 'translateY(-50%)' }}
                    onClick={() => { setShowNewPassword(!showNewPassword) }}
                  />
                  )
                : (
                  <IoIosEyeOff
                    className='icon position-absolute'
                    style={{ right: '37px', top: '50%', transform: 'translateY(-50%)' }}
                    onClick={() => { setShowNewPassword(!showNewPassword) }}
                  />
                  )}
            </div>
            <small className='mt-3 d-block'>Mínimo de 8 caracteres com letras maiúsculas, minúsculas, números e caracteres especiais</small>
            <div className='fv-plugins-message-container'>
              {errors.senha && <span role='alert'>{errors.senha.message as string}</span>}
            </div>
            <div className='container--force-password mt-4'>
              {password.length > 0 && (
                <p>Força da Senha:</p>
              )}
              <ul className='d-flex flex-column gap-5'>

                <li style={{ color: hasEightChar ? 'green' : 'red' }}>
                  {hasEightChar
                    ? <div className='d-flex gap-2 alig-items-center'><BsCheckCircle className='icon' /> 8 caracteres</div>
                    : <div className='d-flex gap-2 alig-items-center'><BsExclamationCircle className='icon' /> 8 caracteres</div>
                  }
                </li>
                <li style={{ color: hasUppercase ? 'green' : 'red' }}>
                  {hasUppercase
                    ? <div className='d-flex gap-2 alig-items-center'><BsCheckCircle className='icon' /> Letras maiúsculas</div>
                    : <div className='d-flex gap-2 alig-items-center'><BsExclamationCircle className='icon' /> Letras maiúsculas</div>
                  }
                </li>
                <li style={{ color: hasLowercase ? 'green' : 'red' }}>
                  {hasLowercase
                    ? <div className='d-flex gap-2 alig-items-center'><BsCheckCircle className='icon' /> Letras minúsculas</div>
                    : <div className='d-flex gap-2 alig-items-center'><BsExclamationCircle className='icon' /> Letras minúsculas</div>
                  }
                </li>
                <li style={{ color: hasNumber ? 'green' : 'red' }}>
                  {hasNumber
                    ? <div className='d-flex gap-2 alig-items-center'><BsCheckCircle className='icon' /> Números</div>
                    : <div className='d-flex gap-2 alig-items-center'><BsExclamationCircle className='icon' /> Números</div>
                  }
                </li>
                <li style={{ color: hasSpecialChar ? 'green' : 'red' }}>
                  {hasSpecialChar
                    ? <div className='d-flex gap-2 alig-items-center'><BsCheckCircle className='icon' /> Caracteres especiais</div>
                    : <div className='d-flex gap-2 alig-items-center'><BsExclamationCircle className='icon' /> Caracteres especiais</div>
                  }
                </li>
              </ul>
            </div>
          </div>
          <div className='col-md-4'>
            <label className='form-label fs-6 fw-bolder text-dark required'>Confirmar Senha</label>
            <div className='position-relative'>
              <input
                {...register('confirmarSenha')}
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder={getInternalizeText(systemMessages.keysMessages.AuthLabelPlaceholderEnterYourPassword)}
                className={clsx(
                  'form-control form-control-solid',
                  { 'is-invalid': errors.confirmarSenha },
                  { 'is-valid': errors.confirmarSenha == null && watch('confirmarSenha') }
                )}
              />
              {showConfirmPassword
                ? (
                  <IoMdEye
                    className='icon position-absolute'
                    style={{ right: '37px', top: '50%', transform: 'translateY(-50%)' }}
                    onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}
                  />
                  )
                : (
                  <IoIosEyeOff
                    className='icon position-absolute'
                    style={{ right: '37px', top: '50%', transform: 'translateY(-50%)' }}
                    onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}
                  />
                  )}
            </div>
            <div className='fv-plugins-message-container'>
              {errors.confirmarSenha && <span role='alert'>{errors.confirmarSenha.message as string}</span>}
            </div>
          </div>

        </div>

      </div>
    </>
  )
}

export default StepperPeronsalData
