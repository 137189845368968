/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { type IFormsCompleteds } from 'app/shared/types/processos/accessPrefeitura/demandaById'

import { apiProcessos } from '../../api'

const endpointForms = '/requerimento/tramitacao'

const createAnswersForm = async (
  REQID: number,
  payload: { formulariosRespondidos: IFormsCompleteds[] }
) => {
  const response = await apiProcessos.post(`${endpointForms}/responder/${REQID}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

export default {
  createAnswersForm
}
