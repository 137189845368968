interface IBadge {
  rounded: boolean
  modeDark: boolean
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
  backgroundColor?: string
  className?: string
  children: React.ReactNode
}

const Badge = ({ rounded, modeDark, variant = 'primary', backgroundColor, className, children }: IBadge): JSX.Element => {
  return (
    <span style={{ backgroundColor }} className={`badge badge-${modeDark ? 'light-' : ''}${variant} ${className} ${rounded ? 'rounded-pill' : ''}`}>{children}</span>
  )
}

export default Badge
